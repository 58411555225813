import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import translations from './services/translations.service';
import { NodeType } from '@vdi-helki/helki-node-management';

export interface StoreDevDataUpdate {
  dev_id: string;
  data: any;
}

export interface StoreHomeDataFieldUpdate {
  home_id: string;
  key: string;
  data: any;
}

export interface StoreDevDataFieldUpdate {
  dev_id: string;
  key: string;
  data: any;
}

export interface StoreDevNodeDataFieldUpdate {
  dev_id: string;
  type: NodeType;
  addr: number;
  key: string;
  data: any;
}

export interface StoreDevNodeDataSamplesUpdate {
  dev_id: string;
  type: NodeType;
  addr: number;
  year: number;
  month: number;
  samples: any[];
}

export interface StoreDevNode {
  dev_id: string;
  type: NodeType;
  addr: number;
}

export interface StoreDevNodeSamples {
  dev_id: string;
  type: NodeType;
  addr: number;
  year: number;
  month: number;
}

export interface UserRegisterData {
  email: string;
  password: string;
}

export interface PrivacyData {
  accepted: boolean;
  date: Date;
}

export interface NodeSelectData {
  homeId: string;
  devId: string;
  addr: number;
  type: NodeType;
}

export interface BrandingData {
  marcas: string;
  css: any;
  productos: any;
}

export interface DevicePreferences {
  htrSortMethod: string;
  scheduleMethod: ScheduleMethod;
}

export enum ScheduleMethod {
  HOUR = 0,
  HALF_HOUR = 1,
}

export interface HomePreferenceData {
  homeId: string;
  value: string;
}

Vue.use(Vuex);

function getMonthSampleKey(year: number, month: number) {
  return `${year}-${month}`;
}

function getDevData(devId: string, homes: any) {
  const homeList: any = Object.values(homes);
  let devData: any;
  for (let i = 0; i < homeList.length && !devData; i++) {
    devData = homeList[i].devs[devId];
  }
  return devData;
}

function isHomeConnected(home: any) {
  const devList: any = Object.values(home.devs);
  let connected = false;
  for (let i = 0; i < devList.length && !connected; i++) {
    connected = devList[i].connected === true;
  }
  return connected;
}

function isHomeAway(home: any) {
  const devList: any = Object.values(home.devs);
  let away = false;
  for (let i = 0; i < devList.length && !away; i++) {
    away = devList[i].away_status?.away === true;
  }
  return away;
}

function getDevHome(homes: any, devId: string) {
  return Object.values(homes).find((home: any) => {
    const foundDevice = !!home.devs[devId];
    return foundDevice;
  });
}

function getHomeGeoData(home: any) {
  const devList: any = Object.values(home.devs);
  let geoData;
  for (let i = 0; i < devList.length && !geoData; i++) {
    geoData = devList[i].geo_data;
  }
  return geoData;
}

function isWifiDevice(productId: string) {
  switch(productId.toUpperCase()) {
    case '021D':
    case '0132':
    case '0A31':
    case '0A34':
    case '0A39':
    case '0433':
    case '0435':
    case '0838':
      return true;
    default:
      return false;
  }
}

const store = new Vuex.Store({
  state: {
    interface: {
      maxItemsOnCarousel: 10,
      onlyReadyNodes: false,
    },
    errors: {
      unknown: 'UNKNOWN',
      connection: 'CONNECTION',
      dataUpdate: 'UPDATE',
      userRequest: 'REQUEST',
    },
    error: '',
    homePreferences: {},
    homesSortMethod: '',
    demo: false,
    awayOffsets: [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0],
    fahrenheitAwayOffsets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    serialid: '',
    mainSupportEmail: 'soporte@helki.com',
    brandingData: {},
    mainLogo: undefined,
    defaultLogo: '',
    logo: undefined,
    secondaryLogo: undefined,
    privacyData: {
      accepted: false,
      date: undefined,
    },
    userEmail: '',
    userRegisterData: undefined,
    nodeTypes: ['htr', 'acm', 'pmo'],
    htrModels: [],
    nodeModelLabels: [],
    acmModels: [],
    pmoModels: [{nombre_producto: 'Medidor'}],
    htrPriorities: ['low', 'medium', 'high'],
    resistorModes: {
      acm: [0, 1, 2, 3],
    },
    operationalModes: {
      acm: [0, 1],
    },
    sortMethods: ['id', 'name'],
    scheduleMethods: [ScheduleMethod.HOUR, ScheduleMethod.HALF_HOUR],
    nodeSortMethod: '',
    languages: Object.keys(translations.messages),
    currentLanguage: translations.locale,
    selectedNodes: {},
    showSidePanel: false,
    homes: {},
    qrActive: false,
  },
  mutations: {
    setMaxItemsOnCarousel: (state: any, maxItemsOnCarousel: number) => {
      state.interface.maxItemsOnCarousel = maxItemsOnCarousel;
    },
    setOnlyReadyNodes: (state: any, onlyReadyNodes: boolean) => {
      state.interface.onlyReadyNodes = onlyReadyNodes;
    },
    setHomes: (state: any, homes: any) => {
      const homeSet: any = {};
      homes.forEach((home: any) => {
        const devs: any = {};
        home.devs.forEach((device: any) => {
          devs[device.dev_id] = {
            connected: false,
            ...device,
            nodes: [],
            htr_system: {
              power_limit: {
                power_limit: '0',
              },
            },
            pmo_system: {
              main_circuit_pmos: [],
              max_power_config: {
                profiles: [],
                slots: [],
              },
            },
          };
        });
        homeSet[home.id] = {
          ...home,
          connected: true,
          devs,
          waitingForFirstUpdate: true,
          weather: {},
          isAway: false,
        };
        homeSet[home.id].geo_data = homeSet[home.id].geo_data || {};
      });
      state.homes = homeSet;
    },
    removeHome: (state: any, homeId: string) => {
      Vue.delete(state.homes, homeId);
    },
    setHomeName: (state: any, data: any) => {
      const homeIndex: any = Object.keys(state.homes).find((key) => key === data.homeId);
      state.homes[homeIndex].name = data.name;
    },
    removeDevice: (state: any, data: any) => {
      Vue.delete(state.homes[data.homeId].devs, data.devId);
    },
    addHome: (state: any, data: any) => {
      Vue.set(state.homes, data.homeId, data.homeData);
    },
    addDevice: (state: any, data: any) => {
      Vue.set(state.homes[data.homeId].devs, data.devId, data.devData);
    },
    setHtrModels: (state: any, models: any) => {
      state.htrModels = models;
    },
    setAcmModels: (state: any, models: any) => {
      state.acmModels = models;
    },
    setError: (state: any, error: string) => {
      state.error = error;
    },
    setHomesSortMethod: (state: any, sortMethod: string) => {
      state.homesSortMethod = sortMethod;
    },
    setHomePreferences: (state: any, homePreferences: DevicePreferences) => {
      state.homePreferences = homePreferences || {};
    },
    setHtrSystemSortMethod: (state: any, data: HomePreferenceData) => {
      if (!state.homePreferences[data.homeId]) {
        state.homePreferences[data.homeId] = {};
      }
      state.homePreferences[data.homeId].htrSortMethod = data.value;
      state.homePreferences = Object.assign({}, state.homePreferences);
    },
    setScheduleMethod: (state: any, data: HomePreferenceData) => {
      if (!state.homePreferences[data.homeId]) {
        state.homePreferences[data.homeId] = {};
      }
      state.homePreferences[data.homeId].scheduleMethod = data.value;
    },
    setValidSerialIds: (state: any, brandingData: any) => {
      state.validSerialIds = Object.keys(brandingData);
    },
    setSerialId: (state: any, serialid: string) => {
      state.serialid = serialid;
    },
    updateMainLogo: (state: any, mainLogo: string) => {
      state.mainLogo = mainLogo;
    },
    updateLogo: (state: any, logo: string) => {
      state.logo = logo;
    },
    updateSecondaryLogo: (state: any, secondaryLogo: string|undefined) => {
      state.secondaryLogo = secondaryLogo;
    },
    updateBrandingData: (state: any, brandingData: any) => {
      state.brandingData = brandingData;
      if (state.serialid === '') {
        state.serialid = `${(brandingData.marcas || brandingData.marca).substring(0, 2)}.00`;
      }
    },
    updateDefaultLogo: (state: any, defaultLogo: any) => {
      state.defaultLogo = defaultLogo.default;
    },
    setPrivacy: (state: any, privacyData: PrivacyData) => {
      state.privacyData = privacyData;
    },
    setUserEmail: (state: any, email: string) => {
      state.userEmail = email;
      state.demo = false;
    },
    setUserRegistrationData: (state: any, userRegisterData: UserRegisterData) => {
      state.userRegisterData = userRegisterData;
    },
    eraseUserRegistrationData: (state: any) => {
      state.userRegisterData = undefined;
    },
    setNodeSortMethod: (state: any, sortMethod: string) => {
      state.nodeSortMethod = sortMethod;
    },
    selectNode: (state: any, data: NodeSelectData) => {
      if (!state.selectedNodes[data.homeId]) {
        state.selectedNodes[data.homeId] = new Set();
      }
      state.selectedNodes[data.homeId]?.add(JSON.stringify({
        homeId: data.homeId,
        devId: data.devId,
        addr: data.addr,
        type: data.type,
      }));
      state.selectedNodes = Object.assign({}, state.selectedNodes);
    },
    deselectNode: (state: any, data: NodeSelectData) => {
      if (!state.selectedNodes[data.homeId]) {
        return;
      }
      state.selectedNodes[data.homeId]?.delete(JSON.stringify({
        homeId: data.homeId,
        devId: data.devId,
        addr: data.addr,
        type: data.type,
      }));
      if (state.selectedNodes[data.homeId]?.size === 0) {
        delete state.selectedNodes[data.homeId];
      }
      state.selectedNodes = Object.assign({}, state.selectedNodes);
    },
    deselectNodes: (state: any) => {
      state.selectedNodes = {};
    },
    setCurrentLanguage: (state: any, language: string) => {
      state.currentLanguage = language;
    },
    toggleSidePanel: (state: any) => {
      state.showSidePanel = !state.showSidePanel;
    },
    resetHomes: (state: any) => {
      state.homes = {};
    },
    updateDevData: (state: any, update: StoreDevDataUpdate) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      } else {
        Object.keys(update.data).forEach((updateKey: string) => {
          if (updateKey === 'nodes' && update.data.nodes.length > 0) {
            devData.nodes = update.data.nodes;
          } else {
            let updatedData;
            if (_.isObject(update.data[updateKey])) {
              updatedData = _.merge(devData[updateKey], update.data[updateKey]);
            } else {
              updatedData = update.data[updateKey];
            }
            devData[updateKey] = updatedData;
          }
        });
        // Update home data
        const home: any = getDevHome(state.homes, update.dev_id);
        home.connected = isHomeConnected(home);
        home.waitingForFirstUpdate = false;
        home.isAway = isHomeAway(home);
        home.devs[update.dev_id] = devData;
        home.geo_data = getHomeGeoData(home);
      }
    },
    updateDevDataField: (state: any, update: StoreDevDataFieldUpdate) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      devData[update.key] = update.data;
      // Update home data
      const home: any = getDevHome(state.homes, update.dev_id);
      home.connected = isHomeConnected(home);
      home.isAway = isHomeAway(home);
      home.devs[update.dev_id] = devData;
      home.geo_data = getHomeGeoData(home);
    },
    updateHomeDataField: (state: any, update: StoreHomeDataFieldUpdate) => {
      state.homes[update.home_id][update.key] = update.data;
    },
    patchDevNodes: (state: any, update: StoreDevDataUpdate) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      const remainingNodes = devData?.nodes.filter((nodeData: any) => {
        return !!update.data.find((currentNode: any) => currentNode.addr === nodeData.addr);
      });
      devData.nodes = _.merge(remainingNodes, update.data).map((nodeData: any) => {
        return {
          ...nodeData,
          status: nodeData.status || {},
          setup: nodeData.setup || {},
          version: nodeData.version || {},
          samples: nodeData.samples || {},
        };
      });
    },
    patchDevDataField: (state: any, update: StoreDevDataFieldUpdate) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      devData[update.key] = _.merge(devData[update.key], update.data);
    },
    updateDevNodeDataField: (state: any, update: StoreDevNodeDataFieldUpdate) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      const nodeIndex = devData?.nodes.findIndex((node: any) => {
        return node.type === update.type && node.addr === update.addr;
      });
      if (!devData?.nodes[nodeIndex]) {
        return;
      }
      devData.nodes[nodeIndex][update.key] = update.data;
    },
    patchDevNodeDataField: (state: any, update: StoreDevNodeDataFieldUpdate) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      const nodeIndex = devData?.nodes.findIndex((node: any) => {
        return node.type === update.type && node.addr === update.addr;
      });
      if (typeof update.data === 'object') {
        devData.nodes[nodeIndex][update.key] = {
          ...devData?.nodes[nodeIndex][update.key],
          ...update.data,
        };
      } else {
        devData.nodes[nodeIndex][update.key] = update.data;
      }
    },
    updateDevNodeMonthSamples: (state: any, update: StoreDevNodeDataSamplesUpdate) => {
      if (!update.year || !update.month) {
        return;
      }
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      const nodeIndex = devData?.nodes.findIndex((node: any) => {
        return node.type === update.type && node.addr === update.addr;
      });
      const key = getMonthSampleKey(update.year, update.month);
      devData.nodes[nodeIndex].samples =
        devData?.nodes[nodeIndex]?.samples || {};
      devData.nodes[nodeIndex].samples[key] = update.samples;
    },
    updateHtrModeAfterProgUpdate: (state: any, update: StoreDevNode) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      const nodeIndex = devData?.nodes.findIndex((node: any) => {
        return node.type === update.type && node.addr === update.addr;
      });
      if (devData?.nodes[nodeIndex]?.status.mode === 'modified_auto') {
        devData.nodes[nodeIndex].status.mode = 'auto';
      }
    },
    saveHtrLastOnMode: (state: any, update: StoreDevNode) => {
      const devData: any = getDevData(update.dev_id, state.homes);
      if (!devData) {
        return;
      }
      const nodeIndex = devData?.nodes.findIndex((node: any) => {
        return node.type === update.type && node.addr === update.addr;
      });
      const currentMode = devData?.nodes[nodeIndex]?.status.mode;
      devData.nodes[nodeIndex].lastOnMode = currentMode;
    },
  },
  actions: {
    toggleSidePanel({ commit }) {
      commit('toggleSidePanel');
    },
  },
  getters: {
    getHtrCurrentMode: (state: any) => (homeId: string, devId: string, addr: number) => {
      const nodeIndex = state.homes[homeId]?.devs[devId]?.nodes.findIndex((node: any) => {
        return node.type === 'htr' && node.addr === addr;
      });
      return state.homes[homeId]?.devs[devId]?.nodes[nodeIndex]?.status.mode;
    },
    getHtrLastOnMode: (state: any) => (homeId: string, devId: string, addr: number) => {
      const nodeIndex = state.homes[homeId]?.devs[devId]?.nodes.findIndex((node: any) => {
        return node.type === 'htr' && node.addr === addr;
      });
      return state.homes[homeId]?.devs[devId]?.nodes[nodeIndex]?.lastOnMode;
    },
    getHtrSetTemperature: (state: any) => (homeId: string, devId: string, addr: number) => {
      const nodeIndex = state.homes[homeId].devs[devId]?.nodes.findIndex((node: any) => {
        return node.type === 'htr' && node.addr === addr;
      });
      return state.homes[homeId]?.devs[devId]?.nodes[nodeIndex]?.status.stemp;
    },
    getHtrSetTemperatureUnits: (state: any) => (homeId: string, devId: string, addr: number) => {
      const nodeIndex = state.homes[homeId]?.devs[devId]?.nodes.findIndex((node: any) => {
        return node.type === 'htr' && node.addr === addr;
      });
      return state.homes[homeId]?.devs[devId]?.nodes[nodeIndex]?.status.units;
    },
    getNodeList: (state: any) => (homeId: string, devId: string) => {
      return state.homes[homeId]?.devs[devId]?.nodes;
    },
    getDevGeoData: (state: any) => (homeId: string, devId: string) => {
      return state.homes[homeId]?.devs[devId]?.geo_data;
    },
    getHomeGeoData: (state: any) => (homeId: string) => {
      return state.homes[homeId]?.geo_data;
    },
    getDevNodeMonthSamples: (state: any) => (homeId: string, devNodeSamplesData: StoreDevNodeSamples) => {
      const nodeIndex = state.homes[homeId]?.devs[devNodeSamplesData.dev_id]?.nodes.findIndex((node: any) => {
        return node.type === devNodeSamplesData.type && node.addr === devNodeSamplesData.addr;
      });
      const key = getMonthSampleKey(devNodeSamplesData.year, devNodeSamplesData.month);
      return state.homes[homeId]?.devs[devNodeSamplesData.dev_id]?.nodes[nodeIndex]?.samples ?
        state.homes[homeId]?.devs[devNodeSamplesData.dev_id]?.nodes[nodeIndex]?.samples[key] : undefined;
    },
    getHomeHashMap: (state: any) => () => {
      return state.homes;
    },
    getHomeList: (state: any) => (sortMethod: string) => {
      const homeList = Object.keys(state.homes).map((id: string) => state.homes[id]);
      if (sortMethod === 'name') {
        homeList.sort((home1: any, home2: any) => home1.name > home2.name ? 1 : -1);
      } else {
        homeList.sort((home1: any, home2: any) => home1.id > home2.id ? 1 : -1);
      }
      return homeList;
    },
    getNodeCount: (state: any) => (homeId: string, nodeType: string) => {
      const devList: any[] = Object.values(state.homes[homeId]?.devs || {});
      if (devList.length === 0) {
        return 0;
      }
      let nodeCount = 0;
      devList.forEach((dev: any) => {
        const nodes = dev.nodes.filter((node: any) => {
          return node.type === nodeType;
        });
        nodeCount += nodes.length;
      });
      return nodeCount;
    },
    getInstalledNodeCount: (state: any) => (homeId: string, nodeType: string) => {
      const devList: any[] = Object.values(state.homes[homeId]?.devs || {});
      if (devList.length === 0) {
        return 0;
      }
      let nodeCount = 0;
      devList.forEach((dev: any) => {
        const nodes = dev.nodes.filter((node: any) => {
          return node.type === nodeType && node.installed === true;
        });
        nodeCount += nodes.length;
      });
      return nodeCount;
    },
    getNode: (state: any) => (homeId: string, devId: string, addr: number) => {
      if (!state.homes[homeId] || !state.homes[homeId].devs[devId]) {
        return {};
      }
      return state.homes[homeId]?.devs[devId]?.nodes.find((node: any) => {
        return Number(node.addr) === Number(addr);
      });
    },
    getDevNodesByType: (state: any) => (homeId: string, devId: string, options: any) => {
      const deviceData = state.homes[homeId]?.devs[devId];
      const nodes: any = (state.homes[homeId]?.devs[devId]?.nodes || []).filter((node: any) => {
        if (options.nodeType !== undefined) {
          return node.type === options.nodeType;
        } else if (options.nodeTypes !== undefined) {
          return options.nodeTypes.includes(node.type);
        } else {
          return false;
        }
      }).map((currentNode: any) => {
        const currentNodeData: any = currentNode;
        currentNodeData.devId = devId;
        if (isWifiDevice(deviceData.product_id)) {
          currentNodeData.name = deviceData.name;
        }
        return currentNodeData;
      });

      if (options.sortMethod !== 'name') {
        return nodes;
      }
      nodes.sort((node1: any, node2: any) => {
        return node1.name > node2.name ? 1 : -1;
      });
      return nodes;
    },
    getNodesByType: (state: any) => (homeId: string, options: any) => {
      const devList: any[] = Object.values(state.homes[homeId]?.devs || {});
      if (devList.length === 0) {
        return [];
      }
      let nodes: any = [];

      devList.forEach((dev: any) => {
        const devNodes: any = (dev.nodes || []).filter((node: any) => {
          if (options.nodeType !== undefined) {
            return node.type === options.nodeType;
          } else if (options.nodeTypes !== undefined) {
            return options.nodeTypes.includes(node.type);
          } else {
            return false;
          }
        }).map((currentNode: any) => {
          const currentNodeData: any = currentNode;
          currentNodeData.devId = dev.dev_id;
          return currentNodeData;
        });

        nodes = nodes.concat(devNodes);
      });

      if (options.sortMethod !== 'name') {

        return nodes;
      }
      nodes.sort((node1: any, node2: any) => {
        return node1.name > node2.name ? 1 : -1;
      });
      return nodes;
    },
    getPmoPowerLimit: (state: any) => (homeId: string, devId: string, addr: number) => {
      const DEFAULT_POWER_LIMIT = 15000;
      try {
        const nodes = state.homes[homeId]?.devs[devId]?.nodes;
        const pmo = nodes?.find((node: any) => {
          return node.type === 'pmo' && node.addr === addr;
        });
        return Number(pmo?.power_limit?.power_limit) || DEFAULT_POWER_LIMIT;
      } catch (e) {
        return DEFAULT_POWER_LIMIT;
      }
    },
    getHtrSortMethod: (state: any) => (homeId: string) => {
      if (!state.homePreferences[homeId]) {
        return;
      }
      return state.homePreferences[homeId]?.htrSortMethod;
    },
    getScheduleMethod: (state: any) => (homeId: string) => {
      if (!state.homePreferences[homeId]) {
        return ScheduleMethod.HOUR;
      }
      return state.homePreferences[homeId]?.scheduleMethod;
    },
    getHomePreferences: (state: any) => (homeId: string) => {
      return state.homePreferences[homeId];
    },
    getAllHomePreferences: (state: any) => () => {
      return state.homePreferences;
    },
    getAllDevs: (state: any) => () => {
      return Object.values(state.homes).reduce((deviceList: any[], currentHome: any) => {
        return deviceList.concat(Object.values(currentHome.devs || {}));
      }, []);
    },
    getHomeDevs: (state: any) => (homeId: string) => {
      return Object.values(state.homes[homeId]?.devs || {});
    },
    getHomeDev: (state: any) => (homeId: string, devId: string) => {
      return state.homes[homeId]?.devs[devId];
    },
  },
});

export default store;
