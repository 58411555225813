import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import { devWifiConfig } from '@/services/devWifiConfig';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import ConnectionHelpInfo from '@/components/connectionHelpInfo/ConnectionHelpInfo.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';
import TitleText from '@/components/text/title/TitleText.vue';
import timeout from '@/services/timeout';

@Component({
  components: {
    Slider,
    MainButton,
    ConnectionHelpInfo,
    InstallInfo,
    TitleText,
  },
})

export default class ConnectionInfo extends Vue {

    private devid: any = {};
    private onEditDevice: any = {};
    private wifiCredentials: any = {};
    private pid: any = {};
    private networkPrefix: any = {};
    private apiPort: any = {};
    private network: any = {};
    private errMsg: any = {};
    private Android: any = {};
    private showStartInfo: any = {};
    private modal: any = {};
    private showConnectToDevice = false;
    private devType: any = {};
    private success = false;

    private mounted() {
      this.devid = this.$route.params.devid;
      this.onEditDevice = this.$route.params.onEditDevice;
      this.wifiCredentials = this.$route.params.wifiCredentials || {};
      this.pid = this.$devListService.getDevPID(this.$route.params.type);
      this.devType = this.$devListService.getDevProductName(this.pid).toUpperCase();
      this.networkPrefix = this.$devListService.getDevNetworkPrefix(this.pid);
      this.apiPort = this.$devListService.getDevAPIPort(this.pid);
      this.network = this.networkPrefix + '_xxxx';
      this.errMsg = this.$route.params.errMsg;
      this.Android = (window as any).cordova && (window as any).cordova.platformId === 'android';
      this.showStartInfo = this.$route.params.type === 'wifiHtr' && this.$route.params.provisioningMethod !== 'ap';
      this.$amplitudeService.sendEvent('device_wifi_provisioning_ap_mode_start');
      setTimeout(() => {
        this.waitForDeviceConnection();
      });
    }

    private get subtype() {
      return this.$route.params.subtype;
    }

    private get provisioningMethod() {
      return this.$route.params.provisioningMethod;
    }

    private next(): any {
      this.errMsg = undefined;
      this.updateShowConnectToDevice();
    }

    private updateShowConnectToDevice(): any {
      this.showConnectToDevice = true;
      this.success = false;
      new devWifiConfig().getDeviceInfo().then((data: any) => {
        if (!data || !data.devid) {
          this.$emit('close');
          return;
        }
        this.success = true;
        this.devid = data.devid;
        timeout(() => {
          const info: any = {
            devid: this.devid,
            secureProvisioningEnabled: data.secure_prov_enabled
          };
          this.$emit('close', info);
        }, 2500);
      }).catch((errorDescription: any) => {
        this.onConnectToDeviceClosed(errorDescription);
      });
    }

    private waitForDeviceConnection(): any {
      new devWifiConfig().setAPIPort(this.apiPort).then(async () => {
        return new devWifiConfig().setNetworkPrefix(this.networkPrefix);
      }).then(async () => {
        return new devWifiConfig().waitForDeviceConnection();
      }).then(async () => {
        this.errMsg = undefined;
        this.next();
      }).catch(() => {
        this.$amplitudeService.sendEvent('device_wifi_provisioning_ap_mode_connection_timeout');
        this.$amplitudeService.sendEvent('device_wifi_provisioning_ap_mode_wrong_ap');
        this.errMsg = 'WRONG_WIFI_ERROR';
        setTimeout(() => {
          this.waitForDeviceConnection();
        });
      });
    }

    private onConnectToDeviceClosed(info: any) {
      this.showConnectToDevice = false;
      if (!info) {
        return;
      }
      this.devid = info.devid;
      if (info.error === 'NOT_CONNECTED') {
        this.$amplitudeService.sendEvent('device_wifi_provisioning_ap_mode_wrong_ap');
        this.errMsg = 'WRONG_WIFI_ERROR';
        this.waitForDeviceConnection();
      } else if (!info || info.error === 'INTERNAL' || !info.devid) {
        this.$amplitudeService.sendEvent('device_wifi_provisioning_ap_mode_error');
        this.errMsg = 'DEVICE_CONNECTION_ERROR';
        this.waitForDeviceConnection();
      } else {
        const devData: any = {
          devid: info.devid,
          state: info.state
        };
        routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.accessPointProvisioning', {
          ...this.$route.params,
          devData: devData,
          secureProvisioningEnabled: info.secureProvisioningEnabled,
          type: this.$route.params.type
        });
      }
    }

    private getTitleText(): any {
      return this.provisioningMethod === 'ap' ? this.$t('LETS_START') : this.$t('AP_FALLBACK_TITLE');
    }

    private getDevImage(): any {
      return this.subtype !== 'twoButtonWifiHtr' ? 'info-connect-plug' : 'connect-plug-for-ap-button-0435';
    }

}
