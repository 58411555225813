import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavButton extends Vue {
  @Prop() private label!: string;
  @Prop() private icon!: string;
  @Prop() private disabled!: boolean;
  @Prop() private rotate!: boolean;

  private iconHelp() {
    return this.icon === 'icon-help';
  }

  private iconPlus() {
    return this.icon === 'icon-plus';
  }

  private iconDelete() {
    return this.icon === 'icon-trash';
  }

  private iconConfig() {
    return this.icon === 'icon-config';
  }

  private iconMoreOptions() {
    return this.icon === 'icon-more-options';
  }

  private iconCsvDownload() {
    return this.icon === 'icon-download';
  }

  private iconOptions() {
    return this.icon === 'icon-options';
  }

  private iconSmartbox() {
    return this.icon === 'icon-smartbox';
  }

  private iconList() {
    return this.icon === 'list';
  }

  private iconMap() {
    return this.icon === 'map';
  }

  private iconUserPlus() {
    return this.icon === 'user-plus';
  }

  private iconCheckAll() {
    return this.icon === 'icon-check-all';
  }

  private iconUncheckAll() {
    return this.icon === 'icon-uncheck-all';
  }

  private iconTemp() {
    return this.icon === 'icon-temp';
  }

  private iconNew() {
    return this.icon === 'icon-new';
  }
}
