import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import window from '@/services/window';
import ScheduleEditSlotModal from '@/components/modals/scheduleEditSlot/scheduleEditSlot.vue';
import CopyDayModal from '@/components/modals/copyDay/copyDay.vue';
import DefTempsModal from '@/components/modals/defTemps/defTemps.vue';
import HeaterDayProgHelpModal from '@/components/modals/heaterDayProgHelp/heaterDayProgHelp.vue';
import Slider from '@/components/slider/Slider.vue';
import NavButton from '@/components/button/navButton/NavButton.vue';

import {
  NodeSetting,
  NodeScheduleTemperature,
  NodeScheduleResolution,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    ScheduleEditSlotModal,
    CopyDayModal,
    DefTempsModal,
    HeaterDayProgHelpModal,
    NavButton,
  },
})
export default class ScheduleDay extends Vue {

    private copyDays: any = {};
    private scheduleResolutionSetting: any = {};
    private scheduleResolution: any = {};
    private nodeSet: any = {};
    private selectedProgTemp: any = {};
    private scheduleTemps: any = {};
    private pTemp: any = {};
    private progChange: any = {};
    private pTempChange: any = {};
    private debouncedSaveProg: any = {};
    private defTemps: any = {};
    private showDefTempsModal = false;
    private showCopyDayModal = false;
    private showHeaterDayProgHelpModal = false;
    private rawDayProg: any = {};
    private rawProg: any = {};
    private highResolutionSchedule: any = {};
    private scheduleResolutionAvailable: any = {};
    private dayProg: any = [];
    private dayString: any = {};
    private hours: any = {};
    private showScheduleEditSlot = false;
    private showCopyDay = false;
    private showDefTemps = false;
    private showHeaterDayProgHelp = false;
    private dayIndex = 0;

    private mounted() {
      this.dayIndex = Number(this.day);
      this.copyDays = [];
      this.scheduleResolutionSetting = NodeSetting.SCHEDULE_RESOLUTION;
      this.scheduleResolution = (window as any).nodeSet?.getFirstNodeSettingParams(this.scheduleResolutionSetting).value || 0;
      this.nodeSet = (window as any).nodeSet;
      this.selectedProgTemp = 'ice';
      this.scheduleTemps = (window as any).nodeSet?.getFirstNodeScheduleTemperatures();
      this.pTemp = [];
      this.progChange = false;
      this.pTempChange = false;
      this.debouncedSaveProg = _.debounce(this.saveProg, 30000);

      if (!(window as any).nodeSet) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      if (!(window as any).connected) {
        return this.$router.replace({ name: 'root.authenticated.root.device.list.heating', query: {} });
      }
      this.updateHtrData();
      this.initData();
    }

    private destroyed() {
      this.debouncedSaveProg.flush();
    }

    private get day() {
      return this.$route.params.day;
    }

    private getTemperatureIndex(slot?: any): any {
      return _.findIndex(this.scheduleTemps, (scheduleTemp: any) => {
        return slot === scheduleTemp.id;
      });
    }

    private editSlot(slot?: any): any {
      const tempIndex: any = this.getTemperatureIndex(this.selectedProgTemp);
      slot.id = this.selectedProgTemp;
      slot.t = this.pTemp[tempIndex];
      this.progChange = true;
      this.debouncedSaveProg();
    }

    private openDefTempDialog(): any {
      const defTemps: any = this.pTemp.length > 3 ? this.pTemp.slice(1) : this.pTemp.concat([]);
      this.debouncedSaveProg.flush();
      rootScope.defTemps = defTemps;
      this.showDefTempsModal = true;
    }

    private openCopyDayDialog(): any {
      this.showCopyDayModal = true;
    }

    private openHelp(): any {
      this.showHeaterDayProgHelpModal = true;
    }

    private hasOffProgSlots(): any {
      return this.getTemperatureIndex('off') !== -1;
    }

    private convertProg(rawDayProg?: any): any {
      const dayProg: any = [];
      let tempIndex;
      for (let i: any = 0; i < rawDayProg.length; i++) {
        tempIndex = this.getTemperatureIndex(rawDayProg[i].id);
        if (rawDayProg.length === 24 || this.highResolutionSchedule || i % 2 === 0) {
          dayProg.push({
            start: rawDayProg[i].start,
            end: rawDayProg[i].end,
            id: rawDayProg[i].id,
            t: this.pTemp[tempIndex]
          });
        }
      }
      return dayProg;
    }

    private saveProg(): any {
      this.$amplitudeService.sendEvent('saving_heater_schedule');
      this.rawDayProg = [];
      for (let i: any = 0; i < this.dayProg.length; i++) {
        const rawDayProgSize: any = Object.keys(this.rawDayProg).length;
        this.rawDayProg[rawDayProgSize] = {
          start: this.dayProg[i].start,
          end: this.dayProg[i].end,
          id: this.dayProg[i].id
        };
        if (this.scheduleResolutionAvailable && this.dayProg.length === 24) {
          this.rawDayProg[rawDayProgSize + 1] = this.dayProg[i].id;
        }
      }
      this.rawProg[this.dayIndex] = this.rawDayProg;
      _.forEach(this.copyDays, (copyDay: any) => {
        this.rawProg['' + copyDay] = this.rawDayProg;
      });
      if (this.progChange || this.copyDays.length !== 0) {
        (window as any).nodeSet?.setSchedule(this.rawProg).catch(() => {
          this.$toast.error(this.$t('DEVICE_UPDATE_ERROR_MSG'));
        });
      }
      this.progChange = false;
      if (this.$route.params.multi) {
        this.copyProgToHeaters(this.$route.params.multi, this.rawProg);
        this.copyProgTempToHeaters(this.$route.params.multi, this.scheduleTemps);
      }
    }

    private async saveProgTemp(progTempData?: any): Promise<any> {
      await (window as any).nodeSet?.setScheduleTemperatures(progTempData).catch(() => {
        this.$toast.error(this.$t('DEVICE_UPDATE_ERROR_MSG'))
      });
      if (this.$route.params.multi) {
        this.copyProgTempToHeaters(this.$route.params.multi, progTempData);
      }
    }

    private copyProgToHeaters(list?: any, prog?: any): any {
      _.forEach(list, (nodeData: any) => {
        this.$devListService.getNodeController(nodeData.devid, nodeData.addr).then(async (currentController: any) => {
          return currentController.setSchedule(prog);
        });
      });
    }

    private copyProgTempToHeaters(list?: any, progTemp?: any): any {
      _.forEach(list, (nodeData: any) => {
        this.$devListService.getNodeController(nodeData.devid, nodeData.addr).then(async (currentController: any) => {
          return currentController.setScheduleTemperatures(progTemp);
        });
      });
    }

    private updateHtrData(): any {
      this.scheduleTemps = (window as any).nodeSet?.getFirstNodeScheduleTemperatures();
      this.pTemp = this.scheduleTemps.map((temp: NodeScheduleTemperature) => temp.value.toFixed(1));
      const progResolution: any = (window as any).nodeSet?.getFirstNodeSettingParams('prog_resolution') || {} || 0;
      this.scheduleResolutionAvailable = (progResolution || {}).editable || 0;
      this.highResolutionSchedule = (progResolution || {}).value === 1 || 0;
      let scheduleResolution = NodeScheduleResolution.HOUR;
      if (this.nodeSet.nodeControllers[0].nodeData.prog.prog[0].length === 48) {
        this.highResolutionSchedule = true;
        scheduleResolution = NodeScheduleResolution.HALF_HOUR;
      }
      this.rawProg = (this.nodeSet.getFirstNodeSchedule(
        {
          allowRepeatedSlots: true,
          scheduleResolution,
        },
      ) || {});
      this.rawDayProg = this.rawProg['' + this.day];
      this.dayProg = this.convertProg(this.rawDayProg);
    }

    private async onDefTempsModalClosed(): Promise<any> {
      this.showDefTempsModal = false;
      this.defTemps = (window as any).defTemps;
      this.scheduleTemps = (window as any).nodeSet?.getFirstNodeScheduleTemperatures();
      this.pTemp = [];
      this.progChange = false;
      this.pTempChange = false;
      this.updateHtrData();
      this.initData();
    }

    private checkProgTempChange(progTemps?: any): any {
      let i;
      for (i = 0; i < progTemps.length && progTemps[i] === this.pTemp[i]; i++);
      return i < progTemps.length;
    }

    private onCopyDayModalClosed(): any {
      this.showCopyDayModal = false;
      this.copyDays = (window as any).selectedDays.concat([]);
      this.debouncedSaveProg();
    }

    private getWeekDay(index?: any): any {
      switch (index) {
      case 0:
        return 'SUNDAY';
      case 1:
        return 'MONDAY';
      case 2:
        return 'TUESDAY';
      case 3:
        return 'WEDNESDAY';
      case 4:
        return 'THURSDAY';
      case 5:
        return 'FRIDAY';
      case 6:
        return 'SATURDAY';
      }
    }

    private initData(): any {
      let i;
      this.dayString = this.getWeekDay(this.day);
      this.hours = [];
      for (i = 0; i < 10; i++) {
        this.hours.push('0' + i + ':00');
        if (this.highResolutionSchedule) {
          this.hours.push('0' + i + ':30');
        }
      }
      for (i = 10; i < 24; i++) {
        this.hours.push('' + i + ':00');
        if (this.highResolutionSchedule) {
          this.hours.push('' + i + ':30');
        }
      }
    }

}
