import { Component, Vue, Watch } from 'vue-property-decorator';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import NavButton from '@/components/button/navButton/NavButton.vue';
import DashboardNavButton from '@/components/button/dashboardNavButton/DashboardNavButton.vue';
@Component({
  components: {
    Slider,
    NavButton,
    DashboardNavButton,
  },
})

export default class EditHome extends Vue {

  private homeId: any = {};
  private wifiEnabled: any = {};
  private geolocationEnabled: any = {};
  private inviteEnabled: any = {};
  private isHomeOwner: any = {};
  private web: any = {};
  private hideMenu: any = {};

  private async mounted() {
    this.homeId = this.$route.params.homeid;
    this.wifiEnabled = (await this.$configService.getConfig()).wifiFlag;
    this.geolocationEnabled = (await this.$configService.getConfig()).geolocationFlag;
    this.inviteEnabled = (await this.$configService.getConfig()).inviteFlag;
    this.isHomeOwner = this.$devListService.isOwner(this.homeId);

    if (!this.homeId) {
      return this.$router.replace({ name: 'root.authenticated.root', query: {} });
    }
    if (!(window as any).cordova) {
      this.web = true;
    }
    this.updateMenuState();
  }

  private get currentRoute(): any {
    return this.$route.name;
  }

  @Watch('currentRoute')
  private onCurrentRouteChanged() {
    this.updateMenuState();
  }

  private updateMenuState(): any {
    this.hideMenu = this.$route.name === 'root.authenticated.root.editHome.maxPowerManagement' || this.$route.name === 'root.authenticated.root.editHome.maxPowerManagementDay';
  }

  private isInDevicesView(): boolean {
    return this.$route.name === 'root.authenticated.root.editHome.devices';
  }

  private isInHomeDataView(): boolean {
    return this.$route.name === 'root.authenticated.root.editHome.data';
  }

  private isInGeolocationView(): boolean {
    return this.$route.name === 'root.authenticated.root.editHome.geolocation';
  }

  private isInInviteUsersView(): boolean {
    return this.$route.name === 'root.authenticated.root.editHome.inviteUsers';
  }
}
