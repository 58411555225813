import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import ConfirmDeviceRemovalModal from '@/components/modals/confirmDeviceRemoval/confirmDeviceRemoval.vue';
import ChangeHomeModal from '@/components/modals/changeHome/changeHome.vue';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import WarningButton from '@/components/button/warningButton/WarningButton.vue';

@Component({
  components: {
    Slider,
    ConfirmDeviceRemovalModal,
    ChangeHomeModal,
    MainButton,
    WarningButton,
  },
})

export default class Main extends Vue {

    private homeId: any = {};
    private devId: any = {};
    private connected = false;
    private isWeb: any = {};
    private homeTransferAvailable: any = {};
    private name: any = {};
    private pid: any = {};
    private deviceType: any = {};
    private isWifi = false;
    private showConfirmDeviceRemovalModal = false;
    private showChangeHomeModal = false;
    private showConfirmDeviceRemoval = false;
    private showChangeHome = false;
    private isHomeOwner = false;

    private mounted() {

      this.homeId = this.$route.params.homeid;
      this.devId = this.$route.params.devid;
      this.connected = false;
      this.isWeb = !(window as any).cordova;
      this.homeTransferAvailable = this.$devListService.count() > 1;
      this.isHomeOwner = this.$devListService.isOwner(this.homeId);

      this.$amplitudeService.sendEvent('edit_device_start');
      this.$devListService.getDevProxy(this.homeId, this.devId).then(async (devProxy: any) => {
        this.name = devProxy.getName();
        this.pid = devProxy.getPid();
        this.isWifi = ['wifi', 'wifiHtr'].includes(this.$devListService.getDevProductName(this.pid.toUpperCase()));
        this.deviceType = this.$devListService.getDevProductName(this.pid);
      });
      this.updateDevConnection();
      this.$userService.addListener(this.homeId, this.updateDevConnection);
    }

    private destroyed() {
      this.$userService.removeListener(this.homeId, this.updateDevConnection);
    }

    private copyDevid(): any {
      (document as any).getElementById('devid').select();
      (document as any).execCommand('copy');
      this.$toast(this.$t('ID_COPIED'));
    }

    private updateDevName(): any {
      this.$devListService.setDevName(this.devId, this.name);
    }

    private goToEditWifi(): any {
      this.$amplitudeService.sendEvent('edit_device_wifi_start');
      const productName: any = this.$devListService.getDevProductName(this.pid);
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.setAPData', {
        ...this.$route.params,
        type: productName,
        onEditDevice: true
      });
    }

    private deleteDevice(): any {
      this.$amplitudeService.sendEvent('edit_device_delete_start');
            this.showConfirmDeviceRemovalModal = true;
    }

    private goToHomeTransfer(): any {
      this.$amplitudeService.sendEvent('edit_device_transfer_start');
            this.showChangeHomeModal = true;
    }


    private updateDevConnection(): any {
      this.$devListService.checkDevConnection(this.homeId, this.devId).then(async (connected: any) => {
        this.connected = connected;
      });
    }

}
