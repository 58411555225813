import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})
export default class SignUpVerify extends Vue {

    private email: any = {};
    private code: any = {};
    private verifyError: any = {};
    private verifySuccess: any = {};

    private mounted() {
      this.email = this.$route.query.user;
      this.code = this.$route.query.code;
      this.verifyError = false;
      this.verifySuccess = false;
      this.$apiService.verifyUser(
        this.email,
        this.code,
      ).then(async (resp: any) => {
        this.verifySuccess = true;
        this.$amplitudeService.setUserId(resp.id);
        this.$amplitudeService.sendEvent('signup_confirm_success');
      }).catch((err: any) => {
        this.verifyError = true;
        function getReason(err: any) {
          if (err.status === 404) {
            return 'bad user or this.code';
          } else if (err.status === 500) {
            return 'server error';
          } else if (err.status > 500 && err.status < 600) {
            return 'proxy error';
          } else {
            return 'unknown';
          }
        }
        this.$amplitudeService.sendEvent('signup_confirm_error', {
          reason: getReason(err),
          status_code: err.status
        });
      });
    }

     
  
  
  
  
  
  
  
}
