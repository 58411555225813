import { Component, Vue } from 'vue-property-decorator';
import { devWifiConfig } from '@/services/devWifiConfig';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    TitleText,
  },
})
export default class BTProvisioning extends Vue {

  private progressBar: any = {};
  private wifiCredentials: any = {};
  private btTimeout: any;
  private provisioningCompleted = false;
  private credentialsSent = false;
  private esp = false;
  private connected = false;
  private heaterAddress = '';
  private heaterService = '';
  private heaterName = '';
  private selectedAP = '';
  private password = '';
  private encryptedPass = '';
  private devId = '';
  private pid = '';
  private onEditDevice = false;
  private devType = '';

  private async mounted() {
    this.devType = this.$route.params.type.toUpperCase();
    this.onEditDevice = Boolean(this.$route.params.onEditDevice);
    this.pid = this.$devListService.getDevPID(this.$route.params.type);
    this.wifiCredentials = this.$route.params.wifiCredentials;
    this.selectedAP = this.wifiCredentials.essid;
    this.password = this.wifiCredentials.pass;
    this.encryptedPass = this.wifiCredentials.encryptedPass;
    const progressTimeout = 60000;
    this.createProgressBar(progressTimeout);
    this.progressBar.animate(1);
    this.btTimeout = setTimeout(() => {
      this.progressBar.stop();
      this.provisioningError('timeout');
    }, progressTimeout);
    await new devWifiConfig().storeWifiConfig(
      this.selectedAP,
      this.password,
    );
    (window as any).bluetoothle.initialize(this.onInitializeResult, {});
  }

  private destroyed() {
    (window as any).bluetoothle.stopScan(() => undefined, () => undefined);
    clearTimeout(this.btTimeout);
  }

  private async goToNextStep(): Promise<any> {
    return Promise.resolve().then(async () => {
      this.$amplitudeService.sendEvent('device_wifi_provisioning_bt_success');
      return this.completeProgressBar();
    }).then(async () => {
      if (this.onEditDevice) {
        routerWrapper.push(this.$router, 'root.authenticated.root');
      } else {
        routerWrapper.push(this.$router, this.getSetNameRoute(), {
          ...this.$route.params,
          devid: this.devId,
          pid: this.pid,
        });
      }
    });
  }

  private async onSuccess() {
    if (this.credentialsSent) {
      return;
    }
    this.credentialsSent = true;
    await this.checkWifiHeaterConnection();
    this.provisioningCompleted = true;
    clearTimeout(this.btTimeout);
    this.$amplitudeService.sendEvent('device_wifi_provisioning_bt_success');
    await this.completeProgressBar();
    this.goToNextStep();
  }

  private espConnectSuccess() {
    (window as any).ESPIdfProvisioning.provision(
      this.heaterName,
      this.selectedAP,
      this.encryptedPass,
      this.onSuccess,
      this.provisioningError,
    );
  }

  private stopSearchSuccess() {
    (window as any).ESPIdfProvisioning.connectBLEDevice(
      this.heaterName,
      this.heaterService,
      '1234',
      this.espConnectSuccess,
      this.provisioningError,
    );
  }

  private espScanSuccess(data: any) {
    const heaterRegExp = /Heater_.*/g;
    const matches = [...(data.name || '').matchAll(heaterRegExp)];
    if (matches.length > 0 && this.heaterService === '') {
      this.heaterService = data.primaryServiceUuid;
      this.heaterName = data.name;
      (window as any).ESPIdfProvisioning.stopSearchingESPDevices(
        this.stopSearchSuccess,
        this.provisioningError,
      );
      // iOS doesn't call success (nor error) when stopping the search for ESP devices...
      if ((window as any).cordova.platformId === 'ios') {
        this.stopSearchSuccess();
      }
    }
  }

  private async writeSuccess() {
    if (this.provisioningCompleted) {
      return;
    }
    this.provisioningCompleted = true;
    clearTimeout(this.btTimeout);
    await this.completeProgressBar();
    this.goToNextStep();
  }

  private readDevIdSuccess(data: any) {
    const responseBytes = (window as any).bluetoothle.encodedStringToBytes(data.value);
    const response = (window as any).bluetoothle.bytesToString(responseBytes);
    this.devId = JSON.parse(response).body.devid;
    const stringValue = JSON.stringify({
      method: 'POST',
      path: '/mgr/wifi/network',
      body: {
        essid: this.selectedAP,
        pass: this.encryptedPass,
      },
    });
    const bytes = (window as any).bluetoothle.stringToBytes(stringValue);
    const base64String = (window as any).bluetoothle.bytesToEncodedString(bytes);
    (window as any).bluetoothle.write(this.writeSuccess, this.provisioningError, {
      address: this.heaterAddress,
      service: 'DE45',
      characteristic: 'FF33',
      value: base64String,
    });
  }

  private fetchDevIdSuccess() {
    (window as any).bluetoothle.read(this.readDevIdSuccess, this.provisioningError, {
      address: this.heaterAddress,
      service: 'DE45',
      characteristic: 'FF33',
    });
  }

  private discoverSuccess() {
    const stringValue = JSON.stringify({
      method: 'GET',
      path: '/info',
    });
    const bytes = (window as any).bluetoothle.stringToBytes(stringValue);
    const base64String = (window as any).bluetoothle.bytesToEncodedString(bytes);
    (window as any).bluetoothle.write(this.fetchDevIdSuccess, this.provisioningError, {
      address: this.heaterAddress,
      service: 'DE45',
      characteristic: 'FF33',
      value: base64String,
    });
  }

  private connectSuccess() {
    if (this.connected) {
      return;
    }
    this.connected = true;
    if ((window as any).cordova.platformId === 'ios') {
      this.mtuSuccess();
    } else {
      (window as any).bluetoothle.mtu(
        this.mtuSuccess,
        this.provisioningError,
        { address: this.heaterAddress, mtu: 500 },
      );
    }
  }

  private bondSuccess() {
    (window as any).bluetoothle.connect(
      this.connectSuccess,
      this.provisioningError,
      { address: this.heaterAddress },
    );
  }

  private servicesSuccess() {
    (window as any).bluetoothle.characteristics(
      this.discoverSuccess,
      this.provisioningError,
      { address: this.heaterAddress, service: 'DE45' },
    );
  }

  private mtuSuccess() {
    if ((window as any).cordova.platformId === 'ios') {
      (window as any).bluetoothle.services(
        this.servicesSuccess,
        this.provisioningError,
        { address: this.heaterAddress },
      );
    } else {
      (window as any).bluetoothle.discover(
        this.discoverSuccess,
        this.provisioningError,
        { address: this.heaterAddress },
      );
    }
  }

  private stopScanSuccess() {
    if (this.esp) {
      return;
    }
    if ((window as any).cordova.platformId === 'ios') {
      this.bondSuccess();
    } else {
      (window as any).bluetoothle.bond(
        this.bondSuccess,
        this.provisioningError,
        { address: this.heaterAddress },
      );
    }
  }

  private startScanSuccess(data: any) {
    if (this.heaterAddress !== '') {
      return;
    }
    const espHeaterRegExp = /Heater_.*/g;
    const heaterRegExp = /Heater-.*/g;
    const espMatches = [...(data.name || '').matchAll(espHeaterRegExp)];
    const matches = [...(data.name || '').matchAll(heaterRegExp)];
    if (espMatches.length > 0) {
      this.heaterAddress = data.address;
      this.esp = true;
      (window as any).bluetoothle.stopScan(this.stopScanSuccess, this.provisioningError);
      if (this.password.length > 15) {
        this.provisioningError();
        return;
      }
      (window as any).ESPIdfProvisioning.searchESPDevices(
        '',
        'ble',
        '0',
        this.espScanSuccess,
        this.provisioningError,
      );
    } else if (matches.length > 0) {
      this.heaterAddress = data.address;
      (window as any).bluetoothle.stopScan(this.stopScanSuccess, this.provisioningError);
    }
  }

  private gotScannerStatus(data: any) {
    if (!data.isScanning) {
      (window as any).bluetoothle.startScan(this.startScanSuccess, this.provisioningError, {});
    }
  }

  private gotBtEnabledResult(data: any) {
    if (data.isEnabled) {
      (window as any).bluetoothle.isScanning(this.gotScannerStatus);
    } else {
      this.provisioningError();
    }
  }

  private onPermissionSuccess() {
    (window as any).bluetoothle.isEnabled(this.gotBtEnabledResult);
  }

  private onScanPermissionSuccess() {
    (window as any).bluetoothle.requestPermissionBtConnect(this.onPermissionSuccess, this.provisioningError);
  }

  private provisioningError(data?: any) {
    if (data !== 'timeout' && (this.provisioningCompleted || this.credentialsSent)) {
      return;
    }
    if (!this.esp && data !== undefined && data.message === 'Connection failed') {
      return;
    }
    if (!this.esp && data !== undefined && data.message === 'Device already bonding') {
      this.bondSuccess();
      return;
    }
    if (!this.esp && data !== undefined && data.message === 'Device previously connected, reconnect or close for new connection') {
      (window as any).bluetoothle.reconnect(
        this.connectSuccess,
        this.provisioningError,
        { address: this.heaterAddress },
      );
      return;
    }
    if (!this.esp && data !== undefined && data.message === 'Device previously connected, reconnect or close for new device') {
      (window as any).bluetoothle.reconnect(
        this.connectSuccess,
        this.provisioningError,
        { address: this.heaterAddress },
      );
      return;
    }
    if (!this.esp && data !== undefined && data.message === 'Device isn\'t disconnected') {
      this.connectSuccess();
      return;
    }
    if (data !== 'timeout' && this.esp && (window as any).cordova.platformId === 'ios' && this.password.length <= 15) {
      this.esp = false;
      this.stopScanSuccess();
      return;
    }
    (window as any).bluetoothle.stopScan(() => undefined, () => undefined);
    clearTimeout(this.btTimeout);
    routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.connectionInfo', this.$route.params);
  }

  private onInitializeResult() {
    if ((window as any).cordova.platformId === 'ios') {
      this.onPermissionSuccess();
    } else {
      (window as any).bluetoothle.requestPermissionBtScan(
        this.onScanPermissionSuccess,
        this.provisioningError,
      );
    }
  }

  private createProgressBar(progressTimeout: any): any {
    const item: any = (document as any).getElementById('progress-bar-container');
    this.progressBar = new (window as any).ProgressBar.Circle(item, {
      color: '#54a7f7',
      strokeWidth: 4,
      trailWidth: 1,
      easing: 'linear',
      duration: progressTimeout,
      text: { autoStyleContainer: false },
      from: {
        color: '#F9B521',
        width: 1
      },
      to: {
        color: '#54a7f7',
        width: 4
      },
      step: (state: any, circle: any) => {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);
        const value: any = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('');
        } else {
          circle.setText(value + ' %');
        }
      }
    });
  }

  private async completeProgressBar(): Promise<any> {
    return new Promise((resolve: any) => {
      const progressBarCompletionDelay: any = 750;
      this.progressBar.stop();
      const currentProgress: any = this.progressBar.value();
      this.progressBar.destroy();
      this.createProgressBar(progressBarCompletionDelay);
      this.progressBar.set(currentProgress);
      this.progressBar.animate(1);
      setTimeout(() => {
        resolve();
      }, 1.25 * progressBarCompletionDelay);
    });
  }

  private checkWifiHeaterConnection(): any {
    return this.$devListService.connectToSmartboxDiscovery()
    .then(async (devId: any) => {
      if (!devId) {
        this.$amplitudeService.sendEvent('device_wifi_provisioning_bt_timeout');
        throw new Error('Device not connected');
      }
      this.devId = devId;
    });
  }

  private getSetNameRoute(): any {
    let productName: any = this.$devListService.getDevProductName(this.pid);
    productName = productName === 'wifiHtr' ? 'wifi' : productName;
    return 'root.authenticated.root.addDevice.' + productName + '.setName';
  }

}
