import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    MainButton,
  },
})

export default class ConfirmInvite extends Vue {

    private userConflictError = false;
    private inputErrors = false;
    private confirmPassword = '';
    private password = '';
    private tcAccepted = false;

    private async mounted() {
      (document as any).getElementById('privacyLabel').innerHTML = ` ${this.$t('TC_PP_LINK_TEXT')}`
      .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost)
      .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost);
      if (!this.userId || !this.code) {
        routerWrapper.push(this.$router, 'root.anonymous.signIn');
      }
    }

    private get userId() {
      return this.$route.query.userid;
    }
    private get code() {
      return this.$route.query.code;
    }

    private confirmInvite(): any {
      if (!this.tcAccepted) {
        return;
      }
      if (true && !this.hasPasswordError() && !this.hasPasswordMatchError()) {
        this.resetErrors();
        return this.$userService.confirmInvite(this.userId, this.password, this.code)
        .then(async () => {
          routerWrapper.push(this.$router, 'root.anonymous.confirmInviteSuccess');
        }).catch((err: any) => {
          if (err.status === 404) {
            this.userConflictError = true;
          }
        });
      } else {
        this.inputErrors = true;
      }

    }

    private hasErrors(): any {
      return this.inputErrors;
    }

    private hasMissingFields(): any {
      return false;
    }

    private hasEmailError(): any {
      return false;
    }

    private hasPasswordError(): any {
      return !/^\w{6,}$/.test(this.password);
    }

    private hasPasswordMatchError(): any {
      return this.password !== this.confirmPassword;
    }

    private hasUserConflictError(): any {
      return this.userConflictError;
    }


    private resetErrors(): any {
      this.inputErrors = false;
      this.userConflictError = false;
    }

}
