import { render, staticRenderFns } from "./Privacy.vue?vue&type=template&id=6857b69b"
import script from "./Privacy.ts?vue&type=script&lang=js&external"
export * from "./Privacy.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports