import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import { NodeTypeConfig } from '@/services/config';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import InstallNodeLabel from '@/components/installNodeLabel/InstallNodeLabel.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    CancelButton,
    InstallNodeLabel,
  },
})
export default class installNode extends Vue {

  private homeId: any = {};
  private isWeb: any = {};
  private devices: any = {};
  private isEnabled: any = () => false;
  private nodeInstallationAvailable: any = {};
  private supportedDevices: NodeTypeConfig[] = [];

  private async mounted() {
    this.supportedDevices = (await this.$configService.getConfig()).clientData.supportedDevices;
    this.homeId = this.$route.params.homeid;
    this.isWeb = !(window as any).cordova;
    this.devices = this.$devListService.getDevList(this.homeId).filter((dev: any) => {
      const connected = this.$store.state.homes[this.homeId].devs[dev.dev_id].connected;
      const productId = dev.product_id || '';
      const devType = this.$devListService.getDevProductName(productId)
      return devType !== 'wifiHtr' && connected;
    });
    this.isEnabled = (deviceType: string) => {
      return this.supportedDevices.findIndex(supportedDevice => supportedDevice.type === deviceType) !== -1;
    };
    this.nodeInstallationAvailable = this.$devListService.getDevList(this.homeId).find((dev: any) => {
      const productId = dev.product_id || '';
      const devType = this.$devListService.getDevProductName(productId);
      return devType !== 'wifiHtr' && this.deviceConnected === true;
    });
  }

  private get deviceConnected(): boolean {
    const devList = this.$devListService.getDevList(this.homeId);
    const devIds = Array.from(new Set(devList.map((obj: any) => obj.devid)));

    const someDevConnected = Array.from(devIds).some((devId: any) => {
     const deviceInfo = this.$store.state.homes[this.homeId].devs[devId];
     return deviceInfo && deviceInfo.connected === true;
    });
    return someDevConnected;
  }

  private getDeviceLabel(deviceType: string) {
    const index = this.supportedDevices.findIndex((supportedDevice) => supportedDevice.type === deviceType);
    return this.supportedDevices[index].label;
  }

  private close(): any {
    this.$emit('close');
  }

  private selectWifiHeater(subtype: string): any {
    if (this.isWeb) {
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.notAllowed', {
        homeid: this.homeId,
        newHome: this.$route.params.newHome
      });
    } else {
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.setAPData', {
        homeid: this.homeId,
        newHome: this.$route.params.newHome,
        type: 'wifiHtr',
        subtype,
      });
    }
  }

  private goToNodeInstall(nodeType?: any, extraPress?: boolean): any {
    const currentNodeType = (nodeType === 'towelRailHtr') ? 'htr' : nodeType;
    if (this.devices.length === 1) {
      routerWrapper.push(this.$router, 'root.authenticated.root.device.' + currentNodeType + 'Install', { devid: this.devices[0].devid, extraPress, nodeType } );
    } else {
      this.$router.push({ name: 'root.authenticated.root.device.nodeInstall', query: { currentNodeType } });
    }
  }

  private hasNodeImage(nodeType?: any, image?: any): any {
    const index = this.supportedDevices.findIndex((supportedDevice?: any) => supportedDevice.type === nodeType);
    return this.supportedDevices[index].image === image;
  }
}
