import { Component, Vue, Prop } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import {
  NodeMode,
} from '@vdi-helki/helki-node-management';
import { NodeSelectData } from '@/store';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
  },
})
export default class setTemp extends Vue {
    @Prop() private homeId!: any;
    private nodeSet: any = {};
    private temperatureList: any = {};
    private tempSetup: any = {};
    private temperatureListLength: any = {};
    private sliderPosition: any = {};
    private slickSettings: any = {};
    private slick: any = {};

    private async mounted() {
      this.nodeSet = await this.$devListService.getNodeControllerSet(this.selectedNodeList);
      this.initTemperatureSlider();
    }

    private get selectedNodeList(): NodeSelectData[] {
      const selectedNodeList: NodeSelectData[] = Array.from(this.$store.state.selectedNodes[this.homeId])
      .map((node: any) => {
        return JSON.parse(node);
      });
      return selectedNodeList;
    }

    private tempUp(): any {
      const currentPos: any = this.sliderPosition;
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value + this.tempSetup.step;
      if (this.temperatureList[currentPos].value.toFixed(1) === this.temperatureList[currentPos].max.toFixed(1)) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos + 1);
      } else if (this.temperatureList[currentPos].value > this.temperatureList[currentPos].max) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        return;
      }
    }

    private isHtrModType(): boolean {
      return this.nodeSet.nodeControllers[0].nodeData.type === 'htr_mod';
    }

    private tempDown(): any {
      const currentPos: any = this.sliderPosition;
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value - this.tempSetup.step;
      if (currentPos > 0 && this.temperatureList[currentPos].value < this.temperatureList[currentPos].initialValue) {
        this.temperatureList[currentPos - 1].value = this.temperatureList[currentPos].value;
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos - 1);
      } else if (this.temperatureList[currentPos].value < this.temperatureList[currentPos].min) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        return;
      }
    }

    private cancel(): any {
      this.$emit('close');
    }

    private async confirm(): Promise<any> {
      const currentPos: any = this.sliderPosition;
      const selectedTemp: any = this.temperatureList[currentPos].value;
      for (const node of this.nodeSet?.nodeControllers) {
        if (node.nodeData.type === 'htr_mod') {
          const modeParams = node.getModeParams('comfort');
          modeParams.temperature = selectedTemp;
          await node.setMode('comfort', modeParams);
        } else if (node.getMode() === 'off') {
          const modeParams = node.getModeParams('manual');
          modeParams.temperature = selectedTemp;
          await node.setMode('manual', modeParams);
        } else {
          await this.nodeSet?.setTemp(selectedTemp);
        }
      }
      this.$emit('close');
    }

    private getSliderPosition(temp?: any): any {
      for (let i: any = 0; i < this.temperatureListLength - 1; i++) {
        if (temp < this.temperatureList[i + 1].initialValue) {
          return i;
        }
      }
      return this.temperatureListLength - 1;
    }

    private getTempSetup(): any {
      const modeParams: any = this.nodeSet.getFirstNodeModeParams(this.nodeSet.getFirstNodeMode() as NodeMode);
      const fahrenheit: any = modeParams.temperatureUnits === 'F';
      const tempLimits: any = this.nodeSet.getTempLimits();
      return {
        min: fahrenheit ? tempLimits.minFTemp : tempLimits.minCTemp,
        max: fahrenheit ? tempLimits.maxFTemp : tempLimits.maxCTemp,
        units: modeParams.temperatureUnits,
        step: modeParams.temperatureStep
      };
    }

    private initTemperatureList(): any {
      this.temperatureList = [];
      this.tempSetup = this.getTempSetup();
      this.temperatureListLength = Math.ceil(this.tempSetup.max - this.tempSetup.min + 1);
      const minShownValue: any = Math.trunc(this.tempSetup.min);
      for (let i: any = 0; i < this.temperatureListLength; i++) {
        this.temperatureList.push({
          value: minShownValue + i,
          initialValue: minShownValue + i,
          min: i === 0 ? minShownValue : minShownValue + i - 1,
          max: i === this.temperatureListLength - 1 ? minShownValue + i : minShownValue + i + 1
        });
      }
    }

    private getCurrentSliderPos(): any {
      const sTemp: any = this.nodeSet?.nodeControllers[0].getModeParams(this.nodeSet?.nodeControllers[0].getMode()).temperature || '20.0';
      this.sliderPosition = this.getSliderPosition(parseInt(sTemp, 10));
      this.temperatureList[this.sliderPosition].value = Number(sTemp);
      return this.sliderPosition;
    }

    private initTemperatureSlider(): any {
      this.initTemperatureList();
      this.sliderPosition = 20;
      this.slickSettings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        swipeToSlide: true,
        method: {},
        initialSlide: this.getCurrentSliderPos(),
        event: {
          init: (event: any, slick: any) => {
            this.slick = slick;
          },
          beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
            if (oldPos === newPos || oldPos > this.temperatureList.length) {
              return;
            }
            this.temperatureList[oldPos].value = this.temperatureList[oldPos].initialValue;
            this.sliderPosition = newPos;
          },
          afterChange: (event: any, slick: any, newPos: any) => {
            this.sliderPosition = newPos;
          },
        }
      };
    }
}
