import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import { ProvisioningMethod } from '@/services/config';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    MainButton,
    TitleText,
  },
})

export default class SelectProvisioning extends Vue {

    private provisioningMethod: any = {};
    private pid: any = {};
    private devType: any = {};
    private provisioningMethods: ProvisioningMethod[] = [];

    private async mounted() {
      this.pid = this.$devListService.getDevPID(this.$route.params.type || this.$route.query.type);
      this.devType = this.$devListService.getDevProductName(this.pid.toUpperCase()).toUpperCase();
      this.provisioningMethods = (await this.$configService.getConfig()).clientData.provisioningMethods;
      this.provisioningMethod =  this.provisioningMethods[0].value;
      this.$amplitudeService.sendEvent('device_wifi_select_provisioning_method_start');
    }

    private setProvisioningMethod(): any {
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.startInfo', {
        ...this.$route.params,
        provisioningMethod: this.provisioningMethod
      });
    }

}
