import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalHelpContent from '@/components/modalComponents/modalHelpContent/ModalHelpContent.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import SkipButton from '@/components/button/skipButton/SkipButton.vue';

@Component({
  components: {
    Slider,
    ModalHelpContent,
    MainButton,
    SkipButton,
  },
})
export default class generalHelp extends Vue {

  private slickSettings: any = {};
  private slickPosition: any = 0;
  private slick: any = {};
  private buttonText: any = '';

  private mounted() {
    this.slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
      method: {},
      initialSlide: 0,
      nextArrow: false,
      prevArrow: false,
      arrows: false,
      event: {
        beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
          this.slickPosition = newPos;
        },
        init: (event: any, slick: any) => {
          this.slick = slick;
          this.slickPosition = 0;
        }
      }
    };
    this.updateButtonText();
  }

  private close(): any {
    this.$emit('close');
  }

  private nextStep(): any {
    (this.$refs.slick as any).goTo(this.slickPosition + 1);
    this.slickPosition ++;
    this.updateButtonText();
  }

  private buttonAction(): any {
    if (this.slickPosition !== 5) {
      this.nextStep();
    } else {
      this.close();
    }
  }

  private updateButtonText(): any {
    this.buttonText = (this.slickPosition !== 5) ? this.$t('NEXT') : this.$t('READY');
  }

}
