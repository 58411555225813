import { Component, Vue, Watch } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import routerWrapper from '@/services/routerWrapper';
import MaxPowerSetupModal from '@/components/modals/maxPowerSetup/maxPowerSetup.vue';
import MaxPowerManagementModal from '@/components/modals/maxPowerManagement/maxPowerManagement.vue';
import { IDevProxy, DevData } from '@/services/DevProxy';
import { HomeData } from '@/services/devList.service';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';

@Component({
  components: {
    Slider,
    MaxPowerSetupModal,
    MaxPowerManagementModal,
    MainButton,
    CancelButton,
  },
})

export default class MaxPowerManagement extends Vue {

    private usingGlobalConfig: any = {};
    private deviceId: any = {};
    private maxPowerSlots: any = {};
    private maxPowerProfiles: any = {};
    private convertedProg: any = {};
    private showMaxPowerSetupModal = false;
    private profiles: any = {};
    private showMaxPowerManagementModal = false;
    private showMaxPowerManagement = false;
    private debouncedSaveProg: any;

    private mounted() {
      this.debouncedSaveProg = _.debounce(this.saveProg, 5000);
      this.usingGlobalConfig = rootScope.devId === undefined;
      this.deviceId = rootScope.devId || this.$devListService.getDevids(this.homeId)[0];
      this.maxPowerSlots = [];
      this.maxPowerProfiles = [];
      this.convertedProg = [];
      this.$userService.addListener(this.homeId, this.init);
      this.init();
    }

    private destroyed() {
      this.$userService.removeListener(this.homeId, this.init);
    }

    private init() {
      this.$devListService.getExtraHomeData(this.homeId).then(async (extraHomeData: any) => {
        this.maxPowerProfiles = extraHomeData.maxPowerProfiles || [];
        if (!_.isEqual(this.profiles, this.maxPowerProfiles)) {
          this.profiles = this.maxPowerProfiles;
        }
        if (!this.maxPowerProfilesDefined()) {
          this.displaySetupModal();
        } else {
          const updatedSlots = this.getMaxPowerSlots(
            this.$store.state.homes[this.homeId].devs[this.deviceId]?.pmo_system.max_power_config.slots,
          );
          if (!_.isEqual(this.maxPowerSlots, updatedSlots)) {
            this.maxPowerSlots = updatedSlots;
          }
          this.convertProg(this.maxPowerSlots);
        }
      });
      if (this.maxPowerSlots.length === 0 && this.maxPowerProfiles.length > 0) {
        this.generateSingleTariffProg();
      }
    }

    private get homeId() {
      return this.$route.params.homeid;
    }

    @Watch('maxPowerProfiles')
    private onMaxPowerProfilesChanged() {
      this.debouncedSaveProg();
    }

    @Watch('maxPowerSlots')
    private onMaxPowerSlotsChanged() {
      this.debouncedSaveProg();
    }

    private displaySetupModal(): any {
      if (this.maxPowerProfiles.length > 0) {
        this.displayMaxPowerModal();
        return;
      }
            this.showMaxPowerSetupModal = true;
    }

    private goToDay(index?: any): any {
      this.$userService.getLanguage().then(async (currentLanguage: any) => {
        let dayIndex;
        switch (currentLanguage) {
        case 'en':
          dayIndex = index;
          break;
        default:
          dayIndex = index + 1;
          if (dayIndex > 6) {
            dayIndex = 0;
          }
          break;
        }
        let params: any = {};
        params = { ...params };
        params.day = dayIndex;
        routerWrapper.push(this.$router, 'root.authenticated.root.editHome.maxPowerManagementDay', params);
      });
    }

    private getSlotStyle(slot?: any): any {
      return {
        backgroundColor: (this.maxPowerProfiles[slot.i] || {}).color,
        height: slot.duration / 2 + 'px'
      };
    }

    private maxPowerProfilesDefined(): any {
      return this.maxPowerProfiles !== undefined && this.maxPowerProfiles.length !== 0;
    }


    private async onMaxPowerSetupModalClosed(config?: any): Promise<any> {
      this.showMaxPowerSetupModal = false;
      if (config !== null && config.maxPowerProfiles !== undefined && config.maxPowerSlots !== undefined) {
        this.maxPowerProfiles = config.maxPowerProfiles;
        const rawSlots: any = _.map(config.maxPowerSlots, (slotData: any) => {
          return {
            i: slotData.index,
            m: slotData.minute
          };
        });
        this.maxPowerSlots = this.getMaxPowerSlots(rawSlots);
        this.convertProg(this.maxPowerSlots);
      }
      this.displayMaxPowerModal();
    }

    private onMaxPowerManagementModalClosed(profileData?: any): any {
      this.showMaxPowerManagementModal = false;
      if (!profileData) {
        return;
      }
      this.maxPowerProfiles = profileData;
      this.updateProg();
    }

    private displayMaxPowerModal(): any {
      this.profiles = undefined;
      this.showMaxPowerManagementModal = true;
    }

    private generateSingleTariffProg(): any {
      this.maxPowerSlots = [];
      for (let i: any = 0; i < 7; i++) {
        this.maxPowerSlots.push([{
            i: 0,
            color: this.maxPowerProfiles[0].color,
            m: 0,
            duration: 1440
          }]);
      }
      this.convertProg(this.maxPowerSlots);
    }

    private updateProg(): any {
      if (this.maxPowerProfiles.length === 0) {
        this.maxPowerSlots = [];
        return;
      }
      if (this.isProgEmpty()) {
        this.generateSingleTariffProg();
      }
    }

    private isProgEmpty(): any {
      return this.maxPowerSlots.length === 0
      || this.maxPowerProfiles.length === 0
      || _.findIndex(this.maxPowerSlots, (dayProg: any) => {
        return dayProg.length === 0;
      }) !== -1;
    }

    private savePmoSystem(devProxy?: any, rawProg?: any): any {
      return devProxy.setPmoSystemMaxPowerConfig({
        slots: rawProg,
        profiles: _.map(this.maxPowerProfiles, (data: any) => {
          return data.value;
        })
      });
    }

    private async saveHomeProg(rawProg?: any): Promise<any> {
      const devProxyList: any = (await this.$devListService.getList()).find((home: HomeData) => home.id === this.homeId).devs.map((dev: DevData) => dev.proxy);
      devProxyList.forEach((devProxy: IDevProxy) => {
        this.savePmoSystem(devProxy, rawProg);
      });
    }

    private async saveDevProg(rawProg?: any): Promise<any> {
      const devProxy: any = await this.$devListService.getDevProxy(this.homeId, this.deviceId);
      return this.savePmoSystem(devProxy, rawProg);
    }

    private async saveProg(): Promise<any> {
      if (this.isProgEmpty()) {
        return;
      }
      const rawProg: any = [];
      for (let i: any = 0; i < this.maxPowerSlots.length; i++) {
        for (let j: any = 0; j < this.maxPowerSlots[i].length; j++) {
          const slot: any = this.maxPowerSlots[i][j];
          rawProg.push({
            i: slot.i,
            m: slot.m + 1440 * i
          });
        }
      }
      await this.$devListService.updateExtraData(this.homeId, 'maxPowerProfiles', this.maxPowerProfiles);
      return this.usingGlobalConfig ? this.saveHomeProg(rawProg) : this.saveDevProg(rawProg);
    }

    private convertProg(rawProg?: any): any {
      this.convertedProg = [].concat(rawProg);
      this.$userService.getLanguage().then(async (currentLanguage: any) => {
        if (currentLanguage !== 'en') {
          this.convertedProg.push(this.convertedProg.shift());
        }
      });
    }

    private getMaxPowerSlots(data?: any): any {
      if (data === undefined) {
        return [];
      }
      const rawProg: any = [].concat(data);
      const prog: any = [];
      for (let i: any = 0; i < 7; i++) {
        prog.push([]);
      }
      for (let j: any = 0; j < rawProg.length; j++) {
        const day: any = Math.trunc(rawProg[j].m / 1440);
        const to: any = j < rawProg.length - 1 ? rawProg[j + 1].m : 10080;
        prog[day].push({
          i: rawProg[j].i,
          m: rawProg[j].m % 1440,
          duration: to - rawProg[j].m
        });
      }
      return prog;
    }

}
