import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
  },
})

export default class privacy extends Vue {

  private ppAccepted = false;

  private async mounted() {
    (document as any).getElementById('privacyLabel').innerHTML = ` ${this.$t('PP_READ_AND_ACCEPTED')}`
    .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost)
    .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost);
  }

  private acceptPrivacyPolicy(): any {
    this.$apiService.setPrivacy(this.$sessionService.getUserId(), true);
    this.$emit('close');
  }

}
