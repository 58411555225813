import { ISessionService, PrivacyResp } from './session.service';
import { IConfigService, HelkiConfiguration } from './config';
import {
  APIManager,
  NodeType,
} from '@vdi-helki/helki-node-management';

export interface ApiServiceSetup {
  host: string;
  oauthClientBasicAuthString: string;
  timeout?: number;
}

export interface VersionResp {
  major: number;
  minor: number;
  subminor: number;
  commit: string;
}

export interface SignUpResp {
  id: string;
}

export interface VerifyUserResp {
  id: string;
}

export interface GetUserPreferencesResp {
  lang: string;
}

export interface SetUserPreferencesReq {
  lang: string;
}

export interface RespDevData {
  dev_id: string;
  name: string;
  product_id: string;
  fw_version: string;
  serial_id: string;
}

export interface RespNodeData {
  type: string;
  addr: number;
  installed: boolean;
  lost: boolean;
}

export interface GetDevListResp {
  id: string;
  name: string;
  devs: RespDevData[];
  owner: boolean;
}

export interface GetNodeListResp {
  nodes: RespNodeData[];
}

export interface GeoDataPosition {
  longitude: number;
  latitude: number;
}

export interface GeoData {
  position?: GeoDataPosition;
  outdoor_range?: number;
  city?: string;
  country?: string;
  state?: string;
  tz_code?: string;
  zip?: string;
}

export interface AddDevReq {
  dev_id: string;
  serial_id: string;
  name: string;
  product_id?: string;
  tz_code: string;
  country: string;
  state: string;
  city: string;
  zip: string;
}

export interface ApiNodeSample {
  t: number;
  counter: number;
  temp?: string;
  min?: number;
  max?: number;
}

export interface GuestUser {
  email: string;
  pending: boolean;
}

export interface GroupData {
  id: string;
  name: string;
}

export interface DevTimeResp {
  y: number; // year
  n: number; // month
  d: number; // day
  h: number; // hour
  m: number; // minute
  s: number; // second
  w: number; // day of week
}

export interface IApiService {
  setHomeExtraData(homeId: string, data: any): Promise<void>;
  getLocationInfo(): Promise<any>;
  getVersion(): Promise<VersionResp>;
  getHost(): string;
  getFrontendHost(): Promise<string>;
  signIn(credentials: any, remember?: boolean): Promise<void>;
  signOut(): Promise<void>;
  signUp(email: string, password: string, serialId: string, language: string): Promise<SignUpResp>;
  getPrivacy(userId: string): Promise<PrivacyResp>;
  setPrivacy(userId: string, accepted: boolean): Promise<void>;
  verifyUser(user: string, code: string): Promise<VerifyUserResp>;
  getUserPreferences(): Promise<GetUserPreferencesResp>;
  setUserPreferences(preferences: SetUserPreferencesReq): Promise<void>;
  forgotUserPassword(user: string): Promise<void>;
  resetUserPassword(user: string, code: string): Promise<void>;
  changeUserPassword(oldPass: string, newPass: string): Promise<void>;
  changeUserEmail(password: string, newEmail: string): Promise<void>;
  confirmNewEmail(user: string, code: string): Promise<void>;
  getDevList(): Promise<GetDevListResp[]>;
  getNodeList(devId: string): Promise<GetNodeListResp[]>;
  setHomeName(homeId: string, name: string): Promise<void>;
  setDevName(devId: string, name: string): Promise<void>;
  getGeoData(homeId: string): Promise<GeoData>;
  setGeoData(devId: string, geoData: GeoData): Promise<void>;
  setDevAwayStatus(devId: string, away: boolean, enabled?: boolean): Promise<void>;
  checkConnection(devId: string): Promise<boolean>;
  getDiscovery(devId: string): Promise<boolean>;
  setDiscovery(devId: string, discovery: boolean, type?: NodeType, address?: string): Promise<void>;
  eraseNode(devId: string, addr: number, type: NodeType, purge?: boolean): Promise<void>;
  setNodeName(devId: string, addr: number, type: NodeType, name: string): Promise<void>;
  selectNode(devId: string, addr: number, type: NodeType, select: boolean): Promise<void>;
  getPower(devId: string, addr: number, type: NodeType): Promise<number>;
  setPowerLimit(devId: string, addr: number, type: NodeType, powerLimit: number): Promise<void>;
  setStatus(devId: string, addr: number, type: NodeType, data: any): Promise<void>;
  setProg(devId: string, addr: number, type: NodeType, data: any): Promise<void>;
  setSetup(devId: string, addr: number, type: NodeType, data: any): Promise<void>;
  setHtrSystemPowerLimit(devId: string, powerLimit: number): Promise<void>;
  setLockConfig(devId: string, addr: number, type: NodeType, data: any): Promise<void>;
  setProgTempConfig(devId: string, addr: number, type: NodeType, data: any): Promise<void>;
  setBoostConfig(devId: string, addr: number, type: NodeType, data: any): Promise<void>;
  setMode(devId: string, addr: number, type: NodeType, data: any): Promise<void>;
  getSamples(
    devId: string,
    addr: number,
    type: NodeType,
    from: number,
    to: number,
  ): Promise<ApiNodeSample[]>;
  addDev(data: AddDevReq, timeout?: number): Promise<void>;
  getGuests(devId: string): Promise<GuestUser[]>;
  inviteUser(userId: string, devId: string, email: string): Promise<void>;
  revokeInvite(userId: string, devId: string, email: string): Promise<void>;
  confirmInvite(userId: string, pass: string, code: string): Promise<void>;
  getTime(devId: string): Promise<DevTimeResp>;
  getDeviceLinkUrl(devId: string): string;
  updateDevGroup(devId: string, groupData: GroupData): Promise<void>;
  deleteDevice(devId: string): Promise<void>;
  setPmoSystem(devId: string, data: any): Promise<void>;
  getEncryptedWifiCredentials(ssid: string, password: string): Promise<void>;
  deleteUserAccount(user: string, code: string): Promise<void>;
}

export class ApiService implements IApiService {
  private axiosInstance: any;
  private frontendHost!: string;
  private apiManager!: APIManager;
  private readyPromise: Promise<any>;

  constructor(
    private setup: ApiServiceSetup,
    private sessionService: ISessionService,
    private configService: IConfigService,
    private store: any,
    private router: any,
  ) {
    this.setDefaultValues();
    this.readyPromise = this.configService.getConfig()
    .then((config: HelkiConfiguration) => {
      this.apiManager = new APIManager(this.setup.host, this.setup.timeout || 5000, config.serialId);
      if (this.sessionService.getSessionData()) {
        this.getPrivacy(this.sessionService.getUserId())
        .then((respData: PrivacyResp) => this.sessionService.setPrivacy(respData));
      }
    });
  }

  public async setHomeExtraData(homeId: string, extraData: any) {
    await this.readyPromise;
    return this.apiManager.setExtraGroupData(homeId, extraData, await this.getBearerToken());
  }

  public async getLocationInfo(): Promise<any> {
    await this.readyPromise;
    return this.apiManager.getLocationInfo()
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async getVersion(): Promise<VersionResp> {
    await this.readyPromise;
    return this.apiManager.getVersion()
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public getHost(): string {
    return this.setup.host;
  }

  public async getFrontendHost(): Promise<string> {
    return (await this.configService.getConfig()).frontendHost;
  }

  public async getPrivacy(userId: string): Promise<PrivacyResp> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getPrivacy(userId, bearerToken))
    .then((response) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async setPrivacy(userId: string, accepted: boolean): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setPrivacy(userId, accepted, bearerToken))
    .then(() => this.sessionService.setPrivacy({ accepted: true, date: new Date() }))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async setPmoSystem(devId: string, data: any) {
    await this.readyPromise;
    return this.apiManager.setPmoSystem(devId, data, await this.getBearerToken());
  }

  public async getEncryptedWifiCredentials(ssid: string, pass: string) {
    await this.readyPromise;
    return (await this.apiManager.getEncryptedWifiCredentials(
      ssid,
      pass,
      await this.getBearerToken(),
    ))?.data;
  }

  public async deleteUserAccount(userId: string, code: string): Promise<void> {
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.deleteUserAccount(userId, code, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async signUp(
    username: string,
    password: string,
    serialId: string,
    language: string,
  ): Promise<SignUpResp> {
    await this.readyPromise;
    return this.apiManager.signUp(username, password, serialId, language, this.frontendHost)
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async signIn(
    credentials: any,
    remember = false,
  ): Promise<void> {
    await this.readyPromise;
    return this.apiManager.signIn(credentials.username, credentials.password, this.setup.oauthClientBasicAuthString)
    .then((respData: any) => {
      this.sessionService.setPersistance(remember);
      this.sessionService.renewSession({
        token: respData.access_token,
        refreshToken: respData.refresh_token,
        username: credentials.username,
      });
      return this.getPrivacy(this.sessionService.getUserId());
    })
    .then((respData: PrivacyResp) => {
      this.sessionService.setPrivacy(respData);
    })
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async signOut(): Promise<void> {
    return Promise.resolve()
    .then(() => {
      this.sessionService.clearSession();
    });
  }

  public async verifyUser(user: string, code: string): Promise<VerifyUserResp> {
    await this.readyPromise;
    return this.apiManager.verifyUser(user, code)
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async getUserPreferences(): Promise<GetUserPreferencesResp> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getUserPreferences(bearerToken))
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async setUserPreferences(preferences: SetUserPreferencesReq): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setUserPreferences(preferences, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async forgotUserPassword(user: string): Promise<void> {
    await this.readyPromise;
    return this.apiManager.forgotUserPassword(user, this.getSerialId(), this.frontendHost)
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async resetUserPassword(user: string, code: string): Promise<void> {
    await this.readyPromise;
    return this.apiManager.resetUserPassword(user, code)
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async changeUserPassword(oldPass: string, newPass: string): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.changeUserPassword(oldPass, newPass, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async changeUserEmail(password: string, newEmail: string): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.changeUserEmail(
      password,
      newEmail,
      this.getSerialId(),
      this.frontendHost,
      bearerToken,
    ))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async confirmNewEmail(user: string, code: string): Promise<void> {
    await this.readyPromise;
    return this.apiManager.confirmNewEmail(user, code)
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async getDevList(): Promise<GetDevListResp[]> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getDevList(bearerToken))
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async getNodeList(devId: string): Promise<GetNodeListResp[]> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getNodeList(devId, bearerToken))
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async setHomeName(homeId: string, name: string): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setHomeName(homeId, name, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async setDevName(devId: string, name: string): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setDevName(devId, name, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async getGeoData(homeId: string): Promise<GeoData> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getGeoData(homeId, bearerToken))
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async setGeoData(homeId: string, geoData: GeoData): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setGeoData(homeId, geoData, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async setDevAwayStatus(
    devId: string,
    away: boolean,
    enabled = true,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setDevAwayStatus(devId, away, bearerToken, enabled))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async addDev(data: AddDevReq, timeout = 3000): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.addDev(data, bearerToken, timeout))
    .catch((error: any) => this.handleException(error, 'post', true));
  }

  public async checkConnection(devId: string): Promise<boolean> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.checkConnection(devId, bearerToken))
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async getDiscovery(devId: string): Promise<boolean> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getDiscovery(devId, bearerToken))
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async setDiscovery(
    devId: string,
    discovery: boolean,
    type?: NodeType,
    address?: string,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setDiscovery(devId, discovery, bearerToken, type, address, 1))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async eraseNode(
    devId: string,
    addr: number,
    type: NodeType,
    purge = false,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.eraseNode(devId, type, addr, bearerToken, purge))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async setNodeName(
    devId: string,
    addr: number,
    type: NodeType,
    name: string,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setNodeName(devId, type, addr, name, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async selectNode(
    devId: string,
    addr: number,
    type: NodeType,
    select: boolean,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.selectNode(devId, type, addr, select, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async getPower(
    devId: string,
    addr: number,
    type: NodeType,
  ): Promise<number> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getPower(devId, type, addr, bearerToken))
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async setPowerLimit(
    devId: string,
    addr: number,
    type: NodeType,
    powerLimit: number,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setPowerLimit(devId, type, addr, powerLimit, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  // TODO: Define interface for data argument
  public async setStatus(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setStatus(devId, addr, type, data, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  // TODO: Define interface for data argument
  public async setProg(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setProg(devId, addr, type, data, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  // TODO: Define interface for data argument
  public async setSetup(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setSetup(devId, addr, type, data, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async setHtrSystemPowerLimit(
    devId: string,
    powerLimit: number,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setHtrSystemPowerLimit(devId, powerLimit, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  // TODO: Define interface for data argument
  public async setProgTempConfig(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setProgTempConfig(devId, addr, type, data, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  // TODO: Define interface for data argument
  public async setMode(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setMode(devId, addr, type, data, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  // TODO: Define interface for data argument
  public async setLockConfig(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setLockConfig(devId, addr, type, data, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  // TODO: Define interface for data argument
  public async setBoostConfig(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.setBoostConfig(devId, addr, type, data, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async getSamples(
    devId: string,
    addr: number,
    type: NodeType,
    from: number,
    to: number,
  ): Promise<ApiNodeSample[]> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getSamples(devId, addr, type, from, to, bearerToken))
    .then((response: any) => response.data)
    .then((resp: any) => (resp?.samples || []).map((sample: any) => ({
      t: sample.t * 1000,
      counter: Number(sample.counter),
      temp: sample.temp ? Number(sample.temp) : undefined,
      max: sample.max ? Number(sample.max) : undefined,
      min: sample.min ? Number(sample.min) : undefined,
      valley: sample.valley,
    })))
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async getGuests(homeId: string): Promise<GuestUser[]> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getGuests(homeId, bearerToken))
    .then((response: any) => response?.data?.guest_users || [])
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async inviteUser(userId: string, homeId: string, email: string): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.inviteUser(
      userId,
      homeId,
      email,
      this.getSerialId(),
      this.frontendHost,
      bearerToken,
    ))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async revokeInvite(userId: string, homeId: string, email: string): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.revokeInvite(userId, homeId, email, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async confirmInvite(userId: string, pass: string, code: string): Promise<void> {
    await this.readyPromise;
    return this.apiManager.confirmInvite(userId, pass, code)
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async getTime(devId: string): Promise<DevTimeResp> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.getTime(devId, bearerToken))
    .then((response: any) => response.data)
    .catch((error: any) => this.handleException(error, 'get'));
  }

  public async updateDevGroup(devId: string, groupData: GroupData): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.updateDevGroup(devId, groupData, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public async deleteDevice(devId: string): Promise<void> {
    await this.readyPromise;
    return this.getBearerToken()
    .then((bearerToken) => this.apiManager.deleteDevice(devId, bearerToken))
    .catch((error: any) => this.handleException(error, 'post'));
  }

  public getDeviceLinkUrl(): string {
    return `${this.setup.host}/client/code`;
  }

  private async setDefaultValues(): Promise<void> {
    this.setup.timeout = this.setup.timeout || 10000;
    this.frontendHost = await this.getFrontendHost();
  }

  private getXReferer(): string|null {
    if (!this.frontendHost) {
      return null;
    }
    const parts = this.frontendHost.split('/');
    if (parts.length < 3) {
      return null;
    }
    return parts.slice(0, 3).join('/');
  }

  private async getBearerToken(): Promise<string> {
    return Promise.resolve()
    .then(() => {
      if (this.sessionService.hasTokenExpired() && !this.store.state.demo) {
        return this.refreshSession();
      }
    })
    .then(() => {
      return this.sessionService.getSessionData()?.token || '';
    });
  }

  private getRefreshToken(): string|null {
    try {
      return this.sessionService.getSessionData()?.refreshToken || '';
    } catch (e) {
      return null;
    }
  }

  private async refreshSession(): Promise<void> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return Promise.reject(new Error('no refresh token available'));
    }
    await this.readyPromise;
    return this.apiManager.oauthRequest(
      'post',
      '/client/token',
      this.setup.oauthClientBasicAuthString,
      { refresh_token: refreshToken, grant_type: 'refresh_token' },
    )
    .then((respData: any) => {
      const currentSessionData = this.sessionService.getSessionData();
      this.sessionService.renewSession({
        token: respData.access_token,
        refreshToken: respData.refresh_token,
        username: currentSessionData?.username || '',
      });
      return this.getPrivacy(this.sessionService.getUserId());
    })
    .then((respData: PrivacyResp) => {
      this.sessionService.setPrivacy(respData);
    })
    .catch((error: any) => this.handleException(error, 'post'));
  }

  private getSerialId(): string {
    return this.store.state.serialid.replace(/\./g, '');
  }

  private async handleException(error: any, method: string, throwError = false): Promise<any> {
    // Handle auth error
    if (!!error && !!error.response && error.response.status === 401) {
      this.sessionService.clearSession();
      this.router.replace({ name: 'main' });
      throw error;
    }
    return Promise.resolve()
    .then(() => {
      if (!error.response || !error.response.status) {
        return undefined;
      }
      let errorType = this.store.state.errors.unknown;
      if (error.response.status === 408) {
        errorType = this.store.state.errors.connection;
      } else if (method === 'get') {
        errorType = this.store.state.errors.dataUpdate;
      } else if (method === 'post') {
        errorType = this.store.state.errors.userRequest;
      }
      this.store.commit('setError', errorType);
      if (throwError) {
        throw error;
      }
    });
  }
}
