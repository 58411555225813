import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})

export default class Redirect extends Vue {

    private mounted() {
      this.checkSessionActive();
    }
  
    private checkSessionActive(): any {
      this.$userService.refreshSession().then(async () => {
        this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }).catch((err: any) => {
        if (err.status >= 400 && err.status < 500) {
          this.$router.replace({ name: 'root.anonymous.signIn', query: {} });
        } else {
          this.$router.replace({ name: 'root.maintenanceError', query: {} });
        }
      });
    }
    
}
