import { Component, Vue, Watch } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import HtrSystemSetupModal from '@/components/modals/htrSystemSetup/htrSystemSetup.vue';
import Slider from '@/components/slider/Slider.vue';
import {
  NodeCapability,
  NodeStatusIndicator,
  NodeIndicator,
  NodeControllerSet,
  NodeController,
  HeatingNodeController,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    HtrSystemSetupModal,
  },
})
export default class Heating extends Vue {

    private nodeGroups: any = {};
    private capabilities: any = {};
    private chargeLevelStyle: any = {};
    private showHtrSystemSetupModal = false;
    private showClassifiedNodes = false;
    private showHtrSystemSetup = false;
    private showNewFeaturesInfoModal = true;

    private mounted() {
      this.nodeGroups = [];
      this.capabilities = NodeCapability;
      this.chargeLevelStyle = {};
      if (!this.homeId) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      this.$userService.addListener(this.homeId, this.homeListener);
      this.refreshList();
      document.addEventListener('resume', this.refreshList, false);
    }

    private destroyed() {
      this.$userService.removeListener(this.homeId, this.homeListener);
      document.removeEventListener('resume', this.refreshList, false);
    }

    private get homeId() {
      return this.$route.params.homeid;
    }

    private get homeDevices() {
      return this.$store.state.homes[this.homeId]?.devs;
    }

    private get selectedNodes() {
      return this.$store.state.selectedNodes[this.homeId];
    }

    @Watch('homeId')
    private onRouteParamsChanged() {
      this.$store.commit('deselectNodes');
      this.homeListener();
    }

    @Watch('homeDevices')
    private onHomeDataChanged() {
      this.homeListener();
    }

    @Watch('selectedNodes')
    private onSelectedNodeChanged() {
      this.homeListener();
    }

    private isCharging(nodeController: HeatingNodeController): any {
      const statusIndicators: any = nodeController?.getStatusIndicators();
      return statusIndicators.findIndex((indicatorData: NodeStatusIndicator) => {
        return indicatorData.id === NodeIndicator.CHARGING && indicatorData.active;
      }) !== -1;
    }

    private isHeating(nodeController?: any): any {
      const statusIndicators: any = nodeController?.getStatusIndicators((nodeController?.nodeData || {}).status || {});
      return statusIndicators.findIndex((indicatorData: NodeStatusIndicator) => {
        return indicatorData.id === NodeIndicator.ACTIVE && indicatorData.active;
      }) !== -1;
    }

    private isCooling(nodeController?: any): any {
      const statusIndicators: any = nodeController?.getStatusIndicators((nodeController?.nodeData || {}).status || {});
      return statusIndicators.findIndex((indicatorData: NodeStatusIndicator) => {
        return indicatorData.id === NodeIndicator.COOLING && indicatorData.active;
      }) !== -1;
    }

    private isInValley(nodeController?: any): any {
      if (!(nodeController?.nodeData || {}).setup) {
        return false;
      }
      const currentDate: any = new Date();
      const currentDayMinute: any = currentDate.getHours() * 60 + currentDate.getMinutes();
      function checkSlotInValley(slotData: any) {
        if (slotData.start === slotData.end) {
          return false;
        }
        if (slotData.start > slotData.end) {
          if (currentDayMinute >= slotData.start) {
            return true;
          }
          if (currentDayMinute < slotData.end) {
            return true;
          }
        }
        if (slotData.start < slotData.end) {
          if (currentDayMinute >= slotData.start && currentDayMinute < slotData.end) {
            return true;
          }
        }
        return false;
      }
      const slot1InValley: any = checkSlotInValley(((nodeController?.nodeData.setup || {}).charging_conf || {}).slot_1 || {});
      const slot2InValley: any = checkSlotInValley(((nodeController?.nodeData.setup || {}).charging_conf || {}).slot_2 || {});
      return slot1InValley || slot2InValley;
    }

    private goToHeater(heater: any): any {
      const devId = this.getDevId(heater);
      const addr = heater.nodeData.addr;
      rootScope.thmSelectedList = [];
      rootScope.htrSelectedList = [];
      rootScope.acmSelectedList = [];
      rootScope.htrModSelectedList = [];
      return this.$devListService.checkDevConnection(this.homeId, devId).then(async (connected: any) => {
        const htr: any = this.getNode(devId, addr, 'htrList', (nodeController: any) => {
          return nodeController?.nodeData.addr === addr && nodeController?.devId === devId;
        }) || this.getNode(devId, addr, 'acmList', (nodeController: any) => {
          return nodeController?.nodeData.addr === addr && nodeController?.devId === devId;
        }) || this.getNode(devId, addr, 'thmList', (nodeController: any) => {
          return nodeController?.nodeData.addr === addr && nodeController?.devId === devId;
        }) || this.getNode(devId, addr, 'htrModList', (nodeController: any) => {
          return nodeController?.nodeData.addr === addr && nodeController?.devId === devId;
        });
        const type: any = this.getFullNodeType((htr.nodeData || {}).type);
        const nodeControllerList = [
          ...(window as any).htrSelectedList || [],
          ...(window as any).acmSelectedList || [],
          ...(window as any).thmSelectedList || [],
        ]
        .filter((nodeController) => {
          return nodeController.devId !== devId
          || Number(nodeController.nodeData.addr) !== Number(addr);
        });
        nodeControllerList.unshift(await this.$devListService.getNodeController(this.homeId, devId, type, Number(addr)));
        window.nodeSet = new NodeControllerSet(nodeControllerList);
        window.devId = devId;
        window.connected = connected;
        if ((window as any).connected && !(htr.nodeData || {}).lost && (htr.nodeData || {}).status !== undefined) {
          routerWrapper.push(this.$router, `root.authenticated.root.device.${ type }.nav.temperature`, {
            devid: devId,
            addr: addr
          });
        } else {
          const date: any = new Date();
          routerWrapper.push(this.$router, `root.authenticated.root.device.${ type }.nav.stats.day`, {
            devid: devId,
            addr: addr,
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate()
          });
        }
      }).catch(() => {
        routerWrapper.push(this.$router, 'root.authenticated.root');
      });
    }

    private goToDevHtrSystemSetup(devId?: any): any {
      this.$devListService.getDevHtrSystemPowerLimit(this.homeId, devId).then(async (powerLimit: any) => {
        rootScope.currentDevHtrSystemPowerLimit = powerLimit;
        rootScope.devId = devId;
                this.showHtrSystemSetupModal = true;
      });
    }

    private getSetTemperatureIntegerString(thermostat?: any): any {
      if (!this.$helkiNMService.isNodeReady(thermostat)) {
        return;
      }
      const setTempString: any = String((thermostat.getModeParams(thermostat.getMode() || {}) || {}).temperature);
      if (!setTempString) {
        return '';
      }
      return setTempString.split('.')[0] + '.';
    }

    private getSetTemperatureDecimalString(thermostat?: any): any {
      if (!this.$helkiNMService.isNodeReady(thermostat)) {
        return;
      }
      const setTempString: any = String((thermostat.getModeParams(thermostat.getMode() || {}) || {}).temperature);
      if (!setTempString) {
        return '';
      }
      return setTempString.split('.')[1] || '0';
    }

    private updateSelectedList(): any {
      this.nodeGroups.forEach((nodeGroup: any) => {
        const nodeList = [
          ...nodeGroup.thmList,
          ...nodeGroup.htrList,
          ...nodeGroup.acmList,
          ...nodeGroup.htrModList,
        ];
        _.forEach(nodeList, (item: any) => {
          const selectionData = {
            homeId: this.homeId,
            devId: item.devId,
            addr: item.nodeData.addr,
            type: item.nodeData.type,
          };
          if (item.checked) {
            this.$store.commit('selectNode', selectionData);
          } else {
            this.$store.commit('deselectNode', selectionData);
          }
        });
      });
    }

    private getCurrentModeString(nodeController?: any): any {
      if (!this.$helkiNMService.isNodeReady(nodeController)) {
        return;
      }
      switch (nodeController?.getMode()) {
      case 'modified_auto':
        return 'AUTO';
      case 'self_learn':
        return 'SELF_LEARN_SHORT';
      case 'ice':
        return 'ANTI_ICE';
      case 'off':
        return;
      default:
        return nodeController?.getMode().toUpperCase();
        break;
      }
    }

    private truncateString(str: string, num: number): string {
      return str.length > num ? str.slice(0, num) + '.' : str;
    }

    private showModeIcon(nodeController?: any): any {
      if (!this.$helkiNMService.isNodeReady(nodeController)) {
        return false;
      }
      const mode: any = nodeController?.getMode();
      return mode === 'auto' || mode === 'self_learn' || mode === 'presence';
    }

    private nodeChecked(addr?: any, devId?: any): any {
      return Array.from(this.$store.state.selectedNodes[this.homeId] || new Set())
      .findIndex((selectionData: any) => {
        const selectionObject = JSON.parse(selectionData);
        return selectionObject.addr === addr && selectionObject.devId === devId;
      }) !== -1;
    }

    private async refreshList() {
      await this.$devListService.updateList();
      await this.updateList();
    }

    private async updateList() {
      const homeList = await this.$devListService.getList();
      if (homeList.length === 0) {
        return this.$router.replace({ name: 'root.authenticated.root.welcome', query: {} });
      }
      this.nodeGroups = [];
      return this.$devListService.getDevClassifiedNodeList(this.homeId).then(async (classifiedNodeList: any) => {
        this.showClassifiedNodes = !this.$devListService.hasSingleDevice(this.homeId);
        rootScope.hasSingleSmartbox = this.$devListService.hasSingleSmartbox(this.homeId);
        this.nodeGroups = classifiedNodeList || [];
        this.nodeGroups.forEach((nodeGroup: any) => {
          nodeGroup.thmList = _.filter(nodeGroup.nodeList, (node: any) => {
            node.checked = this.nodeChecked((node.nodeData || {}).addr, node.devId);
            return (node.nodeData || {}).type === 'thm';
          });
          nodeGroup.htrList = _.filter(nodeGroup.nodeList, (node: any) => {
            node.checked = this.nodeChecked((node.nodeData || {}).addr, node.devId);
            return (node.nodeData || {}).type === 'htr';
          });
          nodeGroup.acmList = _.filter(nodeGroup.nodeList, (node: any) => {
            node.checked = this.nodeChecked((node.nodeData || {}).addr, node.devId);
            const isStorageHeater: any = (node.nodeData || {}).type === 'acm';
            if (isStorageHeater) {
              this.chargeLevelStyle[(node.nodeData || {}).devId + '.' + (node.nodeData || {}).addr] = this.getChargeLevelStyle(node);
            }
            return isStorageHeater;
          });
          nodeGroup.htrModList = _.filter(nodeGroup.nodeList, (node: any) => {
            node.checked = this.nodeChecked((node.nodeData || {}).addr, node.devId);
            return (node.nodeData || {}).type === 'htr_mod';
          });
          const hasAnyPmoInstalled: any = nodeGroup.nodeList.findIndex((node: NodeController) => {
            return (node as any).nodeData?.type === 'pmo';
          }) !== -1;
          if (nodeGroup.htrList.length > 0 || nodeGroup.acmList.length > 0 || nodeGroup.htrModList.length > 0 || hasAnyPmoInstalled) {
            nodeGroup.showSmartboxConfig = true;
          }
        });
      });
    }

    private homeListener(): any {
      return this.updateList();
    }

    private getNode(devId?: any, addr?: any, list?: any, comparisonFunction?: any): any {
      let node;
      for (let i: any = 0; i < this.nodeGroups.length && !node; i++) {
        node = _.find(this.nodeGroups[i][list], comparisonFunction);
      }
      return node;
    }

    private getChargeLevel(data?: any): any {
      if (!data || !data.status) {
        return 0;
      }
      switch (((data.version || {}).pid || '').toUpperCase().slice(2, 4)) {
      case '1C': {
        const actualChargeLevel: any = data.status.current_charge_per;
        const isCharging: any = data.status.charging;
        return isCharging ? Math.max(20, actualChargeLevel) : actualChargeLevel;
      }
      default:
        return data.status.charge_level;
      }
    }

    private getChargeLevelStyle(storageHeater?: any): any {
      if ((storageHeater.nodeData || {}).lost) {
        return { width: '0%' };
      }
      return { width: String(this.getChargeLevel(storageHeater.nodeData || {})) + '%' };
    }

    private getFullNodeType(nodeType?: any): any {
      switch (nodeType) {
      case 'thm':
        return 'thermostat';
      case 'htr':
        return 'heater';
      case 'acm':
        return 'storageheater';
      case 'htr_mod':
        return 'heaterMod';
      }
    }

    private onHtrSystemSetupModalClosed(): any {
      this.showHtrSystemSetupModal = false;
      this.$apiService.setHtrSystemPowerLimit((window as any).devId, rootScope.currentDevHtrSystemPowerLimit);
    }

    private getDevType(pid: any): string {
      return this.$devListService.getDevProductName(pid);
    }

    private isWifiHeater(pid: any): boolean {
      return this.$devListService.getDevProductName(pid) === 'wifiHtr';
    }

    private isDevidConnected(devId: any, pid: any) {
      return (this.$store.state.homes[this.homeId]?.devs || {})[devId]?.connected || this.isWifiHeater(pid);
    }

    private getDevId(heater: any): any {
      return heater.devId ? heater.devId : heater.nodeData.devId;
    }
}
