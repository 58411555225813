import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from '@/services/_';
import window from '@/services/window';
import { sampleTools } from '@/services/sampleTools';
import routerWrapper from '@/services/routerWrapper';
import BPromise from 'bluebird';
import Slider from '@/components/slider/Slider.vue';
import { Bar } from 'vue-chartjs/legacy';
import 'chartjs-adapter-date-fns';
import {
  Chart,
  TimeScale,
  LinearScale,
  BarElement,
  LineElement,
  LineController,
  PointElement,
  Legend,
  Tooltip,
} from 'chart.js'
Chart.register(TimeScale);
Chart.register(LinearScale);
Chart.register(BarElement);
Chart.register(LineElement);
Chart.register(LineController);
Chart.register(PointElement);
Chart.register(Legend);
Chart.register(Tooltip);
import {
  NodeType,
  NodeSampleKey,
  NodeSample,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    Bar,
  },
})
export default class Day extends Vue {

    private consumptionString: any = {};
    private nodeSet: any = {};
    private data: any = {};
    private consumptionColor: any = {};
    private loadConsumptionColor: any = {};
    private temperatureColor = '#2CB7D8';
    private tempString: any = {};
    private usageString: any = {};
    private loadUsageString: any = {};
    private nonLoadUsageString: any = {};
    private powerString: any = {};
    private setup: any = {};
    private fahrenheit: any = {};
    private samples: any = [];
    private nonLoadSamples: any = {};
    private tempData: any = {};
    private usageTimeData: any = {};
    private months: any = {};
    private nodeType: any = {};
    private yData: any = {};
    private max: any = {};
    private lastY: any = {};
    private lastUsageY: any = {};
    private showTooltip = false
    private tooltipText: any = {};
    private tooltipPos: any = {};
    private debouncedInitialize: any;
    private chartData: any = {};
    private chartOptions: any = {};
    private nodeName = '';
    private language!: string;

    private async mounted() {
      Chart.register({
        id: 'backgroundPlugin',
        beforeDraw: function (chart) {
          const ctx = chart.ctx;
          const chartArea = chart.chartArea;
          ctx.save();
          const gradient = ctx.createLinearGradient(0, 0, 0, 500);
          gradient.addColorStop(0, "#fff");
          gradient.addColorStop(1, "#ddd");
          ctx.fillStyle = gradient;
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
          ctx.restore();
        }
      });
      this.$eventHub.$on('saveCsv', this.saveCsv);
      this.debouncedInitialize = _.debounce(this.initialize, 100);
      this.consumptionString = 'consumption';
      this.nodeSet = (window as any).nodeSet?.nodeControllers[0];
      this.nodeName = this.nodeSet.nodeData?.name || '';
      this.data = [];
      this.consumptionColor = (await this.$configService.getConfig()).mainColor;
      this.loadConsumptionColor = '#7AB353';
      this.tempString = '';
      this.usageString = '';
      this.loadUsageString = '';
      this.nonLoadUsageString = '';
      this.powerString = '';
      this.setup = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).setup || {};
      this.fahrenheit = this.setup && this.setup.units === 'F';
      this.samples = [];
      this.nonLoadSamples = [];
      this.tempData = [];
      this.usageTimeData = [];
      this.months = [];
      this.nodeType = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type || 'htr';
      this.initStrings();
      this.initialize();
      this.language = await this.$userService.getLanguage();
    }

    private destroyed() {
      Chart.unregister({ id: 'backgroundPlugin' });
    }

    private get noNullValues(): any {
      this.yData = this.nodeType === 'thm' ? this.samples.map((sample: NodeSample) => sample.usageTime || 0) : this.samples.map((sample: NodeSample) => sample.totalConsumption || 0);
      return _.filter(this.yData, (value: any) => {
        return !!value;
      });
    }

    private async onZoomIn () {
      this.yData = this.nodeType === 'thm' ? this.samples.map((sample: NodeSample) => sample.usageTime || 0) : this.samples.map((sample: NodeSample) => sample.totalConsumption || 0);
      if (this.noNullValues.length === 0) {
        return;
      }
      this.max = Math.max.apply(null, this.yData) || 0;
      const ceilValue = this.nodeType === 'thm' ? 10 : 100;
      const roundMax = (this.max > 100) ? Math.ceil(this.max / ceilValue) * ceilValue : Math.ceil(this.max);
      this.lastY = this.nodeType === 'thm' ? this.getThermostatTimeLimit() : this.getTemperatureLimit();
      let step = 0;
      if (this.max !== 0) {
        step = 0.1;
      }
      while (this.lastY > roundMax + step && roundMax !== 0) {
        this.lastY -= step;
      }
      await this.plot({ usageYAxis: this.getUsageYAxisOpts(Math.max(roundMax, this.lastY)) });
    }

    private async onZoomOut () {
      await this.plot({ usageYAxis: this.getDefaultUsageYAxisOpts() });
    }

    private get year() {
      return Number(this.$route.params.year);
    }

    private get month() {
      return Number(this.$route.params.month);
    }

    private get day() {
      return Number(this.$route.params.day);
    }

    private get homeId(): string {
      return this.$route.params.homeid;
    }

    private get addr(): string {
      return this.$route.params.addr;
    }

    private get devId(): string {
      return this.$route.params.devid;
    }

    @Watch('addr')
    @Watch('homeId')
    @Watch('devId')
    @Watch('day')
    @Watch('month')
    @Watch('year')
    private onDateChanged() {
      this.debouncedInitialize();
    }

    private onClick(data: any): any {
      const currentNodeType: any = this.getNodeType();
      if (!isNaN(Number(this.day))) {
        return;
      }
      if (data.length > 0 && !isNaN(Number(this.month))) {
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ currentNodeType }.nav.stats.day`, {
          year: Number(this.$route.params.year),
          month: Number(this.$route.params.month) || 0,
          day: data[0].index + 1,
        });
      } else if (data.length > 0 && !isNaN(this.year)) {
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ currentNodeType }.nav.stats.month`, {
          year: Number(this.$route.params.year),
          month: data[0].index,
        });
      }
    }

    private getTempUnitsString(): any {
      return this.fahrenheit ? 'ºF' : 'ºC';
    }

    private async initStrings(): Promise<any> {
      this.tempString = await this.$t('TEMPERATURE');
      this.usageString = await this.$t('USAGE');
      this.loadUsageString = await this.$t('USAGE_VALLEY');
      this.nonLoadUsageString = await this.$t('USAGE_PEAK');
      this.powerString = await this.$t('POWER');
      this.months = await BPromise.map([
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
      ], (monthKey) => this.$t(monthKey));
    }

    private getTemperatureLimit(): any {
      if (!isNaN(Number(this.day))) {
        return 4;
      } else if (!isNaN(Number(this.month))) {
        return 96;
      } else {
        return 3600;
      }
    }

    private getMonthShortName(index?: any): any {
      const windowWidth: any = (window as any).innerWidth;
      return windowWidth >= 400 ? this.months[index] : this.months[index].substring(0, 1);
    }

    private getXAxisOptions(): any {
      const max: any = Math.max(this.tempData.length, this.samples.length);
      const ticks: any = [];
      let tickUnits;
      let maxConsumptionValue;
      if (!isNaN(Number(this.day))) {
        tickUnits = 'hour';
        maxConsumptionValue = this.nodeType === NodeType.THM ? 60 : 4;
        for (let i = 0; i < max; i++) {
          const labelDate = new Date(this.samples[i].timestamp);
          ticks.push(labelDate);
        }
      } else if (!isNaN(Number(this.month))) {
        tickUnits = 'day';
        maxConsumptionValue = this.nodeType === NodeType.THM ? 24 : 96;
        for (let i = 0; i < max; i ++) {
          const labelDate = new Date(this.year, this.month, i + 1);
          labelDate.setMinutes(0);
          labelDate.setHours(0);
          ticks.push(labelDate);
        }
      } else {
        tickUnits = 'month';
        maxConsumptionValue = this.nodeType === NodeType.THM ? 720 : 3600;
        for (let i = 0; i < max; i++) {
          const labelDate = new Date(this.year, i);
          labelDate.setMinutes(0);
          labelDate.setHours(0);
          ticks.push(labelDate);
        }
      }
      return {
        ticks,
        tickUnits,
        maxConsumptionValue,
      };
    }

    private getMaxRoundValue(maxYValue: number): number {
      return (maxYValue > 100) ? Math.ceil((maxYValue * 5 / 4.5) / 100) * 100 : Math.round((maxYValue * 5 / 4.5) / 10) * 10;
    }

    private getUsageYAxisOpts(lastY?: any, minValue?: any): any {
      const opts: any = {
        min: minValue || 0,
        max: lastY,
        position: 'right'
      };
      const ticks: any = [];
      for (let i: any = 0; i < 5; i++) {
        ticks.push(opts.min + (lastY - opts.min) * i / 4);
      }
      opts.ticks = ticks;
      return opts;
    }

    private getDefaultUsageYAxisOpts(): any {
      this.lastY = this.getTemperatureLimit();
      let minValue: any = 0;
      if (this.nodeType === 'thm') {
        const samples = this.samples.map((sample: NodeSample) => sample.usageTime || 0);
        this.max = Math.max.apply(null, samples) || 0;
        this.lastUsageY = Math.min(this.getThermostatTimeLimit(), this.max);
      } else {
        const maxSample: any = _.maxBy(this.samples, (sample: NodeSample) => Math.abs(sample.totalConsumption || 0));
        if (this.samples.findIndex((sample: NodeSample) => !!sample.totalConsumption && sample.totalConsumption < 0) === -1) {
          this.lastUsageY = Math.round(maxSample.totalConsumption);
        } else if (this.samples.findIndex((sample: NodeSample) => !!sample.totalConsumption && sample.totalConsumption > 0) === -1) {
          minValue = Math.round(maxSample.totalConsumption);
          this.lastUsageY = 0;
        }
      }
      const lastUsageY = (this.lastUsageY > 100) ? this.getMaxRoundValue(Math.round(this.lastUsageY)) : Math.ceil((this.lastUsageY * 5 / 4.5) / 10) * 10;
      return this.getUsageYAxisOpts(lastUsageY, minValue);
    }

    private getChartData(chartSamples?: any, id?: any): any {
      const key: any = isNaN(id) && id !== undefined ? id : 'consumption';
      return chartSamples.map((sample: NodeSample, index: any) => {
        return [
          index,
          (sample as any)[key] !== undefined ? (sample as any)[key] : null
        ];
      });
    }

    private getSampleDataset(sampleKey: NodeSampleKey) {
      switch(sampleKey) {
        case NodeSampleKey.TEMPERATURE:
          return {
            label: this.tempString + ' (' + this.getTempUnitsString() + ')',
            type: 'line',
            data: this.tempData.map((sample: any) => ({
              x: sample[2],
              y: sample[1],
            })),
            borderColor: this.temperatureColor,
            backgroundColor: this.temperatureColor,
            cubicInterpolationMode: 'monotone',
          };
        case NodeSampleKey.USAGE_TIME: {
          const usageLabel: any = !isNaN(Number(this.day)) ? ' (min.)' : ' (h)';
          return {
            label: this.usageString + usageLabel,
            type: 'bar',
            data: this.usageTimeData.map((sample: NodeSample) => ({
              x: sample.timestamp,
              y: sample.usageTime,
            })),
            borderColor: this.consumptionColor,
            backgroundColor: this.consumptionColor,
            yAxisID: 'consumptionAxis',
            minBarLength: 3,
            cubicInterpolationMode: 'monotone',
          };
        }
        case NodeSampleKey.CONSUMPTION: {
          const color = this.nodeType === NodeType.PMO ? this.temperatureColor : this.consumptionColor;
          const units = this.nodeType === NodeType.PMO && !isNaN(this.day) ? 'W' : 'kWh';
          let title;
          if (this.nodeType === NodeType.PMO && !isNaN(this.day)) {
            title = this.powerString;
          } else if (this.nodeType === NodeType.ACM) {
            title = this.nonLoadUsageString;
          } else {
            title = this.usageString;
          }
          return {
            label: `${title} (${units})`,
            type: this.nodeType === NodeType.PMO && !isNaN(this.day) ? 'line' : 'bar',
            data: this.samples.map((sample: NodeSample) => ({
              x: sample.timestamp,
              y: sample.loadConsumption || sample.consumption,
            })),
            borderColor: color,
            backgroundColor: color,
            yAxisID: 'consumptionAxis',
            minBarLength: 3,
            cubicInterpolationMode: 'monotone',
          };
        }
        case NodeSampleKey.LOAD_CONSUMPTION:
          return {
            label: this.loadUsageString + ' (kWh)',
            data: this.nonLoadSamples.map((sample: NodeSample) => ({
              x: sample.timestamp,
              y: sample.consumption,
            })),
            borderColor: this.loadConsumptionColor,
            backgroundColor: this.loadConsumptionColor,
            yAxisID: 'consumptionAxis',
            minBarLength: 3,
            cubicInterpolationMode: 'monotone',
          };
        default:
      }
    }

    private get datasets() {
      const sampleKeys = (window as any).nodeSet.getAvailableSampleKeys();
      return sampleKeys
      .filter((sampleKey: NodeSampleKey) => ![
        NodeSampleKey.GENERATION,
        NodeSampleKey.TOTAL_CONSUMPTION,
      ].includes(sampleKey))
      // Sort sample keys, so that bars don't show over line
      .sort((sampleKey1: NodeSampleKey, sampleKey2: NodeSampleKey) => {
        if (sampleKey1 === NodeSampleKey.TEMPERATURE) {
          return -1;
        } else if (sampleKey2 === NodeSampleKey.TEMPERATURE) {
          return 1;
        } else if (sampleKey1 === NodeSampleKey.LOAD_CONSUMPTION) {
          return 1;
        } else {
          return -1;
        }
      })
      .map((sampleKey: NodeSampleKey) => this.getSampleDataset(sampleKey));
    }

    private getFirstLetterCapitilaze(text: string): string {
      if (!text) return text;
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    private async plot(opts?: any): Promise<any> {
      const options = this.getXAxisOptions();
      const maxYValue = opts?.usageYAxis?.max || options.maxConsumptionValue;
      const maxTempValue = this.fahrenheit ? 100 : 40;
      this.chartOptions = {
        aspectRatio: '1',
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
              title: (items: any) => {
                const index = items[0].dataIndex;
                let labelDate;
                if (!_.isNaN(Number(this.day))) {
                  labelDate = new Date(items[0].parsed.x)
                  const labelString = labelDate.toLocaleString(this.language, { hour: "2-digit",
                                                                                minute: "2-digit",
                                                                                weekday: "short",
                                                                                day: "numeric",
                                                                                year: "numeric",
                                                                                month: "short" });
                  return this.getFirstLetterCapitilaze(labelString);
                }
                 if (!_.isNaN(Number(this.month))) {
                  labelDate = new Date(this.year, this.month, 1);
                  labelDate.setDate(index + 1);
                  const labelString = labelDate.toLocaleString(this.language, { weekday: "short",
                                                                                day: "numeric",
                                                                                year: "numeric",
                                                                                month: "short" });
                  return this.getFirstLetterCapitilaze(labelString);
                } else {
                  labelDate = new Date(this.year, 0, 1);
                  labelDate.setMonth(index);
                  const labelString = labelDate.toLocaleString(this.language, { month: "long", year: "numeric"});
                  return this.getFirstLetterCapitilaze(labelString);
                }
              },
              label: (context: any) => {
                const value = context.parsed.y || 0;
                const fractionDigits = context.dataset.label.includes(this.tempString) ? 1 : 3;
                const formattedValue = value.toLocaleString(this.language, {
                    minimumFractionDigits: fractionDigits,
                    maximumFractionDigits: fractionDigits,
                });
                return context.dataset.label + ': ' + formattedValue;
              }
            }
          },
        },
        scales: {
          x: {
            labels: options.ticks,
            type: 'time',
            display: true,
            bounds: 'ticks',
            time: {
              unit: options.tickUnits,
              stepSize: 1,
              displayFormats: {
                hour: 'HH:mm',
                day: 'dd',
                month: 'MM',
              },
            },
            ticks: {
              fontFamily: 'Open Sans Condensed',
              fontSize: 14,
              autoSkip: true,
              maxTicksLimit: 5,
              source: 'labels',
              maxRotation: 0,
              callback: (value: number) => {
                return value;
              },
            },
            stacked: true,
          },
          y: {
            display: this.nodeType !== NodeType.PMO,
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              fontFamily: 'Open Sans Condensed',
              fontSize: 14,
              autoSkip: true,
              maxTicksLimit: 5,
              stepSize: maxTempValue / 4,
            },
            bounds: 'ticks',
            max: maxTempValue,
            stacked: true,
          },
          consumptionAxis: {
            beginAtZero: true,
            position: 'right',
            ticks: {
              fontFamily: 'Open Sans Condensed',
              fontSize: 14,
              autoSkip: true,
              maxTicksLimit: 5,
              stepSize: maxYValue / 4,
              callback: (value: number) => {
                return (maxYValue > 10) ? value.toFixed(0) : (maxYValue > 2) ? value.toFixed(1) : value.toFixed(2);
              },
            },
            bounds: 'ticks',
            max: maxYValue,
            stacked: true,
          },
        },
        onClick: (event: any, activeElements: any) => {
          this.onClick(activeElements);
        },
        hideZoomButtons: this.nodeType === NodeType.PMO,
      };
      this.chartData = {
        datasets: this.datasets,
      };
    }

    private getThermostatTimeLimit(): any {
      if (!isNaN(Number(this.day))) {
        return 60;
      } else if (!isNaN(Number(this.month))) {
        return 24;
      } else {
        return 24 * 30;
      }
    }

    private getNodeType(): any {
      switch (this.nodeType) {
      case 'acm':
        return 'storageheater';
      case 'thm':
        return 'thermostat';
      case 'pmo':
        return 'powerMeter';
      default:
        return 'heater';
      }
    }

    private async getDayConsumptionSamples(): Promise<any> {
      if (this.nodeType === 'pmo') {
        return await (window as any).nodeSet?.getHiResDaySamples(this.year, this.month, this.day);
      }
      return (window as any).nodeSet?.getDaySamples(this.year, this.month, this.day);
    }

    private getConsumption(sample?: any): any {
      return sample.loadConsumption !== undefined ? sample.loadConsumption : sample.totalConsumption;
    }

    private async initialize(): Promise<any> {
      let temperatureSamples;
      let consumptionSamples;
      this.nonLoadSamples = [];
      this.samples = [];
      this.usageTimeData = [];
      if (!isNaN(Number(this.day))) {
        consumptionSamples = await this.getDayConsumptionSamples();
        temperatureSamples = await (window as any).nodeSet?.getDaySamples(this.year, this.month, this.day, ['temperature']);
        this.$amplitudeService.sendEvent('on_day_samples');
      } else if (!isNaN(Number(this.month))) {
        consumptionSamples = await (window as any).nodeSet?.getMonthSamples(this.year, this.month);
        temperatureSamples = await (window as any).nodeSet?.getMonthSamples(this.year, this.month, ['temperature']);
        this.$amplitudeService.sendEvent('on_month_samples');
      } else {
        temperatureSamples = await (window as any).nodeSet?.getYearSamples(this.year, ['temperature']);
        consumptionSamples = await (window as any).nodeSet?.getYearSamples(this.year);
        this.$amplitudeService.sendEvent('on_year_samples');
      }
      consumptionSamples.forEach((sampleData: NodeSample) => {
        if (sampleData.totalConsumption === null || sampleData.totalConsumption === 0) {
          this.samples.push(sampleData);
          this.nonLoadSamples.push(sampleData);
        } else {
          const factor: any = this.nodeType === 'pmo' && !isNaN(Number(this.day)) ? 1000 : 1;
          this.nonLoadSamples.push(Object.assign({}, sampleData, {
            totalConsumption: sampleData.totalConsumption !== undefined ? sampleData.totalConsumption - (sampleData.loadConsumption || 0) : undefined,
            consumption: sampleData.totalConsumption !== undefined ? sampleData.totalConsumption - (sampleData.loadConsumption || 0) : undefined,
          }));
          this.samples.push(Object.assign({}, sampleData, {
            totalConsumption: sampleData.totalConsumption !== undefined ? factor * sampleData.totalConsumption : undefined,
            consumption: factor * this.getConsumption(sampleData),
          }));
        }
        this.usageTimeData.push(Object.assign({}, sampleData, {
          totalConsumption: sampleData.usageTime,
          consumption: sampleData.usageTime
        }));
      });
      this.tempData = temperatureSamples.map((tempSample: NodeSample, index: number) => [
        index,
        tempSample.temperature,
        tempSample.timestamp,
      ]);
      await this.plot({ usageYAxis: this.getDefaultUsageYAxisOpts() });
    }

    private saveCsv () {
      const ticks = [];
      let fileName;
      if (!_.isNaN(this.day)) {
        for (let i = 0; i <= 24; i ++) {
          const hourString = (i < 10 ? '0' : '') + i;
          ticks.push(hourString + ':00');
        }
        fileName = 'daySamples';
      } else if (!_.isNaN(this.month)) {
        const max = Math.max(this.tempData.length, this.samples.length);
        for(let i = 0; i < max; i ++) {
          ticks.push((i + 1) + '');
        }
        fileName = 'monthSamples';
      } else {
        for (let i = 0; i < 12; i++) {
         ticks.push(this.getMonthShortName(i));
        }
        fileName = 'yearSamples';
      }
      new sampleTools().downloadCSV(this.datasets, this.chartOptions, fileName, this.nodeName, ticks);
    }
}
