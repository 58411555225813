import { Component, Vue, Watch } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import SetTemperatureHelpModal from '@/components/modals/setTemperatureHelp/setTemperatureHelp.vue';
import SetBoostModal from '@/components/modals/setBoost/setBoost.vue';
import Slider from '@/components/slider/Slider.vue';
import NodeSelector from '@/components/selector/nodeSelector/NodeSelector.vue';
import NavButton from '@/components/button/navButton/NavButton.vue';
import DialogButton from '@/components/button/dialogButton/DialogButton.vue';

import {
  NodeMode,
  NodeCapability,
  NodeControllerSet,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    SetTemperatureHelpModal,
    SetBoostModal,
    NodeSelector,
    NavButton,
    DialogButton,
  },
})
export default class Nav extends Vue {

    private devId: any = {};
    private nodeNavPrefix: any = {};
    private sampleDownloadEnabled: any = {};
    private ready: any = {};
    private list: any = {};
    private date: any = {};
    private statsParams: any = {};
    private getSetupUri: any = {};
    private getScheduleUri: any = {};
    private getStatsUri: any = {};
    private currentNodeType: any = {};
    private nodeSet: any = {};
    private moreOptsDialogOpen = false;
    private modesDialogOpen = false;
    private showSetTemperatureHelpModal = false;
    private boostMode = false;
    private runbackMode = false;
    private node: any = {};
    private showSetBoostModal = false;
    private slickSettings: any = { event: { afterChange: () => Promise.resolve() } };
    private changeModeDisabled: any = {};
    private availableModes: any = [];
    private boostAvailable: any = false;
    private runbackAvailable: any = false;
    private setbackAvailable: any = false;
    private manualAvailable: any = false;
    private autoAvailable: any = false;
    private selfLearnAvailable: any = false;
    private presenceAvailable: any = false;
    private ecoAvailable: any = false;
    private comfortAvailable: any = false;
    private iceAvailable: any = false;
    private currentMode: any = {};
    private autoMode = false;
    private deviceConnected = false;
    private showSetTemperatureHelp = false;
    private hideBoost = false;
    private boostTimeLabel = '';
    private boostModalTitle = '';
    private targetBoostMode = NodeMode.BOOST;

    private async mounted() {
      this.devId = this.$route.params.devid;
      this.nodeNavPrefix = 'root.authenticated.root.device';
      this.sampleDownloadEnabled = (await this.$configService.getConfig()).sampleDownloadEnabled;
      this.ready = (window as any).nodeSet?.isReady();
      this.list = [];
      this.date = new Date();
      this.hideBoost = (await this.$configService.getConfig()).boostAvailable === false;
      this.statsParams = {
      addr: this.addr,
      year: this.date.getFullYear(),
      month: this.date.getMonth(),
      day: this.date.getDate()
    };
      this.getSetupUri = () => `${ this.nodeNavPrefix }.${ this.currentNodeType }.setup`;
      this.getScheduleUri = () => `${ this.nodeNavPrefix }.${ this.currentNodeType }.schedule`;
      this.getStatsUri = () => `${ this.nodeNavPrefix }.${ this.currentNodeType }.nav.stats.day(${ JSON.stringify(this.statsParams) })`;

      this.currentNodeType = this.getNodeType();
      this.nodeSet = (window as any).nodeSet;

      if (!(window as any).nodeSet || !this.homeId) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      rootScope.currentHtr = (window as any).nodeSet;
      if (this.ready) {
        this.devListener();
      }
      this.updateList();
      this.updateSwitch();
      this.$userService.addListener(this.homeId, this.devListener);
    }

    private destroyed() {
      this.$userService.removeListener(this.homeId, this.devListener);
    }

    private get scheduleAvailable(): boolean {
      return this.ready && (window as any)
      .nodeSet?.getActiveCapabilities().includes(NodeCapability.SCHEDULE_AVAILABLE);
    }

    private get addr() {
      return Number(this.$route.params.addr);
    }
    private get homeId() {
      return this.$route.params.homeid;
    }


    @Watch('addr')
    @Watch('homeId')
    private onRouteParamsChanged() {
      this.devListener();
    }

    private get currentRoute(): any {
      return this.$route.name;
    }

    @Watch('currentRoute')
    private onCurrentRouteChanged() {
      this.moreOptsDialogOpen = false;
      this.modesDialogOpen = false;
    }

    private onTemperatureView(): any {
      return this.$route.name === `root.authenticated.root.device.${ this.currentNodeType }.nav.temperature`;
    }

    private displayHelpBtn(): any {
      return this.$route.name === `root.authenticated.root.device.${ this.currentNodeType }.nav.temperature`;
    }

    private openHelp(): any {
      switch (this.$route.name) {
      case `root.authenticated.root.device.${ this.currentNodeType }.nav.temperature`:
                this.showSetTemperatureHelpModal = true;
        break;
      }
    }

    private saveCsv(): any {
      this.$eventHub.$emit('saveCsv', { nodeName: ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).name || (window as any).devId + '.' + this.addr });
    }

    private displayMoreOptsAndModeDialogs(): any {
      return !this.currentRoute.includes('nav.stats');
    }

    private getAlternativeMode(): any {
      if (!(window as any).nodeSet?.isReady()) {
        return undefined;
      }
      return this.availableModes.find((mode: NodeMode) => {
        return mode !== 'on'
        && (this.runbackAvailable && mode === 'off' || mode !== 'off')
        && mode !== 'boost'
        && mode !== 'modified_auto'
        && mode !== 'runback'
      });
    }

    private getAlternativeModeString(): any {
      const alternativeMode: any = this.getAlternativeMode();
      return alternativeMode === 'manual' ? 'Man.' : alternativeMode;
    }

    private toggleModeEnabled(): any {
      if (!this.ready) {
        return false;
      }
      const modes: any = this.availableModes.filter((mode: NodeMode) => ![
        'off',
        'on'
      ].includes(mode));
      return !(modes.length > 2);
    }

    private async toggleBoostMode(): Promise<any> {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      const currentMode: any = (window as any).nodeSet?.getFirstNodeMode();
      if (currentMode === 'off') {
        return;
      }
      if (!this.boostMode) {
        this.boostMode = false;
        const mode = this.getAlternativeMode();
        this.$amplitudeService.sendEvent(`set_mode${mode}`);
        return (window as any).nodeSet?.setMode(mode, {}).catch(async () => {
          return this.$toast.error(this.$t('DEVICE_UPDATE_ERROR_MSG'));
        });
      } else {
        this.node = (window as any).nodeSet;
        this.$amplitudeService.sendEvent('set_mode_boost');
        this.boostModalTitle = await this.$t('SET_BOOST_MODE') as string;
        this.boostTimeLabel = await this.$t('BOOST_END_TIME') as string;
        this.targetBoostMode = NodeMode.BOOST;
        this.showSetBoostModal = true;
      }
    }

    private async toggleRunbackMode(): Promise<any> {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      if (!this.runbackMode) {
        this.runbackMode = false;
        const mode = this.getAlternativeMode();
        this.$amplitudeService.sendEvent(`set_mode${mode}`);
        return (window as any).nodeSet?.setMode(mode, {}).catch(async () => {
          return this.$toast.error(this.$t('DEVICE_UPDATE_ERROR_MSG'));
        });
      } else {
        this.node = (window as any).nodeSet;
        this.$amplitudeService.sendEvent('set_mode_runback');
        this.boostModalTitle = (await this.$t('SET_BOOST_MODE') as string).replace('Boost', 'Runback');
        this.boostTimeLabel = (await this.$t('BOOST_END_TIME') as string).replace('Boost', 'Runback');
        this.showSetBoostModal = true;
        this.targetBoostMode = NodeMode.RUNBACK;
      }
    }

    private toggleAutoMode(): any {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      let currentMode: any = (window as any).nodeSet?.getFirstNodeMode();
      if (currentMode === 'off') {
        return;
      }
      currentMode = currentMode === 'modified_auto' ? 'auto' : currentMode;
      const mode: any = this.availableModes.find((mode: NodeMode) => mode !== 'on' && mode !== 'off' && mode !== 'modified_auto' && mode !== currentMode);
      this.$amplitudeService.sendEvent(`set_mode${mode}`);
      return (window as any).nodeSet?.setMode(mode, {});
    }

    private setMode(mode?: any): any {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      this.toggleModeDialog();
      this.$amplitudeService.sendEvent(`set_mode${mode}`);
      return (window as any).nodeSet?.setMode(mode, {});
    }

    private toggleMoreOptsDialog(): any {
      this.modesDialogOpen = false;
      this.moreOptsDialogOpen = !this.moreOptsDialogOpen;
    }

    private toggleModeDialog(): any {
      this.moreOptsDialogOpen = false;
      this.modesDialogOpen = !this.modesDialogOpen;
    }

    private getCurrentTemperature(): any {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      return (window as any).nodeSet?.getFirstNodeModeParams((window as any).nodeSet?.getFirstNodeMode()).temperature;
    }

    private getNodeType(): any {
      const type: any = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type || 'htr';
      switch (type) {
      case 'acm':
        return 'storageheater';
      case 'thm':
        return 'thermostat';
      case 'htr_mod':
        return 'heaterMod';
      default:
        return 'heater';
      }
    }

    private updateDevConnection(): any {
      this.$devListService.checkDevConnection(this.homeId, this.devId).then(async (connected: any) => {
        this.deviceConnected = connected;
      });
    }

    private get selectedIndex(): number {
      return this.list.findIndex((node: any) => {
        return node.devId === this.devId && node.nodeData.addr === Number(this.$route.params.addr);
      });
    }

    private get onlyReadyNodes() {
      return this.$store.state.interface.onlyReadyNodes;
    }

    private updateList(): any {
      if (this.list.length === 0) {
        this.$devListService.getNodeList(this.homeId).then(async (nodes: any) => {
          this.list = _.filter(nodes, (node: any) => {
            const hasType = (node.nodeData || {}).type === ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type;
            return hasType && (!this.onlyReadyNodes || node.isReady());
          });
          let startPosition: any = 0;
          for (let i: any = 1; i < this.list.length; i++) {
            if (this.list[i].nodeData.addr === this.addr && this.list[i].devId === (window as any).devId) {
              startPosition = i;
            }
          }
          this.slickSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            method: {},
            initialSlide: startPosition,
            event: {
              afterChange: (event: any, slick: any, newPos: any) => {
                if (newPos !== startPosition) {
                  startPosition = newPos;
                  this.nodeIndexChanged(newPos);
                }
              }
            }
          };
        });
      }
    }

    private nodeIndexChanged(newIndex: number) {
      window.nodeSet = new NodeControllerSet([this.list[newIndex]]);
      window.devId = this.list[newIndex].devId;

      if (this.$route.name === `root.authenticated.root.device.${ this.currentNodeType }.nav.temperature`) {
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ this.currentNodeType }.nav.temperature`, {
          devid: this.list[newIndex].devId,
          addr: this.list[newIndex].nodeData.addr
        });
      } else if (this.$route.name?.indexOf(`root.authenticated.root.device.${ this.currentNodeType }.nav.stats.day`) !== -1) {
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ this.currentNodeType }.nav.stats.day`, {
          devid: this.list[newIndex].devId,
          addr: this.list[newIndex].nodeData.addr
        });
      } else if (this.$route.name?.indexOf(`root.authenticated.root.device.${ this.currentNodeType }.nav.stats.month`) !== -1) {
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ this.currentNodeType }.nav.stats.month`, {
          devid: this.list[newIndex].devId,
          addr: this.list[newIndex].nodeData.addr
        });
      } else if (this.$route.name?.indexOf(`root.authenticated.root.device.${ this.currentNodeType }.nav.stats.year`) !== -1) {
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ this.currentNodeType }.nav.stats.year`, {
          devid: this.list[newIndex].devId,
          addr: this.list[newIndex].nodeData.addr
        });
      }
    }

    private updateSwitch(): any {
      if (!this.nodeSet.isReady()) {
        return;
      }
      if (this.runbackAvailable) {
        this.changeModeDisabled = false;
        return;
      }
      this.changeModeDisabled = !this.ready || (window as any).nodeSet?.getFirstNodeMode() === 'off';
    }

    private onSetBoostModalClosed(): any {
      this.showSetBoostModal = false;
      if (rootScope.boostConfig !== undefined) {
        const params = {
          active: true,
          temperature: rootScope.boostConfig.temp,
          units: (((window as any).nodeSet?.nodeControllers[0].nodeData || {}).status || {}).units,
        };
        if (this.boostAvailable) {
          return (window as any).nodeSet?.setBoost({
            time: rootScope.boostConfig.time / 60,
            ...params,
          });
        } else if (this.runbackAvailable) {
          return (window as any).nodeSet?.setRunback({
            time: rootScope.boostConfig.time,
            ...params,
          });
        }
      } else {
        this.boostMode = false;
      }
    }

    private getName(heater: any): string {
      const name: string = (heater.nodeData || {}).name;
      const maxCharacters = 16;
      return name.length < maxCharacters ? name : (name.substr(0, maxCharacters) + '...');
    }

    private devListener(): any {
      this.updateList();
      this.updateDevConnection();
      if (!this.nodeSet.isReady()) {
        return;
      }
      if (this.ready) {
        this.updateSwitch();
      }
      this.availableModes = (window as any).nodeSet?.getAvailableModes()
      .filter((mode: any) => mode !== 'boost' || !this.hideBoost);
      this.boostAvailable = this.availableModes.includes('boost');
      this.runbackAvailable = this.availableModes.includes('runback');
      this.setbackAvailable = this.availableModes.includes('setback');
      this.manualAvailable = this.availableModes.includes('manual');
      this.autoAvailable = this.availableModes.includes('auto');
      this.selfLearnAvailable = this.availableModes.includes('self_learn');
      this.presenceAvailable = this.availableModes.includes('presence');
      this.ecoAvailable = this.availableModes.includes('eco');
      this.comfortAvailable = this.availableModes.includes('comfort');
      this.iceAvailable = this.availableModes.includes('ice');
      this.currentMode = (window as any).nodeSet?.getFirstNodeMode();
      const autoLikeModes: any = [
        NodeMode.AUTO,
        NodeMode.MODIFIED_AUTO
      ];
      this.autoMode = (window as any).nodeSet?.isReady() && autoLikeModes.includes((window as any).nodeSet?.getFirstNodeMode());
      this.boostMode = (window as any).nodeSet?.isReady() && (window as any).nodeSet?.getFirstNodeMode() === NodeMode.BOOST;
      this.runbackMode = (window as any).nodeSet?.isReady() && (window as any).nodeSet?.getFirstNodeMode() === NodeMode.RUNBACK;
    }

    private get maxItemsOnCarousel(): number {
      return this.$store.state.interface.maxItemsOnCarousel;
    }

    private showSelector(): boolean {
      return this.list.length > this.maxItemsOnCarousel;
    }

    private getNodeImage(heater: any): any {
      switch(heater.getNodeType()) {
        case 'thm':
          return 'ico-disp-termostato-big';
        case 'towel_rail_heater':
          return 'ico-disp-towel-rail-list';
        case 'str':
          return 'ico-disp-acumulador-list';
        case 'htr':
        case 'htr_mod':
        default:
          return 'ico-disp-radiador-list';
      }
    }
}
