import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DeviceDataLabel extends Vue {
  @Prop() private label!: string;
  @Prop() private prevIcon!: string;
  @Prop() private postIcon!: string;
  @Prop() private device!: string;
  @Prop() private connected!: boolean;

  private prevIconPlus() {
    return this.prevIcon === 'icon-plus';
  }

  private prevIconHome() {
    return this.prevIcon === 'icon-home';
  }

  private postIconPlus() {
    return this.postIcon === 'icon-plus';
  }

  private postIconArrowRight() {
    return this.postIcon === 'icon-arrow-right';
  }
}
