import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import InviteUserModal from '@/components/modals/inviteUser/inviteUser.vue';
import RevokeInviteModal from '@/components/modals/revokeInvite/revokeInvite.vue';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';
import HomeDataLabel from '@/components/homeDataLabel/HomeDataLabel.vue';

@Component({
  components: {
    Slider,
    InviteUserModal,
    RevokeInviteModal,
    TitleText,
    HomeDataLabel,
  },
})
export default class InviteUsers extends Vue {

    private homeId = '';
    private guests: any = {};
    private showInviteUserModal = false;
    private showRevokeInviteModal = false;
    private showInviteUser = false;
    private showRevokeInvite = false;

    private mounted() {
      this.homeId = this.$route.params.homeid;
      this.guests = (window as any).guests;
      if (!this.homeId) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      this.updateGuestList();
    }

    private openInviteUserDialog(): any {
      this.showInviteUserModal = true;
    }

    private openRevokeInviteDialog(guest?: any): any {
      (window as any).guest = guest.email;
      this.showRevokeInviteModal = true;
    }

    private updateGuestList(): any {
      return this.$devListService.getGuests(this.homeId).then(async (guests: any) => {
        this.guests = guests;
      });
    }

    private onInviteUserModalClosed(): any {
      this.showInviteUserModal = false;
      this.updateGuestList();
    }

    private onRevokeInviteModalClosed(): any {
      this.showRevokeInviteModal = false;
      this.updateGuestList();
    }

}
