import { Component, Vue } from 'vue-property-decorator';
import ChangeEmailSuccessModal from '@/components/modals/changeEmailSuccess/changeEmailSuccess.vue';
import ConfirmRemoveAccountModal from '@/components/modals/confirmRemoveAccount/confirmRemoveAccount.vue';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import WarningButton from '@/components/button/warningButton/WarningButton.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    ChangeEmailSuccessModal,
    ConfirmRemoveAccountModal,
    MainButton,
    WarningButton,
    TitleText,
  },
})
export default class EditAccount extends Vue {
  private newEmail: any = {};
  private newPass: any = {};
  private supportedLanguages: any = [];
  private showConfirmWithPassword: any = {};
  private showCurrentPassword: any = {};
  private showNewPassword: any = {};
  private showChangeEmailSuccessModal = false;
  private showConfirmRemoveAccountModal = false;
  private language: any = {};
  private email: any = {};
  private showChangeEmailSuccess = false;
  private showConfirmRemoveAccount = false;

  private async mounted() {
    this.supportedLanguages = (await this.$configService.getConfig()).clientData.supportedLanguages;
    this.showConfirmWithPassword = false;
    this.showCurrentPassword = false;
    this.showNewPassword = false;
    this.initData();
  }

  private toggleConfirmWithPasswordVisibility(): any {
    this.showConfirmWithPassword = !this.showConfirmWithPassword;
  }

  private toggleCurrentPasswordVisibility(): any {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  private toggleNewPasswordVisibility(): any {
    this.showNewPassword = !this.showNewPassword;
  }

  private updateLanguage(): any {
    this.$userService.setPreferences({ lang: this.language });
  }

  private changeEmail(): any {
    this.$userService.changeEmail(
      this.newEmail.pass,
      this.newEmail.email,
    ).then(async () => {
      this.showChangeEmailSuccessModal = true;
    }).catch((err: any) => {
      let errorMsg;
      switch (err.status) {
      case 400:
        errorMsg = this.$t('EMAIL_BAD_FORMAT_ERROR_MSG');
        break;
      case 409:
        errorMsg = this.$t('EMAIL_ALREADY_EXISTS_ERROR_MSG');
        break;
      default:
        errorMsg = this.$t('EMAIL_CHANGE_ERROR_MSG');
        break;
      }
      this.$toast.error(errorMsg);
    });
  }

  private changePass(): any {
    this.$userService.changePassword(
      this.newPass.oldPass,
      this.newPass.newPass,
    ).then(async () => {
      this.$toast.success(this.$t('PASSWORD_CHANGE_SUCCESS_MSG'));
    }).catch(() => {
      this.$toast.error(this.$t('PASSWORD_CHANGE_ERROR_MSG'));
    });
  }

  private displayConfirmRemoveAccountModal(): any {
    this.showConfirmRemoveAccountModal = true;
  }

  private initData(): any {
    const userData: any = this.$userService.get();
    let lang: string;
    this.$userService.getLanguage().then(async (currentLang: any) => {
      lang = currentLang;
    }).catch(() => {
      lang = userData.language || 'en';
    }).finally(() => {
      this.language = lang;
    });
    this.email = userData.email;
  }

}
