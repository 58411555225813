import { Component, Vue } from 'vue-property-decorator';
import _ from '@/services/_';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import {
  NodeMode,
  NodeControllerSet,
  NodeScheduleTemperature,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
  },
})
export default class defTemps extends Vue {

  private currentTemp: any = {};
  private scheduleTemps: any = {};
  private showSlick: any = {};
  private forcedUpdate: any = {};
  private sliderPosition: any = {};
  private iceTempIndex: any = {};
  private ecoTempIndex: any = {};
  private comfortTempIndex: any = {};
  private temperatureList: any = {};
  private tempSetup: any = {};
  private temperatureListLength: any = {};
  private slickSettings: any = {};
  private slick: any = {};
  private nodeSet!: NodeControllerSet;
  private ready = false;

  private mounted() {
    this.currentTemp = 'comfort';
    this.init();
  }

  private init() {
    this.nodeSet = (window as any).nodeSet;
    this.scheduleTemps = this.nodeSet.getFirstNodeScheduleTemperatures().length > 3 ? this.nodeSet.getFirstNodeScheduleTemperatures().slice(1) : this.nodeSet.getFirstNodeScheduleTemperatures();
    this.showSlick = true;
    this.forcedUpdate = false;
    this.initTempIndex();
    this.initTemperatureSlider();
    setTimeout(this.updateSliderPosition);
    this.ready = true;
  }

  private tempUp(): any {
    const currentPos: any = this.sliderPosition;
    this.temperatureList[currentPos].value = this.temperatureList[currentPos].value + this.tempSetup.step;
    if (this.temperatureList[currentPos].value.toFixed(1) === this.temperatureList[currentPos].max.toFixed(1)) {
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
      (this.$refs.slick as any).goTo(currentPos + 1);
    } else if (this.temperatureList[currentPos].value > this.temperatureList[currentPos].max) {
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
      return;
    }
    this.updateValue();
    this.pushTemperaturesUp();
  }

  private tempDown(): any {
    const currentPos: any = this.sliderPosition;
    this.temperatureList[currentPos].value = this.temperatureList[currentPos].value - this.tempSetup.step;
    if (currentPos > 0 && this.temperatureList[currentPos].value < this.temperatureList[currentPos].initialValue) {
      this.temperatureList[currentPos - 1].value = this.temperatureList[currentPos].value;
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
      (this.$refs.slick as any).goTo(currentPos - 1);
    } else if (this.temperatureList[currentPos].value < this.temperatureList[currentPos].min) {
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
      return;
    }
    this.updateValue();
    this.pushTemperaturesDown();
  }

  private selectTemp(index?: any): any {
    this.checkAndCorrectTempValues();
    this.currentTemp = index;
    this.initTemperatureList();
    this.showSlick = false;
    setTimeout(() => {
      this.showSlick = true;
    });
    this.updateSliderPosition();
    this.init();
  }

  private async save(): Promise<any> {
    this.checkAndCorrectTempValues();
    await this.nodeSet.setScheduleTemperatures(this.scheduleTemps.filter((temp: NodeScheduleTemperature) => temp.editable), this.currentTemp);
    this.close();
  }

  private close(): any {
    this.$emit('close');
  }

  private updateSliderPosition(): any {
    const sTemp: any = (window as any).defTemps[this.getTemperatureIndex(this.currentTemp)];
    const pos: any = this.getSliderPosition(parseInt(sTemp, 10));
    this.temperatureList[pos].value = Number(sTemp);
    if (this.$refs.slick !== undefined) {
      (this.$refs.slick as any).goTo(pos);
    }
    this.sliderPosition = pos;
    this.forcedUpdate = true;
  }

  private getTemperatureIndex(slot?: any): any {
    return _.findIndex(this.scheduleTemps, (scheduleTemp: any) => {
      return slot === scheduleTemp.id;
    });
  }

  private initTempIndex(): any {
    this.iceTempIndex = this.getTemperatureIndex('ice');
    this.ecoTempIndex = this.getTemperatureIndex('eco');
    this.comfortTempIndex = this.getTemperatureIndex('comfort');
  }

  private getEcoTempSetup(): any {
    return {
      min: this.scheduleTemps[this.ecoTempIndex].min,
      max: this.scheduleTemps[this.ecoTempIndex].max
    };
  }

  private getComfortTempSetup(): any {
    return {
      min: this.scheduleTemps[this.comfortTempIndex].min,
      max: this.scheduleTemps[this.comfortTempIndex].max
    };
  }

  private getIceTempSetup(): any {
    return {
      min: this.scheduleTemps[this.iceTempIndex].min,
      max: this.scheduleTemps[this.iceTempIndex].max
    };
  }

  private getOffsetSetup(modeParams?: any): any {
    return {
      min_step: modeParams.temperatureStep,
      max_temp: modeParams.temperatureUnits === 'C' ? 30 : 54
    };
  }

  private getTempSetup(): any {
    const modeParams: any = this.nodeSet.getFirstNodeModeParams(this.nodeSet.getFirstNodeMode() as NodeMode);
    const currentTempIndex: any = this.getTemperatureIndex(this.currentTemp);
    const tempLimits: any = this.scheduleTemps[currentTempIndex];
    return {
      min: tempLimits.min,
      max: tempLimits.max,
      units: modeParams.temperatureUnits,
      step: modeParams.temperatureStep,
      eco: this.getEcoTempSetup(),
      comfort: this.getComfortTempSetup(),
      ice: this.getIceTempSetup(),
      ice_offset: this.getOffsetSetup(modeParams),
      eco_offset: this.getOffsetSetup(modeParams)
    };
  }

  private initTemperatureList(): any {
    this.temperatureList = [];
    this.tempSetup = this.getTempSetup();
    this.temperatureListLength = Math.ceil(this.tempSetup.max - this.tempSetup.min + 1);
    const minShownValue: any = Math.trunc(this.tempSetup.min);
    for (let i: any = 0; i < this.temperatureListLength; i++) {
      this.temperatureList.push({
        value: minShownValue + i,
        initialValue: minShownValue + i,
        min: i === 0 ? minShownValue : minShownValue + i - 1,
        max: i === this.temperatureListLength - 1 ? minShownValue + i : minShownValue + i + 1
      });
    }
  }

  private initTemperatureSlider(): any {
    this.initTemperatureList();
    let previousPos: number;
    this.sliderPosition = 20;
    this.slickSettings = {
      centerMode: true,
      centerPadding: '60px',
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
      arrows: false,
      focusOnSelect: true,
      swipeToSlide: true,
      method: {},
      initialSlide: 20,
      event: {
        beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
          previousPos = oldPos;
          if (oldPos === newPos || oldPos > this.temperatureList.length) {
            return;
          }
          this.temperatureList[oldPos].value = this.temperatureList[oldPos].initialValue;
          this.sliderPosition = newPos;
        },
        afterChange: (event: any, slick: any, newPos: any) => {
          this.sliderPosition = newPos;
          this.updateValue();
          if (!this.forcedUpdate) {
            if (newPos > previousPos) {
              this.pushTemperaturesUp();
            } else {
              this.pushTemperaturesDown();
            }
          }
          this.forcedUpdate = false;
        },
        init: (event: any, slick: any) => {
          this.slick = slick;
          this.updateSliderPosition();
        }
      }
    };
  }

  private getSliderPosition(temp?: any): any {
    for (let i: any = 0; i < this.temperatureListLength - 1; i++) {
      if (temp < this.temperatureList[i + 1].initialValue) {
        return i;
      }
    }
    return this.temperatureListLength - 1;
  }

  private checkAndCorrectTempValues(): any {
    let ice: any = Number((window as any).defTemps[this.iceTempIndex]);
    let eco: any = Number((window as any).defTemps[this.ecoTempIndex]);
    let comfort: any = Number((window as any).defTemps[this.comfortTempIndex]);
    if (ice > this.tempSetup.ice.max) {
      ice = this.tempSetup.ice.max;
    }
    if (ice < this.tempSetup.ice.min) {
      ice = this.tempSetup.ice.min;
    }
    if (ice > eco) {
      eco = ice + this.tempSetup.step;
    }
    if (eco > comfort) {
      comfort = eco + this.tempSetup.step;
    }
    (window as any).defTemps[this.iceTempIndex] = ice.toFixed(1);
    (window as any).defTemps[this.ecoTempIndex] = eco.toFixed(1);
    (window as any).defTemps[this.comfortTempIndex] = comfort.toFixed(1);
    this.scheduleTemps[this.iceTempIndex].value = (window as any).defTemps[this.iceTempIndex];
    this.scheduleTemps[this.ecoTempIndex].value = (window as any).defTemps[this.ecoTempIndex];
    this.scheduleTemps[this.comfortTempIndex].value = (window as any).defTemps[this.comfortTempIndex];
  }

  private updateValue(): any {
    const currentPos: any = Math.min(this.sliderPosition, this.temperatureList.length - 1);
    let limits: any = [
      this.tempSetup.ice,
      this.tempSetup.eco,
      this.tempSetup.comfort
    ];
    if ((window as any).defTemps.length > 3) {
      limits = [
        {},
        this.tempSetup.ice,
        this.tempSetup.eco,
        this.tempSetup.comfort
      ];
    }
    (window as any).defTemps[this.getTemperatureIndex(this.currentTemp)] = this.temperatureList[currentPos].value.toFixed(1);
    if (Number((window as any).defTemps[this.getTemperatureIndex(this.currentTemp)]) > limits[this.getTemperatureIndex(this.currentTemp)].max) {
      (window as any).defTemps[this.getTemperatureIndex(this.currentTemp)] = limits[this.getTemperatureIndex(this.currentTemp)].max.toFixed(1);
      this.pushTemperaturesUp();
      this.updateSliderPosition();
    }
    if (Number((window as any).defTemps[this.getTemperatureIndex(this.currentTemp)]) < limits[this.getTemperatureIndex(this.currentTemp)].min) {
      (window as any).defTemps[this.getTemperatureIndex(this.currentTemp)] = limits[this.getTemperatureIndex(this.currentTemp)].min.toFixed(1);
      this.pushTemperaturesDown();
      this.updateSliderPosition();
    }
  }

  private pushTemperaturesUp(): any {
    const offsets: any = [
      this.tempSetup.ice_offset,
      this.tempSetup.eco_offset
    ];
    for (let i: any = this.getTemperatureIndex(this.currentTemp); i < 2; i++) {
      if (Number((window as any).defTemps[i]) + offsets[i].min_step > Number((window as any).defTemps[i + 1])) {
        (window as any).defTemps[i + 1] = (Number((window as any).defTemps[i]) + offsets[i].min_step).toFixed(1);
      }
    }
    for (let i = this.getTemperatureIndex(this.currentTemp); i > 0; i--) {
      if (Number((window as any).defTemps[i]) - offsets[i - 1].max_temp > Number((window as any).defTemps[i - 1])) {
        (window as any).defTemps[i - 1] = (Number((window as any).defTemps[i]) - offsets[i - 1].max_temp).toFixed(1);
      }
    }
  }

  private pushTemperaturesDown(): any {
    const offsets: any = [
      this.tempSetup.ice_offset,
      this.tempSetup.eco_offset
    ];
    for (let i = this.getTemperatureIndex(this.currentTemp); i > 0; i--) {
      if (Number((window as any).defTemps[i]) - offsets[i - 1].min_step < Number((window as any).defTemps[i - 1])) {
        (window as any).defTemps[i - 1] = (Number((window as any).defTemps[i]) - offsets[i - 1].min_step).toFixed(1);
      }
    }
    for (let i: any = this.getTemperatureIndex(this.currentTemp); i < 2; i++) {
      if (Number((window as any).defTemps[i]) + offsets[i].max_temp < Number((window as any).defTemps[i + 1])) {
        (window as any).defTemps[i + 1] = (Number((window as any).defTemps[i]) + offsets[i].max_temp).toFixed(1);
      }
    }
  }

}
