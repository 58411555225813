import { Component, Vue } from 'vue-property-decorator';
import ModalHelpContent from '@/components/modalComponents/modalHelpContent/ModalHelpContent.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    ModalHelpContent,
    MainButton,
  },
})

export  default class GeolocationHelp extends Vue {
  private close(): any {
    this.$emit('close');
  }
}
