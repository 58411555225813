import { Store } from 'vuex';
import { IApiService, ApiNodeSample } from './api.service';
import {
  NodeType,
  NodeMode,
  DevNode,
  DevNodeSamples,
  DevNodeDataSamplesUpdate,
  DevNodeDataFieldUpdate,
  NodeControlProxy,
} from '@vdi-helki/helki-node-management';

export class VueControlProxy implements NodeControlProxy {
  constructor(private store: Store<any>, private apiService: IApiService) {}

  public async getSamples(
    devId: string,
    addr: number,
    type: NodeType,
    from: number,
    to: number,
  ): Promise<any> {
    return Promise.resolve()
    .then(() => {
      if (this.store.state.demo) {
        return this.getDemoSamples(from, to);
      }
      return this.apiService.getSamples(devId, addr, type, from, to);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.dataUpdate));
  }

  public async getPower(devId: string, addr: number, type: NodeType): Promise<number> {
    // Return random power for demo mode
    if (this.store.state.demo) {
      return Math.floor(Math.random() * 5000);
    }
    return this.apiService.getPower(devId, addr, type)
    .then((power) => power);
  }

  public async setMode(devId: string, addr: number, type: NodeType, data: any): Promise<void> {
    return Promise.resolve()
    .then(() => {
      if (data === NodeMode.OFF) {
        const lastModeUpdate: DevNode = {
          dev_id: devId,
          type,
          addr,
        };
        this.store.commit('saveHtrLastOnMode', lastModeUpdate);
      }
      return this.apiService.setMode(devId, addr, type, data);
    })
    .then(() => {
      this.updateStore(devId, addr, type, 'mode', data);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setStatus(devId: string, addr: number, type: NodeType, data: any): Promise<void> {
    return this.apiService.setStatus(devId, addr, type, data)
    .then(() => {
      this.updateStore(devId, addr, type, 'status', data);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setBoost(devId: string, addr: number, type: NodeType, data: any): Promise<void> {
    return this.apiService.setBoostConfig(devId, addr, type, data)
    .then(() => {
      this.updateStore(devId, addr, type, 'status', data);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setSetup(devId: string, addr: number, type: NodeType, data: any): Promise<void> {
    return this.apiService.setSetup(devId, addr, type, data)
    .then(() => {
      this.updateStore(devId, addr, type, 'setup', data);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setLock(devId: string, addr: number, type: NodeType, data: any): Promise<void> {
    return this.apiService.setLockConfig(devId, addr, type, data)
    .then(() => {
      this.updateStore(devId, addr, type, 'status', data);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setProgTemps(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    return this.apiService.setProgTempConfig(devId, addr, type, data)
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setProg(devId: string, addr: number, type: NodeType, data: any): Promise<void> {
    return this.apiService.setProg(devId, addr, type, data)
    .then(() => {
      this.updateStore(devId, addr, type, 'prog', data);
      const modeUpdate: DevNode = {
        dev_id: devId,
        type,
        addr,
      };
      this.store.commit('updateHtrModeAfterProgUpdate', modeUpdate);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setPowerLimit(
    devId: string,
    addr: number,
    type: NodeType,
    data: any,
  ): Promise<void> {
    return this.apiService.setPowerLimit(devId, addr, type, data)
    .then(() => {
      this.updateStore(devId, addr, type, 'power_limit', { power_limit: this.store.state.errors.userRequest });
    });
  }

  public async select(
    devId: string,
    addr: number,
    type: NodeType,
    select: boolean,
  ): Promise<void> {
    return this.apiService.selectNode(devId, addr, type, select)
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setName(
    devId: string,
    addr: number,
    type: NodeType,
    name: string,
  ): Promise<void> {
    return this.apiService.setNodeName(devId, addr, type, name)
    .then(() => {
      this.updateStore(devId, addr, type, 'name', name);
    })
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async remove(devId: string, addr: number, type: NodeType, purge: boolean): Promise<void> {
    return this.apiService.eraseNode(devId, addr, type, purge)
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  private getDemoSamples(from: number, to: number): any {
    const elapsedHours: number = (to - from) / 3600000;
    return [...Array(elapsedHours).keys()]
    .map((item: any, index: number) => ({
      temp: (10 + Math.random() * 10).toFixed(2),
      counter: index + Math.random() * 500,
      t: from + index * 3600000,
    }));
  }

  private updateStore(devId: string, addr: number, type: NodeType, key: string, data: any) {
    const storeUpdate: DevNodeDataFieldUpdate = {
      dev_id: devId,
      type,
      addr,
      key,
      data,
    };
    this.store.commit('patchDevNodeDataField', storeUpdate);
  }

  private async getDaySamples(
    devId: string,
    addr: number,
    type: NodeType,
    year: number,
    month: number,
    day: number,
  ): Promise<any> {
    return Promise.resolve()
    .then(() => {
      const cachedSamples = this.getCachedSamples(devId, addr, type, year, month);
      return cachedSamples || this.getMonthSamplesFromApi(devId, addr, type, year, month);
    })
    .then((monthSamples) => {
      const from = new Date(year, month, day).getTime() - 3600000; // 1 extra hour
      const to = new Date(year, month, day + 1).getTime();
      return this.filterSamples(monthSamples, from, to);
    });
  }

  private getSampleIndexFromTimestamp(timestamp: number, dayStart: number, dayEnd: number): number {
    if (timestamp < dayStart) {
      return -1;
    }
    if (timestamp >= dayEnd) {
      return 24;
    }
    return (new Date(timestamp)).getHours();
  }

  private setCachedSamples(
    devId: string,
    addr: number,
    type: NodeType,
    year: number,
    month: number,
    samples: ApiNodeSample[],
  ): void {
    const storeUpdate: DevNodeDataSamplesUpdate = {
      dev_id: devId,
      type,
      addr,
      year,
      month,
      samples,
    };
    this.store.commit('updateDevNodeMonthSamples', storeUpdate);
  }

  private getCachedSamples(
    devId: string,
    addr: number,
    type: NodeType,
    year: number,
    month: number,
  ): ApiNodeSample[] {
    const storeData: DevNodeSamples = {
      dev_id: devId,
      type,
      addr,
      year,
      month,
    };
    return this.store.getters.getDevNodeMonthSamples(devId, storeData);
  }

  private getMonthSamplesFromApi(
    devId: string,
    addr: number,
    type: NodeType,
    year: number,
    month: number,
  ): Promise<ApiNodeSample[]> {
    const from = new Date(year, month, 1).getTime();
    const to = new Date(year, month + 1, 1).getTime();
    return this.getSamples(devId, addr, type, from, to)
    .then((samples) => {
      this.setCachedSamples(devId, addr, type, year, month, samples);
      return samples;
    });
  }

  private async getMonthSamples(
    devId: string,
    addr: number,
    type: NodeType,
    year: number,
    month: number,
  ): Promise<ApiNodeSample[]> {
    return Promise.resolve()
    .then(() => {
      const cachedSamples = this.getCachedSamples(devId, addr, type, year, month);
      return cachedSamples || this.getMonthSamplesFromApi(devId, addr, type, year, month);
    });
  }

  private filterSamples(samples: ApiNodeSample[], from: number, to: number) {
    return samples.filter((sample) => (sample.t >= from && sample.t <= to));
  }
}
