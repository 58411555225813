
export class dateTimeHelpers {
  
    public dayMinuteToTimeString(dayMinute?: any): any {
      const hour = Math.floor(dayMinute / 60);
      const minute = dayMinute - hour * 60;
      let string = '';
      string += hour < 10 ? '0' + hour : hour;
      string += ':';
      string += minute < 10 ? '0' + minute : minute;
      return string;
    }
    
    public timeStringToDayMinute(string?: any): any {
      const parts = string.split(':');
      if (parts.length !== 2 || isNaN(parts[0]) || isNaN(parts[1])) {
        return 0;
      }
      const hour = Number(parts[0]);
      const minute = Number(parts[1]);
      return hour * 60 + minute;
    }
    
}
