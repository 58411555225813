import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import WarningButton from '@/components/button/warningButton/WarningButton.vue';

@Component({
  components: {
    MainButton,
    ModalTitle,
    CancelButton,
    WarningButton,
  }
})
export default class WarningModal extends Vue {
  @Prop() private title!: string;
  @Prop() private subtitle!: string;
  @Prop() private body!: string;
  @Prop() private buttonLabel!: string;
  @Prop() private secondaryButtonLabel!: string;
  @Prop() private icon!: string;
  @Prop() private secondaryButtonIcon!: boolean;
  private doubleButton = false;

  private mounted() {
    this.doubleButton = (this.secondaryButtonLabel !== undefined);
  }

  private isWarningIcon() {
    return this.icon === 'icon-aviso';
  }

  private isIconOk() {
    return this.icon === 'icon-ok';
  }
}
