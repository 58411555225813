import { Component, Vue } from 'vue-property-decorator';
import _ from '@/services/_';
import Slider from '@/components/slider/Slider.vue';
import { HomeData } from '@/services/devList.service';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import WarningButton from '@/components/button/warningButton/WarningButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    CancelButton,
    WarningButton,
  },
})
export default class changeHome extends Vue {

  private homeId: any = {};
  private devId: any = {};
  private homes: HomeData[] = [];
  private selectedHome: any = {};

  private async mounted() {
    this.homeId = this.$route.params.homeid;
    this.devId = this.$route.params.devid;
    const homes = (await this.$devListService.getList())
    .map((homeData: HomeData) => (_.clone({ id: String(homeData.id), name: String(homeData.name) })));
    this.homes = homes.filter((currentHome: any) => {
      return currentHome.id !== this.homeId;
    });
    if (this.homes.length > 0) {
      this.selectedHome = this.homes[0].id;
    }
  }

  private close(data?: any): any {
    this.$emit('close', data);
  }

  private transferDevice(): any {
    this.$devListService.transferDevice(this.homeId, this.devId, this.selectedHome).then(() => {
      this.$amplitudeService.sendEvent('edit_device_transfer_success');
      this.close();
    });
  }


}
