import axios from 'axios';

export interface ConfigServiceSetup {
  host: string;
  timeout?: number;
}

export interface ApplicationParameters {
  title: string;
  frontendHost: string;
  maxOutdoorRange: number;
}

export interface HelkiSupportData {
  address: string[];
  email: string;
  phone: string;
  fax: string;
}

export interface NodeTypeConfig {
  type: string;
  label: string;
  image: string;
}

export interface ApplicationCapabilities {
  supportedNodeTypes: NodeTypeConfig[];
  supportedLanguages: LanguageConfig[];
  supportedTimezones: TimezoneConfig[];
  geolocationAvailable: boolean;
  wifiSmartboxAvailable: boolean;
  inviteEnabled: boolean;
  powerScheduleAvailable: boolean;
  sampleDownloadEnabled: boolean;
  hideApiHost: boolean;
  htrPowerLimitAvailable: boolean;
  boostAvailable: boolean;
  provisioningMethods: ProvisioningMethod[];
}

export interface ApplicationResources {
  androidAppLink: string;
  iOSAppLink: string;
  voiceAssistantInfoUri: string;
  alexaInfoUri: string;
  deprecated: boolean;
  deprecationTargetName: string;
}

export interface ThresholdConfig {
  eco: number;
  comfort: number;
}

export interface ThmThresholdConfig {
  heat: ThresholdConfig;
  cold: ThresholdConfig;
}

export interface TempThresholdConfig {
  thm: ThmThresholdConfig;
}

export interface NodeParameters {
  tempThresholds: TempThresholdConfig;
}

export interface HelkiGeolocationConfig {
  maxOutdoorRange: number;
}

export interface LanguageConfig {
  code: string;
  caption: string;
}

export interface TimezoneConfig {
  value: string;
  stringKey: string;
}

export interface ProvisioningMethod {
  value: string;
  name: string;
}

export interface HelkiClientData {
  title: string,
  supportData: HelkiSupportData,
  supportedDevices: NodeTypeConfig[],
  geolocationConfig: HelkiGeolocationConfig,
  supportedLanguages: LanguageConfig[],
  supportedTimezones: TimezoneConfig[],
  provisioningMethods: ProvisioningMethod[],
}

export interface HelkiPowerProfile {
  name: string,
  color: string,
  value: number,
}

export interface HelkiPowerSlot {
  index: number,
  minute: number,
}

export interface HelkiPowerConfig {
  name: string,
  maxPowerProfiles: HelkiPowerProfile[],
  maxPowerSlots: HelkiPowerSlot[],
}

export interface HelkiConfiguration {
  apiEndpoint: string,
  clientBasicAuth: string,
  clientData: HelkiClientData,
  hideApiHost: boolean,
  frontendHost: string,
  wifiFlag: boolean,
  geolocationFlag: boolean,
  googleMapsApiKey: string,
  serialId: string,
  inviteFlag: boolean,
  amplitudeApiKey: string,
  clientName: string,
  buildTimestamp: number,
  androidAppLink: string,
  iosAppLink: string,
  gitHash: string,
  multiplePowerLimitFlag: boolean,
  sampleDownloadEnabled: boolean,
  voiceAssistantInfoUri: string,
  alexaInfoUri: string,
  thmHeatEcoThreshold: number,
  thmColdEcoThreshold: number,
  thmHeatComfortThreshold: number,
  thmColdComfortThreshold: number,
  htrPowerLimitAvailable: boolean,
  mainColor: string,
  maxPowerConfig: HelkiPowerConfig[],
  boostAvailable: boolean,
  deprecated: boolean,
  deprecationTargetName: string,
}

export interface IConfigService {
  getConfig(): Promise<HelkiConfiguration>;
  connected(): Promise<boolean>;
}

export class ConfigService implements IConfigService {
  private axiosInstance: any;
  private config!: HelkiConfiguration;
  private serialId = '';
  private applicationParameters!: ApplicationParameters;
  private supportData!: HelkiSupportData;
  private supportedDevices: NodeTypeConfig[] = [];
  private supportedLanguages: LanguageConfig[] = [];
  private supportedTimezones: TimezoneConfig[] = [];
  private initializePromise: Promise<void>;
  private provisioningMethods: ProvisioningMethod[] = [];

  constructor(
    private setup: ConfigServiceSetup,
  ) {
    this.setDefaultValues();
    this.axiosInstance = axios.create({
      baseURL: this.setup.host,
      timeout: this.setup.timeout,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.initializePromise = this.initConfig();
  }

  public async getConfig(): Promise<HelkiConfiguration> {
    await this.initializePromise;
    return this.config;
  }

  public async connected(): Promise<boolean> {
    try {
      await this.axiosInstance.get('/health_check');
      return true;
    } catch {
      return false;
    }
  }

  private async initConfig(): Promise<void> {
    this.serialId = process.env.VUE_APP_SERIAL_ID || 1;
    this.applicationParameters = await this.getApplicationParameters();
    this.supportData = await this.getSupportData();
    const capabilities = await this.getCapabilities();
    const resources = await this.getResources();
    const nodeParameters = await this.getNodeParameters();
    this.supportedDevices = capabilities.supportedNodeTypes;
    this.supportedLanguages = capabilities.supportedLanguages;
    this.supportedTimezones = capabilities.supportedTimezones;
    this.provisioningMethods = capabilities.provisioningMethods;
    this.config = {
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT || 'https://test-api.helki.com',
      clientBasicAuth: process.env.VUE_APP_CLIENT_BASIC_AUTH || 'NTQ3YzRjM2Q2NGI5ZTg2MjNkMzIxMTExOnZkaXZkaQ',
      clientData: this.getClientData(),
      hideApiHost: process.env.VUE_APP_HIDE_API_HOST === 'true',
      frontendHost: this.applicationParameters.frontendHost,
      wifiFlag: capabilities.wifiSmartboxAvailable,
      geolocationFlag: capabilities.geolocationAvailable,
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      serialId: this.serialId,
      inviteFlag: capabilities.inviteEnabled,
      amplitudeApiKey: process.env.VUE_APP_AMPLITUDE_API_KEY,
      clientName: process.env.VUE_APP_CLIENT_NAME || 'helki',
      buildTimestamp: process.env.VUE_APP_BUILD_TIMESTAMP || new Date().toISOString(),
      androidAppLink: resources.androidAppLink,
      iosAppLink: resources.iOSAppLink,
      gitHash: process.env.VUE_APP_GIT_HASH || '0000',
      multiplePowerLimitFlag: capabilities.powerScheduleAvailable,
      sampleDownloadEnabled: capabilities.sampleDownloadEnabled,
      voiceAssistantInfoUri: resources.voiceAssistantInfoUri,
      alexaInfoUri: resources.alexaInfoUri,
      thmHeatEcoThreshold: nodeParameters.tempThresholds.thm.heat.eco,
      thmColdEcoThreshold: nodeParameters.tempThresholds.thm.cold.eco,
      thmHeatComfortThreshold: nodeParameters.tempThresholds.thm.heat.comfort,
      thmColdComfortThreshold: nodeParameters.tempThresholds.thm.cold.comfort,
      htrPowerLimitAvailable: capabilities.htrPowerLimitAvailable,
      mainColor: process.env.VUE_APP_MAIN_COLOR || '#F9B521',
      maxPowerConfig: await this.getMaxPowerConfig(),
      boostAvailable: capabilities.boostAvailable,
      deprecated: resources.deprecated || false,
      deprecationTargetName: resources.deprecationTargetName || '',
    }
  }

  private setDefaultValues(): void {
    this.setup.timeout = this.setup.timeout || 10000;
  }

  private async getMaxPowerConfig(): Promise<HelkiPowerConfig[]> {
    return (await this.axiosInstance.get('/assets/maxPowerConfig.json')).data;
  }

  private getClientData(): HelkiClientData {
    return {
      title: this.applicationParameters.title,
      supportData: this.supportData,
      supportedDevices: this.supportedDevices,
      geolocationConfig: { maxOutdoorRange: this.applicationParameters.maxOutdoorRange },
      supportedLanguages: this.supportedLanguages,
      supportedTimezones: this.supportedTimezones,
      provisioningMethods: this.provisioningMethods,
    }
  }

  private async getApplicationParameters(): Promise<ApplicationParameters> {
    return (await this.axiosInstance.get(`/client-data/${this.serialId}/appParameters.json`)).data;
  }

  private async getSupportData(): Promise<HelkiSupportData> {
    return (await this.axiosInstance.get(`/client-data/${this.serialId}/supportData.json`)).data;
  }

  private async getCapabilities(): Promise<ApplicationCapabilities> {
    return (await this.axiosInstance.get(`/client-data/${this.serialId}/capabilities.json`)).data;
  }

  private async getResources(): Promise<ApplicationResources> {
    return (await this.axiosInstance.get(`/client-data/${this.serialId}/resources.json`)).data;
  }

  private async getNodeParameters(): Promise<NodeParameters> {
    return (await this.axiosInstance.get(`/client-data/${this.serialId}/nodeParameters.json`)).data;
  }
}
