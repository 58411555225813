import { Component, Vue, Watch } from 'vue-property-decorator';
import uuid from '@/services/uuid';
import InstallPmoSetupModal from '@/components/modals/installPmoSetup/installPmoSetup.vue';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    InstallPmoSetupModal,
    MainButton,
    InstallInfo,
    TitleText,
  },
})

export default class PmoInstall extends Vue {

    private homeId: any = {};
    private devId: any = {};
    private amplitudeEventData: any = {};
    private discoveryActive: any = {};
    private pmo: any = {};
    private showInstallPmoSetupModal = false;
    private ready = false;

    private async mounted() {
      this.homeId = this.$route.params.homeid;
      this.devId = this.$route.params.devid;
      this.amplitudeEventData = {
      type: 'pmo',
      install_session_id: uuid.v4()
    };
      this.discoveryActive = await this.getDiscoveryStatus();
      if (!this.homeId) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      this.$amplitudeService.sendEvent('install_node_start', this.amplitudeEventData);
      this.$userService.addListener(this.homeId, this.devListener);
    }

    private destroyed() {
      this.$userService.removeListener(this.homeId, this.devListener);
    }

    private activateDiscovery(): any {
      this.$amplitudeService.sendEvent('install_node_discovery_start', this.amplitudeEventData);
      return this.$devListService.getDevProxy(this.homeId, this.devId).then(async (devProxy: any) => {
        return devProxy.setDiscovery('on', 'pmo');
      }).catch(() => {
        this.updateDiscovery();
      });
    }

    private async getDiscoveryStatus(): Promise<boolean> {
      const devProxy = await this.$devListService.getDevProxy(this.homeId, this.devId);
      return devProxy.getDiscovery();
    }

    private async updateDiscovery(): Promise<void> {
      try {
        const discoveryStatus = await this.getDiscoveryStatus();
        const oldValue: any = this.discoveryActive;
        this.discoveryActive = discoveryStatus;
        if (oldValue && !this.discoveryActive) {
          this.$amplitudeService.sendEvent('install_node_timeout', this.amplitudeEventData);
        }
      } catch {
        this.discoveryActive = false;
      }
    }

    private showErrorMsg(): any {
      this.$toast.error(this.$t('INSTALL_ERROR_MSG'))
    }

    private devListener(): any {
      this.updateDiscovery();
    }

    private get nodeCount() {
      return (this.$store.state.homes[this.homeId]?.devs[this.devId]?.nodes || []).length;
    }

    @Watch('nodeCount')
    private onNodeCountChanged() {
      if (!this.ready) {
        this.ready = true;
        return;
      }
      this.$toast.clear();
      this.$amplitudeService.sendEvent('install_node_sucess', this.amplitudeEventData);
      this.pmo = { type: 'pmo' };
      this.showInstallPmoSetupModal = true;
    }
}
