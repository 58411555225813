
export class nodeCache {

  private cacheSamples: any = {};
  
    public isCurrentMonth(year?: any, month?: any): any {
      const today = new Date();
      if (today.getFullYear() === year && today.getMonth() === month) {
        return true;
      }
      return false;
    }
    
    public getCacheKey(devId?: any, addr?: any, type?: any, year?: any, month?: any): any {
      return [
        devId,
        addr,
        type,
        year,
        month
      ].join('-');
    }
    
    public getCachedMonthSamples(devId?: any, addr?: any, type?: any, year?: any, month?: any): any {
      let timeout = 7 * 24 * 3600 * 1000;
      if (this.isCurrentMonth(year, month)) {
        timeout = 15 * 60 * 1000;
      }
      const key = this.getCacheKey(devId, addr, type, year, month);
      if (this.cacheSamples[key] && this.cacheSamples[key].timestamp > Date.now() - timeout) {
        return this.cacheSamples[key];
      }
      return null;
    }
    
    public setCachedMonthSamples(devId?: any, addr?: any, type?: any, year?: any, month?: any, samples?: any): any {
      const key = this.getCacheKey(devId, addr, type, year, month);
      this.cacheSamples[key] = samples;
      this.cacheSamples[key].timestamp = Date.now();
      return this.cacheSamples;
    }
    
}
