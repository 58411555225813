import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalHelpContent from '@/components/modalComponents/modalHelpContent/ModalHelpContent.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import SkipButton from '@/components/button/skipButton/SkipButton.vue';

@Component({
  components: {
    Slider,
    ModalHelpContent,
    MainButton,
    SkipButton,
  },
})

export default class newFeaturesInfo extends Vue {

  private homeId: any = {};
  private homes: any = {};
  private skipOrCloseText: any = {};
  private slickSettings: any = {};
  private slickPosition: any = {};
  private slick: any = {};
  private buttonText: any = '';

  private async mounted() {
    this.homeId = this.$route.params.homeid;
    this.homes = {};
    this.skipOrCloseText = '';
    this.slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
      arrows: false,
      nextArrow: false,
      prevArrow: false,
      method: {},
      initialSlide: 0,
      event: {
        beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
          this.slickPosition = newPos;
        },
        init: (event: any, slick: any) => {
          this.slick = slick;
          this.slickPosition = 0;
        }
      }
    };
    this.updateButtonText();
    this.$devListService.getList().then((list: any) => {
      this.homes = list;
    });
  }

  private close(): any {
    this.$emit('close');
  }

  private nextStep(): any {
    if (!this.slickPosition && this.$devListService.length === 1) {
      this.slick.slickGoTo(this.slickPosition + 1);
      this.updateButtonText();
    } else {
      this.close();
    }
  }

  private goToGeolocalizationConfig(): any {
    this.$router.push({ name: 'root.authenticated.root.editHome.geolocation', query: {} });
    this.$emit('close');
  }

  private getSkipOrCloseText(): any {
    if (!this.slickPosition && this.homes.length === 1) {
        return 'SKIP';
    } else {
        return 'CLOSE';
    }
  }

  private buttonAction(): any {
    switch(this.slickPosition) {
      case 0:
        this.goToGeolocalizationConfig();
        break;
      case 1:
      default:
        this.nextStep();
        break;
    }
  }

  private updateButtonText(): any {
    this.buttonText = (this.slickPosition === 0) ? this.$t('CONFIGURE') : this.getSkipOrCloseText();
  }
}
