import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalHelpContent from '@/components/modalComponents/modalHelpContent/ModalHelpContent.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    ModalHelpContent,
    MainButton,
  },
})

export default class setTemperatureHelp extends Vue {

    private slickSettings: any = {};
    private slickPosition: any = {};
    private slick: any = {};

    private mounted() {
      this.slickSettings = {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        arrows: false,
        event: {
          beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
            this.slickPosition = newPos;
          },
          init: (event: any, slick: any) => {
            this.slick = slick;
            this.slickPosition = 0;
          }
        }
      };
    }

    private close(): any {
      this.$emit('close');
    }

}
