import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Root from '../views/root/Root.vue';
import Redirect from '../views/root/redirect/Redirect.vue';
import Anonymous from '../views/root/anonymous/Anonymous.vue';
import SignUp from '../views/root/anonymous/signUp/SignUp.vue';
import SignUpSuccess from '../views/root/anonymous/signUpSuccess/SignUpSuccess.vue';
import SignUpVerify from '../views/root/anonymous/signUpVerify/SignUpVerify.vue';
import ChangeEmail from '../views/root/anonymous/changeEmail/ChangeEmail.vue';
import SignIn from '../views/root/anonymous/signIn/SignIn.vue';
import ForgotPass from '../views/root/anonymous/forgotPass/ForgotPass.vue';
import ForgotPassSuccess from '../views/root/anonymous/forgotPassSuccess/ForgotPassSuccess.vue';
import ResetPass from '../views/root/anonymous/resetPass/ResetPass.vue';
import ConfirmInvite from '../views/root/anonymous/confirmInvite/ConfirmInvite.vue';
import ConfirmInviteSuccess from '../views/root/anonymous/confirmInviteSuccess/ConfirmInviteSuccess.vue';
import Authenticated from '../views/root/authenticated/Authenticated.vue';
import AuthenticatedRoot from '../views/root/authenticated/root/Root.vue';
import Welcome from '../views/root/authenticated/root/welcome/Welcome.vue';
import Support from '../views/root/authenticated/root/support/Support.vue';
import Privacy from '../views/root/authenticated/root/privacy/Privacy.vue';
import Version from '../views/root/authenticated/root/version/Version.vue';
import Homes from '../views/root/authenticated/root/homes/Homes.vue';
import AddHome from '../views/root/authenticated/root/addHome/AddHome.vue';
import SetHomeName from '../views/root/authenticated/root/addHome/setName/SetName.vue';
import SetTimezone from '../views/root/authenticated/root/addHome/setTimezone/SetTimezone.vue';
import AddDevice from '../views/root/authenticated/root/addDevice/AddDevice.vue';
import StartInfo from '../views/root/authenticated/root/addDevice/startInfo/StartInfo.vue';
import EthernetStartInfo from '../views/root/authenticated/root/addDevice/ethernet/startInfo/StartInfo.vue';
import WifiStartInfo from '../views/root/authenticated/root/addDevice/wifi/startInfo/StartInfo.vue';
import SelectType from '../views/root/authenticated/root/addDevice/selectType/SelectType.vue';
import NotAllowed from '../views/root/authenticated/root/addDevice/notAllowed/NotAllowed.vue';
import Ethernet from '../views/root/authenticated/root/addDevice/ethernet/Ethernet.vue';
import SetDevid from '../views/root/authenticated/root/addDevice/ethernet/setDevid/SetDevid.vue';
import Wifi from '../views/root/authenticated/root/addDevice/wifi/Wifi.vue';
import WifiHtr from '../views/root/authenticated/root/addDevice/wifiHtr/WifiHtr.vue';
import SelectProvisioning from '../views/root/authenticated/root/addDevice/wifi/selectProvisioning/SelectProvisioning.vue';
import SetAPData from '../views/root/authenticated/root/addDevice/wifi/setAPData/SetAPData.vue';
import ConnectionInfo from '../views/root/authenticated/root/addDevice/wifi/connectionInfo/ConnectionInfo.vue';
import AccessPointProvisioning from '../views/root/authenticated/root/addDevice/wifi/accessPointProvisioning/AccessPointProvisioning.vue';
import ESPProvisioning from '../views/root/authenticated/root/addDevice/wifi/ESPProvisioning/ESPProvisioning.vue';
import BTProvisioning from '../views/root/authenticated/root/addDevice/wifi/BTProvisioning/BTProvisioning.vue';
import SetName from '../views/root/authenticated/root/addDevice/wifi/setName/SetName.vue';
import EditHome from '../views/root/authenticated/root/editHome/EditHome.vue';
import Devices from '../views/root/authenticated/root/editHome/devices/Devices.vue';
import Data from '../views/root/authenticated/root/editHome/data/Data.vue';
import MaxPowerManagement from '../views/root/authenticated/root/editHome/maxPowerManagement/MaxPowerManagement.vue';
import MaxPowerManagementDay from '../views/root/authenticated/root/editHome/maxPowerManagementDay/MaxPowerManagementDay.vue';
import Geolocation from '../views/root/authenticated/root/editHome/geolocation/Geolocation.vue';
import EditDevice from '../views/root/authenticated/root/editDevice/EditDevice.vue';
import Main from '../views/root/authenticated/root/editDevice/main/Main.vue';
import InviteUsers from '../views/root/authenticated/root/editHome/inviteUsers/InviteUsers.vue';
import EditAccount from '../views/root/authenticated/root/editAccount/EditAccount.vue';
import Device from '../views/root/authenticated/root/device/Device.vue';
import List from '../views/root/authenticated/root/device/list/List.vue';
import Heating from '../views/root/authenticated/root/device/list/heating/Heating.vue';
import Electricity from '../views/root/authenticated/root/device/list/electricity/Electricity.vue';
import Weather from '../views/root/authenticated/root/device/list/weather/Weather.vue';
import Thermostat from '../views/root/authenticated/root/device/thermostat/Thermostat.vue';
import Schedule from '../views/root/authenticated/root/device/heater/schedule/Schedule.vue';
import ThermostatScheduleDay from '../views/root/authenticated/root/device/thermostat/scheduleDay/ScheduleDay.vue';
import Setup from '../views/root/authenticated/root/device/heater/setup/Setup.vue';
import PowerMeter from '../views/root/authenticated/root/device/powerMeter/PowerMeter.vue';
import Usage from '../views/root/authenticated/root/device/powerMeter/nav/usage/Usage.vue';
import Heater from '../views/root/authenticated/root/device/heater/Heater.vue';
import Nav from '../views/root/authenticated/root/device/heater/nav/Nav.vue';
import ElectricityNav from '../views/root/authenticated/root/device/powerMeter/nav/Nav.vue';
import Temperature from '../views/root/authenticated/root/device/heater/nav/temperature/Temperature.vue';
import Stats from '../views/root/authenticated/root/device/heater/nav/stats/Stats.vue';
import Day from '../views/root/authenticated/root/device/heater/nav/stats/day/Day.vue';
import ScheduleDay from '../views/root/authenticated/root/device/heater/scheduleDay/ScheduleDay.vue';
import NodeInstall from '../views/root/authenticated/root/device/nodeInstall/NodeInstall.vue';
import ThmInstall from '../views/root/authenticated/root/device/thmInstall/ThmInstall.vue';
import TrvInstall from '../views/root/authenticated/root/device/trvInstall/TrvInstall.vue';
import HtrInstall from '../views/root/authenticated/root/device/htrInstall/HtrInstall.vue';
import AcmInstall from '../views/root/authenticated/root/device/acmInstall/AcmInstall.vue';
import PmoInstall from '../views/root/authenticated/root/device/pmoInstall/PmoInstall.vue';
import HtrModInstall from '../views/root/authenticated/root/device/htrModInstall/HtrModInstall.vue';
import MaintenanceError from '../views/root/maintenanceError/MaintenanceError.vue';
import RemoveAccountHelp from '../views/root/removeAccountHelp/RemoveAccountHelp.vue';
import DeprecatedMessage from '../views/root/deprecatedMessage/DeprecatedMessage.vue';
import Menu from '../views/root/authenticated/root/device/list/heating/menu/Menu.vue';

import store from '@/store';
import { sessionService, devListService, userService } from '@/main';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    "path": "/",
    "name": "main",
    "redirect": {
      "name": "root.authenticated.root"
    }
  },
  {
    "path": "root",
    "name": "root",
    children: [
      {
        "path": "/redirect",
        "name": "root.redirect",
        "component": Redirect,
      },
      {
        "path": "/removeAccountHelp",
        "name": "root.removeAccountHelp",
        "component": RemoveAccountHelp,
      },
      {
        "path": "/maintenanceError",
        "name": "root.maintenanceError",
        "component": MaintenanceError,
      },
      {
        "path": "/deprecatedMessage",
        "name": "root.deprecatedMessage",
        "component": DeprecatedMessage,
      },
      {
        "path": "anonymous",
        "name": "root.anonymous",
        children: [
          {
            "path": "/signUp",
            "name": "root.anonymous.signUp",
            "component": SignUp,
          },
          {
            "path": "/signUpSuccess",
            "name": "root.anonymous.signUpSuccess",
            "component": SignUpSuccess,
          },
          {
            "path": "/registration-confirm/:serialid_info",
            "name": "root.anonymous.signUpVerify",
            "component": SignUpVerify,
          },
          {
            "path": "/changeEmail",
            "name": "root.anonymous.changeEmail",
            "component": ChangeEmail,
          },
          {
            "path": "/signIn",
            "name": "root.anonymous.signIn",
            "component": SignIn,
          },
          {
            "path": "/forgotPass",
            "name": "root.anonymous.forgotPass",
            "component": ForgotPass,
          },
          {
            "path": "/forgotPassSuccess",
            "name": "root.anonymous.forgotPassSuccess",
            "component": ForgotPassSuccess,
          },
          {
            "path": "/password-reset-confirm/:serialid_info",
            "name": "root.anonymous.resetPass",
            "component": ResetPass,
          },
          {
            "path": "/invite-confirm/:serialid_info",
            "name": "root.anonymous.confirmInvite",
            "component": ConfirmInvite,
          },
          {
            "path": "/confirmInviteSuccess",
            "name": "root.anonymous.confirmInviteSuccess",
            "component": ConfirmInviteSuccess,
          }
        ],
        "component": Anonymous,
      },
      {
        "path": "authenticated",
        beforeEnter: (to: any, from: any, next: any) => {
          return Promise.resolve()
          .then(() => sessionService.getSessionData())
          .then(async (sessionData: any) => {
            if (!sessionData) {
              throw new Error('No session');
            }
            store.commit('setUserEmail', sessionData.username);
            await devListService.initDevList();
            await userService.connectToBackend();
          })
          .then(() => {
            next();
          })
          .catch(() => {
            next('/signIn');
          });
        },
        "name": "root.authenticated",
        children: [
          {
            "path": "root",
            "name": "root.authenticated.root",
            children: [
              {
                "path": "/welcome",
                "name": "root.authenticated.root.welcome",
                "component": Welcome,
              },
              {
                "path": "/support",
                "name": "root.authenticated.root.support",
                "component": Support,
              },
              {
                "path": "/privacy",
                "name": "root.authenticated.root.privacy",
                "component": Privacy,
              },
              {
                "path": "/version",
                "name": "root.authenticated.root.version",
                "component": Version,
              },
              {
                "path": "/homes",
                "name": "root.authenticated.root.homes",
                "component": Homes,
              },
              {
                "path": "/addHome",
                "name": "root.authenticated.root.addHome",
                children: [
                  {
                    "path": "setName",
                    "name": "root.authenticated.root.addHome.setName",
                    "component": SetHomeName,
                  },
                  {
                    "path": "setTimezone",
                    "name": "root.authenticated.root.addHome.setTimezone",
                    "component": SetTimezone,
                  }
                ],
                "component": AddHome,
              },
              {
                "path": "/homes/:homeid/addDevice",
                "name": "root.authenticated.root.addDevice",
                children: [
                  {
                    "path": "startInfo",
                    "name": "root.authenticated.root.addDevice.startInfo",
                    "component": StartInfo,
                  },
                  {
                    "path": "selectType",
                    "name": "root.authenticated.root.addDevice.selectType",
                    "component": SelectType,
                  },
                  {
                    "path": "notAllowed",
                    "name": "root.authenticated.root.addDevice.notAllowed",
                    "component": NotAllowed,
                  },
                  {
                    "path": "ethernet",
                    "name": "root.authenticated.root.addDevice.ethernet",
                    children: [
                      {
                        "path": "startInfo",
                        "name": "root.authenticated.root.addDevice.ethernet.startInfo",
                        "component": EthernetStartInfo,
                      },
                      {
                        "path": "setDevid",
                        "name": "root.authenticated.root.addDevice.ethernet.setDevid",
                        "component": SetDevid,
                      },
                      {
                        "path": ":devid/setName",
                        "name": "root.authenticated.root.addDevice.ethernet.setName",
                        "component": SetName,
                      }
                    ],
                    "component": Ethernet,
                  },
                  {
                    "path": "wifi",
                    "name": "root.authenticated.root.addDevice.wifi",
                    children: [
                      {
                        "path": "wifi/selectProvisioning",
                        "name": "root.authenticated.root.addDevice.wifi.selectProvisioning",
                        "component": SelectProvisioning,
                      },
                      {
                        "path": "wifi/startInfo",
                        "name": "root.authenticated.root.addDevice.wifi.startInfo",
                        "component": WifiStartInfo,
                      },
                      {
                        "path": "wifi/setAPData",
                        "name": "root.authenticated.root.addDevice.wifi.setAPData",
                        "component": SetAPData,
                      },
                      {
                        "path": "wifi/connectionInfo",
                        "name": "root.authenticated.root.addDevice.wifi.connectionInfo",
                        "component": ConnectionInfo,
                      },
                      {
                        "path": "wifi/accessPointProvisioning",
                        "name": "root.authenticated.root.addDevice.wifi.accessPointProvisioning",
                        "component": AccessPointProvisioning,
                      },
                      {
                        "path": "wifi/espProvisioning",
                        "name": "root.authenticated.root.addDevice.wifi.ESPProvisioning",
                        "component": ESPProvisioning,
                      },
                      {
                        "path": "wifi/btProvisioning",
                        "name": "root.authenticated.root.addDevice.wifi.BTProvisioning",
                        "component": BTProvisioning,
                      },
                      {
                        "path": "wifi/:devid/setName",
                        "name": "root.authenticated.root.addDevice.wifi.setName",
                        "component": SetName,
                      }
                    ],
                    "component": Wifi,
                  },
                  {
                    "path": "wifiHtr",
                    "name": "root.authenticated.root.addDevice.wifiHtr",
                    children: [
                      {
                        "path": "wifiHtr/:devid/setName",
                        "name": "root.authenticated.root.addDevice.wifiHtr.setName",
                        "component": SetName,
                      }
                    ],
                    "component": WifiHtr,
                  }
                ],
                "component": AddDevice,
              },
              {
                "path": "/homes/:homeid",
                "name": "root.authenticated.root.editHome",
                children: [
                  {
                    "path": "devices",
                    "name": "root.authenticated.root.editHome.devices",
                    "component": Devices,
                  },
                  {
                    "path": "data",
                    "name": "root.authenticated.root.editHome.data",
                    "component": Data,
                  },
                  {
                    "path": "power",
                    "name": "root.authenticated.root.editHome.maxPowerManagement",
                    "component": MaxPowerManagement,
                  },
                  {
                    "path": "powerDay/:day",
                    "name": "root.authenticated.root.editHome.maxPowerManagementDay",
                    "component": MaxPowerManagementDay,
                  },
                  {
                    "path": "geolocation",
                    "name": "root.authenticated.root.editHome.geolocation",
                    "component": Geolocation,
                  },
                  {
                    "path": "invite",
                    "name": "root.authenticated.root.editHome.inviteUsers",
                    "component": InviteUsers,
                  }
                ],
                "component": EditHome,
              },
              {
                "path": "/device/:devid/editDevice",
                "name": "root.authenticated.root.editDevice",
                children: [
                  {
                    "path": "data",
                    "name": "root.authenticated.root.editDevice.main",
                    "component": Main,
                  },
                  {
                    "path": "wifi",
                    "name": "root.authenticated.root.editDevice.wifi",
                    "component": Wifi,
                  },
                  {
                    "path": "wifiHtr",
                    "name": "root.authenticated.root.editDevice.wifiHtr",
                    "component": WifiHtr,
                  }
                ],
                "component": EditDevice,
              },
              {
                "path": "/account",
                "name": "root.authenticated.root.editAccount",
                "component": EditAccount,
              },
              {
                "path": "/:homeid",
                "name": "root.authenticated.root.device",
                children: [
                  {
                    "path": "list",
                    "name": "root.authenticated.root.device.list",
                    children: [
                      {
                        "path": "heating",
                        "name": "root.authenticated.root.device.list.heating",
                        "components": {
                          "list": Heating,
                          "menu": Menu,
                        }
                      },
                      {
                        "path": "electricity",
                        "name": "root.authenticated.root.device.list.electricity",
                        "components": {
                          "list": Electricity,
                          "menu": Menu,
                        }
                      },
                      {
                        "path": "weather",
                        "name": "root.authenticated.root.device.list.weather",
                        "component": Weather,
                      }
                    ],
                    "component": List,
                  },
                  {
                    "path": "dev/:devid/thm/:addr",
                    "name": "root.authenticated.root.device.thermostat",
                    children: [
                      {
                        "path": "nav",
                        "name": "root.authenticated.root.device.thermostat.nav",
                        children: [
                          {
                            "path": "temperature",
                            "name": "root.authenticated.root.device.thermostat.nav.temperature",
                            "component": Temperature,
                          },
                          {
                            "path": "stats",
                            "name": "root.authenticated.root.device.thermostat.nav.stats",
                            children: [
                              {
                                "path": ":year/:month/:day",
                                "name": "root.authenticated.root.device.thermostat.nav.stats.day",
                                "component": Day,
                              },
                              {
                                "path": ":year/:month",
                                "name": "root.authenticated.root.device.thermostat.nav.stats.month",
                                "component": Day,
                              },
                              {
                                "path": ":year",
                                "name": "root.authenticated.root.device.thermostat.nav.stats.year",
                                "component": Day,
                              }
                            ],
                            "component": Stats,
                          }
                        ],
                        "component": Nav,
                      },
                      {
                        "path": "schedule",
                        "name": "root.authenticated.root.device.thermostat.schedule",
                        "component": Schedule,
                      },
                      {
                        "path": "scheduleDay/:day",
                        "name": "root.authenticated.root.device.thermostat.scheduleDay",
                        "component": ThermostatScheduleDay,
                      },
                      {
                        "path": "setup",
                        "name": "root.authenticated.root.device.thermostat.setup",
                        "component": Setup,
                      }
                    ],
                    "component": Thermostat,
                  },
                  {
                    "path": "dev/:devid/pmo/:addr",
                    "name": "root.authenticated.root.device.powerMeter",
                    children: [
                      {
                        "path": "nav",
                        "name": "root.authenticated.root.device.powerMeter.nav",
                        children: [
                          {
                            "path": "usage",
                            "name": "root.authenticated.root.device.powerMeter.nav.usage",
                            "component": Usage,
                          },
                          {
                            "path": "stats",
                            "name": "root.authenticated.root.device.powerMeter.nav.stats",
                            children: [
                              {
                                "path": ":year/:month/:day",
                                "name": "root.authenticated.root.device.powerMeter.nav.stats.day",
                                "component": Day,
                              },
                              {
                                "path": ":year/:month",
                                "name": "root.authenticated.root.device.powerMeter.nav.stats.month",
                                "component": Day,
                              },
                              {
                                "path": ":year",
                                "name": "root.authenticated.root.device.powerMeter.nav.stats.year",
                                "component": Day,
                              }
                            ],
                            "component": Stats,
                          }
                        ],
                        "component": ElectricityNav,
                      },
                      {
                        "path": "setup",
                        "name": "root.authenticated.root.device.powerMeter.setup",
                        "component": Setup,
                      }
                    ],
                    "component": PowerMeter,
                  },
                  {
                    "path": "dev/:devid/htr/:addr",
                    "name": "root.authenticated.root.device.heater",
                    children: [
                      {
                        "path": "nav",
                        "name": "root.authenticated.root.device.heater.nav",
                        children: [
                          {
                            "path": "temperature",
                            "name": "root.authenticated.root.device.heater.nav.temperature",
                            "component": Temperature,
                          },
                          {
                            "path": "stats",
                            "name": "root.authenticated.root.device.heater.nav.stats",
                            children: [
                              {
                                "path": ":year/:month/:day",
                                "name": "root.authenticated.root.device.heater.nav.stats.day",
                                "component": Day,
                              },
                              {
                                "path": ":year/:month",
                                "name": "root.authenticated.root.device.heater.nav.stats.month",
                                "component": Day,
                              },
                              {
                                "path": ":year",
                                "name": "root.authenticated.root.device.heater.nav.stats.year",
                                "component": Day,
                              }
                            ],
                            "component": Stats,
                          }
                        ],
                        "component": Nav,
                      },
                      {
                        "path": "schedule",
                        "name": "root.authenticated.root.device.heater.schedule",
                        "component": Schedule,
                      },
                      {
                        "path": "scheduleDay/:day",
                        "name": "root.authenticated.root.device.heater.scheduleDay",
                        "component": ScheduleDay,
                      },
                      {
                        "path": "setup",
                        "name": "root.authenticated.root.device.heater.setup",
                        "component": Setup,
                      }
                    ],
                    "component": Heater,
                  },
                  {
                    "path": "dev/:devid/acm/:addr",
                    "name": "root.authenticated.root.device.storageheater",
                    children: [
                      {
                        "path": "nav",
                        "name": "root.authenticated.root.device.storageheater.nav",
                        children: [
                          {
                            "path": "temperature",
                            "name": "root.authenticated.root.device.storageheater.nav.temperature",
                            "component": Temperature,
                          },
                          {
                            "path": "stats",
                            "name": "root.authenticated.root.device.storageheater.nav.stats",
                            children: [
                              {
                                "path": ":year/:month/:day",
                                "name": "root.authenticated.root.device.storageheater.nav.stats.day",
                                "component": Day,
                              },
                              {
                                "path": ":year/:month",
                                "name": "root.authenticated.root.device.storageheater.nav.stats.month",
                                "component": Day,
                              },
                              {
                                "path": ":year",
                                "name": "root.authenticated.root.device.storageheater.nav.stats.year",
                                "component": Day,
                              }
                            ],
                            "component": Stats,
                          }
                        ],
                        "component": Nav,
                      },
                      {
                        "path": "schedule",
                        "name": "root.authenticated.root.device.storageheater.schedule",
                        "component": Schedule,
                      },
                      {
                        "path": "scheduleDay/:day",
                        "name": "root.authenticated.root.device.storageheater.scheduleDay",
                        "component": ScheduleDay,
                      },
                      {
                        "path": "setup",
                        "name": "root.authenticated.root.device.storageheater.setup",
                        "component": Setup,
                      }
                    ],
                    "component": Heater,
                  },
                  {
                    "path": "dev/:devid/htr_mod/:addr",
                    "name": "root.authenticated.root.device.heaterMod",
                    children: [
                      {
                        "path": "nav",
                        "name": "root.authenticated.root.device.heaterMod.nav",
                        children: [
                          {
                            "path": "temperature",
                            "name": "root.authenticated.root.device.heaterMod.nav.temperature",
                            "component": Temperature,
                          },
                          {
                            "path": "stats",
                            "name": "root.authenticated.root.device.heaterMod.nav.stats",
                            children: [
                              {
                                "path": ":year/:month/:day",
                                "name": "root.authenticated.root.device.heaterMod.nav.stats.day",
                                "component": Day,
                              },
                              {
                                "path": ":year/:month",
                                "name": "root.authenticated.root.device.heaterMod.nav.stats.month",
                                "component": Day,
                              },
                              {
                                "path": ":year",
                                "name": "root.authenticated.root.device.heaterMod.nav.stats.year",
                                "component": Day,
                              }
                            ],
                            "component": Stats,
                          }
                        ],
                        "component": Nav,
                      },
                      {
                        "path": "schedule",
                        "name": "root.authenticated.root.device.heaterMod.schedule",
                        "component": Schedule,
                      },
                      {
                        "path": "scheduleDay/:day",
                        "name": "root.authenticated.root.device.heaterMod.scheduleDay",
                        "component": ScheduleDay,
                      },
                      {
                        "path": "setup",
                        "name": "root.authenticated.root.device.heaterMod.setup",
                        "component": Setup,
                      }
                    ],
                    "component": Heater,
                  },
                  {
                    "path": "nodeInstall",
                    "name": "root.authenticated.root.device.nodeInstall",
                    "component": NodeInstall,
                  },
                  {
                    "path": ":devid/thmInstall",
                    "name": "root.authenticated.root.device.thmInstall",
                    "component": ThmInstall,
                  },
                  {
                    "path": ":devid/trvInstall",
                    "name": "root.authenticated.root.device.trvInstall",
                    "component": TrvInstall,
                  },
                  {
                    "path": ":devid/htrInstall",
                    "name": "root.authenticated.root.device.htrInstall",
                    "component": HtrInstall,
                  },
                  {
                    "path": ":devid/acmInstall",
                    "name": "root.authenticated.root.device.acmInstall",
                    "component": AcmInstall,
                  },
                  {
                    "path": ":devid/pmoInstall",
                    "name": "root.authenticated.root.device.pmoInstall",
                    "component": PmoInstall,
                  },
                  {
                    "path": ":devid/htrModInstall",
                    "name": "root.authenticated.root.device.htrModInstall",
                    "component": HtrModInstall,
                  }
                ],
                "component": Device,
              }
            ],
            "component": AuthenticatedRoot,
          }
        ],
        "component": Authenticated,
      },

    ],
    "component": Root,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
