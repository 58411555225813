import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import WarningButton from '@/components/button/warningButton/WarningButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    CancelButton,
    WarningButton,
  },
})

export default class eraseNode extends Vue {
  private purge = false;

  private close(): any {
    this.$emit('close');
  }

  private deleteSelected(): any {
    rootScope.eraseNodeModalData = { purge: this.purge };
    this.$emit('erase');
  }

}
