import { Component, Vue, Watch } from 'vue-property-decorator';
import { devWifiConfig } from '@/services/devWifiConfig';
import _ from 'lodash';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import AsciiErrorModal from '@/components/modals/asciiError/asciiError.vue';
import QrScanErrorModal from '@/components/modals/qrScanError/qrScanError.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    AsciiErrorModal,
    QrScanErrorModal,
    MainButton,
    TitleText,
  },
})
export default class SetAPData extends Vue {

    private wifiCredentials: any = {};
    private state: any = {};
    private networkList: any = {};
    private selectedAP: any = '';
    private password: any = {};
    private showPassword: any = {};
    private secretPass: any = {};
    private manualLabel: any = {};
    private currentWifi: any = '';
    private apManualInput = false;
    private devType: any = {};
    private showAsciiErrorModal = false;
    private showQrScanErrorModal = false;
    private maxCredentialLength: any = {};
    private QRScanner: any;
    private scannerReady = false;

    private mounted() {
      this.QRScanner = (window as any).QRScanner;
      this.wifiCredentials = {};
      this.currentWifi;
      this.devType = this.$route.params.type || this.$route.query.type as string;
      this.state = 'loading';
      this.networkList = [];
      this.selectedAP = '';
      this.password = '';
      this.showPassword = false;
      this.secretPass = false;
      this.manualLabel = '';
      this.maxCredentialLength = 32;

      this.$amplitudeService.sendEvent('device_wifi_provisioning_start');
      this.$amplitudeService.sendEvent('device_wifi_provisioning_set_ap');
      Promise.resolve()
      .then(() => {
        this.manualLabel = this.$t('MANUAL_NETWORK_INPUT');
        return new devWifiConfig().getStoredWifiCredentials();
      }).then(async (storedWifiCredentials: any) => {
        this.wifiCredentials = storedWifiCredentials;
        return new devWifiConfig().getPhoneCurrentWifi();
      }).then(async (currentWifiInfo: any) => {
        this.currentWifi = currentWifiInfo.currentNetwork;
        return new devWifiConfig().getPhoneNetworkList();
      }).then(async (networkInfo: any) => {
        this.networkList = this.getSortedNetworkList(networkInfo);
        this.setInitialSelectedAP();
        this.state = 'ready';
      }).catch(() => {
        this.setInitialSelectedAP();
        this.apManualInput = true;
        this.state = 'ready';
      });
    }

    private destroyed() {
      this.qrActive = false;
      if (this.scannerReady) {
        this.QRScanner.hide();
        this.QRScanner.cancelScan();
        this.QRScanner.destroy();
      }
    }

    private onScanDone(err: any, text: any) {
      const essidRegExp = /.*S:([^;]*);.*/g;
      const passRegExp = /.*P:([^;]*);.*/g;
      const essidMatches = [...text.matchAll(essidRegExp)];
      const passMatches = [...text.matchAll(passRegExp)];
      if (essidMatches.length === 0 || passMatches.length === 0) {
        this.QRScanner.scan(this.onScanDone);
        return;
      }
      const essidMatchData = essidMatches[0];
      const passMatchData = passMatches[0];
      const essid = essidMatchData[1];
      const password = passMatchData[1];
      if (!this.wifiCredentials) {
        this.wifiCredentials = {};
      }
      if (this.networkList.findIndex((option: string) => option === essid) === -1) {
        this.networkList.push(essid);
      }
      this.wifiCredentials[essid] = password;
      this.selectedAP = essid;
      this.password = password;
      this.qrActive = false;
    }

    private onScannerReady(err: any, status: any) {
      if (!status.authorized) {
        return;
      }
      this.scannerReady = true;
      this.QRScanner.scan(this.onScanDone);
      this.QRScanner.show();
    }

    @Watch('qrActive')
    private onQrActiveChanged() {
      if (this.qrActive) {
        if (this.scannerReady) {
          this.onScannerReady(undefined, { authorized: true });
        } else {
          this.QRScanner.prepare(this.onScannerReady);
        }
      } else {
        this.QRScanner.hide();
        this.QRScanner.cancelScan();
      }
    }

    @Watch('selectedAP')
    private onSelectedAPChanged(newValue: string, oldValue: string) {
      if (newValue === this.manualLabel) {
        this.apManualInput = true;
        this.selectedAP = '';
        return;
      }
      if (!_.isEqual(oldValue, newValue) && this.checkCredentials(this.selectedAP, this.password)) {
        this.$amplitudeService.sendEvent('device_wifi_provisioning_set_ap');
        const newPassword: any = this.getPasswordFromAP();
        this.password = newPassword;
      }
    }

    private get qrActive(): boolean {
      return this.$store.state.qrActive;
    }

    private set qrActive(active: boolean) {
      this.$store.state.qrActive = active;
    }

    private goToManualAPData() {
      this.$store.state.qrActive = false;
    }

    private togglePasswordVisibility(): any {
      if (this.secretPass) {
        return;
      }
      this.showPassword = !this.showPassword;
    }

    private async setAPData(): Promise<any> {
      if (!this.checkCredentials(this.selectedAP, this.password)) {
        return;
      }
      let route: any = 'root.authenticated.root.addDevice.wifi.selectProvisioning';
      if (this.devType === 'wifi') {
        route = 'root.authenticated.root.addDevice.wifi.startInfo';
      }
      const encryptedPassData: any = await this.$apiService.getEncryptedWifiCredentials(this.selectedAP, this.password);
      const encryptedPass: any = encryptedPassData?.encrypted_pass;
      routerWrapper.push(this.$router, route, {
        wifiCredentials: {
          essid: this.selectedAP,
          pass: this.password,
          encryptedPass
        },
        devData: {},
        ...this.$route.params,
        type: this.devType,
        subtype: this.$route.params.subtype || this.$route.query.subtype,
      });
    }


    private getAPFromNetworkList(): any {
      if (Object.keys(this.wifiCredentials).length > 0) {
        return Object.keys(this.wifiCredentials)[0];
      } else {
        return '';
      }
    }

    private getAPFromStoredWifiCredentials(): any {
      if (this.networkList.length > 0) {
        return this.networkList[0];
      } else {
        return '';
      }
    }

    private getPasswordFromAP(): any {
      if (!this.wifiCredentials || !this.selectedAP || !this.wifiCredentials[this.selectedAP]) {
        return '';
      }
      this.secretPass = true;
      return this.wifiCredentials[this.selectedAP];
    }

    private setInitialSelectedAP(): any {
      if (this.currentWifi !== undefined) {
        this.selectedAP = this.currentWifi;
      } else {
        this.selectedAP = this.getAPFromNetworkList() || this.getAPFromStoredWifiCredentials();
      }
      this.password = this.getPasswordFromAP();
    }

    private getSortedNetworkList(networkInfo?: any): any {
      return Object.keys(networkInfo).sort((network1, network2: any) => {
        return Number(networkInfo[network1]) < Number(networkInfo[network2]) ? 1 : 0;
      })
      .filter((essid) => essid.length < this.maxCredentialLength)
      .concat([this.manualLabel]);
    }

    private checkCredentials(essid: any, password: any): boolean {
      const supportedLength = essid.length <= this.maxCredentialLength && password.length <= this.maxCredentialLength;
      const asciiRegExp = /^[ -~]*$/;
      const asciiOnly = asciiRegExp.test(essid) && asciiRegExp.test(password);
      const valid = supportedLength && asciiOnly;

      if (!supportedLength) {
        this.showQrScanErrorModal = true;
      }

      if (!asciiOnly) {
        this.showAsciiErrorModal = true;
      }

      if (!valid) {
        this.password = '';
        if (this.apManualInput) {
          this.selectedAP = '';
        }
      }

      return valid;
    }
}
