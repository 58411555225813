import { Component, Vue } from 'vue-property-decorator';
import _ from '@/services/_';
import Slider from '@/components/slider/Slider.vue';
import { NodeTypeConfig } from '@/services/config';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    CancelButton,
  },
})
export default class awayStatus extends Vue {

  private homeId: any = {};
  private thmCount: any = {};
  private trvCount: any = {};
  private htrCount: any = {};
  private legacyAcmCount: any = {};
  private acmCount: any = {};
  private pmoCount: any = {};
  private htrModCount: any = {};
  private supportedDevices: NodeTypeConfig[] = [];

  private async mounted() {
    this.supportedDevices = (await this.$configService.getConfig()).clientData.supportedDevices;
    this.homeId = this.$route.params.homeid;
    this.init();
    this.$forceUpdate();
  }

  private get away() {
    return this.$store.state.homes[this.$route.params.homeid].isAway;
  }

  private isEnabled(deviceType: string): boolean {
    return (this.supportedDevices || []).findIndex(supportedDevice => supportedDevice.type === deviceType) !== -1;
  }

  private async setAway(value?: any): Promise<any> {
    this.$amplitudeService.sendEvent('manual_away_status');
    for (const devId in this.$store.state.homes[this.homeId].devs) {
      await this.$apiService.setDevAwayStatus(devId, value);
    }
  }

  private getDeviceLabel(deviceType?: any): any {
    const index: any = this.supportedDevices.findIndex(supportedDevice => supportedDevice.type === deviceType);
    return this.supportedDevices[index].label;
  }

  private close(): any {
    this.$emit('close');
  }

  private init(): any {
    if (!this.homeId) {
      this.$router.push('root.authenticated.root');
      return;
    }
    this.$devListService.getNodeList(this.homeId).then((nodes: any) => {
      const thmList: any = _.filter(nodes, (node: any) => {
        return (node.nodeData || {}).type === 'thm' && !this.$helkiNMService.isTRV(node);
      });
      const trvList: any = _.filter(nodes, (node: any) => {
        return (node.nodeData || {}).type === 'thm' && this.$helkiNMService.isTRV(node);
      });
      const htrList: any = _.filter(nodes, (node: any) => {
        return (node.nodeData || {}).type === 'htr';
      });
      const legacyAcmList: any = _.filter(nodes, (node: any) => {
        return (node.nodeData || {}).type === 'acm';
      });
      const acmList: any = _.filter(nodes, (node: any) => {
        return (node.nodeData || {}).type === 'acm';
      });
      const pmoList: any = _.filter(nodes, (node: any) => {
        return (node.nodeData || {}).type === 'pmo';
      });
      const htrModList: any = _.filter(nodes, (node: any) => {
        return (node.nodeData || {}).type === 'htr_mod';
      });
      this.thmCount = thmList.length;
      this.trvCount = trvList.length;
      this.htrCount = htrList.length;
      this.legacyAcmCount = legacyAcmList.length;
      this.acmCount = acmList.length;
      this.pmoCount = pmoList.length;
      this.htrModCount = htrModList.length;
    });
  }

}
