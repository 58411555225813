import { Component, Vue, Watch } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import AwayStatusModal from '@/components/modals/awayStatus/awayStatus.vue';
import DiscoveryModal from '@/components/modals/discovery/discovery.vue';
import Slider from '@/components/slider/Slider.vue';
import { NodeTypeConfig } from '@/services/config';
import { HomeData } from '@/services/devList.service';
import HomeSelector from '@/components/selector/homeSelector/HomeSelector.vue';
import DashboardNavButton from '@/components/button/dashboardNavButton/DashboardNavButton.vue';

@Component({
  components: {
    Slider,
    AwayStatusModal,
    DiscoveryModal,
    HomeSelector,
    DashboardNavButton,
  },
})
export default class List extends Vue {

  private showAwayStatusModal = false;
  private hasPowerDevices: any = {};
  private slickSettings: any = {};
  private awayStatus: any = {};
  private showDiscoveryModal = false;
  private showAwayStatus = false;
  private showDiscovery = false;
  private supportedDevices: NodeTypeConfig[] = [];
  private showSlick = false;
  private homeIndex = 0;
  private showNewFeaturesInfoModal = true;

  private async mounted() {
    this.supportedDevices = (await this.$configService.getConfig()).clientData.supportedDevices;
    if (!this.homeId) {
      return routerWrapper.push(this.$router, 'root.authenticated.root', undefined, { replace: true });
    }
    this.$devListService.setLastVisited(this.homeId);
    this.init();
    this.$userService.addListener(this.homeId, this.devListener);
    this.$userService.addDiscoveryListener(this.homeId, this.discoveryListener);
  }

  private destroyed() {
    this.$userService.removeListener(this.homeId, this.devListener);
    this.$userService.removeDiscoveryListener(this.homeId, this.discoveryListener);
  }

  private get homeId() {
    return this.$route.params.homeid;
  }

  @Watch('homeId')
  private onRouteParamsChanged() {
    this.$devListService.setLastVisited(this.homeId);
    this.devListener();
  }

  private openAwayStatusDialog(): any {
    this.showAwayStatusModal = true;
  }

  private hasElectricityDevices(): any {
    return this.supportedDevices.findIndex((device) => device.type === 'pmo') !== -1;
  }

  private initSliderPos(): any {
    const i = this.homes.findIndex((home: HomeData) => home.id === this.homeId);
    let pos: number = i !== -1 ? i : 0;
    pos = Math.min(this.homes.length - 1, pos);
    this.slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
      method: {},
      initialSlide: pos,
      event: {
        afterChange: (event: any, slick: any, newPos: any) => {
          if (newPos !== pos) {
            this.updateRouter(newPos);
            pos = newPos;
          }
        }
      }
    };
    this.showSlick = true;
  }

  private get homeConnected() {
    return this.$store.state.homes[this.homeId]?.connected;
  }

  private updateDevStatus(): any {
    return this.$devListService.getAwayStatus(this.homeId)
    .then(async (status: any) => {
      this.awayStatus = status;
      this.$forceUpdate();
    }).catch(() => {
      routerWrapper.push(this.$router, 'root.authenticated.root');
    });
  }

  @Watch('homes')
  private onHomesChanged() {
    this.showSlick = false;
    setTimeout(() => {
      this.showSlick = true;
      this.$forceUpdate();
    });
  }

  private get homes() {
    return this.$store.getters.getHomeList();
  }

  private showHomeCarousel() {
    return (this.homes.length <= 8);
  }

  private changeHomeIndex(newIndex: any) {
    this.updateRouter(newIndex);
    this.homeIndex = newIndex;
  }

  private updateRouter(newPos: number) {
    routerWrapper.push(this.$router, this.$route.name || '', { homeid: this.homes[newPos].id });
  }

  private init(): any {
    this.$devListService.getList().then(async () => {
      this.initSliderPos();
    });
    this.updateDevStatus();
  }

  private devListener(): any {
    this.updateDevStatus();
  }

  private discoveryListener(devId?: any, discovery?: any): any {
    (window as any).devId = devId;
    if (discovery === 'on') {
      this.showDiscoveryModal = true;
    }
  }

  private isInHeatingView(): boolean {
    return this.$route.name === 'root.authenticated.root.device.list.heating';
  }

  private isInElectricityView(): boolean {
    return this.$route.name === 'root.authenticated.root.device.list.electricity';
  }

  private isInWeatherView(): boolean {
    return this.$route.name === 'root.authenticated.root.device.list.weather';
  }

  private getHomeLabel(name: string, owner: boolean) {
    return owner ? name : name + ' (' + this.$t('GUEST') + ')';
  }
}
