import { render, staticRenderFns } from "./setTemperatureHelp.vue?vue&type=template&id=49f42c94"
import script from "./setTemperatureHelp.ts?vue&type=script&lang=js&external"
export * from "./setTemperatureHelp.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports