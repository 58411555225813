var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a',{staticClass:"snav-m-a b-bottom",class:{'disabled': _vm.disabled, 'disabled-btn': _vm.disabled, 'alink': !_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{staticClass:"txt-xxl click",class:{'icon-temp': _vm.iconTemp(),
                   'icon-config': _vm.iconConfig(),
                   'icon-schedule': _vm.iconSchedule(),
                   'icon-locked': _vm.iconLocked(),
                   'icon-unlocked': _vm.iconUnlocked(),
                   'icon-off': _vm.iconOff(),
                   'icon-records': _vm.iconRecords(),
                   'icon-manual': _vm.iconManual(),
                   'icon-m-confort-r': _vm.iconComfort(),
                   'icon-m-eco-r': _vm.iconEco(),
                   'icon-m-antih-r': _vm.iconIce(),
                   'icon-boost': _vm.iconBoost(),
                   'icon-presence': _vm.iconPresence(),
                   'icon-self-learning': _vm.iconSelfLearning()}}),_c('span',{staticClass:"m-xxs-top displayb txt-c uppc txt-s click"},[_vm._v(_vm._s(_vm.label))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }