import * as amplitude from 'amplitude-js';
import { ISessionService } from '@/services/session.service';
import { IUserService } from '@/services/user.service';

export interface IAmplitudeSetup {
  apiKey: string;
}

export interface IAmplitudeService {
  sendEvent(eventName: string, data?: any): void;
  setUserId(userId?: string): void;
  resetUserId(): void;
  resetSession(): void;
}

export class AmplitudeService {
  private amplitudeClient: any;

  constructor(
    private setup: IAmplitudeSetup,
    private sessionService: ISessionService,
    private userService: IUserService,
  ) {
    this.init();
  }

  public sendEvent(eventName: string, data: any = {}) {
    this.amplitudeClient.logEvent(eventName, data);
  }

  public resetUserId() {
    const userId = this.sessionService.getUserId();
    this.setUserId(userId);
    if (userId) {
      const serialId = this.sessionService.getSerialId();
      this.amplitudeClient.setUserProperties({
        serial_id: serialId,
      });
    }
  }

  public resetSession() {
    this.setUserId(null);
  }

  private init() {
    this.amplitudeClient = amplitude.getInstance();
    const currentLanguage = this.userService.getLanguage();
    const appVersion = this.sessionService.getFrontendVersion();
    this.amplitudeClient.init(this.setup.apiKey);
    this.resetUserId();
    this.amplitudeClient.setUserProperties({
      frontend_language: currentLanguage,
      app_version: appVersion,
    });
    this.sendEvent('app_loaded');
  }

  private setUserId(userId: string|null) {
    this.amplitudeClient.setUserId(userId);
  }
}
