import window from '@/services/window';

export class devWifiConfig {

  private wifiPlugin: any;
  private espTouchPlugin: any;

  public constructor() {
    this.wifiPlugin = window.wifiPlugin;
    this.espTouchPlugin = window.helkiEspTouchPlugin;
  }
  
  public async waitForDeviceConnection(devid?: any): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.wifiPlugin.waitForDeviceConnection(devid, () => {
        resolve(undefined);
      }, (err: any) => {
        reject(err);
      });
    });
  }
  
  public async setNetworkPrefix(networkPrefix?: any): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.wifiPlugin.setNetworkPrefix(networkPrefix, () => {
        resolve(undefined);
      }, (err: any) => {
        reject(err);
      });
    });
  }
  
  public async setAPIPort(apiPort?: any): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.wifiPlugin.setAPIPort(apiPort, () => {
        resolve(undefined);
      }, (err: any) => {
        reject(err);
      });
    });
  }
  
  public async getDeviceInfo(): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.wifiPlugin.getDeviceInfo(null, (data: any) => {
        const response = JSON.parse(data);
        resolve(response);
      }, (data: any) => {
        reject(data);
      });
    });
  }
  
  public getNetworkListFrom(data: any) {
    data.sort(function(a: any, b: any){return b.rssi-a.rssi;});
    const networkList = [];
    for(let i=0;i<data.length;i++){
      let networkSignal;
      if(data[i].rssi < -70){
        networkSignal = 1;
      }
      else if(data[i].rssi < -60){
        networkSignal = 2;
      }
      else if(data[i].rssi < -40){
        networkSignal = 3;
      }
      else{
        networkSignal = 4;
      }
      const network = {
        essid: data[i].essid,
        signal: networkSignal
      };
      networkList.push(network);
    }
    return networkList;
  }

  public async getNetworkList(): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.wifiPlugin.getNetworkList(null, (networkList: any) => {
        resolve(this.getNetworkListFrom(JSON.parse(networkList)));
      }, (data: any) => {
        reject(data);
      });
    });
  }
  
  public async getWifiNetwork(): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.wifiPlugin.getWifiNetwork(null, resolve, reject);
    });
  }
  
  public async setWifiNetwork(network?: any): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.wifiPlugin.setWifiNetwork(network, resolve, reject);
    });
  }
  
  public async smartWifiConfig(essid?: any, pass?: any): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.espTouchPlugin.sendWifiConfig({
        essid: essid,
        pass: pass
      }, resolve, reject);
    });
  }
  
  public async storeWifiConfig(essid?: any, pass?: any): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.espTouchPlugin.storeWifiConfig({
        essid: essid,
        pass: pass
      }, resolve, reject);
    });
  }
  
  public async getStoredWifiCredentials(): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.espTouchPlugin.getStoredWifiCredentials(null, resolve, reject);
    });
  }
  
  public async getPhoneNetworkList(): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.espTouchPlugin.getWifiNetworkList(null, resolve, reject);
    }).then((networkList: any) => {
      return networkList;
    });
  }
  
  public async getPhoneCurrentWifi(): Promise<any> {
    return new Promise((resolve, reject: any) => {
      this.espTouchPlugin.getCurrentWifi(null, resolve, reject);
    }).then((currentWifi: any) => {
      return currentWifi;
    }).catch(() => {
      return {};
    });
  }
  
  public async cancel(): Promise<any> {
    return new Promise(() => {
      this.wifiPlugin.cancel();
    });
  }
    
}
