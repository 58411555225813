var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a',{staticClass:"nav-opt-a c-ctrs alink n-outl",on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{staticClass:"size-icon-primary displayb txt-c txt-l",class:{'icon-electricity': _vm.iconElectricity(),
                   'icon-temp': _vm.iconHeating(),
                   'icon-weather': _vm.iconWeather(),
                   'icon-smartbox': _vm.iconSmartbox(),
                   'icon-data': _vm.iconData(),
                   'icon-position': _vm.iconPosition(),
                   'icon-inviteUsers': _vm.iconInviteUsers(),
                   'nav-opt-ac': _vm.selected}}),_c('div',{staticClass:"txt-xs txt-n uppc txt-ellipsis p-xs-top",class:{'nav-opt-ac': _vm.selected}},[_vm._v(_vm._s(_vm.label))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }