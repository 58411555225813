import { Component, Vue } from 'vue-property-decorator';
import uuid from '@/services/uuid';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import { TimezoneConfig } from '@/services/config';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    MainButton,
    TitleText,
  },
})
export default class SetTimezone extends Vue {

  private homeName: any = {};
  private newHome: any = {};
  private supportedTimezones: TimezoneConfig[] = [];
  private tzFocused: any = {};
  private tz_code: any = '';

  private async mounted() {
    this.homeName = this.$route.params.homeName;
    this.newHome = this.$route.params.newHome;
    this.supportedTimezones = (await this.$configService.getConfig()).clientData.supportedTimezones;
    this.tzFocused = false;
  }

  private tzFocus(): any {
    if (!this.tzFocused) {
      this.tzFocused = true;
      this.$amplitudeService.sendEvent('add_home_set_timezone_start');
    }
  }

  private next(): any {
    (window as any).timezone = this.tz_code;
    this.$amplitudeService.sendEvent('add_home_set_timezone_end', { timezone: this.tz_code });
    routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.startInfo', {
      homeName: this.homeName,
      homeid: uuid.v1().replace(/[-]+/g, ''),
      timezone: this.tz_code,
      newHome: this.newHome
    });
  }
}
