import { Component, Vue } from 'vue-property-decorator';
import uuid from '@/services/uuid';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';

@Component({
  components: {
    Slider,
    MainButton,
    InstallInfo,
  },
})

export default class HtrModInstall extends Vue {

    private homeId: any = {};
    private devId: any = {};
    private amplitudeEventData: any = {};
    private discoveryActive: any = {};

    private async mounted() {
      this.homeId = this.$route.params.homeid;
      this.devId = this.$route.params.devid;
      this.amplitudeEventData = {
      type: 'htr_mod',
      install_session_id: uuid.v4()
    };
      this.discoveryActive = await this.getDiscoveryStatus();
      if (!this.homeId) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      this.$amplitudeService.sendEvent('install_node_start', this.amplitudeEventData);
      this.$userService.addListener(this.homeId, this.devListener);
      this.$userService.addNewNodeListener(this.homeId, this.newNodeListener);
    }

    private destroyed() {
      this.$userService.removeListener(this.homeId, this.devListener);
      this.$userService.removeNewNodeListener(this.homeId, this.newNodeListener);
    }

    private activateDiscovery(): any {
      this.$amplitudeService.sendEvent('install_node_discovery_start', this.amplitudeEventData);
      this.discoveryActive = true;
      return this.$devListService.getDevProxy(this.homeId, (window as any).devId).then(async (devProxy: any) => {
        return devProxy.setDiscovery('on', 'htr_mod');
      }).catch(() => {
        this.updateDiscovery();
      });
    }

    private async getDiscoveryStatus(): Promise<boolean> {
      const devProxy = await this.$devListService.getDevProxy(this.homeId, this.devId);
      return devProxy.getDiscovery();
    }

    private async updateDiscovery(): Promise<void> {
      try {
        const discoveryStatus = await this.getDiscoveryStatus();
        if (discoveryStatus === this.discoveryActive) {
          this.newNodeListener({ type: 'htr_mod' });
          return;
        }
        const oldValue: any = this.discoveryActive;
        this.discoveryActive = discoveryStatus;
        if (oldValue && !this.discoveryActive) {
          this.$amplitudeService.sendEvent('install_node_timeout', this.amplitudeEventData);
        }
      } catch {
        this.discoveryActive = false;
      }
    }

    private showErrorMsg(): any {
      this.$toast.error(this.$t('INSTALL_ERROR_MSG'));
    }

    private devListener(): any {
      this.updateDiscovery();
    }

    private newNodeListener(node?: any): any {
      if (node && node.type === 'htr_mod') {
        this.$toast.clear();
        this.$amplitudeService.sendEvent('install_node_sucess', this.amplitudeEventData);
        routerWrapper.push(this.$router, 'root.authenticated.root.device.list.heating');
      }
    }

}
