import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    MainButton,
  },
})
export default class SignUp extends Vue {

    private showPassword = false;
    private showConfirmPassword = false;
    private emailFocused = false;
    private userConflictError = false;
    private inputErrors = false;
    private confirmPassword = '';
    private password = '';
    private email = '';
    private tcAccepted = false;

    private async mounted() {
      const deprecated = (await this.$configService.getConfig()).deprecated;
      if (deprecated) {
        routerWrapper.push(this.$router, 'root.deprecatedMessage');
        return;
      }
      this.showPassword = false;
      this.showConfirmPassword = false;
      this.emailFocused = false;
      this.$amplitudeService.sendEvent('signup_start');
      (document as any).getElementById('privacyLabel').innerHTML = ` ${this.$t('TC_PP_LINK_TEXT')}`
      .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost)
      .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost);
    }

    private togglePasswordVisibility(): any {
      this.showPassword = !this.showPassword;
    }

    private toggleConfirmPasswordVisibility(): any {
      this.showConfirmPassword = !this.showConfirmPassword;
    }

    private signUp(): any {
      if (!this.tcAccepted) {
        return;
      }
      if (true && !this.hasPasswordError() && !this.hasPasswordMatchError()) {
        this.resetErrors();
        this.$userService.getLanguage().then(async (lang: any) => {
          return this.$apiService.signUp(
            this.email,
            this.password,
            this.$store.state.serialid,
            lang,
          );
        }).then(async () => {
          routerWrapper.push(this.$router, 'root.anonymous.signUpSuccess');
        }).catch((error: any) => {
          switch (error.response.status) {
          case 409:
            this.userConflictError = true;
            break;
          }
          function getReason(resp: any) {
            if (resp.status === 400) {
              return 'validation';
            } else if (resp.status === 409) {
              return 'email already taken';
            } else if (resp.status === 500) {
              return 'server error';
            } else if (resp.status > 500 && resp.status < 600) {
              return 'proxy error';
            } else {
              return 'unknown';
            }
          }
          this.$amplitudeService.sendEvent('signup_error', {
            reason: getReason(error.response),
            status_code: error.response.status,
          });
        });
      } else {
        this.inputErrors = true;
      }

    }

    private hasErrors(): any {
      return this.inputErrors || this.userConflictError;
    }

    private hasMissingFields(): any {
      return false;
    }

    private hasEmailError(): any {
      return false;
    }

    private hasPasswordError(): any {
      return false;
    }

    private hasUserConflictError(): any {
      return this.userConflictError;
    }

    private hasPasswordMatchError(): any {
      return this.password !== this.confirmPassword;
    }

    private emailFocus(): any {
      if (!this.emailFocused) {
        this.emailFocused = true;
        this.$amplitudeService.sendEvent('signup_email_start');
      }
    }


    private resetErrors(): any {
      this.inputErrors = false;
      this.userConflictError = false;
    }

}
