import { Component, Vue } from 'vue-property-decorator';
import ChangeEmailSuccessModal from '@/components/modals/changeEmailSuccess/changeEmailSuccess.vue';
import ConfirmRemoveAccountModal from '@/components/modals/confirmRemoveAccount/confirmRemoveAccount.vue';
import {
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    ChangeEmailSuccessModal,
    ConfirmRemoveAccountModal,
  },
})

export default class Device extends Vue {

  private showChangeEmailSuccessModal = false;
  private showConfirmRemoveAccountModal = false;

}
