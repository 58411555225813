import { Component, Vue } from 'vue-property-decorator';
import { DevData } from '@/services/DevProxy';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    MainButton,
  },
})
export default class NodeInstall extends Vue {
  private homeId = '';
  private nodeType = '';
  private selectedDev: DevData | any = {};
  private destinationRoute = '';

  private mounted() {
    this.homeId = this.$route.params.homeid;
    this.nodeType = this.$route.query.nodeType as string;
    if (this.devices.length > 0) {
      this.selectedDev = this.devices[0];
    }
    this.destinationRoute = 'root.authenticated.root.device.' + this.nodeType + 'Install';
  }

  private get devices(): DevData[] {
    const deviceList = this.$devListService.getDevList(this.homeId).filter((dev: DevData) => {
      const connected = this.$store.state.homes[this.homeId].devs[dev.dev_id].connected;
      const productId = dev.product_id || '';
      const devType = this.$devListService.getDevProductName(productId);
      return devType !== 'wifiHtr' && connected;
    });
    if (deviceList.length > 0) {
      this.selectedDev = deviceList[0];
    }
    return deviceList;
  }

  private goToDiscovery() {
    this.$router.push({ name: this.destinationRoute, query: { devid: this.selectedDev?.dev_id } });
  }
}
