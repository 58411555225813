import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    MainButton,
  },
})

export default class Welcome extends Vue {

  private mounted() {
    document.addEventListener('resume', this.goToDashboard, false);
  }

  private destroyed() {
    document.removeEventListener('resume', this.goToDashboard, false);
  }

  private next(): any {
    this.$amplitudeService.sendEvent('add_first_home_start');
    this.$router.push({ name: "root.authenticated.root.addHome.setName" });
  }

  private async goToDashboard() {
    await this.$devListService.updateList();
    return this.$router.replace({ name: 'root.authenticated.root.device.list.heating', query: {} });
  }
}
