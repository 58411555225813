import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import ModalHelpBody from '@/components/modalComponents/modalHelpBody/ModalHelpBody.vue';
import VoiceAssistantHelp from '@/components/voiceAssistantHelp/VoiceAssistantHelp.vue';

@Component({
  components: {
    ModalTitle,
    ModalHelpBody,
    VoiceAssistantHelp,
  },
})
export default class ModalHelpContent extends Vue {
  @Prop() private title!: string;
  @Prop() private subtitle!: string;
  @Prop() private body!: string;
  @Prop() private imageSrc!: any;
  @Prop() private altText!: string;
  @Prop() private showVoiceAssistant!: boolean;
}
