var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"range-setting subtitle",style:({
    'color': _vm.color,
    'font-size': _vm.fontSize,

  })},[_c('v-ons-row',[_c('v-ons-col',{staticClass:"left-label",attrs:{"width":"35px"}},[_vm._v(" "+_vm._s(_vm.min)+" ")]),_c('v-ons-col',[_c('v-ons-range',{staticClass:"range",attrs:{"min":_vm.min || 0,"max":_vm.max,"step":_vm.step || 1,"disabled":_vm.disabled},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('v-ons-col',{staticClass:"right-label",attrs:{"width":"40px"}},[_vm._v(" "+_vm._s(_vm.max)+" ")])],1),_c('font',{staticClass:"txt-b txt-no-transform"},[_vm._v(_vm._s(_vm.label)+" ("+_vm._s(_vm.units)+"):")]),_c('font',{style:({
      'color': _vm.valueColor,
    })},[_vm._v(" "+_vm._s(_vm.currentValue)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }