import { render, staticRenderFns } from "./privacy.vue?vue&type=template&id=3c1236cc"
import script from "./privacy.ts?vue&type=script&lang=js&external"
export * from "./privacy.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports