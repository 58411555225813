import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})

export  default class Weather extends Vue {
  
    private mounted() {
      if (!(window as any).dev) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
    }
  
}
