import { Component, Vue}  from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    TitleText,
  },
})

export default class Privacy extends Vue {

    private homes: any = [];
    private supportEmail: any = {};
    private user: any = {};
    private privacy: any = {};

    private async mounted() {
      this.homes = [];
      this.supportEmail = (await this.$configService.getConfig()).clientData.supportData.email;

      await this.initData();
      (document as any).getElementById('privacyLabel').innerHTML = String(this.$t('PP_READ_AND_ACCEPTED'))
      .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost)
      .replace('{{frontendHost}}', (await this.$configService.getConfig()).frontendHost);
    }

    private getFilteredDevs(): any {
      if (!this.homes) {
        return [];
      }
      return this.homes.filter((home: any) => {
        return home.geoData && home.geoData.position;
      });
    }

    private privacyAcceptedChanged(): any {
      if (this.privacy.accepted) {
        this.$userService.acceptPrivacy();
        this.privacy.date = new Date();
      }
    }

    private getGeoDataForHome(home?: any): any {
      return this.$devListService.getGeoData(home.id).then(async (geoData: any) => {
        this.homes.push({
          id: home.id,
          name: home.name,
          geoData: geoData
        });
      });
    }

    private async initData(): Promise<any> {
      const homeList = await this.$devListService.getList();
      homeList.forEach((home: any) => {
        this.getGeoDataForHome(home);
      });
      this.user = this.$userService.get();
      const privacyData = await this.$userService.getPrivacy();
      if (privacyData.date) {
        privacyData.date = new Date(privacyData.date);
      }
      this.privacy = privacyData;
    }

}
