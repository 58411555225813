import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})
export default class Root extends Vue {
  private async mounted() {
    rootScope.frontendHost = (await this.$configService.getConfig()).frontendHost;
    (document as any).title = (await this.$configService.getConfig()).clientData.title;
    Promise.resolve().then(async () => {
      return this.$userService.getLanguage();
    }).then(async (currentLanguage: any) => {
      rootScope.language = currentLanguage;
    });
    if (this.$route.name === 'root') {
      return this.$router.replace({ name: 'root.authenticated.root', query: {} });
    }
  }

  private get qrActive() {
    return this.$store.state.qrActive;
  }
}
