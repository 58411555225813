import { Component, Vue, Watch } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import HtrSystemSetupModal from '@/components/modals/htrSystemSetup/htrSystemSetup.vue';
import Slider from '@/components/slider/Slider.vue';
import {
  NodeControllerSet,
  NodeController,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    HtrSystemSetupModal
  },
})
export default class Electricity extends Vue {

  private mainCircuitPmos: any = {};
  private nodeGroups: any = [];
  private updatePowerInterval: any = {};
  private showHtrSystemSetupModal = false;
  private showClassifiedNodes = false;
  private showHtrSystemSetup = false;

  private mounted() {
    this.nodeGroups = [];
    this.updatePowerInterval = setInterval(() => {
      this.updatePower();
    }, 3000);

    if (!this.homeId) {
      return this.$router.replace({ name: 'root.authenticated.root', query: {} });
    }
    this.updateList();
    this.$userService.addListener(this.homeId, this.devListener);
  }

  private destroyed() {
    rootScope.pmoSelectedList = [];
    this.$userService.removeListener(this.homeId, this.devListener);
    clearInterval(this.updatePowerInterval);
  }

  private get homeId() {
    return this.$route.params.homeid;
  }

  private goToDevHtrSystemSetup(devId?: any): any {
    this.$devListService.getDevHtrSystemPowerLimit(this.homeId, devId).then(async (powerLimit: any) => {
      rootScope.currentDevHtrSystemPowerLimit = powerLimit;
      rootScope.devId = devId;
      this.showHtrSystemSetupModal = true;
    });
  }

  private goToPowerMeter(devId?: any, addr?: any): any {
    return this.$devListService.checkDevConnection(this.homeId, devId).then(async (connected: any) => {
      const pmo: any = this.getNode(devId, addr, 'list', (node: any) => {
        return (node.nodeData || {}).addr === addr && node.devId === devId;
      });
      const nodeControllerList = (window as any).pmoSelectedList || []
      .filter((nodeController: NodeController) => {
        return (nodeController as any).devId !== devId
        || Number((nodeController as any)?.nodeData.addr) !== Number(addr);
      });
      nodeControllerList.unshift(await this.$devListService.getNodeController(this.homeId, devId, 'pmo', Number(addr)));
      window.nodeSet = new NodeControllerSet(nodeControllerList);
      window.devId = devId;
      window.connected = connected;
      if ((window as any).connected && !(pmo.nodeData || {}).lost) {
        routerWrapper.push(this.$router, 'root.authenticated.root.device.powerMeter.nav.usage', {
          devid: devId,
          addr: addr
        });
      } else {
        const date: any = new Date();
        routerWrapper.push(this.$router, 'root.authenticated.root.device.powerMeter.nav.stats.day', {
          devid: devId,
          addr: addr,
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate()
        });
      }
    });
  }

  private isInMainCircuit(node?: any): any {
    return (this.mainCircuitPmos[node.devId] || []).indexOf((node.nodeData || {}).addr) !== -1;
  }

  private updateSelectedList(): any {
    this.nodeGroups.forEach((nodeGroup: any) => {
      const nodeList = nodeGroup.list;
      _.forEach(nodeList, (item: any) => {
        const selectionData = {
          homeId: this.homeId,
          devId: item.devId,
          addr: item.nodeData.addr,
          type: item.nodeData.type,
        };
        if (item.checked) {
          this.$store.commit('selectNode', selectionData);
        } else {
          this.$store.commit('deselectNode', selectionData);
        }
      });
    });
  }

  private async updatePower(): Promise<any> {
    for (const nodeGroup of this.nodeGroups) {
      const deviceConnected = this.$store.state.homes[this.homeId].devs[nodeGroup.devId]?.connected;
      if (deviceConnected) {
        for (const pmo of nodeGroup.list) {
          pmo.power = !(pmo.nodeData || {}).lost ? await pmo.getPower() : undefined;
        }
      }
    }
    this.$forceUpdate();
  }

  private nodeChecked(addr?: any, devId?: any): any {
    return Array.from(this.$store.state.selectedNodes[this.homeId] || new Set())
    .findIndex((selectionData: any) => {
      const selectionObject = JSON.parse(selectionData);
      return selectionObject.addr === addr && selectionObject.devId === devId;
    }) !== -1;
  }

  private async updateList(): Promise<void> {
    this.nodeGroups = [];
    this.$devListService.getDevClassifiedNodeList(this.homeId).then(async (classifiedNodeList: any) => {
      this.showClassifiedNodes = !this.$devListService.hasSingleDevice(this.homeId);
      rootScope.hasSingleSmartbox = this.$devListService.hasSingleSmartbox(this.homeId);
      this.nodeGroups = classifiedNodeList;
      this.nodeGroups.forEach((nodeGroup: any) => {
        if (nodeGroup.devId) {
          this.updateMainCircuitPmos(nodeGroup.devId);
        }
        nodeGroup.list = _.filter(nodeGroup.nodeList, (node: any) => {
          node.checked = this.nodeChecked((node.nodeData || {}).addr, node.devId);
          return (node.nodeData || {}).type === 'pmo';
        });
        nodeGroup.showSmartboxConfig = true;
      });
      await this.updatePower();
    });
  }

  @Watch('homeId')
  private onRouteParamsChanged() {
    this.devListener();
  }

  private get homeDevices() {
    return this.$store.state.homes[this.homeId]?.devs;
  }

  @Watch('homeDevices')
  private onHomeDataChanged() {
    this.devListener();
  }

  private get selectedNodes() {
    return this.$store.state.selectedNodes[this.homeId];
  }

  @Watch('selectedNodes')
  private onSelectedNodeChanged() {
    this.devListener();
  }

  private devListener(): any {
    this.updateList();
  }

  private getNode(devId?: any, addr?: any, list?: any, comparisonFunction?: any): any {
    let node;
    for (let i: any = 0; i < this.nodeGroups.length && !node; i++) {
      node = _.find(this.nodeGroups[i][list], comparisonFunction);
    }
    return node;
  }

  private onHtrSystemSetupModalClosed(): any {
    this.$devListService.setDevHtrSystemPowerLimit(this.homeId, (window as any).devId, rootScope.currentDevHtrSystemPowerLimit);
  }

  private updateMainCircuitPmos(devId?: any): any {
    this.mainCircuitPmos[devId] = this.$store.state.homes[this.homeId].devs[devId]?.pmo_system?.main_circuit_pmos;
  }

  private getDevType(pid: any): string {
    return this.$devListService.getDevProductName(pid);
  }

  private getPowerLabel(power: number) {
    return power ? (power + ' (W)') : '---';
  }
}
