import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class PowerMeter extends Vue {

  private showCopyDayModal = false;
  private showScheduleEditSlotModal = false;

}
