import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from '@/services/_';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import NavButton from '@/components/button/navButton/NavButton.vue';
import DialogButton from '@/components/button/dialogButton/DialogButton.vue';
import {
  NodeControllerSet,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    NavButton,
    DialogButton,
  },
})
export default class ElectricityNav extends Vue {

  private devId: any = {};
  private homeId: any = {};
  private sampleDownloadEnabled: any = {};
  private lost: any = {};
  private list: any = {};
  private date: any = {};
  private statsParams: any = {};
  private lastMoreOptsToggleTimestamp: any = {};
  private moreOptsDialogOpen = false;
  private slickSettings: any = {};
  private nodeSet: NodeControllerSet | undefined;

  private async mounted() {
    this.nodeSet = (window as any).nodeSet;
    this.devId = this.$route.params.devid;
    this.homeId = this.$route.params.homeid;
    this.sampleDownloadEnabled = (await this.$configService.getConfig()).sampleDownloadEnabled;
    this.lost = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).lost;
    this.list = [];
    this.date = new Date();
    this.statsParams = {
      addr: this.addr,
      year: this.date.getFullYear(),
      month: this.date.getMonth(),
      day: this.date.getDate()
    };
    this.lastMoreOptsToggleTimestamp = 0;

    if (!(window as any).devId || !this.homeId || !(window as any).nodeSet) {
      return this.$router.replace({ name: 'root.authenticated.root', query: {} });
    }
    this.updateList();
    this.$userService.addListener(this.homeId, this.devListener);
  }

  private destroyed() {
    this.$userService.removeListener(this.homeId, this.devListener);
  }

  private get addr() {
    return this.$route.params.addr;
  }


  @Watch('addr')
  private onRouteParamsChanged() {
    this.devListener();
  }

  private get currentRoute(): any {
    return this.$route.name;
  }

  @Watch('currentRoute')
  private onCurrentRouteChanged() {
    this.moreOptsDialogOpen = false;
  }



  private saveCsv(): any {
    this.$emit('saveCsv', { nodeName: ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).name || (window as any).devId + '.' + this.addr });
  }

  private displayMoreOptsDialog(): any {
    return this.$route.name !== 'root.authenticated.root.device.powerMeter.nav.stats' && this.$route.name !== 'root.authenticated.root.device.powerMeter.nav.stats.day' && this.$route.name !== 'root.authenticated.root.device.powerMeter.nav.stats.month' && this.$route.name !== 'root.authenticated.root.device.powerMeter.nav.stats.year';
  }

  private toggleMoreOptsDialog(): any {
    if (Date.now() - this.lastMoreOptsToggleTimestamp > 300) {
      this.moreOptsDialogOpen = !this.moreOptsDialogOpen;
      this.lastMoreOptsToggleTimestamp = Date.now();
    }
  }


  private updateList(): any {
    if (this.list.length === 0) {
      this.$devListService.getNodeList(this.homeId).then(async (nodes: any) => {
        this.list = _.filter(nodes, (node: any) => {
          return (node.nodeData || {}).type === 'pmo';
        });
        let startPosition: any = 0;
        for (let i: any = 1; i < this.list.length; i++) {
          if ((this.list[i].nodeData || {}).addr === this.addr && this.list[i].devId === (window as any).devId) {
            startPosition = i;
          }
        }
        this.slickSettings = {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          method: {},
          initialSlide: startPosition,
          event: {
            afterChange: (event: any, slick: any, newPos: any) => {
              if (newPos !== startPosition) {
                if (this.$route.name === 'root.authenticated.root.device.powerMeter.nav.usage') {
                  routerWrapper.push(this.$router, 'root.authenticated.root.device.powerMeter.nav.usage', {
                    devid: this.list[newPos].devId,
                    addr: (this.list[newPos].nodeData || {}).addr
                  });
                } else if (this.$route.name?.indexOf('root.authenticated.root.device.powerMeter.nav.stats.day') !== -1) {
                  routerWrapper.push(this.$router, 'root.authenticated.root.device.powerMeter.nav.stats.day', {
                    devid: this.list[newPos].devId,
                    addr: (this.list[newPos].nodeData || {}).addr
                  });
                } else if (this.$route.name?.indexOf('root.authenticated.root.device.powerMeter.nav.stats.month') !== -1) {
                  routerWrapper.push(this.$router, 'root.authenticated.root.device.powerMeter.nav.stats.month', {
                    devid: this.list[newPos].devId,
                    addr: (this.list[newPos].nodeData || {}).addr
                  });
                } else if (this.$route.name?.indexOf('root.authenticated.root.device.powerMeter.nav.stats.year') !== -1) {
                  routerWrapper.push(this.$router, 'root.authenticated.root.device.powerMeter.nav.stats.year', {
                    devid: this.list[newPos].devId,
                    addr: (this.list[newPos].nodeData || {}).addr
                  });
                }
              }
            }
          }
        };
      });
    }
  }

  private devListener(): any {
    this.updateList();
  }

}
