import { Component, Vue } from 'vue-property-decorator';
import _ from '@/services/_';
import rootScope from '@/services/rootScope';
import { dateTimeHelpers } from '@/services/dateTimeHelpers';
import window from '@/services/window';
import ConfirmMaxPowerProfileRemovalModal from '@/components/modals/confirmMaxPowerProfileRemoval/confirmMaxPowerProfileRemoval.vue';
import ControlModeChangeModal from '@/components/modals/controlModeChange/controlModeChange.vue';
import Slider from '@/components/slider/Slider.vue';
import EditSlot from '@/components/editSlot/EditSlot.vue';
import {
  NodeTempUnits,
  TempTools,
  NodeSetting,
  NodeSettingParams,
} from '@vdi-helki/helki-node-management';
import BPromise from 'bluebird';

@Component({
  components: {
    Slider,
    ConfirmMaxPowerProfileRemovalModal,
    ControlModeChangeModal,
    EditSlot,
  },
})
export default class Setup extends Vue {

    private mainCircuitPmos: any = {};
    private devId: any = {};
    private homeId: any = {};
    private updatePowerLimitDelay: any = {};
    private userDutyData: any = {};
    private multi: any = {};
    private nodeSet: any = {};
    private powerLimitAvailable: any = {};
    private mainCircuitAvailable: any = {};
    private userDutyFactor: any = {};
    private cControlModes: any = {};
    private fControlModes: any = {};
    private translateLabelMsg: any = {};
    private translateLabelAnd: any = {};
    private cControlModeKeys: any = {};
    private fControlModeKeys: any = {};
    private availableSettings: any = {};
    private debouncedSaveActiveDays: any = {};
    private mainCircuit = false;
    private maxPowerSlots: any = {};
    private maxPowerProfiles: any = {};
    private maxPowerProfileIndex: any = {};
    private showConfirmMaxPowerProfileRemovalModal = false;
    private valley1StartTimeString: any = {};
    private valley1EndTimeString: any = {};
    private valley2StartTimeString: any = {};
    private valley2EndTimeString: any = {};
    private showControlModeChangeModal = false;
    private updatePowerLimitTimer: any = {};
    private nodeGroup: any = {};
    private pmoConsumptionList: any = {};
    private pmoGenerationList: any = {};
    private powerLevelLimitLabelText: any = {};
    private setup: any = {};
    private userDutyFactorArray: any = {};
    private showPowerLimitConfig: any = {};
    private operationalMode: any = {};
    private circuitType: any = {};
    private allowedPowerParams: any = {};
    private controlModes: any = {};
    private nodeData: any = {};
    private maxPowerLimit: any = {};
    private showConfirmMaxPowerProfileRemoval = false;
    private showControlModeChange = false;

    private async mounted() {

      this.devId = this.$route.params.devid;
      this.homeId = this.$route.params.homeid;
      this.mainCircuitPmos = this.$store.state.homes[this.homeId].devs[this.devId]?.pmo_system?.main_circuit_pmos;
      this.mainCircuit = this.isInMainCircuit();
      this.updatePowerLimitDelay = 500;
      this.userDutyData = {
      50: 'Baby Care',
      70: 'LST'
    };
      this.multi = (window as any).nodeSet?.size() > 1;
      this.nodeSet = (window as any).nodeSet;
      this.powerLimitAvailable = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type === 'pmo' && !(await this.$configService.getConfig()).multiplePowerLimitFlag || (await this.$configService.getConfig()).htrPowerLimitAvailable === true;
      this.mainCircuitAvailable = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type === 'pmo';
      this.userDutyFactor = 0;
      this.cControlModes = [];
      this.fControlModes = [];
      this.translateLabelMsg = '';
      this.translateLabelAnd = '';
      this.cControlModeKeys = [
      'HYST_0P2',
      'HYST_0P3',
      'HYST_0P5',
      'HYST_0P7',
      'PID_T60MIN',
      'PID_T15MIN',
      'PID_T30MIN',
      'PID_T20MIN',
      'HYST_0P4',
      'HYST_0P8',
      'HYST_1P6',
      'PID_T10MIN',
      'PID_T25MIN',
      'PID_T45MIN',
      'PID',
    ];
      this.fControlModeKeys = [
      'HYST_0P4F',
      'HYST_0P5F',
      'HYST_0P9F',
      'HYST_1P2F',
      'PID_T60MIN',
      'PID_T15MIN',
      'PID_T30MIN',
      'PID_T20MIN',
      'HYST_0P7F',
      'HYST_1P4F',
      'HYST_2P9F',
      'PID_T10MIN',
      'PID_T25MIN',
      'PID_T45MIN',
      'PID',
    ];
      this.availableSettings = (window as any).nodeSet?.getAvailableSettings();
      this.debouncedSaveActiveDays = _.debounce(this.updateSetup, 10000);

      this.$devListService.getDevProxy(this.homeId, (window as any).devId).then(async (devProxy: any) => {
        this.maxPowerSlots = devProxy.getMaxPowerConfigSlots();
        return this.$devListService.getExtraHomeData(this.homeId);
      }).then(async (extraHomeData: any) => {
        this.maxPowerProfiles = extraHomeData.maxPowerProfiles || [];
        this.updateHtrData();
      });
      if (!(window as any).nodeSet) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      if (!(window as any).connected) {
        return this.$router.replace({ name: 'root.authenticated.root.device.list.heating', query: {} });
      }
      const msg = this.$t('NUMBER_INPUT_LABEL_HELP_MSG');
      this.translateLabelMsg = msg;
      const and = this.$t('AND');
      this.translateLabelAnd = and;
      this.setPowerLevelLimitLabelText();
      this.$devListService.getExtraHomeData(this.homeId).then(async (extraData: any) => {
        this.updateFromExtraData(extraData);
      });
      const translatedControlModes = this.cControlModeKeys.map((key: string) => this.$t(key));
      this.cControlModes = translatedControlModes;
      this.updateControlModes();
      this.fControlModes = this.fControlModeKeys.map((key: string) => this.$t(key));
      this.updateControlModes();
    }

    private destroyed() {
      this.debouncedSaveActiveDays.flush();
    }

    private goToMaxPowerManagement(): any {
      rootScope.devId = (window as any).devId;
      this.$router.push({ name: 'root.authenticated.root.editHome.maxPowerManagement', query: {} });
    }

    private updateMainCircuit(): any {
      if (!this.mainCircuit) {
        this.addPmoToMainCircuit();
      } else {
        this.removePmoFromMainCircuit();
      }
      this.$apiService.setPmoSystem((window as any).devId, { main_circuit_pmos: this.mainCircuitPmos });
      this.updateHtrData();
    }

    private confirmMaxPowerProfileRemoval(index?: any): any {
            this.maxPowerProfileIndex = index;
      this.showConfirmMaxPowerProfileRemovalModal = true;
    }

    private updateUnits(): any {
      [
        'away_offset',
        'e2_mtemp_excess'
      ].forEach(offsetSetting => this.convertOffsetUnits(offsetSetting));
      return this.updateSetup();
    }

    private async updateValley1(): Promise<any> {
      if (!this.setup.valley_1_enabled.value) {
        const defaultValues: any = await this.getDefaultValleyValues();
        this.valley1StartTimeString = defaultValues.startTimeString;
        this.valley1EndTimeString = defaultValues.endTimeString;
      }
      return this.updateSetup();
    }

    private async updateValley2(): Promise<any> {
      if (!this.setup.valley_2_enabled.value) {
        const defaultValues: any = await this.getDefaultValleyValues();
        this.valley2StartTimeString = defaultValues.startTimeString;
        this.valley2EndTimeString = defaultValues.endTimeString;
      }
      return this.updateSetup();
    }

    private updateControlType(): any {
      if (((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type !== 'thm') {
        return;
      }
      this.showControlModeChangeModal = true;
    }

    private async updateSetup(): Promise<any> {
      await BPromise.delay(1000);
      const productId = ((window as any).nodeSet?.nodeControllers[0].product || '');
      const devType = this.$devListService.getDevProductName(productId);
      if (devType === 'wifiHtr' && this.isNameChangeAvailable(this.setup.name.value)) {
        await this.$apiService.setDevName((window as any).devId, { name: this.setup.name.value });
      }
      if (((this.setup || {}).operational_mode || {}).value !== undefined) {
        this.setup.operational_mode.value = Number(this.operationalMode);
      }
      if (((this.setup || {}).circuit_type || {}).value !== undefined) {
        this.setup.circuit_type.value = Number(this.circuitType);
      }
      if (this.setup.active_days !== undefined) {
        this.updateSetupFromInputTimeStrings();
      }
      if ((this.setup || {}).user_duty_factor !== undefined) {
        this.setup.user_duty_factor.value = this.getUserDutyFactorValue(this.userDutyFactor);
      }
      const updatedSettings: any = this.getSetupMap();
      await (window as any).nodeSet?.updateSettings(updatedSettings).catch(() => {
        this.$toast.error(this.$t('DEVICE_UPDATE_ERROR_MSG'));
      });
      this.updateHtrData();
      this.$forceUpdate();
    }

    private updateCircuitType(): any {
      this.updateSetup();
      this.initMainCircuit();
    }

    private updateActiveDays(index: number): any {
      this.setup.active_days.value[index] = Number(!this.setup.active_days.value[index]);
      this.debouncedSaveActiveDays();
      this.$forceUpdate();
    }

    private isSettingAvailable(setting?: any): any {
      return this.$helkiNMService.isSettingAvailable((window as any).nodeSet, setting);
    }

    private getControlModeLabel(modeIndex?: any): any {
      if ((window as any).nodeSet?.getFirstNodeSettingParams('units').value === 'F') {
        return this.fControlModes[modeIndex];
      }
      return this.cControlModes[modeIndex];
    }

    private updateMaxPowerLimit(): any {
      this.clearUpdatePowerLimitTimer();
      const powerLimit: any = this.maxPowerLimit || '5000';
      const nodeData: any = this.nodeData || {};
      nodeData[`${ this.$route.params.devid }${ this.$route.params.addr }`] = {
        ...nodeData[`${ this.$route.params.devid }${ this.$route.params.addr }`] || {},
        max_power: powerLimit
      };
      this.$devListService.updateExtraData(this.homeId, 'nodeData', nodeData);
    }

    private clearUpdatePowerLimitTimer(): any {
      if (this.updatePowerLimitTimer !== undefined) {
        clearTimeout(this.updatePowerLimitTimer);
        this.updatePowerLimitTimer = undefined;
      }
    }

    private setUpdatePowerLimitTimer(): any {
      this.clearUpdatePowerLimitTimer();
      this.updatePowerLimitTimer = setTimeout(() => {
        this.updateMaxPowerLimit();
      }, this.updatePowerLimitDelay);
    }

    private getUserDutyFactorLabel(option?: any): any {
      return this.userDutyData[option] || option;
    }

    private getUserDutyFactorValue(label?: any): any {
      return Number(Object.keys(this.userDutyData).find(key => this.userDutyData[key] === label) || label);
    }


    private isInMainCircuit(): any {
      return this.mainCircuitPmos.indexOf(Number(this.$route.params.addr)) !== -1;
    }

    private updateList(): any {
      return this.$devListService.getDevClassifiedNodeList(this.homeId).then(async (classifiedNodeList: any) => {
        const nodeGroups: any = classifiedNodeList || [];
        this.nodeGroup = nodeGroups.find((group: any) => group.devId === (window as any).devId);
        if (this.nodeGroup !== undefined) {
          this.nodeGroup.pmoList = _.filter(this.nodeGroup.nodeList, (node: any) => {
            return (node.nodeData || {}).type === 'pmo';
          });
          this.updatePmoLists();
        }
      });
    }

    private updatePmoLists(): any {
      this.pmoConsumptionList = [];
      this.pmoGenerationList = [];
      _.each(this.nodeGroup.pmoList, (pmo: any) => {
        if (pmo.getCircuitType() === 0) {
          this.pmoConsumptionList.push(pmo);
        } else {
          this.pmoGenerationList.push(pmo);
        }
      });
    }

    private addPmoToMainCircuit(): any {
      if (this.setup.circuit_type.value === 1) {
        this.removePreviousGenerationPmoFromMainCircuit();
      } else {
        this.removePreviousConsumptionPmoFromMainCircuit();
      }
      if (this.isNotYetInMainCircuit()) {
        this.mainCircuitPmos.push(Number(this.$route.params.addr));
        this.mainCircuitPmos = Array.from(new Set(this.mainCircuitPmos));
      }
    }

    private isNotYetInMainCircuit(): any {
      return this.mainCircuitPmos.indexOf(this.$route.params.addr) === -1;
    }

    private removePreviousConsumptionPmoFromMainCircuit(): any {
      this.pmoConsumptionList.forEach((consumptionPmo: any) => {
        const index: any = this.mainCircuitPmos.indexOf((consumptionPmo.nodeData || {}).addr);
        if (index !== -1) {
          this.mainCircuitPmos.splice(index, 1);
        }
      });
    }

    private removePreviousGenerationPmoFromMainCircuit(): any {
      this.pmoGenerationList.forEach((generationPmo: any) => {
        const index: any = this.mainCircuitPmos.indexOf((generationPmo.nodeData || {}).addr);
        if (index !== -1) {
          this.mainCircuitPmos.splice(index, 1);
        }
      });
    }

    private removePmoFromMainCircuit(): any {
      const index: any = this.mainCircuitPmos.indexOf(this.$route.params.addr);
      this.mainCircuitPmos.splice(index, 1);
    }

    private initMainCircuit(): any {
      this.mainCircuit = this.checkMainCircuit();
      this.updateMainCircuit();
    }

    private checkMainCircuit(): any {
      const list: any = this.setup.circuit_type.value === 0 ? this.pmoConsumptionList : this.pmoGenerationList;
      return _.findIndex(list, (powerMeter: any) => {
        return this.mainCircuitPmos.indexOf((powerMeter.nodeData || {}).addr) !== -1;
      }) === -1;
    }

    private getDefaultValleyValues(): any {
      return this.$userService.getLanguage().then(async (currentLanguage: any) => {
        let defaultStartTimeString;
        let defaultEndTimeString;
        switch (currentLanguage) {
        case 'en':
          defaultStartTimeString = '00:00';
          defaultEndTimeString = '07:00';
          break;
        default:
          defaultStartTimeString = '22:00';
          defaultEndTimeString = '12:00';
          break;
        }
        return {
          startTimeString: defaultStartTimeString,
          endTimeString: defaultEndTimeString
        };
      });
    }

    private setPowerLevelLimitLabelText(): any {
      if (this.setup.power_limit === undefined) {
        return;
      }
      const minValue: any = this.setup.power_limit.min;
      const maxValue: any = this.setup.power_limit.max;
      this.powerLevelLimitLabelText = String(this.translateLabelMsg + minValue + this.translateLabelAnd + maxValue);
    }

    private convertToControllerSetup(setup?: any): any {
      let result: any = {};
      result = { ...result };
      result.control_mode = '' + setup.control_mode;
      result.user_duty_factor = String(setup.user_duty_factor);
      return result;
    }

    private updateInputTimeStringsFromSetup(): any {
      this.valley1StartTimeString = new dateTimeHelpers().dayMinuteToTimeString((this.setup.valley_1_interval || {}).start || 0);
      this.valley1EndTimeString = new dateTimeHelpers().dayMinuteToTimeString((this.setup.valley_1_interval || {}).end || 0);
      if (this.valley1EndTimeString === '24:00') {
        this.valley1EndTimeString = '00:00';
      }
      this.valley2StartTimeString = new dateTimeHelpers().dayMinuteToTimeString((this.setup.valley_2_interval || {}).start || 0);
      this.valley2EndTimeString = new dateTimeHelpers().dayMinuteToTimeString((this.setup.valley_2_interval || {}).end || 0);
      if (this.valley2EndTimeString === '24:00') {
        this.valley2EndTimeString = '00:00';
      }
    }

    private updateSetupFromInputTimeStrings(): any {
      this.setup.valley_1_interval = this.setup.valley_1_interval || {};
      this.setup.valley_2_interval = this.setup.valley_2_interval || {};
      this.setup.valley_1_interval.start = new dateTimeHelpers().timeStringToDayMinute(this.valley1StartTimeString);
      this.setup.valley_1_interval.end = new dateTimeHelpers().timeStringToDayMinute(this.valley1EndTimeString);
      this.setup.valley_2_interval.start = new dateTimeHelpers().timeStringToDayMinute(this.valley2StartTimeString);
      this.setup.valley_2_interval.end = new dateTimeHelpers().timeStringToDayMinute(this.valley2EndTimeString);
    }

    private async updateHtrData(): Promise<any> {
      this.setup = (window as any).nodeSet?.getAvailableSettings().reduce((currentSetup: any, setting: NodeSetting) => {
        currentSetup[setting] = (window as any).nodeSet?.getFirstNodeSettingParams(setting);
        return currentSetup;
      }, {});
      if (this.setup.user_duty_factor !== undefined) {
        this.userDutyFactorArray = this.setup.user_duty_factor.acceptedValues.map((option: any) => this.getUserDutyFactorLabel(option));
        this.userDutyFactor = this.getUserDutyFactorLabel(this.setup.user_duty_factor.value);
      }
      this.showPowerLimitConfig = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type === 'pmo' && (await this.$configService.getConfig()).multiplePowerLimitFlag && this.setup.circuit_type.value === 0 && this.isInMainCircuit();
      this.mainCircuit = this.isInMainCircuit();
      if (this.multi) {
        this.setup.control_mode = '-1';
        this.setup.priority = '-1';
      }
      if (this.setup.operational_mode !== undefined) {
        this.operationalMode = String(this.setup.operational_mode.value);
      }
      if (this.setup.circuit_type !== undefined) {
        this.circuitType = String(this.setup.circuit_type.value);
      }
      if (this.setup.active_days !== undefined) {
        this.updateInputTimeStringsFromSetup();
      }
      this.updateControlModes();
      this.allowedPowerParams = (window as any).nodeSet?.getFirstNodeSettingParams('allowed_power');
      this.setPowerLevelLimitLabelText();
      this.updateList();
      setTimeout(() => {
        this.$forceUpdate();
      });
    }

    private updateControlModes(): any {
      if (this.isSettingAvailable('control_mode')) {
        this.controlModes = (window as any).nodeSet?.getFirstNodeSettingParams('control_mode').acceptedValues;
      }
    }

    private convertOffsetUnits(setting?: any): any {
      if ((this.setup[setting] || {}).value === undefined) {
        return;
      }
      const oldValue: any = Number(this.setup[setting].value || 0);
      let newValue;
      if (((this.setup || {}).units || {}).value === NodeTempUnits.CELSIUS) {
        newValue = TempTools.getCelsiusOffsetFromFahrenheit(oldValue);
      } else {
        newValue = TempTools.getFahrenheitOffsetFromCelsius(oldValue);
      }
      this.setup[setting].value = newValue.toFixed(1);
    }

    private getSetupMap(): any {
      const updatedSettings: any = _.reduce(this.setup, (settings: Map<NodeSetting, NodeSettingParams>, currentData: NodeSettingParams, currentSetting: NodeSetting) => {
        const updatedData: any = Object.assign({}, currentData);
        if (this.isSettingChangeAvailable(currentSetting, currentData)) {
          settings.set(currentSetting, updatedData);
        }
        return settings;
      }, new Map());
      return updatedSettings;
    }

    private async removePower(index?: any): Promise<any> {
      return Promise.resolve().then(async () => {
        this.maxPowerProfiles.splice(index, 1);
        const slotsToRemove: any = [];
        let indexOffset: number;
        this.maxPowerSlots.forEach((powerSlot: any, slotIndex: any) => {
          if (powerSlot.i > index) {
            powerSlot.i--;
          } else if (powerSlot.i === index) {
            slotsToRemove.push(slotIndex);
          }
        });
        slotsToRemove.forEach((slotIndex: any) => {
          if (slotIndex === slotsToRemove[0]) {
            indexOffset = 0;
          }
          if (this.maxPowerSlots[slotIndex - indexOffset].m % 1440 === 0) {
            if (this.maxPowerProfiles.length > 1) {
              if (slotIndex - indexOffset + 1 < this.maxPowerSlots.length) {
                this.maxPowerSlots[slotIndex - indexOffset].i = this.maxPowerSlots[slotIndex - indexOffset + 1].i;
              } else {
                this.maxPowerSlots[slotIndex - indexOffset].i = this.maxPowerSlots[slotIndex - indexOffset - 1].i;
              }
            } else {
              this.maxPowerSlots[slotIndex - indexOffset].i = 0;
            }
          } else {
            this.maxPowerSlots.splice(slotIndex - indexOffset, 1);
            indexOffset++;
          }
        });
        if (this.maxPowerProfiles.length === 0) {
          this.maxPowerSlots = [];
        }
        const maxPowerConfig: any = {
          profiles: _.map(this.maxPowerProfiles, (data: any) => {
            return data.value;
          }),
          slots: this.maxPowerSlots
        };
        return Promise.all([
          this.$devListService.updateExtraData(this.homeId, 'maxPowerProfiles', this.maxPowerProfiles),
          this.$apiService.setPmoSystem((window as any).devId, { max_power_config: maxPowerConfig })
        ]);
      });
    }

    private updateFromExtraData(data?: any): any {
      this.nodeData = data.nodeData || {};
      this.maxPowerLimit = (this.nodeData[`${ this.$route.params.devid }${ this.$route.params.addr }`] || {}).max_power || '5000';
    }

    private onConfirmMaxPowerProfileRemovalModalClosed(powerIndex?: any): any {
      if (powerIndex != undefined) {
        this.removePower(powerIndex);
      }
    }

    private onControlModeChangeModalClosed(result?: any): any {
      this.showControlModeChangeModal = false;
      if (result) {
        this.updateSetup();
      } else {
        this.updateHtrData();
      }
    }

    private isNameChangeAvailable(name?: any): any {
      const settingParams: any = (window as any).nodeSet?.getFirstNodeSettingParams('name');
      return !_.isEqual(settingParams.value, name);
    }

    private isSettingChangeAvailable(setting?: any, data?: any): any {
      const settingParams: any = (window as any).nodeSet?.getFirstNodeSettingParams(setting);
      const settingChanged: any = !_.isEqual(settingParams.value, data.value) || data.start !== settingParams.start || data.end !== settingParams.end;
      return settingChanged && !(this.multi && setting === NodeSetting.CONTROL_MODE) && !(this.multi && setting === NodeSetting.PRIORITY);
    }

    private updateDeviceName(name?: any): any {
      return this.$devListService.getDevProxy(this.homeId, (window as any).nodeSet?.getDevid()).then(async (devProxy: any) => {
        return devProxy.setName(name);
      });
    }

    private updateStartTimeValley1(data: any) {
      this.valley1StartTimeString = data;
      this.updateSetup();
    }

    private updateEndTimeValley1(data: any) {
      this.valley1EndTimeString = data;
      this.updateSetup();
    }

    private updateStartTimeValley2(data: any) {
      this.valley2StartTimeString = data;
      this.updateSetup();
    }

    private updateEndTimeValley2(data: any) {
      this.valley2EndTimeString = data;
      this.updateSetup();
    }

}
