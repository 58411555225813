import { Component, Vue, Watch } from 'vue-property-decorator';
import PrivacyModal from '@/components/modals/privacy/privacy.vue';

@Component({
  components: {
    PrivacyModal,
  },
})

export default class Authenticated extends Vue {

    private showPrivacyModal = false;
    private asideOpen: any = false;
    private privacyPolicyTimeout: any = setTimeout(this.checkPrivacyPolicy, 5000);

    private mounted() {
      this.onCurrentRouteChanged();
    }

    private destroyed() {
      clearTimeout(this.privacyPolicyTimeout);
    }

    private get currentRoute(): any {
      return this.$route.name;
    }

    @Watch('currentRoute')
    private onCurrentRouteChanged() {
      this.asideOpen = false;
      switch (this.currentRoute) {
        case 'root':
        case 'root.authenticated':
        case 'root.authenticated.root':
        case 'root.authenticated.root.device':
          this.handleMainRoute();
          break;
        default:
          break;
      }
    }

    private async handleMainRoute() {
      const homeid = await this.$devListService.getLastVisited();
      if (homeid !== undefined) {
        this.$router.replace({ name : "root.authenticated.root.device.list.heating", params: { homeid }});
      } else {
        this.$router.replace({ name : "root.authenticated.root.welcome" });
      }
    }

    private toggleAside(): any {
      this.asideOpen = !this.asideOpen;
    }

    private async signOut(): Promise<any> {
      await this.$userService.signOut();
      this.$router.replace({ name: 'root.anonymous.signIn' });
    }

    private async getPrivacyPolicy(): Promise<any> {
      return this.$userService.getPrivacy()
      .then((result: any) => {
        if (!result.accepted) {
          this.showPrivacyModal = true;
        }
      })
      .then(() => {
        return this.$sessionService.setPrivacy();
      });
    }

    private checkPrivacyPolicy(): any {
      const lastCheck: any = this.$sessionService.getLastPrivacyPolicyCheck();
      if (!lastCheck || lastCheck < Date.now() - 3600 * 24 * 1000) {
        return this.getPrivacyPolicy();
      }
    }

}
