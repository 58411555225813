import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InstallNodeLabel extends Vue {
  @Prop() private label!: string;
  @Prop() private prevIcon!: string;
  @Prop() private postIcon!: string;

  private prevIconPlus() {
    return this.prevIcon === 'icon-plus';
  }

  private prevIconThm() {
    return this.prevIcon === 'icon-thm';
  }

  private prevIconTrv() {
    return this.prevIcon === 'icon-trv';
  }

  private prevIconHtr() {
    return this.prevIcon === 'icon-htr';
  }

  private prevIconHtrWithSmartbox() {
    return this.prevIcon === 'icon-heater-w-smartbox';
  }

  private prevIconHtrGeolocation() {
    return this.prevIcon === 'icon-htr-geolocation';
  }

  private prevIconTowelRail() {
    return this.prevIcon === 'icon-towelRail';
  }

  private prevIconAcm() {
    return this.prevIcon === 'icon-acm';
  }

  private prevIconAcmLhz() {
    return this.prevIcon === 'icon-storageheater-lhz';
  }

  private prevIconWifiHtr4Buttons() {
    return this.prevIcon === 'icon-wifihtr-w4buttons';
  }

  private prevIconWifiHtr2Buttons() {
    return this.prevIcon === 'icon-wifihtr-w2buttons';
  }

  private prevIconPmo() {
    return this.prevIcon === 'icon-pmo';
  }

  private prevIconPmoLhz() {
    return this.prevIcon === 'icon-pmo-lhz';
  }

  private prevIconHome() {
    return this.prevIcon === 'icon-home';
  }

  private postIconPlus() {
    return this.postIcon === 'icon-plus';
  }
}
