import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})
export default class ChangeEmail extends Vue {
    private email: any = {};
    private code: any = {};
    private error: any = {};
    private success: any = {};

    private mounted() {
      this.email = this.$route.params.user;
      this.code = this.$route.params.code;
      this.error = false;
      this.success = false;
      this.$userService.changeEmailConfirm({
        user: this.email,
        code: this.code
      }).then(async () => {
        this.success = true;
      }).catch(() => {
        this.error = true;
      });
    }
}
