import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import { HomeData } from '@/services/devList.service';
import { DevData } from '@/services/DevProxy';
import window from '@/services/window';
import MaxPowerScheduleEditSlotModal from '@/components/modals/maxPowerScheduleEditSlot/maxPowerScheduleEditSlot.vue';
import CopyDayModal from '@/components/modals/copyDay/copyDay.vue';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
    MaxPowerScheduleEditSlotModal,
    CopyDayModal,
  },
})

export  default class MaxPowerManagementDay extends Vue {

    private copyDays: any = {};
    private usingGlobalConfig: any = {};
    private deviceId: any = {};
    private maxPowerProfiles: any = {};
    private maxPowerSlots: any = {};
    private prog: any = {};
    private dayProg: any = [];
    private profiles: any = {};
    private showMaxPowerScheduleEditSlotModal = false;
    private showCopyDayModal = false;
    private newSlotIndex: any = {};
    private dayString: any = {};
    private hours: any = {};
    private creatingNewSlot = false;
    private showMaxPowerScheduleEditSlot = false;
    private showCopyDay = false;

    private mounted() {
      this.copyDays = [];
      this.usingGlobalConfig = rootScope.devId === undefined;
      this.deviceId = rootScope.devId || this.$devListService.getDevids(this.homeId)[0];

      this.$devListService.getExtraHomeData(this.homeId).then(async (extraHomeData: any) => {
        this.maxPowerProfiles = extraHomeData.maxPowerProfiles || [];
        this.maxPowerSlots = this.$store.state.homes[this.homeId].devs[this.deviceId].pmo_system.max_power_config.slots;
        this.prog = this.getMaxPowerSlots(this.maxPowerSlots);
        this.dayProg = this.getDayProg(this.prog[this.day]);
      });
      this.initData();
    }

    private get homeId() {
      return this.$route.params.homeid;
    }
    private get day() {
      return this.$route.params.day;
    }

    private onSlotModalClosed() {
      this.showMaxPowerScheduleEditSlotModal = false;
      this.creatingNewSlot ? this.onNewSlotCreated() : this.onSlotUpdated();
    }

    private editSlot(slot?: any): any {
      this.creatingNewSlot = false;
      rootScope.editSlot = slot || {};
      this.profiles = undefined;
      this.showMaxPowerScheduleEditSlotModal = true;
    }

    private newSlot(startTime?: any): any {
      this.creatingNewSlot = true;
      startTime = !isNaN(startTime) ? Number(startTime) : 0;
      const slot: any = {
        i: 0,
        from: startTime,
        to: startTime + 60
      };
      rootScope.editSlot = slot;
      this.profiles = undefined;
      this.showMaxPowerScheduleEditSlotModal = true;
    }

    private removeSlot(slotIndex?: any): any {
      if (!this.dayProg || this.dayProg.length < 2) {
        return;
      }
      if (slotIndex < this.dayProg.length - 1) {
        this.dayProg.splice(slotIndex, 1);
        this.dayProg[0].from = 0;
      } else {
        this.dayProg[slotIndex - 1].to = 1440;
        this.dayProg.splice(-1);
      }
      this.cleanDayProg();
      this.saveProg();
    }

    private openCopyDayDialog(): any {
      this.showCopyDayModal = true;
    }

    private onSlotUpdated(): any {
      if (!rootScope.editedSlot) {
        return;
      }
      if (rootScope.editedSlot.from === rootScope.editSlot.from && rootScope.editedSlot.to === rootScope.editSlot.to && rootScope.editedSlot.i === rootScope.editSlot.i) {
        return;
      }
      if (rootScope.editedSlot.from >= rootScope.editedSlot.to) {
        return;
      }
      if (rootScope.editedSlot.from < 0 || rootScope.editedSlot.to > 1440) {
        return;
      }
      const oldSlotIndex: any = this.getSlotIndex(rootScope.editSlot);
      if (typeof oldSlotIndex !== 'number') {
        return;
      }
      this.newSlotIndex = this.getNewSlotIndex(rootScope.editedSlot);
      if (typeof this.newSlotIndex !== 'number') {
        if (rootScope.editedSlot.to === 1440 && rootScope.editedSlot.to === rootScope.editedSlot.to) {
          this.newSlotIndex = this.dayProg.length - 1;
        } else {
          return;
        }
      }
      if (this.newSlotIndex === oldSlotIndex + 1 && oldSlotIndex !== 0) {
        this.newSlotIndex = oldSlotIndex;
      }
      if (oldSlotIndex !== this.newSlotIndex || this.newSlotIndex === this.dayProg.length - 1 && rootScope.editedSlot.to !== 1440) {
        this.insertSlot(this.newSlotIndex, rootScope.editedSlot);
      } else {
        this.updateSlot(oldSlotIndex, rootScope.editedSlot);
      }
      this.saveProg();
    }

    private onNewSlotCreated(): any {
      if (!rootScope.editedSlot) {
        return;
      }
      if (rootScope.editedSlot.from >= rootScope.editedSlot.to) {
        return;
      }
      if (rootScope.editedSlot.from < 0 || rootScope.editedSlot.to > 1440) {
        return;
      }
      const index: any = this.getNewSlotIndex(rootScope.editedSlot);
      if (typeof index !== 'number') {
        return;
      }
      this.insertSlot(index, (window as any).editedSlot);
      this.saveProg();
    }

    private onCopyDayModalClosed(): any {
      this.showCopyDayModal = false;
      this.copyDays = rootScope.selectedDays.concat([]);
      this.saveProg();
    }

    private getSlotIndex(slot?: any): any {
      for (let i: any = 0; i < this.dayProg.length; i++) {
        if (this.dayProg[i].from === slot.from && this.dayProg[i].to === slot.to && this.dayProg[i].i === slot.i) {
          return i;
        }
      }
      return null;
    }

    private getNewSlotIndex(slot?: any): any {
      for (let i: any = 0; i < this.dayProg.length; i++) {
        if (slot.from <= this.dayProg[i].from) {
          return i;
        }
      }
      return this.dayProg.length < 9 ? this.dayProg.length : null;
    }

    private updateSlot(index?: any, newSlot?: any): any {
      if (index > 0) {
        this.dayProg[index - 1].to = newSlot.from;
      }
      if (index < this.dayProg.length - 1) {
        this.dayProg[index + 1].from = newSlot.to;
      }
      this.dayProg[index] = { ...newSlot };
      this.dayProg[index].m = this.dayProg[index].to;
      this.cleanDayProg();
    }

    private insertSlot(index?: any, newSlot?: any): any {
      let slot: any = {};
      let oldTo;
      slot = { ...newSlot };
      this.dayProg.splice(index, 0, slot);
      if (index > 0) {
        oldTo = this.dayProg[index - 1].to;
        this.dayProg[index - 1].to = newSlot.from;
        this.dayProg[index - 1].duration = newSlot.from - this.dayProg[index - 1].from;
        if (oldTo > this.dayProg[index - 1].to) {
          const anotherSlot: any = {
            i: this.dayProg[index - 1].i,
            from: slot.to,
            to: oldTo,
            duration: oldTo - slot.to
          };
          this.dayProg.splice(index + 1, 0, anotherSlot);
        }
      }
      if (index < this.dayProg.length - 1) {
        this.dayProg[index + 1].from = newSlot.to;
      }
      this.dayProg = [ ...this.dayProg ];
    }

    private cleanDayProg(): any {
      let i;
      for (i = 1; i < this.dayProg.length; i++) {
        this.dayProg[i].from = this.dayProg[i - 1].to;
      }
      for (i = 0; i < this.dayProg.length; i++) {
        this.dayProg[i].duration = this.dayProg[i].to - this.dayProg[i].from;
        if (this.dayProg[i].duration <= 0) {
          this.dayProg.splice(i, 1);
          return this.cleanDayProg();
        } else if (i < this.dayProg.length - 1 && this.dayProg[i].i === this.dayProg[i + 1].i) {
          this.dayProg[i + 1].from = this.dayProg[i].from;
          this.dayProg.splice(i, 1);
          return this.cleanDayProg();
        }
      }
    }

    private getWeekDay(index?: any): any {
      switch (index) {
      case 0:
        return 'SUNDAY';
      case 1:
        return 'MONDAY';
      case 2:
        return 'TUESDAY';
      case 3:
        return 'WEDNESDAY';
      case 4:
        return 'THURSDAY';
      case 5:
        return 'FRIDAY';
      case 6:
        return 'SATURDAY';
      }
    }

    private initData(): any {
      let i;
      this.dayString = this.getWeekDay(this.day);
      this.hours = [];
      for (i = 0; i < 10; i++) {
        this.hours.push('0' + i);
      }
      for (i = 10; i < 24; i++) {
        this.hours.push('' + i);
      }
    }

    private savePmoSystem(devProxy?: any, rawProg?: any): any {
      return devProxy.setPmoSystemMaxPowerConfig({
        slots: rawProg,
        profiles: _.map(this.maxPowerProfiles, (data: any) => {
          return data.value;
        })
      });
    }

    private async saveHomeProg(rawProg?: any): Promise<any> {
      const devProxyList: any = (await this.$devListService.getList()).find((home: HomeData) => home.id === this.homeId).devs.map((dev: DevData) => dev.proxy);
      for (const devProxy of devProxyList) {
        await this.savePmoSystem(devProxy, rawProg);
      }
    }

    private async saveDevProg(rawProg?: any): Promise<any> {
      const devProxy: any = await this.$devListService.getDevProxy(this.homeId, this.deviceId);
      return this.savePmoSystem(devProxy, rawProg);
    }

    private saveProg(): any {
      if (this.dayProg.length === 0) {
        return;
      }
      const rawDayProg: any = [];
      for (let j: any = 0; j < this.dayProg.length; j++) {
        rawDayProg.push({
          i: this.dayProg[j].i,
          m: this.dayProg[j].from
        });
      }
      this.prog[this.day] = rawDayProg;
      this.copyDays.forEach((copyDay: any) => {
        this.prog[copyDay] = rawDayProg;
      });
      const rawProg: any = [];
      for (let k: any = 0; k < this.prog.length; k++) {
        for (let l: any = 0; l < this.prog[k].length; l++) {
          const slot: any = this.prog[k][l];
          rawProg.push({
            i: slot.i,
            m: slot.m + 1440 * k
          });
        }
      }
      const maxPowerConfig: any = {
        profiles: _.map(this.maxPowerProfiles, (data: any) => {
          return data.value;
        }),
        slots: rawProg
      };
      const pmoSystem = { max_power_config: maxPowerConfig };
      this.$store.commit('updateDevDataField', { dev_id: this.deviceId, key: 'pmo_system', data: pmoSystem });
      this.$apiService.setPmoSystem(this.deviceId, pmoSystem);
      return this.usingGlobalConfig ? this.saveHomeProg(rawProg) : this.saveDevProg(rawProg);
    }

    private getMaxPowerProfiles(maxPowerData?: any): any {
      if (maxPowerData.maxPowerProfiles === undefined) {
        return [];
      }
      return maxPowerData.maxPowerProfiles;
    }

    private getMaxPowerSlots(slots?: any): any {
      if (slots === undefined) {
        return [];
      }
      const rawProg: any = slots;
      const prog: any = [];
      for (let i: any = 0; i < 7; i++) {
        prog.push([]);
      }
      for (let j: any = 0; j < rawProg.length; j++) {
        const day: number = Math.trunc(rawProg[j].m / 1440);
        prog[day as number].push({
          i: rawProg[j].i,
          m: rawProg[j].m % 1440
        });
      }
      return prog;
    }

    private getDayProg(rawDayProg?: any): any {
      const dayProg: any = [];
      for (let j: any = 0; j < rawDayProg.length; j++) {
        const slotData: any = {
          i: rawDayProg[j].i,
          m: rawDayProg[j].m,
          from: j > 0 ? dayProg[j - 1].to : 0,
          to: j < rawDayProg.length - 1 ? rawDayProg[j + 1].m : 1440
        };
        slotData.duration = slotData.to - slotData.from;
        dayProg.push(slotData);
      }
      return dayProg;
    }

}
