import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    MainButton,
    TitleText,
  },
})
export default class StartInfo extends Vue {

  private provisioningMethod: any = {};
  private errMsg: any = {};
  private type: any = {};

  private mounted() {
    this.provisioningMethod = this.$route.params.provisioningMethod || 'ap';
    this.errMsg = this.$route.params.errMsg;
    this.type = this.$route.params.type;
    this.$amplitudeService.sendEvent('add_device_wifi_start');
  }

  private get subtype() {
    return this.$route.params.subtype;
  }

  private startProvisioning() {
    let route = '';
    switch(this.provisioningMethod) {
    case 'bt':
      route = 'root.authenticated.root.addDevice.wifi.BTProvisioning';
      break;
    case 'esp':
      route = 'root.authenticated.root.addDevice.wifi.ESPProvisioning';
      break;
    default:
      route = 'root.authenticated.root.addDevice.wifi.connectionInfo';
    }
    routerWrapper.push(this.$router, route, {
      ...this.$route.params,
    });
  }
}
