import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import HtrSystemSetupModal from '@/components/modals/htrSystemSetup/htrSystemSetup.vue';
import DeviceRegistrationConflictModal from '@/components/modals/deviceRegistrationConflict/deviceRegistrationConflict.vue';
import ServerConnectionErrorModal from '@/components/modals/serverConnectionError/serverConnectionError.vue';
import Slider from '@/components/slider/Slider.vue';
import BPromise from 'bluebird';
import { DevProxy, DevData } from '@/services/DevProxy';
import { HomeData } from '@/services/devList.service';
import NewFeaturesInfoModal from '@/components/modals/newFeaturesInfo/newFeaturesInfo.vue';
import DeviceInstallationErrorStatesModal from '@/components/modals/deviceInstallationErrorStates/deviceInstallationErrorStates.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    HtrSystemSetupModal,
    DeviceRegistrationConflictModal,
    NewFeaturesInfoModal,
    DeviceInstallationErrorStatesModal,
    ServerConnectionErrorModal,
    MainButton,
    InstallInfo,
    TitleText,
  },
})
export default class SetName extends Vue {

  private state = 'setName';
  private name = '';
  private registerStartDate: Date | undefined;
  private conflict = false;
  private showRegistrationConflictModal = false;
  private showServerConnectionErrorModal = false;
  private showNewFeaturesInfoModal = false;
  private newHome: boolean = (window as any).newHome;
  private progressBar: any;
  private checkDeviceConnectionDelay = 120000;
  private deviceProxy?: DevProxy;
  private wifiConnectionFailTimer: any;
  private wifiConnectionFailed = false;
  private showDeviceInstallationErrorStatesModal = false;

  private mounted() {
    // Wait until DOM is ready to create initial progress bar
    setTimeout(() => {
      this.createProgressBar(this.checkDeviceConnectionDelay);
    });
  }

  private destroyed() {
    clearTimeout(this.wifiConnectionFailTimer);
  }

  private get homeId(): string {
    return this.$route.params.homeid;
  }

  private get subtype(): string {
    return this.$route.params.subtype;
  }

  private async assertBackendConnectivity(): Promise<void> {
    let retries = 7;
    while (retries > 0 && !await this.$configService.connected()) {
      await BPromise.delay(1000);
      retries --;
    }
    if (retries === 0) {
      throw new Error('server_unreachable');
    }
    return;
  }

  private getTimezone(): string {
    if (!!(window as any).timezone && (window as any).timezone !== '') {
      return (window as any).timezone;
    } else {
      return this.$devListService.getTimezone(this.homeId);
    }
  }

  private async performDeviceRegistration(timezone: string) {
    try {
      await this.$apiService.addDev({
        dev_id: this.devId,
        name: this.name,
        product_id: this.$devListService.getDevPID(this.subtype || this.deviceType),
        tz_code: timezone,
        groupData: {
          id: this.homeId,
          name: (window as any).homeName || this.$store.state.homes[this.homeId].name,
        },
      }, 25000);
      await this.$devListService.initDevList();
      this.deviceProxy = await this.$devListService.getDevProxy(this.homeId, this.devId);
      return this.deviceProxy;
    } catch(error: any) {
      if (error?.response?.status !== 409) {
        throw error;
      }
      if (await this.deviceRegistered()) {
        this.$amplitudeService.sendEvent('device_conflict_avoided');
        return this.$devListService.getDevProxy(this.homeId, this.devId);
      }
      throw new Error('device_conflict');
    }
  }

  private async registerDevice(): Promise<void> {
    try {
      this.state = 'registering';
      this.progressBar.animate(1.0);
      await this.assertBackendConnectivity();
      this.$amplitudeService.sendEvent('add_device_set_name_end');
      this.registerStartDate = this.registerStartDate || new Date();
      this.conflict = false;
      const timezone = await this.getTimezone();
      await this.assertBackendConnectivity();
      this.deviceProxy = await this.performDeviceRegistration(timezone);
      this.$amplitudeService.sendEvent('add_device_success');
      const elapsedTime = new Date().getTime() - this.registerStartDate.getTime();
      this.checkWifiDevConnection(this.checkDeviceConnectionDelay - elapsedTime);
    } catch(error: any) {
      if (error.message === 'server_unreachable') {
        this.displayServerConnectionErrorModal();
      } else if (error.message === 'device_conflict') {
        this.$amplitudeService.sendEvent('add_device_conflict');
        this.conflict = true;
        this.state = 'setName';
        this.displayConflictModal();
      } else if (error?.response?.status !== 400) {
        return this.registerDevice();
      }
    }
  }

  private displayConflictModal() {
    this.showRegistrationConflictModal = true;
  }

  private displayServerConnectionErrorModal() {
    this.showServerConnectionErrorModal = true;
  }

  private async onSuccess() {
    this.$amplitudeService.sendEvent('device_wifi_provisioning_success');
    await this.completeProgressBar();
    this.state = 'success';
    this.goToDashboardWithTimeout();
  }

  private goToDashboardWithTimeout() {
    setTimeout(() => {
    this.goToDashboard();
    }, 3000);
  }

  private goToDashboard() {
    if (this.newHome) {
      this.showNewFeaturesInfoModal = true;
    }
    routerWrapper.push(this.$router, 'root.authenticated.root');
  }

  private async completeProgressBar() {
    return new Promise((resolve) => {
      const progressBarCompletionDelay = 750;
      // Progress bar has to be stopped and recreated in order to change animation speed for completion
      this.progressBar.stop();
      // Get progress made so far by the progress bar
      const currentProgress = this.progressBar.value();
      this.progressBar.destroy();
      // Recreate progress bar with lower time until completion
      this.createProgressBar(progressBarCompletionDelay);
      // Start progress bar with progress already made
      this.progressBar.set(currentProgress);
      // Animate until completion
      this.progressBar.animate(1.0);
      setTimeout(() => {
        resolve(undefined);
      }, 1.25 * progressBarCompletionDelay);
    });
  }

  private createProgressBar(progressTimeout: number) {
    const item = (document as any).getElementById('progress-bar-register-container');
    this.progressBar = new (window as any).ProgressBar.Circle(item, {
      color: '#54a7f7',
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 4,
      trailWidth: 1,
      easing: 'linear',
      duration: progressTimeout,
      text: {
        autoStyleContainer: false
      },
      from: { color: '#F9B521', width: 1 },
      to: { color: '#54a7f7', width: 4 },
      // Set default step function for all animate calls
      step: function(state: any, circle: any) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);
        const value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('');
        } else {
          circle.setText(value + ' %');
        }
      },
    });
  }

  private async deviceRegistered(): Promise<boolean> {
    const homeList = await this.$devListService.getList();
    return homeList.findIndex((home: HomeData) => {
      return home.devs.findIndex((device: DevData) => device.dev_id === this.devId) !== -1;
    }) !== -1;
  }

  private checkWifiDevConnection(waitTime: number) {
    this.wifiConnectionFailTimer = setTimeout(() => {
      this.wifiConnectionFailed = true;
      this.$userService.removeListener(this.verifyDeviceConnection);
      this.showDeviceInstallationErrorStatesModal = true;
    }, waitTime || 40000);
    this.verifyDeviceConnection();
    this.$userService.addListener(this.verifyDeviceConnection);
  }

  private verifyDeviceConnection() {
    const connected = this.$store.state.homes[this.homeId].devs[this.devId].connected;
    if (connected) {
      clearTimeout(this.wifiConnectionFailTimer);
      this.$userService.removeListener(this.verifyDeviceConnection);
      this.onSuccess();
    }
  }

  private restartProvisioning() {
    routerWrapper.push(this.$router, this.getInitialProvisioningRoute(), {
      errMsg: 'ACCESS_POINT_CONNECTION_ERROR',
      devid: this.devId,
      newHome: this.newHome,
    });
  }

  private getInitialProvisioningRoute(): string {
    const productName = this.$devListService.getDevProductName(this.pid);
    if (!this.$devListService.isWifiDevice(this.pid)) {
      return 'root.authenticated.root.addDevice.' + productName + '.startInfo';
    } else {
      return 'root.authenticated.root.addDevice.' + productName + '.wifiConfig.startInfo';
    }
  }

  private get devId(): string {
    return this.$route.params.devid;
  }

  private get deviceType(): string {
    return this.$devListService.getDevProductName(this.pid);
  }

  private get pid(): string {
    return this.$route.params.pid;
  }

  private displayDeviceDisconnectedModal() {
    this.$devListService.updateList();
    this.progressBar.stop();
    this.showDeviceInstallationErrorStatesModal = true;
  }

  private onDeviceDisconnectedModalClosed(accepted: boolean) {
    this.showDeviceInstallationErrorStatesModal = false;
    if (accepted) {
      this.goToDashboard();
    }
  }

  private getSetDevName(): any {
    switch(this.deviceType) {
      case 'wifi':
        return this.$t('SET_WIFI_SMARTBOX_NAME');
      case 'ethernet':
        return this.$t('SET_ETHERNET_SMARTBOX_NAME');
      case 'wifiHtr':
      default:
        return this.$t('SET_WIFI_HTR_NAME');
    }
  }

  private getSetDevImage(): string {
    switch(this.deviceType) {
      case 'wifi':
        return 'wifi-smartbox';
      case 'ethernet':
        return 'ethernet-smartbox';
      case 'wifiHtr':
      default:
        return 'wifi-heater';
    }
  }

  private getSuccessText(): any {
    switch(this.deviceType) {
      case 'wifi':
        return this.$t('WIFI_SMARTBOX_CONFIG_SUCCESSFUL');
      case 'ethernet':
        return this.$t('ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL');
      case 'wifiHtr':
      default:
        return this.$t('WIFI_HTR_CONFIG_SUCCESSFUL');
    }
  }
}
