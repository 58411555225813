import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import WarningModal from '@/components/modalComponents/warningModal/WarningModal.vue';

@Component({
  components: {
    WarningModal,
  },
})

export default class revokeInvite extends Vue {

  private get homeId(): string {
    return this.$route.params.homeid;
  }

  private async accept(): Promise<any> {
    this.$amplitudeService.sendEvent('remove_guest_user');
    try {
      await this.$userService.revokeInvite(
        this.homeId,
        (window as any).guest,
      );
      this.$toast.success(this.$t('REVOKE_INVITE_SUCCESS_MSG'));
      this.close();
    } catch (err: any) {
      this.$toast.error(this.$t('REVOKE_INVITE_ERROR_MSG'));
    }
  }

  private close(): any {
    this.$emit('close');
  }

}
