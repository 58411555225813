import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';

@Component({
  components: {
    Slider,
    MainButton,
    InstallInfo,
  },
})

export default class StartInfo extends Vue {

    private newHome: any = {};

    private mounted() {
      this.newHome = this.$route.params.newHome;
      this.$amplitudeService.sendEvent('add_device_ethernet_start');
    }

}
