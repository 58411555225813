import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    MainButton,
  },
})
export default class ForgotPass extends Vue {
    private notFoundError = false;
    private inputErrors = false;
    private email = '';

    private async mounted() {
      const deprecated = (await this.$configService.getConfig()).deprecated;
      if (deprecated) {
        routerWrapper.push(this.$router, 'root.deprecatedMessage');
      }
    }

    private resetPassword(): any {
      this.resetErrors();
      this.$apiService.forgotUserPassword(this.email).then(async () => {
        routerWrapper.push(this.$router, 'root.anonymous.forgotPassSuccess');
      }).catch((resp: any) => {
        switch (resp.status) {
        case 404:
          this.notFoundError = true;
          break;
        default:
          break;
        }
      });
    }

    private hasErrors(): any {
      return this.inputErrors || this.notFoundError;
    }

    private hasMissingFields(): any {
      return false;
    }

    private hasEmailError(): any {
      return false;
    }

    private hasNotFoundError(): any {
      return this.notFoundError;
    }


    private resetErrors(): any {
      this.inputErrors = false;
      this.notFoundError = false;
    }

}
