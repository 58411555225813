import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from '@/services/_';
import window from '@/services/window';
import { dateTimeHelpers } from '@/services/dateTimeHelpers';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import {
  NodeCapability,
  NodeTempUnits,
  NodeStatusIndicator,
  NodeMode,
  NodeSetting,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
  },
})
export default class Temperature extends Vue {

    private scopeIsActive: any = {};
    private homeId: any = {};
    private devId: any = {};
    private tempDirty: any = {};
    private tempInitialized: any = {};
    private nodeSet: any = {};
    private off: any = {};
    private showSlick: any = {};
    private updateTempSlider: any = {};
    private updateBoostTimeDebounced: any;
    private updateRunbackTimeDebounced: any;
    private toggleLocked: any = {};
    private errorMsg: any = {};
    private boostEndHour: any = {};
    private boostEndTimeModified: any = {};
    private boostEndMin: any = {};
    private htrTempSetup: any = {};
    private temperatureList: any = undefined;
    private temperatureListLength: any = {};
    private sliderPosition: any = {};
    private updateHtrTimeout: any = {};
    private slickSettings: any = {};
    private slick: any = {};
    private ready = false;
    private isHaverlandClient = false;
    private timeLabel = '';

    private mounted() {
      this.init();
      if (this.nodeSet.isReady()) {
        this.updateSliderPosition();
      }
    }

    private async init() {
      this.scopeIsActive = true;
      this.homeId = this.$route.params.homeid;
      this.devId = this.$route.params.devid;
      this.tempDirty = false;
      this.tempInitialized = false;
      this.nodeSet = (window as any).nodeSet;
      this.off = this.isThermostatOff();
      this.showSlick = true;
      this.updateTempSlider = false;
      this.updateHtrTimeout;
      this.updateBoostTimeDebounced = _.debounce(this.updateBoostTime, 3000);
      this.updateRunbackTimeDebounced = _.debounce(this.updateRunbackTime, 3000);
      this.isHaverlandClient = (await this.$configService.getConfig()).clientName === 'haverland';
      this.timeLabel = await this.$t('BOOST_ACTIVE_UNTIL') as string;
      this.toggleLocked = async () => {
      try {
        if (!(window as any).nodeSet) {
          return;
        }
        await (window as any).nodeSet?.setLock((window as any).nodeSet?.nodeControllers[0].nodeData.status.locked ? false : true);
        this.$forceUpdate();
      } catch {
          this.errorMsg = this.$t('DEVICE_UPDATE_ERROR_MSG');
          this.$toast.error(this.errorMsg);
        }
      };

      if (!(window as any).nodeSet) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      if (!(window as any).connected || (window as any).nodeSet?.nodeControllers[0].nodeData.lost) {
        const date: any = new Date();
        return routerWrapper.push(this.$router, 'root.authenticated.root.device.heater.nav.stats.day', {
          devid: (window as any).devId,
          addr: this.$route.params.addr,
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate()
        }, { location: 'replace' });
      }
      this.refreshBoostEndTime();
      this.initTemperatureSliderData();
      this.initTempCarousel();
      this.$userService.addListener(this.homeId, this.devListener);
      this.ready = true;
    }

    private destroyed() {
      this.scopeIsActive = false;
      this.$userService.removeListener(this.homeId, this.devListener);
    }

    private get currentAddress(): string {
      return this.$route.params.addr;
    }

    private get currentDevId(): string {
      return this.$route.params.devId;
    }

    @Watch('currentAddress')
    @Watch('currentDevId')
    private onControllerChanged() {
      this.init();
      if (this.nodeSet.isReady()) {
        this.updateSliderPosition();
      }
    }

    private sliderTempUp(): any {
      const currentPos: any = this.sliderPosition;
      if (this.temperatureList[currentPos].value === this.htrTempSetup.max) {
        return;
      }
      if (this.temperatureList[currentPos].value < this.htrTempSetup.min) {
        return (this.$refs.slick as any).goTo(currentPos + 1);
      }
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value + this.htrTempSetup.step;
      if (this.temperatureList[currentPos].value.toFixed(1) === this.temperatureList[currentPos].max.toFixed(1)) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos + 1);
      } else if (this.temperatureList[currentPos].value > this.temperatureList[currentPos].max) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        return;
      }
      this.updateHtrTemperature(currentPos);
      this.$forceUpdate();
    }

    private sliderTempDown(): any {
      const currentPos: any = this.sliderPosition;
      if (this.temperatureList[currentPos].value === this.htrTempSetup.min) {
        return;
      }
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value - this.htrTempSetup.step;
      if (currentPos > 0 && this.temperatureList[currentPos].value < this.temperatureList[currentPos].initialValue) {
        this.temperatureList[currentPos - 1].value = this.temperatureList[currentPos].value;
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos - 1);
      } else if (this.temperatureList[currentPos].value < this.temperatureList[currentPos].min) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        return;
      }
      this.updateHtrTemperature(currentPos);
      this.$forceUpdate();
    }

    private getMaxBoostTime() {
      let max = 24;
      if (this.nodeSet.getAvailableSettings().includes(NodeSetting.MAX_RUNBACK_TIME)) {
        max = Number(this.nodeSet.getFirstNodeSettingParams(NodeSetting.MAX_RUNBACK_TIME).value) / 60;
      }
      return max;
    }

    private hourUpDisabled() {
      const boostEndHour = this.boostEndHour % 24;
      return (boostEndHour - new Date().getHours()) >= this.getMaxBoostTime();
    }

    private hourDownDisabled() {
      if (!this.nodeSet.getAvailableModes().includes(NodeMode.RUNBACK)) {
        return false;
      }
      const endTimeString = this.boostEndHour + ':' + this.boostEndMin;
      const endTime = new dateTimeHelpers().timeStringToDayMinute(endTimeString);
      const minOffset = Math.trunc((endTime - new Date().getMinutes() - new Date().getHours() * 60) / 10) * 10;
      return minOffset <= 60;
    }

    private hourUp(): any {
      this.boostEndHour = (this.boostEndHour + 1) % 24;
      this.boostEndTimeModified = true;
      if (this.nodeSet.getAvailableModes().includes(NodeMode.RUNBACK)) {
        this.updateRunbackTimeDebounced(true);
      } else {
        this.updateBoostTimeDebounced(true);
      }
    }

    private hourDown(): any {
      this.boostEndHour = (this.boostEndHour + 23) % 24;
      this.boostEndTimeModified = true;
      if (this.nodeSet.getAvailableModes().includes(NodeMode.RUNBACK)) {
        this.updateRunbackTimeDebounced(true);
      } else {
        this.updateBoostTimeDebounced(true);
      }
    }

    private updateBoostTime(): any {
      const endTimeString: any = this.boostEndHour + ':' + this.boostEndMin;
      const endTime: any = new dateTimeHelpers().timeStringToDayMinute(endTimeString);
      let minOffset: any = Math.trunc((endTime - new Date().getMinutes() - new Date().getHours() * 60) / 10) * 10;
      while (minOffset < 0) {
        minOffset += 24 * 60;
      }
      const currentPos: any = this.sliderPosition;
      const temp: any = this.temperatureList[currentPos].value;
      return this.nodeSet?.editBoostTime({
        active: true,
        temperature: temp,
        units: ((this.nodeSet?.nodeControllers[0].nodeData || {}).status || {}).units || NodeTempUnits.CELSIUS,
        time: Math.round(minOffset / 60)
      }).then(async () => {
        this.boostEndTimeModified = false;
      });
    }

    private updateRunbackTime(): any {
      const endTimeString: any = this.boostEndHour + ':' + this.boostEndMin;
      const endTime: any = new dateTimeHelpers().timeStringToDayMinute(endTimeString);
      let minOffset: any = Math.trunc((endTime - new Date().getMinutes() - new Date().getHours() * 60) / 10) * 10;
      while (minOffset < 0) {
        minOffset += 24 * 60;
      }
      return this.nodeSet?.editRunbackTime(minOffset)
      .then(async () => {
        this.boostEndTimeModified = false;
      });
    }

    private getTimeLabel() {
      return this.timeLabel.replace('Boost', this.nodeSet.getFirstNodeMode());
    }

    private onOffAvailable() {
      const availableModes = this.nodeSet.getAvailableModes();
      if (availableModes.includes(NodeMode.RUNBACK)) {
        return false;
      }
      return availableModes.includes(NodeMode.ON)
      && this.nodeSet.getAvailableModes().includes(NodeMode.OFF);
    }

    private async toggleOnOff(): Promise<any> {
      if (!(window as any).nodeSet || !this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      const mode: any = (window as any).nodeSet?.getFirstNodeMode() === 'off' ? 'on' : 'off';
      this.$amplitudeService.sendEvent(`set_mode_${mode}`);
      await (window as any).nodeSet?.setMode(mode, (window as any).nodeSet?.getFirstNodeModeParams(mode)).catch(() => {
        this.$toast.error(this.$t('DEVICE_UPDATE_ERROR_MSG'));
      });
      this.off = mode === 'off';
      this.$forceUpdate();
    }

    private isIndicatorActive(indicatorId?: any): any {
      if (!(window as any).nodeSet) {
        return false;
      }
      return (window as any).nodeSet?.getStatusIndicators((window as any).nodeSet?.nodeControllers[0].nodeData.status).findIndex((indicatorData: NodeStatusIndicator) => indicatorData.id === indicatorId && indicatorData.active) !== -1;
    }

    private temperatureEditAvailable(): any {
      return (window as any).nodeSet?.getActiveCapabilities().includes(NodeCapability.TEMPERATURE_CONTROL_AVAILABLE);
    }

    private currentTemperature(): any {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      const rawTemp: string | undefined = (window as any).nodeSet?.getFirstNodeModeParams(
        (window as any).nodeSet?.getFirstNodeMode(),
      ).temperature;
      const sTemp: string =  rawTemp !== undefined ? rawTemp : '20.0';
      return sTemp;
    }

    private lockAvailable(): any {
      return (window as any).nodeSet?.getActiveCapabilities().includes(NodeCapability.LOCK_AVAILABLE) || this.isHaverlandClient;
    }


    private isThermostatOff(): any {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      return ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).lost || (window as any).nodeSet?.getFirstNodeMode() === 'off';
    }

    private refreshBoostEndTime(): any {
      const currentBoostEndTime: any = (((window as any).nodeSet?.nodeControllers[0].nodeData || {}).status || {}).boost_end_min || 0;
      this.boostEndMin = currentBoostEndTime % 60;
      this.boostEndHour = Math.trunc(currentBoostEndTime / 60);
    }

    private getTempSetup(): any {
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      const modeParams: any = (window as any).nodeSet?.getFirstNodeModeParams((window as any).nodeSet?.getFirstNodeMode());
      const tempLimits: any = (window as any).nodeSet?.getTempLimits();
      const celsius: any = (window as any).nodeSet?.getTempUnits() === 'C';
      return {
        min: celsius ? tempLimits.minCTemp : tempLimits.minFTemp,
        max: celsius ? tempLimits.maxCTemp : tempLimits.maxFTemp,
        units: modeParams.temperatureUnits,
        step: modeParams.temperatureStep
      };
    }

    private hasOffProgSlots(): any {
      return (window as any).nodeSet.getFirstNodeScheduleTemperatures()
      .findIndex((data: any) => data.id === 'off') !== -1;
    }

    private initTemperatureSliderData(): any {
      this.htrTempSetup = this.getTempSetup();
      if (this.temperatureList !== undefined && (this.htrTempSetup.min === this.temperatureList[0].min && this.htrTempSetup.max === this.temperatureList[this.temperatureList.length - 1].max)) {
        this.updateTempSlider = false;
        return;
      }
      this.temperatureList = [];
      this.temperatureListLength = Math.ceil(this.htrTempSetup.max - this.htrTempSetup.min + 1);
      if (this.hasOffProgSlots()) {
        this.temperatureList.push({
          stringValue: 'OFF',
          value: 0,
          initialValue: 0,
          min: 0,
          max: 0,
          visibleIfSelected: true
        });
      }
      const minShownValue: any = Math.trunc(this.htrTempSetup.min);
      for (let i: any = 0; i < this.temperatureListLength; i++) {
        this.temperatureList.push({
          value: minShownValue + i,
          initialValue: minShownValue + i,
          min: i === 0 ? minShownValue : minShownValue + i - 1,
          max: i === this.temperatureListLength - 1 ? minShownValue + i : minShownValue + i + 1
        });
      }
      this.updateTempSlider = true;
    }

    private getSliderPosition(temp?: any): any {
      for (let i: any = 0; i < this.temperatureListLength - 1; i++) {
        if (temp < this.temperatureList[i + 1].initialValue) {
          return i;
        }
      }
      return this.temperatureListLength - 1;
    }

    private updateSliderPosition(initialized?: any): any {
      if (!this.slick || !this.temperatureList) {
        return;
      }
      if (!this.$helkiNMService.isNodeReady((window as any).nodeSet)) {
        return;
      }
      const rawTemp: string | undefined = (window as any).nodeSet?.getFirstNodeModeParams(
        (window as any).nodeSet?.getFirstNodeMode(),
      ).temperature;
      const sTemp: string = rawTemp !== undefined ? rawTemp : '20.0';
      const pos: any = this.getSliderPosition(parseInt(sTemp, 10));
      this.temperatureList[pos].value = Number(sTemp);
      this.temperatureList[this.sliderPosition].value = Number(sTemp);
      if (initialized) {
        this.tempInitialized = true;
      }
      if (this.$refs.slick !== undefined) {
        (this.$refs.slick as any).goTo(pos);
      }
      this.sliderPosition = pos;
    }

    private cancelHtrTemperatureUpdate(): any {
      clearTimeout(this.updateHtrTimeout);
    }

    private updateHtrTemperature(pos?: any): any {
      this.cancelHtrTemperatureUpdate();
      this.updateHtrTimeout = setTimeout(() => {
        (window as any).nodeSet?.setTemp(this.temperatureList[pos].value).catch(() => {
          this.$toast.error(this.$t('DEVICE_UPDATE_ERROR_MSG'));
        });
        this.tempDirty = false;
      }, 2000);
    }

    private initTempCarousel(): any {
      const rawTemp: string | undefined = (window as any).nodeSet?.getFirstNodeModeParams(
        (window as any).nodeSet?.getFirstNodeMode(),
      ).temperature;
      const sTemp: string =  rawTemp !== undefined ? rawTemp : '20.0';
      this.sliderPosition = this.getSliderPosition(parseInt(sTemp, 10));
      function getSlidesToShow() {
        if ((window as any).innerWidth < 600) {
          return 3;
        }
        if ((window as any).innerWidth < 1200) {
          return 5;
        }
        return 7;
      }
      function getCenterPadding() {
        if ((window as any).innerWidth < 600) {
          return '60px';
        }
        if ((window as any).innerWidth < 1200) {
          return '75px';
        }
        return '90px';
      }
      this.slickSettings = {
        centerMode: true,
        centerPadding: getCenterPadding(),
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        swipeToSlide: true,
        method: {},
        initialSlide: this.sliderPosition,
        event: {
          beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
            if (oldPos > this.temperatureList.length) {
              oldPos = this.temperatureList.length - 1;
            }
            if (oldPos === newPos) {
              return;
            }
            this.temperatureList[oldPos].value = this.temperatureList[oldPos].initialValue;
            this.sliderPosition = newPos;
          },
          afterChange: (event: any, slick: any, newPos: any) => {
            this.sliderPosition = newPos;
            if (this.tempInitialized) {
              this.tempInitialized = false;
              return;
            }
            this.cancelHtrTemperatureUpdate();
            if ((window as any).nodeSet?.getFirstNodeModeParams((window as any).nodeSet?.getFirstNodeMode()).temperature === this.temperatureList[newPos].value.toFixed(1)) {
              return;
            }
            this.tempDirty = true;
            this.updateHtrTemperature(newPos);
          },
          init: (event: any, slick: any) => {
            this.slick = slick;
            this.updateSliderPosition(true);
          }
        }
      };
    }

    private updateHtrData(): any {
      if (this.tempDirty) {
        return;
      }
      this.off = this.isThermostatOff();
      this.updateSliderPosition(true);
      this.initTemperatureSliderData();
      if (this.updateTempSlider) {
        this.showSlick = false;
        setTimeout(() => {
          this.showSlick = true;
          this.$forceUpdate();
        });
        this.updateTempSlider = false;
      }
      this.refreshBoostEndTime();
    }

    private devListener(): any {
      this.updateHtrData();
    }

}
