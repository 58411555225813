import { Component, Prop, Vue } from 'vue-property-decorator';
import VueTimepicker from 'vue2-timepicker';

@Component({
  components: {
    VueTimepicker,
  },
})
export default class EditSlot extends Vue {
  @Prop() private label!: string;
  @Prop() private timeValue!: any;
  @Prop() private disabled!: boolean;
  @Prop() private shortWidth!: boolean;

  private updateTimeValue() {
    this.$emit('change', this.timeValue);
  }
}
