import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class RangeSetting extends Vue {
  @Prop() private label!: string;
  @Prop() private value!: number;
  @Prop() private subtitle!: boolean;
  @Prop() private disabled!: boolean;
  @Prop() private color!: string;
  @Prop() private valueColor!: string;
  @Prop() private fontSize!: string;
  @Prop() private units!: string;
  @Prop() private min!: number;
  @Prop() private max!: number;
  @Prop() private step!: number;

  @Watch('max')
  private onMaxUpdate(maxValue: number) {
    this.range = `${Math.min(this.value, maxValue)}`;
  }

  private get range(): string {
    return `${this.value}`;
  }

  private set range(range: string) {
    this.$emit('input', Number(range));
  }

  private get currentValue(): string {
    if (this.value === undefined) {
      return '';
    }
    if (!this.step || this.step > 1) {
      return Number(this.value).toFixed(0);
    } else if (this.step < 0.1) {
      return Number(this.value).toFixed(2);
    } else if (this.step < 1) {
      return Number(this.value).toFixed(1);
    } else {
      return String(this.value);
    }
  }
}
