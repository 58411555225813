import _ from 'lodash';
import { Store } from 'vuex';
import { IDevProxy, DevProxy, DevData } from './DevProxy';
import { ISessionService } from './session.service';
import { IApiService, GeoData } from './api.service';
import { nodeCache } from '@/services/nodeCache';

import {
  NodeControllerSet,
  NodeController,
  NodeType,
  NodeData,
  NodeControllerManager,
} from '@vdi-helki/helki-node-management';
import { VueControlProxy } from './VueControlProxy';

export interface NodeSelectData {
  homeId: string;
  devId: string;
  addr: number;
  type: NodeType;
}

export interface HomeData {
  id: string;
  name: string;
  devs: DevData[];
  owner: boolean;
  extraData?: any;
}

export interface IDevListService {
  getLastVisited(): Promise<string>;
  setLastVisited(homeId: string): void;
  initDevList(): Promise<void>;
  reset(): Promise<void>;
  fetchHomeData(): Promise<void>;
  getHomeList(): Promise<HomeData[]>;
  getDevProxy(homeId: string, devId: string): Promise<IDevProxy>;
  getNodeController(
    homeId: string,
    devId: string,
    type: NodeType,
    addr: number,
  ): Promise<NodeController>;
  getNodeControllerSet(nodeList: NodeSelectData[]): Promise<NodeControllerSet>;
  connectToSmartboxDiscovery(): Promise<string>;
  endSmartboxDiscovery(): void;
  getGeoData(homeId: string): Promise<GeoData>;
  count(): number;
  transferDevice(homeId: string, devId: string, newHomeId: string): Promise<any>;
  getDevPID(name: string): string | undefined;
  getDevNetworkPrefix(pid: string): string;
  getDevAPIPort(pid: string): string;
  getTimezone(homeId: string): string;
}

export class DevListService implements IDevListService {
  private homes: HomeData[] = [];

  private smartboxDiscoverMaxTime = 30000;

  private smartboxDiscoveryTimer: any;

  private smartboxDiscoverySocket: any;

  private controlProxy: VueControlProxy;

  private nodeControllerManager: NodeControllerManager;

  private devInfo = {
    '021D': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
    },
    '0132': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
    },
    '0433': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
      subtype: 'fourButtonWifiHtr',
    },
    '0435': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
      subtype: 'twoButtonWifiHtr',
    },
    '0A31': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
    },
    '0A34': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
    },
    '0A39': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
    },
    '0838': {
      name: 'wifiHtr',
      networkPrefix: 'Heater',
      APIPort: '8080',
    },
    '0B05': {
      name: 'ethernet',
    },
    '0105': {
      name: 'ethernet',
    },
    '0119': {
      name: 'ethernet',
    },
    '0106': {
      name: 'wifi',
      networkPrefix: 'Smartbox',
      APIPort: '80',
    },
    '0B06': {
      name: 'wifi',
      networkPrefix: 'Smartbox',
      APIPort: '80',
    },
    '0B26': {
      name: 'ethernet',
    },
    '0126': {
      name: 'ethernet',
    },
    '0130': {
      name: 'ethernet',
    },
  };

  constructor(
    private sessionService: ISessionService,
    private apiService: IApiService,
    private store: Store<any>,
  ) {
    this.controlProxy = new VueControlProxy(this.store, this.apiService);
    this.nodeControllerManager = new NodeControllerManager(this.controlProxy);
  }

  public getTimezone(homeId: string): string {
    return this.store.state.homes[homeId].geo_data.tz_code;
  }

  public getDevProductName(pid : string) {
    return (this.devInfo as any)[pid?.toUpperCase()]?.name || '';
  }

  public getDevNetworkPrefix(pid: string): string {
    if (!(this.devInfo as any)[pid]) {
      throw new Error('Unsupported product id: ' + pid);
    }
    return (this.devInfo as any)[pid].networkPrefix;
  }

  public getDevAPIPort(pid: string): string {
    if (!(this.devInfo as any)[pid]) {
      throw new Error('Unsupported product id: ' + pid);
    }
    return (this.devInfo as any)[pid].APIPort;
  }

  public getDevPID(name: string) {
    return Object.keys(this.devInfo).find((pid) => {
      const data = (this.devInfo as any)[pid];
      if (data.subtype !== undefined && data.subtype === name) {
        return true;
      }
      return data.name === name;
    });
  }

  public async transferDevice(homeId: string, devId: string, newHomeId: string) {
    const devProxy = await this.getDevProxy(homeId, devId);
    await devProxy.transferToHome(newHomeId, this.getName(newHomeId));
    // Remove dev data from old home
    const home = this.getHome(homeId);
    const devData = this.removeDevData(home, devId);
    // Add dev data to new home
    if (!devData !== undefined) {
      const homeIndex = this.homes.findIndex((home) => home.id === newHomeId);
      if (homeIndex !== -1 && devData !== undefined) {
        this.homes[homeIndex].devs = this.homes[homeIndex].devs || [];
        this.homes[homeIndex].devs.push(devData);
      }
    }
    // Remove home if it no longer has any device
    if (home.devs.length === 0) {
      this.deleteHome(homeId);
    }
    await this.initDevList();
  }

  public count(): number {
    return Object.keys(this.store.state.homes).length;
  }

  public async getDevHtrSystemPowerLimit(homeId: string, devId: string): Promise<number | undefined> {
    return this.getDevProxy(homeId, devId)
    .then((devProxy) => {
      return devProxy.getHtrSystemPowerLimit();
    });
  }

  public getLastVisited(): Promise<string> {
    return this.getList()
    .then(() => {
      if (this.homes.length === 0) {
        return undefined;
      }
      const storedLastHome = (window as any).localStorage.lastHomeVisited;
      return this.hasHome(storedLastHome) ? storedLastHome : this.homes[0].id;
    });
  }

  public hasHome(homeId: string) {
    const homeIndex = _.findIndex(this.homes, (home) => {
      return home.id === homeId;
    });
    return homeIndex !== -1;
  }

  public async getGuests(homeId: string) {
    const home = this.getHome(homeId);
    if (!home) {
      throw new Error('Home not found');
    }
    return this.apiService.getGuests(homeId);
  }

  public getExtraHomeData(homeId: string) {
    return this.getList()
    .then(() => {
      const home = this.getHome(homeId) || {extraData: undefined};
      return home.extraData || {energyTariffProfiles: [], maxPowerProfiles: []};
    });
  }

  public getHtrSystemPowerLimit(homeId: string) {
    const home = this.getHome(homeId);
    return (home?.devs || []).reduce((powerLimit: number | undefined, dev) => {
      if (!dev.proxy || !this.store.state.homes[homeId].devs[dev.dev_id].connected) {
        return powerLimit;
      }
      const currentLimit = dev.proxy.getHtrSystemPowerLimit() || 0;
      return powerLimit === undefined || powerLimit > currentLimit ? currentLimit : powerLimit;
    }, undefined);
  }

  public updateExtraData(homeId: string, key: string, payload: any) {
    return this.getList()
    .then(() => {
      const home = this.getHome(homeId);
      if (!home) {
        return;
      }
      home.extraData = home.extraData || {};
      home.extraData[key] = payload;
      return this.apiService.setHomeExtraData(homeId, home.extraData);
    });
  }

  public async getNodeList(homeId: string) {
    let nodeList: NodeController[] = [];
    for (const devId in this.store.state.homes[homeId].devs) {
      const device= this.store.state.homes[homeId].devs[devId];
      const timeZone: string = this.store?.state?.homes[`${homeId}`].geo_data?.tz_code;
      nodeList = [
        ...nodeList,
        ...device.nodes
          .filter((node: NodeData) => node.installed === true)
          .map((node: NodeData) => {
            return this.nodeControllerManager.getNodeController(
              devId,
              node,
              timeZone,
            );
          }),
      ];
    }
    return nodeList;
  }

  public async getDevClassifiedNodeList(homeId: string) {
    const finalNodeList = [];
    let ungroupedNodes: any = [];
    for (const devId in this.store.state.homes[homeId]?.devs) {
      const device = this.store.state.homes[homeId]?.devs[devId];
      const timeZone: string = this.store?.state?.homes[`${homeId}`].geo_data?.tz_code;
      const pid = device.product_id?.toUpperCase();
      const currentNodeList = device.nodes;
      if (this.getDevProductName(pid) === 'wifiHtr') {
        const processedNodeList = currentNodeList.map((data: any) => {
          const newData = _.cloneDeep(data);
          newData.lost = newData.lost || !device.connected;
          newData.devId = devId;
          newData.name = device.name;
          return this.nodeControllerManager.getNodeController(
            devId,
            newData,
            timeZone,
          );
        });
        ungroupedNodes = ungroupedNodes.concat(processedNodeList);
      } else {
        finalNodeList.push({
          lost: !device.connected,
          pid: device.product_id,
          name: device.name,
          devId: device.dev_id,
          nodeList: device.nodes
          .filter((node: NodeData) => node.installed === true)
          .map((node: NodeData) => {
            return this.nodeControllerManager.getNodeController(
              devId,
              node,
              timeZone,
            );
          }),
        });
      }
    }
    finalNodeList.push({pid: '021D', nodeList: ungroupedNodes}); // pid: any considered as wifiHtr
    return finalNodeList;
  }

  public hasSingleDevice(homeId: string) {
    return Object.keys(this.store.state.homes[homeId]?.devs || {}).length === 1;
  }

  public hasSingleSmartbox(homeId: string) {
    const productId = (Object.values(this.store.state.homes[homeId]?.devs || {})[0] as any)?.product_id;
    return this.hasSingleDevice(homeId) &&
           productId !== '021D' &&
           productId !== '0132' &&
           productId !== '0433' &&
           productId !== '0435' &&
           productId !== '0A31' &&
           productId !== '0A34' &&
           productId !== '0A39' &&
           productId !== '0838';
  }

  public setLastVisited(homeId: string) {
    (window as any).localStorage.lastHomeVisited = homeId;
  }

  public async connectToSmartboxDiscovery(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.smartboxDiscoverySocket = new WebSocket(this.getWSSmartboxDiscoveryUrl())
      this.smartboxDiscoverySocket.addEventListener('message', (event: any) => {
        try {
          const dataObj = JSON.parse(event.data) || {};
          if (dataObj.event === 'dev_handshake') {
            this.endSmartboxDiscovery();
            resolve(dataObj.data.dev_id);
          }
        } catch (e: unknown) {
          this.smartboxDiscoverySocket.close();
        }
      })

      this.smartboxDiscoveryTimer = setTimeout(() => {
        this.endSmartboxDiscovery();
        reject();
      }, this.smartboxDiscoverMaxTime);
    });
  }

  public endSmartboxDiscovery(): void {
    clearTimeout(this.smartboxDiscoveryTimer);
    this.smartboxDiscoverySocket?.close();
  }

  public async initDemoDevList(): Promise<void> {
    return Promise.resolve()
    .then(() => {
      const ownedHomes = this.store.state.homes || {};
      this.homes = [];
      Object.keys(ownedHomes).forEach((homeId: string) => {
        const homeInfo = ownedHomes[homeId];
        const devs: any = {};
        const devData: any = {
          dev_id: homeId,
          name: homeInfo.name,
          product_id: homeInfo.product_id,
          fw_version: homeInfo.fw_version,
          serial_id: homeInfo.serial_id,
        };
        devs[homeId] = {
          ...devData,
          proxy: new DevProxy(devData, homeId, this.sessionService, this.apiService, this.store),
        };
        this.homes.push({
          id: homeId,
          name: homeInfo.name,
          owner: true,
          devs,
        });
      });
    });
  }

  public async reset(): Promise<void> {
    return this.initDevList();
  }

  public async initDevList(): Promise<void> {
    if (this.store.state.demo) {
      return this.initDemoDevList();
    }
    this.store.commit('resetHomes');
    return this.apiService.getDevList()
    .then((homeList) => {
      const homeDataList = (homeList || []).map((home) => home as HomeData);
      this.homes = _.sortBy(homeDataList, (home: HomeData) => home.name?.toLowerCase());
      return this.fetchNodes();
    })
    .then(() => {
      this.store.commit('setHomes', this.homes);
      for (const home of this.homes) {
        for (const dev of home.devs) {
          dev.proxy = this.initDevProxy(dev, home);
        }
      }
    })
  }

  public async fetchHomeData(): Promise<void> {
    return this.apiService.getDevList()
    .then((homeList) => {
      const homeDataList = (homeList || []).map((home) => home as HomeData);

      this.homes = _.sortBy(homeDataList, (home: HomeData) => home.name?.toLowerCase());

      return this.fetchNodes();
    })
    .then(() => {
      this.store.commit('setHomes', this.homes);
      for (const home of this.homes) {
        for (const dev of home.devs) {
          dev.proxy = this.initDevProxy(dev, home);
        }
      }
    })
    .catch(() => {
      this.store.commit('setError', this.store.state.errors.dataUpdate);
    });
  }

  public async updateList() {
    // Update homes
    const homeList = await this.apiService.getDevList();
    const homesToRemove = this.homes.reduce((indexList: number[], home) => {
      const currentIndex: number = homeList.findIndex((updatedHome) => {
        return updatedHome.id === home.id;
      });
      const storedIndex: number = this.homes.findIndex((storedHome: HomeData) => {
        return storedHome.id === home.id;
      });
      if (currentIndex === -1 && storedIndex !== -1) {
        indexList.push(storedIndex);
      }
      return indexList;
    }, []);
    for (const index of homesToRemove) {
      this.store.commit('removeHome', this.homes[index].id);
      this.homes.splice(index, 1);
    }
    const homesToAdd = homeList.filter((updatedHome) => {
      return this.homes.findIndex((home) => {
        return home.id === updatedHome.id;
      }) === -1;
    });
    for (const home of homesToAdd) {
      this.homes.push(home as HomeData);
      const homeData = _.cloneDeep(this.homes[this.homes.length - 1]);
      homeData.devs = homeData.devs.reduce((currentData: any, dev: DevData) => {
        currentData[dev.dev_id] = dev;
        return currentData;
      }, {});
      this.store.commit('addHome', {
        homeId: this.homes[this.homes.length - 1].id,
        homeData,
      });
      this.initDevProxies(this.homes[this.homes.length - 1]);
    }
    this.homes = _.sortBy(this.homes, (home) => home.name.toLowerCase());
    // Update devices
    for (const updatedHome of homeList) {
      const home = this.getHome(updatedHome.id);
      const devsToRemove = home.devs.reduce((indexList: number[], dev) => {
        const currentIndex: number = updatedHome.devs.findIndex((updatedDev) => {
          return updatedDev.dev_id === dev.dev_id;
        });
        const storedIndex: number = home.devs.findIndex((storedDev) => {
          return storedDev.dev_id === dev.dev_id;
        });
        if (currentIndex === -1 && storedIndex !== -1) {
          indexList.push(storedIndex);
        }
        return indexList;
      }, []);
      for (const index of devsToRemove) {
        this.store.commit('removeDevice', { homeId: home.id, devId: home.devs[index].dev_id });
        delete home.devs[(home?.devs[index]?.dev_id as any)];
      }
      const devsToAdd = updatedHome.devs.filter((updatedDev) => {
        return home.devs.findIndex((dev) => {
          return dev.dev_id === updatedDev.dev_id;
        }) === -1;
      });
      for (const dev of devsToAdd) {
        home.devs.push(dev as DevData);
        home.devs[home.devs.length - 1].proxy = this.initDevProxy(dev as DevData, home);
        this.store.commit('addDevice', {
          homeId: home.id,
          devId: home.devs[home.devs.length - 1].dev_id,
          devData: home.devs[home.devs.length - 1],
        });
      }
      // Update device names
      for (const dev of updatedHome.devs) {
        this.setDevName(dev.dev_id, dev.name);
      }
    }
  }

  public async getHomeList(): Promise<HomeData[]> {
    return this.homes;
  }

  public async getList(): Promise<HomeData[]> {
    return this.getHomeList();
  }

  public getDevList(homeId: string): DevData[] {
    return this.homes.find((home) => home.id === homeId)?.devs?.map((device) =>
      ({ ...device, devid: device.dev_id, isWifiSmartbox: this.isWifiDevice(device.product_id) }),
    ) || [];
  }

  public getDevids(homeId: string): string[] {
    return this.getDevList(homeId)
    .map((dev) => dev.dev_id);
  }

  public getName(homeId: string): string {
    const home = this.getHome(homeId);
    if (!home) {
      throw new Error('Home ' + homeId + ' not found');
    }
    return home.name;
  }

  public async getAwayStatus(homeId: string): Promise<boolean> {
    const deviceList = this.getDevList(homeId);
    return deviceList.length > 0 && deviceList[0]?.proxy?.getAwayStatus();
  }

  public async checkDevConnection(homeId: string, devId: string) {
    return this.store.state.homes[homeId].devs[devId].connected;
  }

  public async checkConnection(homeId: string) {
    return this.store.state.homes[homeId].connected;
  }

  public async getGeoData(homeId: string) {
    return this.apiService.getGeoData(homeId);
  }

  public async getDevProxy(homeId: string, devId: string): Promise<IDevProxy> {
    const home: HomeData = this.getHome(homeId);
    if (!home) {
      throw new Error(`Home not found: ${homeId}`);
    }
    const dev = _.find(home.devs, (currentDev: DevData) => currentDev.dev_id === devId);
    if (!dev || !dev.proxy) {
      throw new Error('Dev not found');
    }
    return Promise.resolve(dev.proxy);
  }

  public async getNodeController(
    homeId: string,
    devId: string,
    type: NodeType,
    addr: number,
  ): Promise<NodeController> {
    const nodeData: NodeData = this.store?.getters.getNode(homeId, devId, addr);
    const timeZone: string = this.store?.state?.homes[`${homeId}`].geo_data?.tz_code;
    const nodeController = this.nodeControllerManager.getNodeController(
      devId,
      nodeData,
      timeZone,
    );
    nodeController.enableNodeDataUpdates();
    nodeController.setNodeCache(new nodeCache());
    return nodeController;
  }

  public async getNodeControllerSet(nodeList: NodeSelectData[]): Promise<NodeControllerSet> {
    return Promise.all(nodeList.map((nodeData: NodeSelectData) => this.getNodeController(
      nodeData.homeId,
      nodeData.devId,
      nodeData.type,
      nodeData.addr,
    )))
    .then((nodeControllerList: any) => new NodeControllerSet(
      nodeControllerList as NodeController[],
    ));
  }

  private getDevNodes(dev: DevData) {
    if (this.getDevProductName(dev.product_id) !== 'wifiHtr' || (dev.nodes || []).length > 0) {
      return dev.nodes || [];
    }
    return [{
      installed: true,
      lost: true,
      type: 'htr',
      addr: 2,
      status: {},
      setup: {},
      version: { pid: '021D' },
      name: dev.name,
    }];
  }

  private initDevProxy(dev: DevData, home: HomeData) {
    return new DevProxy({...dev, nodes: this.getDevNodes(dev)}, home.id, this.sessionService, this.apiService, this.store);
  }

  private initDevProxies(home: HomeData) {
    for (const dev of home.devs) {
      dev.proxy = this.initDevProxy(dev, home);
    }
  }

  private getDevFromHome(home: HomeData, devid: string) {
    return home.devs.find((dev) => dev.dev_id === devid);
  }

  private async setDevName(devid: string, newName: string) {
    let dev;
    for (let i = 0; i < this.homes.length && !dev; i++) {
      dev = this.getDevFromHome(this.homes[i], devid);
    }
    if (!dev) {
      throw new Error('Dev not found');
    }
    await dev?.proxy?.setName(newName);
    dev.name = newName;
  }
  public isOwner(homeId: string): boolean {
    return this.getHome(homeId).owner;
  }

  public async deleteDevice(homeId: string, devId: string) {
    return this.getDevProxy(homeId, devId)
    .then((devProxy) => {
      return devProxy.deleteDevice();
    })
    .then(() => {
      // Remove device from home
      const home = this.getHome(homeId);
      this.removeDevData(home, devId);
      // Remove home if it no longer has any device
      if (home.devs.length === 0) {
        this.deleteHome(homeId);
      }
    });
  }

  private isWifiDevice(productId: string) {
    switch ((productId || '').toUpperCase()) {
      case '0B06':
      case '0106':
        return true;
      default:
        return false;
    }
  }

  private deleteHome(homeId: string) {
    const homeIndex = this.homes.findIndex((home) => {
      return home.id === homeId;
    });
    if (homeIndex !== -1) {
      this.store.commit('removeHome', { homeId });
      this.homes.splice(homeIndex, 1);
    }
  }

  private removeDevData(home: HomeData, devId: string) {
    const devIndex = home.devs.findIndex((dev: DevData) => {
      return dev.dev_id === devId;
    });
    if (devIndex !== -1) {
      const devData = home.devs[devIndex];
      home.devs.splice(devIndex, 1);
      return devData;
    }
  }

  private getHome(homeId: string): HomeData {
    const home: HomeData | undefined = _.find(
      this.homes,
      (currentHome: HomeData) => currentHome.id === homeId,
    );
    if (!home) {
      throw new Error('Home not found');
    }
    return home;
  }

  private getWSSmartboxDiscoveryUrl(): string {
    const sessionData = this.sessionService.getSessionData();
    const encodedToken = encodeURIComponent(sessionData?.token || '');
    const host = this.apiService.getHost().replace('https:', 'wss:');
    return `${host}:443/api/v2/ws_user?token=${encodedToken}&dev_id=*`;
  }

  private async fetchDevNodes(device: any): Promise<void> {
    return this.apiService.getNodeList(device.dev_id)
    .then((nodeData: any) => {
      this.store.commit('updateDevData', {
        dev_id: device.dev_id,
        data: {
          nodes: nodeData?.nodes || [],
        },
      });
    });
  }

  private async fetchHomeNodes(home: any): Promise<void> {
    return Promise.all((home?.devs || []).map((device: any) => this.fetchDevNodes(device)))
    .then(() => Promise.resolve());
  }

  private async fetchNodes(): Promise<void> {
    return Promise.all(this.homes.map((home) => this.fetchHomeNodes(home)))
    .then(() => Promise.resolve());
  }
}
