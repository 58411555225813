import { Component, Vue, Prop, Watch } from 'vue-property-decorator';


@Component
export default class HomeSelector extends Vue {
  @Prop() private homes!: any[];
  @Prop() private homeIndex!: number;
  private refreshInterval: any;

  private mounted() {
    this.refreshInterval = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  }

  @Watch('homeIndex')
  private onRouteHomeIndexChanged() {
    this.selectedOption = this.currentHomeId();
  }

  private get selectedOption() {
    return this.currentHomeId();
  }

  private set selectedOption(newSelectOption: any) {
    this.$emit('updateHomeId', this.homes.findIndex((home) => home.id === newSelectOption));
  }

  private openAwayStatusDialog() {
    this.$emit('openAwayDialog');
  }

  private currentHomeId(): any {
    return this.homes[this.homeIndex].id;
  }

  private destroyed() {
    clearInterval(this.refreshInterval);
  }

  private get selectedHomeData() {
    return this.homes[this.homeIndex];
  }

  private getHomeTitle(home: any): string {
    return home.owner ? String(home.name) : `${home.name} (${String(this.$t('titles.guest')).toLowerCase()})`;
  }

  private selectedHomeIndexChanged(newIndex: number) {
    // A bit hacky, but unfortunately the event gets triggered when switching to a different route
    // so handling the event needs to be prevented in that case
    if (this.$route.path !== '/') {
      return;
    }
    const homeIndex = newIndex;
    this.$router.replace({
      path: '/',
      query: {homeIndex: String(homeIndex), tabIndex: '0'},
    });
  }

  private getHomeLabel(name: string, owner: boolean) {
    return owner ? name : name + ' (' + this.$t('GUEST') + ')';
  }

}
