import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})

export default class AddHome extends Vue {
  
}
