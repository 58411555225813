import { ISessionService } from '@/services/session.service';
import { IConfigService } from '@/services/config';

export class version {

  private frontendVersion = '';
  private gitHash = '';
  private rawFrontendVersion = '';

  public constructor(
    private sessionService: ISessionService,
    private configService: IConfigService,
  ) {
  }

  public async getVersion(): Promise<any> {
    this.frontendVersion = this.sessionService.getFrontendVersion();
    return Promise.resolve().then(() => {
      return [
        this.sessionService.getPlatform(),
        this.frontendVersion,
      ].join('-');
    });
  }
    
}
