import { Component, Prop, Vue } from 'vue-property-decorator';
import TitleText from '@/components/text/title/TitleText.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    TitleText,
    MainButton,
  },
})

export default class InstallInfo extends Vue {
  @Prop() private label!: string;
  @Prop() private message!: string;
  @Prop() private imageSrc!: string;
  @Prop() private smallImage!: boolean;
  @Prop() private buttonText!: string;
  @Prop() private progressBar!: boolean;
  @Prop() private installingNode!: boolean;
  @Prop() private disabledButton!: boolean;
  @Prop() private extraMessage!: string;

  private onClick(): any {
    this.$emit('click');
  }
}
