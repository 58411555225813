import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})

export default class WifiHtr extends Vue {
  
    private mounted() {
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifiHtr.setAPData', this.$route.params);
    }
  
}
