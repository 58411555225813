import { Vue, Component } from 'vue-property-decorator';
import WarningModal from '@/components/modalComponents/warningModal/WarningModal.vue';

@Component({
  components: {
    WarningModal,
  },
})
export default class serverConnectionErrorModal extends Vue {

  private close(): any {
    this.$emit('close');
  }

}
