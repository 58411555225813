import _ from '@/services/_';
import {
  NodeController,
} from '@vdi-helki/helki-node-management';

export class helkiNodeManagement {

  public isSettingAvailable(nodeController?: any, setting?: any): any {
    return _.findIndex(nodeController.getAvailableSettings(), (availableSetting: any) => {
      return availableSetting === setting;
    }) !== -1;
  }

  public isInMode(nodeController?: any, modeTemperature?: any): any {
    if (!nodeController.isReady()) {
      return false;
    }
    const currentTemperature = Number(nodeController.nodeData.status.stemp || 0);
    return nodeController.getMode() === 'auto' && currentTemperature === modeTemperature;
  }

  public isInModeArea(nodeController?: any, area?: any): any {
    if (!nodeController.isReady() || nodeController.getMode() === 'off') {
      return false;
    }
    return nodeController.getModeParams(nodeController.getMode()).area === area;
  }

  public isInComfortMode(nodeController?: any): any {
    if (!nodeController.isReady() || nodeController.getMode() === 'off') {
      return false;
    }
    return nodeController.getModeParams(nodeController.getMode()).profile === 'comfort' &&
            (nodeController.getMode() === 'auto' || nodeController.getMode() === 'comfort');
  }

  public isInEcoMode(nodeController?: any): any {
    if (!nodeController.isReady() || nodeController.getMode() === 'off') {
      return false;
    }
    return nodeController.getModeParams(nodeController.getMode()).profile === 'eco' &&
            (nodeController.getMode() === 'auto' || nodeController.getMode() === 'eco');
  }

  public isInAntiIceMode(nodeController?: any): any {
    if (!nodeController.isReady() || nodeController.getMode() === 'off') {
      return false;
    }
    return nodeController.getModeParams(nodeController.getMode()).profile === 'ice' &&
            (nodeController.getMode() === 'auto' || nodeController.getMode() === 'ice');
  }

  public isInEcoArea(nodeController?: any): any {
    return this.isInModeArea(nodeController, 'eco');
  }

  public isInAntiIceArea(nodeController?: any): any {
    return this.isInModeArea(nodeController, 'ice');
  }

  public isTRV(nodeController?: any): any {
    return (nodeController.product || '').slice(-2).toUpperCase() === '0F';
  }

  public isInHeatMode(nodeController?: any): any {
    return ((nodeController.nodeData || {}).setup || {}).type === 'heat';
  }

  public isNodeReady(nodeController?: NodeController): boolean {
    return nodeController?.isReady() || false;
  }

  public getTempSetup(nodeController?: any): any {
    const modeParams = nodeController.getModeParams();
    const tempLimits = nodeController.getTempLimits();
    const celsius = nodeController.getTempUnits() === 'C';
    return {
      min: celsius ? tempLimits.minCTemp : tempLimits.minFTemp,
      max: celsius ? tempLimits.maxCTemp : tempLimits.maxFTemp,
      units: modeParams.temperatureUnits,
      step: modeParams.temperatureStep
    };
  }

}
