import { render, staticRenderFns } from "./InfoHeader.vue?vue&type=template&id=55144166&scoped=true"
import script from "./InfoHeader.ts?vue&type=script&lang=js&external"
export * from "./InfoHeader.ts?vue&type=script&lang=js&external"
import style0 from "./InfoHeader.vue?vue&type=style&index=0&id=55144166&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55144166",
  null
  
)

export default component.exports