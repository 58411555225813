import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import WarningModal from '@/components/modalComponents/warningModal/WarningModal.vue';

@Component({
  components: {
    WarningModal,
  },
})
export default class confirmDeviceRemoval extends Vue {

  private homeId: any = {};
  private devId: any = {};

  private mounted() {
    this.homeId = this.$route.params.homeid;
    this.devId = this.$route.params.devid;
  }

  private close(data?: any): any {
    this.$emit('close', data);
  }

  private deleteDevice(): any {
    this.$devListService.deleteDevice(this.homeId, this.devId).then(() => {
      this.$amplitudeService.sendEvent('edit_device_delete_success');
      routerWrapper.push(this.$router, 'root.authenticated.root');
      this.close();
    });
  }

}
