import { Component, Vue, Watch } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import ConfirmMaxPowerProfileRemovalModal from '@/components/modals/confirmMaxPowerProfileRemoval/confirmMaxPowerProfileRemoval.vue';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import {
  NodeType,
} from '@vdi-helki/helki-node-management';
import { TimezoneConfig } from '@/services/config';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    ConfirmMaxPowerProfileRemovalModal,
    MainButton,
    TitleText,
  },
})
export default class Data extends Vue {
    private showPowerLimitConfig: any = {};
    private homeId: any = {};
    private devId: any = {};
    private isOwner: any = {};
    private supportedTimezones: TimezoneConfig[] = [];
    private deviceId: any = {};
    private maxPowerProfiles: any = {};
    private maxPowerSlots: any = [];
    private maxPowerProfileIndex: any = {};
    private showConfirmMaxPowerProfileRemovalModal = false;
    private geoData: any = {};
    private name: any = {};
    private showConfirmMaxPowerProfileRemoval = false;

    private async mounted() {

      this.showPowerLimitConfig = (await this.$configService.getConfig()).multiplePowerLimitFlag;
      this.homeId = this.$route.params.homeid;
      this.devId = this.$route.params.devid;
      this.isOwner = this.$devListService.isOwner(this.homeId);
      this.supportedTimezones = (await this.$configService.getConfig()).clientData.supportedTimezones;
      this.deviceId = this.$devListService.getDevids(this.homeId)[0];

      rootScope.devId = undefined;
      this.$devListService.getExtraHomeData(this.homeId).then(async (extraHomeData: any) => {
        this.maxPowerProfiles = extraHomeData.maxPowerProfiles || [];
      });
      if (!this.homeId) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      return this.initData();
    }

    private get storedPowerSlots() {
      return this.$store.state.homes[this.homeId].devs[this.deviceId]
      .pmo_system.max_power_config.slots;
    }

    @Watch('storedPowerSlots')
    private onStoredPowerSlotsChanged() {
      this.maxPowerSlots = this.storedPowerSlots;
    }

    private async save(): Promise<any> {
      await this.$apiService.setHomeName(this.homeId, this.name);
      this.$store.commit('setHomeName', { homeId: this.homeId, name: this.name });
      await this.$apiService.setGeoData(this.homeId, this.geoData);
    }

    private confirmMaxPowerRemoval(index?: any): any {
      this.maxPowerProfileIndex = index;
      this.showConfirmMaxPowerProfileRemovalModal = true;
    }

    private get pmoList() {
      return this.$store.getters.getNodesByType(this.homeId, { nodeType: NodeType.PMO });
    }

    private onConfirmMaxPowerProfileRemovalModalClosed(confirmed: boolean): any {
      this.showConfirmMaxPowerProfileRemovalModal = false;
      if (confirmed) {
        this.removePower(this.maxPowerProfileIndex);
      }
    }

    private async removePower(index?: any): Promise<any> {
      this.onStoredPowerSlotsChanged();
      return Promise.resolve().then(async () => {
        this.maxPowerProfiles.splice(index, 1);
        const slotsToRemove: any = [];
        let indexOffset: number;
        this.maxPowerSlots.forEach((powerSlot: any, slotIndex: any) => {
          if (powerSlot.i > index) {
            powerSlot.i--;
          } else if (powerSlot.i === index) {
            slotsToRemove.push(slotIndex);
          }
        });
        slotsToRemove.forEach((slotIndex: any) => {
          if (slotIndex === slotsToRemove[0]) {
            indexOffset = 0;
          }
          if (this.maxPowerSlots[slotIndex - indexOffset].m % 1440 === 0) {
            if (this.maxPowerProfiles.length > 1) {
              if (slotIndex - indexOffset + 1 < this.maxPowerSlots.length) {
                this.maxPowerSlots[slotIndex - indexOffset].i = this.maxPowerSlots[slotIndex - indexOffset + 1].i;
              } else {
                this.maxPowerSlots[slotIndex - indexOffset].i = this.maxPowerSlots[slotIndex - indexOffset - 1].i;
              }
            } else {
              this.maxPowerSlots[slotIndex - indexOffset].i = 0;
            }
          } else {
            this.maxPowerSlots.splice(slotIndex - indexOffset, 1);
            indexOffset++;
          }
        });
        if (this.maxPowerProfiles.length === 0) {
          this.maxPowerSlots = [];
        }
        const updatedSlots = this.maxPowerSlots
        .reduce((newSlotData: any, currentSlot: any, index: number) => {
          if (currentSlot.m % 1440 === 0 || currentSlot.i !== this.maxPowerSlots[index - 1].i) {
            newSlotData.push(currentSlot);
          }
          return newSlotData;
        }, []);
        const maxPowerConfig: any = {
          profiles: _.map(this.maxPowerProfiles, (data: any) => {
            return data.value;
          }),
          slots: updatedSlots,
        };
        return Promise.all([
          this.$devListService.updateExtraData(this.homeId, 'maxPowerProfiles', this.maxPowerProfiles),
          this.$apiService.setPmoSystem(this.deviceId, { max_power_config: maxPowerConfig })
        ]);
      });
    }

    private async initData(): Promise<any> {
      this.geoData = await this.$devListService.getGeoData(this.homeId);
      this.name = this.getHomeLabel(this.$store.state.homes[this.homeId].name);
      this.onStoredPowerSlotsChanged();
    }

    private getHomeLabel(name: string) {
      return this.isOwner ? name : name + ' (' + this.$t('GUEST') + ')';
    }

}
