import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';
import HomeDataLabel from '@/components/homeDataLabel/HomeDataLabel.vue';
import DeviceDataLabel from '@/components/deviceDataLabel/DeviceDataLabel.vue';

@Component({
  components: {
    Slider,
    TitleText,
    HomeDataLabel,
    DeviceDataLabel,
  },
})
export default class Devices extends Vue {

  private devices: any = [];

  private mounted() {
    this.devices = this.$devListService.getDevList(this.homeId);

    if (!this.homeId) {
      return this.$router.replace({ name: 'root.authenticated.root.device.list.heating', query: {} });
    }
    this.devices.forEach((device: any) => {
      device.isWifiHeater = this.$devListService.getDevProductName(device.product_id.toUpperCase()) === 'wifiHtr';
      device.disconnected = false;
      device.isWifiSmartbox = this.$devListService.getDevProductName(device.product_id.toUpperCase()) === 'wifi';
    });
    this.updateAllDevsConnection();
    this.$userService.addListener(this.homeId, this.updateAllDevsConnection);
    (window as any).homeId = this.homeId;
    (window as any).homeName = this.$store.state.homes[this.homeId].name;
  }

  private destroyed() {
    this.$userService.removeListener(this.homeId, this.updateAllDevsConnection);
  }

  private get homeId(): string {
    return this.$route.params.homeid;
  }

  private updateDevConnection(index?: any): any {
    this.$devListService.checkDevConnection(this.homeId, this.devices[index].devid).then(async (connected: any) => {
      this.devices[index].disconnected = !connected;
    });
  }

  private updateAllDevsConnection(): any {
    for (let i: any = 0; i < this.devices.length; i++) {
      this.updateDevConnection(i);
    }
  }

  private getDeviceImage(device: any): any {
    if (device.isWifiSmartbox) {
      return 'wifi-smartbox';
    } else if (device.isWifiHeater) {
      return 'wifi-heater';
    } else {
      return 'ethernet-smartbox';
    }
  }

}
