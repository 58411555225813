import { Component, Vue, Prop } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    MainButton,
  },
})

export default class deviceRegistrationConflict extends Vue {
  @Prop() private devId!: any;

  private message: any = {};
  private translatedString: any = String(this.$t('DEVICE_ALREADY_TAKEN'));

  private mounted() {
    this.message = this.translatedString.replace('DEVID', this.devId);
  }

  private copyDevid(): any {
    const devidText: any = document.createElement('input');
    devidText.setAttribute('value', this.devId);
    document.body.appendChild(devidText);
    devidText.select();
    document.execCommand('copy');
    document.body.removeChild(devidText);
    this.$toast.success(this.$t('ID_COPIED'));
  }

  private close(): any {
    this.$emit('close');
  }
}
