import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import WarningButton from '@/components/button/warningButton/WarningButton.vue';

@Component({
  components: {
    Slider,
    WarningButton,
  },
})

export default class RemoveAccountHelp extends Vue {
  private clientName = '';
  private appName = '';

  private async mounted() {
    const config = await this.$configService.getConfig();
    this.clientName = config.clientData.title;
    this.appName = config.clientName;
  }

}
