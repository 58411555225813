import { Component, Vue } from 'vue-property-decorator';
import _ from '@/services/_';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
  },
})
export default class copyDay extends Vue {

  private days: any = [];

  private mounted() {
    this.days = [];
    this.initDays();
  }

  private copy(): any {
    (window as any).selectedDays = [];
    _.forEach(this.days, (day: any) => {
      if (day.checked) {
        (window as any).selectedDays.push(day.index);
      }
    });
    this.close();
  }

  private close(): any {
    this.$emit('close');
  }


  private initDays(): any {
    const WEEK_DAYS: any = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ];
    for (let i: any = 0; i < 7; i++) {
      this.days.push({
        name: WEEK_DAYS[i],
        checked: false,
        index: i
      });
    }
    this.$userService.getLanguage().then((currentLanguage: any) => {
      if (currentLanguage !== 'en') {
        const sunday: any = this.days.splice(0, 1)[0];
        this.days.push(sunday);
      }
    });
  }

}
