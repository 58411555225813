import { Component, Vue } from 'vue-property-decorator';
import Background1 from '@/img/weather_backgrounds/bg-helki-home-1.jpg';
import Background2 from '@/img/weather_backgrounds/bg-helki-home-2.jpg';
import Background3 from '@/img/weather_backgrounds/bg-helki-home-3.jpg';
import Background4 from '@/img/weather_backgrounds/bg-helki-home-4.jpg';
import Background5 from '@/img/weather_backgrounds/bg-helki-home-5.jpg';

@Component({
  components: {
    
  },
})

export default class Slider extends Vue {
//   @Prop() private images!: any[];
  private interval: any;
  private currentIndex = 0;
  
  private mounted() {    
    this.interval = setInterval(() => this.updateBackground(), 5000);
  }

  private destroyed() {
    clearInterval(this.interval);
  }

  private updateBackground() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
  }

  private get images(): any[] {
    return [
      Background1,
      Background2,
      Background3,
      Background4,
      Background5,
    ];
  }

  private get currentBackground() {
    return this.images[this.currentIndex];
    return ((this.images || [])[this.currentIndex] || {}).src;
  }
}
