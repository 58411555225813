import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})

export default class DeprecatedMessage extends Vue {
  private message = 'prueba';

  private mounted() {
    this.updateMessage();
  }

  private async updateMessage() {
    const translatedString = String(this.$t('DEPRECATION_MESSAGE'));
    const targetName = (await this.$configService.getConfig()).deprecationTargetName;
    this.message =  translatedString.replace('TARGET', targetName);
  }

  private async goToAndroidApp(): Promise<any> {
    this.$amplitudeService.sendEvent('add_device_web_warning_go_to_android');
    (window as any).location.href = (await this.$configService.getConfig()).androidAppLink;
  }

  private async goToiOSApp(): Promise<any> {
    this.$amplitudeService.sendEvent('add_device_web_warning_go_to_ios');
    (window as any).location.href = (await this.$configService.getConfig()).iosAppLink;
  }

}
