import { Component, Vue } from 'vue-property-decorator';
import { devWifiConfig } from '@/services/devWifiConfig';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import BPromise from 'bluebird';
import ServerConnectionErrorModal from '@/components/modals/serverConnectionError/serverConnectionError.vue';
import DeviceInstallationErrorStatesModal from '@/components/modals/deviceInstallationErrorStates/deviceInstallationErrorStates.vue';
import { DevProxy } from '@/services/DevProxy';

@Component({
  components: {
    Slider,
    ServerConnectionErrorModal,
    DeviceInstallationErrorStatesModal,
  },
})
export default class AccessPointProvisioning extends Vue {

    private pid: any = {};
    private wifiCredentials: any = {};
    private devData: any = {};
    private secureProvisioningEnabled: any = {};
    private onEditDevice: any = {};
    private wifiProvisioningDelay: any = {};
    private progressBar: any = {};
    private devType: any = {};
    private serverConnectionError = 'server_unreachable';
    private showServerConnectionErrorModal = false;
    private showDeviceInstallationErrorStatesModal = false;
    private provisioningStartDate!: Date;
    private failTimer: any = {};
    private deviceProxy?: DevProxy;

    private mounted() {
      this.pid = this.$devListService.getDevPID(this.$route.params.type);
      this.devType = this.$devListService.getDevProductName(this.pid).toUpperCase();
      this.wifiCredentials = this.$route.params.wifiCredentials;
      this.devData = this.$route.params.devData;
      this.secureProvisioningEnabled = this.$route.params.secureProvisioningEnabled;
      this.onEditDevice = this.$route.params.onEditDevice;
      this.wifiProvisioningDelay = 75000;
      this.progressBar;
      this.provisioningStartDate = new Date();

      setTimeout(() => {
        this.createProgressBar(this.wifiProvisioningDelay);
        this.applyWifiConfig();
      });
    }

    private createProgressBar(progressTimeout?: any): any {
      const item: any = (document as any).getElementById('progress-bar-ap-container');
      this.progressBar = new (window as any).ProgressBar.Circle(item, {
        color: '#54a7f7',
        strokeWidth: 4,
        trailWidth: 1,
        easing: 'linear',
        duration: progressTimeout,
        text: { autoStyleContainer: false },
        from: {
          color: '#F9B521',
          width: 1
        },
        to: {
          color: '#54a7f7',
          width: 4
        },
        step: (state: any, circle: any) => {
          circle.path.setAttribute('stroke', state.color);
          circle.path.setAttribute('stroke-width', state.width);
          const value: any = Math.round(circle.value() * 100);
          if (value === 0) {
            circle.setText('');
          } else {
            circle.setText(value + ' %');
          }
        }
      });
    }

    private async completeProgressBar(): Promise<any> {
      return new Promise((resolve: any) => {
        const progressBarCompletionDelay: any = 750;
        this.progressBar.stop();
        const currentProgress: any = this.progressBar.value();
        this.progressBar.destroy();
        this.createProgressBar(progressBarCompletionDelay);
        this.progressBar.set(currentProgress);
        this.progressBar.animate(1);
        setTimeout(() => {
          resolve();
        }, 1.25 * progressBarCompletionDelay);
      });
    }

    private getSetNameRoute(): any {
      let productName: string = this.$devListService.getDevProductName(this.pid);
      productName = productName === 'wifiHtr' ? 'wifi' : productName;
      return `root.authenticated.root.addDevice.${productName}.setName`;
    }


    private async assertBackendConnectivity() {
      let retries = 20;
      while (retries > 0 && !await this.$configService.connected()) {
        await BPromise.delay(1000);
        retries --;
      }
      if (retries === 0) {
        throw new Error(this.serverConnectionError);
      }
    }

    private async onSuccess(): Promise<any> {
      this.$amplitudeService.sendEvent('device_wifi_provisioning_ap_success');
      try {
        if (this.onEditDevice) {
          this.$amplitudeService.sendEvent('device_wifi_provisioning_success');
          await this.assertBackendConnectivity();
          await this.completeProgressBar();
          routerWrapper.push(this.$router, 'root.authenticated.root');
        } else {
          await this.completeProgressBar();
          routerWrapper.push(this.$router, this.getSetNameRoute(), {
            ...this.$route.params,
            devid: this.devData.devid,
            pid: this.pid,
            newHome: this.$route.params.newHome,
            type: this.$route.params.type
          });
        }
      } catch (error: any) {
        if (error.message === this.serverConnectionError) {
          this.progressBar.stop();
          this.showServerConnectionErrorModal = true;
        }
      }
    }

    private verifyDeviceConnection() {
      const connected = this.$store.state.homes[this.homeId].devs[this.devId].connected;
      if (connected) {
        clearTimeout(this.failTimer);
        this.$userService.removeListener(this.verifyDeviceConnection);
        this.onSuccess();
      }
    }

    private checkWifiDevConnection(waitTime: any) {
      this.failTimer = setTimeout(() => {
        this.$userService.removeListener(this.verifyDeviceConnection);
        this.showDeviceInstallationErrorStatesModal = true;
      }, waitTime || 400000);
      this.verifyDeviceConnection();
      this.$userService.addListener(this.verifyDeviceConnection);
    }

    private async applyWifiConfig(): Promise<any> {
      return Promise.resolve().then(async () => {
        this.progressBar.animate(1);
        return new devWifiConfig().setWifiNetwork({
          essid: this.wifiCredentials.essid,
          pass: this.secureProvisioningEnabled ? this.wifiCredentials.encryptedPass : this.wifiCredentials.pass
        });
      })
      .then(async() => {
        await this.assertBackendConnectivity();
        if (!this.onEditDevice) {
          return this.onSuccess();
        }
        this.deviceProxy = await this.$devListService.getDevProxy(this.homeId, this.devId);
        const elapsedTime = new Date().getTime() - this.provisioningStartDate.getTime();
        this.checkWifiDevConnection(this.wifiProvisioningDelay - elapsedTime);
      }).catch((error: any) =>  {
        if (error.message === this.serverConnectionError) {
          this.progressBar.stop();
          this.showDeviceInstallationErrorStatesModal = true;
        }
      });
    }

    private get devId(): any {
      return this.devData.devid;
    }

    private get homeId(): string {
      return this.$route.params.homeid;
    }

}
