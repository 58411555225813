import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavButton extends Vue {
  @Prop() private label!: string;
  @Prop() private icon!: string;
  @Prop() private disabled!: boolean;
  @Prop() private selected!: boolean;

  private iconElectricity() {
    return this.icon === 'icon-electricity';
  }

  private iconHeating() {
    return this.icon === 'icon-temp';
  }

  private iconWeather() {
    return this.icon === 'icon-weather';
  }

  private iconSmartbox() {
    return this.icon === 'icon-smartbox';
  }

  private iconData() {
    return this.icon === 'icon-data';
  }

  private iconPosition() {
    return this.icon === 'icon-position';
  }

  private iconInviteUsers() {
    return this.icon === 'icon-inviteUsers';
  }

}
