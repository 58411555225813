import { Component, Vue } from 'vue-property-decorator';
import _ from '@/services/_';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import { Doughnut } from 'vue-chartjs/legacy';
import { Chart, ArcElement } from 'chart.js'
Chart.register(ArcElement);

@Component({
  components: {
    Slider,
    Doughnut,
  },
})
export default class Usage extends Vue {
  private homeId: any = {};
  private devId: any = {};
  private mainCircuitPmos: any = {};
  private nodeSet: any = {};
  private power: any = {};
  private powerLimit: any = {};
  private doughnutData: any = {};
  private perc: any = {};
  private interval: any = {};
  private setup: any = {};
  private maxPowerLimit: any = {};
  private mainColor: string | undefined;

  private async mounted() {
    this.mainColor = (await this.$configService.getConfig()).mainColor;
    this.homeId = this.$route.params.homeid;
    this.devId = this.$route.params.devid;
    this.mainCircuitPmos = [];
    this.nodeSet = (window as any).nodeSet;
    this.power = '';
    this.powerLimit = '';
    this.doughnutData = {
      labels: [],
      datasets: [],
    };
    this.perc = 0;
    this.interval;

    if (!(window as any).nodeSet) {
      return this.$router.replace({ name: 'root.authenticated.root', query: {} });
    }
    if (!(window as any).connected || ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).lost) {
      return this.$router.replace({ name: 'root.authenticated.root.device.list.electricity', query: {} });
    }
    this.$devListService.getDevProxy(this.homeId, (window as any).devId).then(async (devProxy: any) => {
      this.mainCircuitPmos = devProxy.getMainCircuitPmos();
      return this.$devListService.getExtraHomeData(this.homeId);
    }).then(async (extraData: any) => {
      this.updateFromExtraData(extraData);
      this.updatePmoData();
    });
    this.updatePmoData();
  }

  private destroyed() {
    clearInterval(this.interval);
  }

  private getPowerLimitInKw(): any {
    if (isNaN(this.powerLimit)) {
      return '';
    }
    return Number(this.powerLimit) / 1000;
  }

  private async updatePmoData(): Promise<any> {
    const setup: any = (window as any).nodeSet?.nodeControllers[0].nodeData.setup;
    this.updatePower();
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.updatePower();
    }, 3000);
    this.setup = this.convertToControllerSetup(setup);
    this.powerLimit = (await this.$configService.getConfig()).multiplePowerLimitFlag && this.setup.circuit_type !== '0' || (await this.$configService.getConfig()).multiplePowerLimitFlag && this.setup.circuit_type === '0' && !this.isInMainCircuit() ? this.maxPowerLimit : this.getPowerLimit();
  }

  private getPowerLimit(): any {
    if (!(window as any).nodeSet?.nodeControllers[0].nodeData.power_limit || (window as any).nodeSet?.nodeControllers[0].nodeData.power_limit === '0' || (window as any).nodeSet?.nodeControllers[0].nodeData.power_limit.power_limit === '0') {
      return '5000';
    }
    return !_.isObject((window as any).nodeSet?.nodeControllers[0].nodeData.power_limit) ? (window as any).nodeSet?.nodeControllers[0].nodeData.power_limit : (window as any).nodeSet?.nodeControllers[0].nodeData.power_limit.power_limit;
  }

  private updatePower(): any {
    (window as any).nodeSet?.getPower().then(async (power: any) => {
      this.power = power;
      this.updatePerc();
      this.updateDoughnutData();
      this.$forceUpdate();
    });
  }

  private updatePerc(): any {
    if (!isNaN(this.powerLimit) && !isNaN(this.power) && Number(this.powerLimit) > 0) {
      this.perc = Math.abs((this.power / this.powerLimit * 100)).toFixed(0);
    } else {
      this.perc = '';
    }
  }

  private updateDoughnutData(): any {
    this.perc = this.perc ? Number(this.perc) : 0;
    const doughNutPerc: any = Math.min(100, this.perc);
    const empty = doughNutPerc ? 100 - doughNutPerc : 0;
    this.doughnutData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [doughNutPerc, empty],
          backgroundColor: [this.mainColor, '#fff'],
          borderColor: ['#fff', '#fff'],
          hoverBackgroundColor: [this.mainColor, '#fff'],
          hoverBorderColor: [this.mainColor, '#fff'],
        },
      ],
    };
  }

  private convertToControllerSetup(setup?: any): any {
    let result: any = {};
    result = { ...result };
    result.circuit_type = String(setup.circuit_type || 0);
    return result;
  }

  private updateFromExtraData(data?: any): any {
    this.maxPowerLimit = ((data.nodeData || {})[`${ this.$route.params.devid }${ this.$route.params.addr }`] || {}).max_power || '5000';
  }

  private isInMainCircuit(): any {
    return this.mainCircuitPmos.indexOf(this.$route.params.addr) !== -1;
  }

}
