import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    TitleText,
  },
})

export default class NotAllowed extends Vue {

    private mounted() {
      this.$amplitudeService.sendEvent('add_device_web_warning');
    }

    private async goToAndroidApp(): Promise<any> {
      this.$amplitudeService.sendEvent('add_device_web_warning_go_to_android');
      (window as any).location.href = (await this.$configService.getConfig()).androidAppLink;
    }

    private async goToiOSApp(): Promise<any> {
      this.$amplitudeService.sendEvent('add_device_web_warning_go_to_ios');
      (window as any).location.href = (await this.$configService.getConfig()).iosAppLink;
    }

}
