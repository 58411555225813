import { render, staticRenderFns } from "./confirmRemoveAccount.vue?vue&type=template&id=7e92ac3b"
import script from "./confirmRemoveAccount.ts?vue&type=script&lang=js&external"
export * from "./confirmRemoveAccount.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports