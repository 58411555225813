import storageInstance from 'store2';
import VueJwtDecode from 'vue-jwt-decode';
// @ts-ignore
import * as PackageData from '@/../package.json';

export interface SessionData {
  token: string;
  refreshToken: string;
  username: string;
}
export interface PrivacyResp {
  accepted: boolean;
  date: Date;
}
export interface AppInfo {
  gitHash: string;
  gitDate: string;
  dirtyBranch: boolean;
  apiEndpoint: string;
  serialId: string;
}
export interface ISessionService {
  setPersistance(persist: boolean): void;
  hasTokenExpired(): boolean;
  getSessionData(): SessionData | null;
  getUserId(): string;
  setPrivacy(privacyData: PrivacyResp): void;
  getLastPrivacyPolicyCheck(): Date | null;
  renewSession(data: SessionData): void;
  clearSession(): void;
  getPlatform(): 'ios'|'and'|'web';
  getAppId(): string;
  getHomeGeolocationPermission(homeId: string): boolean;
  setHomeGeolocationPermission(homeId: string, value: boolean): void;
  getApiEndpoint(): string;
  onDirtyBranch(): boolean;
  getFrontendVersion(): string;
  getSerialId(): string;
}

export class SessionService implements ISessionService {
  private sessionData: SessionData | null;
  private persist = true;
  private store: any;
  private appIdKey = 'app_id';
  private frontendVersionData: typeof PackageData;
  private minStorageVersion = 2;
  private storageVersionKey = 'storageVersionKey';

  constructor(vuexStore: any, private info: AppInfo) {
    this.frontendVersionData = (PackageData as any)?.version;
    this.sessionData = storageInstance.local.get('sessionData') || storageInstance.session.get('sessionData');
    this.store = vuexStore;
    this.store.commit('setPrivacy', storageInstance.local.get('privacyData'));
    this.storeSerialId();
  }

  public setPersistance(persist: boolean): void {
    this.persist = persist;
    if (!persist) {
      storageInstance.local.remove('sessionData');
      storageInstance.session.remove('sessionData');
    }
  }

  public renewSession(data: SessionData): void {
    this.sessionData = data;
    this.persist ? storageInstance.local.set('sessionData', data) : storageInstance.session.set('sessionData', data);
    this.store.commit('setSerialId', VueJwtDecode.decode(data.token).serialId);
  }

  public hasTokenExpired(): boolean {
    const timeToExpiry = this.getTimeToExpiry();
    // 10 minutes margin
    return ((timeToExpiry - 10 * 60 * 1000) < 0);
  }

  public getSessionData(): SessionData | null {
    return this.sessionData;
  }

  public getUserId(): string {
    try {
      const sessionData = this.getSessionData();
      const tokenPayload = this.getTokenPayload(sessionData?.token || '');
      return tokenPayload ? tokenPayload.userId : '';
    } catch (e) {
      return '';
    }
  }

  public getLastPrivacyPolicyCheck(): Date | null {
    return storageInstance.local.get('privacyData')?.date || null;
  }

  public setPrivacy(privacyData: PrivacyResp) {
    if (!privacyData) {
      return;
    }
    if (privacyData.accepted) {
      privacyData.date = new Date(privacyData.date);
    }
    this.store.commit('setPrivacy', privacyData);
    storageInstance.local.set('privacyData', privacyData);
  }

  public clearSession(): void {
    this.sessionData = null;
    storageInstance.clearAll();
  }

  public getSortFrontendVersion(): string {
    // This method is only available on frontends
    if (typeof window === 'undefined') {
      return '';
    }
    const version = `${this.frontendVersionData}-${this.info.gitHash.substring(0, 4)}`;
    return this.onDirtyBranch() ? `${version}-internal` : version;
  }

  public getFrontendVersion(): string {
    // This method is only available on frontends
    if (typeof window === 'undefined') {
      return '';
    }
    const version = `${this.frontendVersionData}-${this.info.gitHash.substring(0, 4)} (${this.info.gitDate})`;
    return this.onDirtyBranch() ? `${version} (Internal development version)` : version;
  }

  public onDirtyBranch(): boolean {
    return this.info.dirtyBranch;
  }

  public getApiEndpoint(): string {
    return this.info.apiEndpoint;
  }

  public getPlatform(): 'ios'|'and'|'web' {
    const w = window as any;
    if (!w.cordova) {
      return 'web';
    }
    if (w.cordova.platformId === 'android') {
      return 'and';
    }
    if (w.cordova.platformId === 'ios') {
      return 'ios';
    }
    return 'web';
  }

  public getAppId(): string {
    return storageInstance.local.get(this.appIdKey) || this.generateAppId();
  }

  public getHomeGeolocationPermission(homeId: string): boolean {
    const key = this.getHomeGeolocationKey(homeId);
    const value = storageInstance.local.get(key);
    return value || false;
  }

  public setHomeGeolocationPermission(homeId: string, value: boolean): void {
    const key = this.getHomeGeolocationKey(homeId);
    storageInstance.local.set(key, value);
  }

  public getSerialId(): string {
    try {
      return VueJwtDecode.decode(this.sessionData?.token).serialId;
    } catch (e) {
      return this.info.serialId || '';
    }
  }

  private getHomeGeolocationKey(homeId: string): string {
    return `gloc:${homeId}`;
  }

  private getRandom2ByteString(): string {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  private generateAppId(): string {
    let uuid = `${this.getPlatform()}_${this.getSortFrontendVersion()}_`;
    for (let i = 0; i < 8; i++) {
      uuid += this.getRandom2ByteString();
    }
    storageInstance.local.set(this.appIdKey, uuid);
    return uuid;
  }

  private getTimeToExpiry(): number {
    try {
      const sessionData = this.getSessionData();
      const tokenPayload = this.getTokenPayload(sessionData?.token || '');
      const expiryTime = tokenPayload ? tokenPayload.exp : 0;
      return expiryTime * 1000 - Date.now();
    } catch (e) {
      return 0;
    }
  }

  private getTokenPayload(token: string): any {
    try {
      return JSON.parse(window.atob(token.split('.')[1] .replaceAll('-', '+') .replaceAll('_', '/')));
    } catch (e) {
      return null;
    }
  }

  private storeSerialId() {
    const serialId = this.getSerialId();
    if (!serialId) {
      return;
    }
    this.store.commit('setSerialId', serialId);
  }
}
