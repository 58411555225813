import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import Slider from '@/components/slider/Slider.vue';
import ReinventedColorWheel from "reinvented-color-wheel";
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    CancelButton,
    MainButton,
  },
})
export default class maxPowerManagement extends Vue {

  private newProfileData: any = {};
  private newNameString: any = {};
  private selectedProfile: any = {};
  private maxPowerProfiles: any = {};
  private limitless: any = {};
  private updatingProfile: any = {};
  private currentProfileName: any = {};
  private currentProfileValue: any = {};
  private colorWheel: any = {};
  private powerLimitMinValue = 0;
  private powerLimitMaxValue = 0;

  @Prop() private profiles!: any[];

  private mounted() {
    this.newProfileData = this.getNewProfileData();
    this.newNameString = '';
    this.selectedProfile = '0';
    this.maxPowerProfiles = [...this.profiles];
    this.maxPowerProfiles.push(this.newProfileData);
    this.limitless = this.maxPowerProfiles[Number(this.selectedProfile)].value === -1;
    this.powerLimitMinValue = 0;
    this.powerLimitMaxValue = 23000;
    this.newNameString = this.$t('NEW_PROFILE');
    this.newProfileData = this.getNewProfileData();
    this.maxPowerProfiles = _.cloneDeep(this.profiles).concat(this.newProfileData);
    this.getUpdatedProfileData();
    setTimeout(() => {
      this.initColorWheel();
    });
  }

  private onProfileChanged(): any {
    this.limitless = this.maxPowerProfiles[Number(this.selectedProfile)].value === -1;
    this.updatingProfile = true;
    this.colorWheel.hex = this.maxPowerProfiles[Number(this.selectedProfile)].color;
    this.getUpdatedProfileData();
  }

  private confirm(): any {
    const profileData: any = this.maxPowerProfiles;
    profileData.pop();
    this.$emit('update', profileData);
    this.close(profileData);
  }

  private close(profileData: any): any {
    this.$emit('close', profileData);
  }

  private goToNewProfile(): any {
    this.maxPowerProfiles.push(this.maxPowerProfiles[this.maxPowerProfiles.length - 1]);
    this.maxPowerProfiles[this.maxPowerProfiles.length - 2].value = -1;
    this.selectedProfile = String(this.maxPowerProfiles.length - 2);
    this.onProfileChanged();
  }

  private showNewBtn(): any {
    return !this.onNewProfile();
  }


  private getRandomColor(): any {
    return '#' + (Math.random() * 16777215 << 0).toString(16).padStart(6, '0');
  }

  private getNewProfileData(): any {
    return {
      name: this.newNameString,
      color: this.getRandomColor()
    };
  }

  private getUpdatedProfileData(): any {
    if (!this.onNewProfile()) {
      this.currentProfileName = this.maxPowerProfiles[Number(this.selectedProfile)].name;
      this.currentProfileValue = this.limitless ? 0 : this.maxPowerProfiles[Number(this.selectedProfile)].value;
    } else {
      this.currentProfileName = undefined;
      this.currentProfileValue = undefined;
    }
  }

  private addNewProfile(name?: any, value?: any, color?: any): any {
    this.maxPowerProfiles[this.maxPowerProfiles.length - 1] = {
      name: name,
      color: color,
      value: value
    };
    this.maxPowerProfiles.push(this.getNewProfileData());
  }

  @Watch('currentProfileName')
  private handleDataChange(): any {
    if (this.currentProfileName === undefined) {
      return;
    }
    this.maxPowerProfiles[this.selectedProfile].name = this.currentProfileName;
    if (this.currentProfileValue === undefined && !this.limitless) {
      return;
    }
    if (this.limitless) {
      this.currentProfileValue = -1;
    }
    if (this.onNewProfile()) {
      this.addNewProfile(this.currentProfileName, this.currentProfileValue, this.colorWheel.hex);
    } else {
      this.maxPowerProfiles[this.selectedProfile].name = this.currentProfileName;
      this.maxPowerProfiles[this.selectedProfile].value = Number(this.currentProfileValue);
    }
  }

  @Watch('limitless')
  private handleLimitlessChange(): any {
    if (this.onNewProfile()) {
      this.handleDataChange();
      return;
    }
    const currentValue: any = this.maxPowerProfiles[Number(this.selectedProfile)].value;
    if (this.limitless) {
      this.maxPowerProfiles[Number(this.selectedProfile)].value = -1;
    } else if (currentValue === -1) {
      this.maxPowerProfiles[Number(this.selectedProfile)].value = 0;
    }
    this.getUpdatedProfileData();
  }

  @Watch('currentProfileValue')
  private handleProfileValueChange(): any {
    this.handleDataChange();
  }

  private onNewProfile(): any {
    return this.selectedProfile === null || Number(this.selectedProfile) === this.maxPowerProfiles.length - 1;
  }

  private initColorWheel(): any {
    this.colorWheel = new ReinventedColorWheel({
      appendTo: (document.getElementById('color-wheel-container') as HTMLElement),
      hex: this.maxPowerProfiles[this.selectedProfile].color,
      wheelDiameter: 200,
      wheelThickness: 20,
      handleDiameter: 16,
      wheelReflectsSaturation: false,
      onChange: (color: any) => {
        if (this.updatingProfile) {
          this.updatingProfile = false;
          return;
        }
        this.maxPowerProfiles[this.selectedProfile].color = color.hex;
      }
    });
  }

}
