import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    MainButton,
    InstallInfo,
    TitleText,
  },
})
export default class SetDevid extends Vue {

  private pid: any = {};
  private onEditDevice: any = {};
  private detectTimeout = false;
  private devId = '';
  private success = false;
  private showSetDevidData = false;

  private mounted() {
    this.pid = this.$devListService.getDevPID('ethernet');
    this.onEditDevice = this.$route.params.onEditDevice;
    this.detectTimeout = false;
    this.showSetDevidData = false;
    this.$amplitudeService.sendEvent('add_home_cable_start');
    this.discover();
  }

  private async discover(): Promise<void> {
    try {
      this.devId = await this.$devListService.connectToSmartboxDiscovery();
      this.setDevId();
    } catch {
      this.detectTimeout = true;
    }
  }

  private setDevId(): any {
    routerWrapper.push(this.$router, this.getSetNameRoute(), {
      devid: this.devId,
      type: 'ethernet',
      newHome: this.$route.params.newHome,
      pid: this.pid,
    });
  }

  private goToEnterDevid(): any {
    this.showSetDevidData = true;
  }

  private saveDevidData(): any {
    this.setDevId();
  }

  private getSetNameRoute(): any {
    const operation: any = this.onEditDevice ? 'editDevice' : 'addDevice';
    let productName = 'ethernet';
    if (this.$route.name?.includes('wifiHtr')) {
      productName = 'wifiHtr';
    } else if (this.$route.name?.includes('wifi')) {
      productName = 'wifi';
    }
    return 'root.authenticated.root.' + operation + '.' + productName + '.setName';
  }

  private getLabelInfo(): any {
    return this.success ? this.$t('DEVICE_ADDED_MSG') : this.$t('HOME_INFO');
  }

  private getMessageText(): any {
    return this.detectTimeout ? this.$t('DETECT_SMARTBOX_ERROR_MSG') : this.$t('DETECT_SMARTBOX_HELP_MSG');
  }

  private getButtonText(): any {
    return this.detectTimeout ? this.$t('CONTINUE') : undefined;
  }
}
