import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';
import HomeDataLabel from '@/components/homeDataLabel/HomeDataLabel.vue';

@Component({
  components: {
    Slider,
    TitleText,
    HomeDataLabel,
  },
})

export default class Homes extends Vue {

  private mounted() {
    this.initList();
  }

  private get homes() {
    return this.$store.getters.getHomeList();
  }

  private initList(): any {
    this.$devListService.getList();
  }

  private getHomeLabel(name: string, homeId: any) {
    return !this.$devListService.isOwner(homeId) ? (name + ' (' + this.$t('GUEST')) + ')' : name;
  }
}
