import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
  },
})

export default class inviteUser extends Vue {

  private email = '';

  private close(): any {
    this.$emit('close');
  }

  private async accept(): Promise<void> {
    this.$amplitudeService.sendEvent('add_guest_user');
    try {
      await this.$userService.inviteUser(
        this.$route.params.homeid,
        this.email,
      );
      this.$toast.success(this.$t('INVITE_USER_SUCCESS_MSG'));
      this.close();
    } catch {
      this.$toast.error(this.$t('INVITE_USER_ERROR_MSG'));
    }
  }

  private emailValid(): any {
    const EMAIL_REGEXP: any = /^.+@.+\..+$/;
    return EMAIL_REGEXP.test(this.email);
  }


}
