import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DialogButton extends Vue {
  @Prop() private label!: string;
  @Prop() private icon!: string;
  @Prop() private disabled!: boolean;
  @Prop() private rotate!: boolean;

  private iconTemp() {
    return this.icon === 'icon-temp';
  }

  private iconOff() {
    return this.icon === 'icon-off';
  }

  private iconLocked() {
    return this.icon === 'icon-locked';
  }

  private iconUnlocked() {
    return this.icon === 'icon-unlocked';
  }

  private iconSchedule() {
    return this.icon === 'icon-schedule';
  }

  private iconConfig() {
    return this.icon === 'icon-config';
  }

  private iconRecords() {
    return this.icon === 'icon-records';
  }

  private iconManual() {
    return this.icon === 'icon-manual';
  }

  private iconAuto() {
    return this.icon === 'icon-schedule';
  }

  private iconBoost() {
    return this.icon === 'icon-boost';
  }

  private iconSelfLearning() {
    return this.icon === 'icon-self-learning';
  }

  private iconPresence() {
    return this.icon === 'icon-presence';
  }

  private iconComfort() {
    return this.icon === 'icon-m-confort-r';
  }

  private iconEco() {
    return this.icon === 'icon-m-eco-r';
  }

  private iconIce() {
    return this.icon === 'icon-m-antih-r';
  }
}
