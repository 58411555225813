import { Component, Vue } from 'vue-property-decorator';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';

@Component({
  components: {
    Slider,
    MainButton,
    InstallInfo,
  },
})

export default class StartInfo extends Vue {

    private homeid: any = {};
    private homeName: any = {};
    private timezone: any = {};
    private newHome: any = {};
    private errMsg: any = {};

    private mounted() {
      this.homeid = this.$route.params.homeid;
      this.homeName = this.$route.params.homeName;
      this.timezone = this.$route.params.timezone;
      this.newHome = this.$route.params.newHome;
      this.errMsg = this.$route.params.errMsg;
      this.$amplitudeService.sendEvent('add_first_home_dev_start');
    }

    private goToDeviceTypeSelection(): any {
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.selectType', {
        homeid: this.homeid,
        homeName: this.homeName,
        timezone: this.timezone,
        newHome: this.newHome
      });
    }


}
