var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"toggable-setting",class:{
    'subtitle': _vm.subtitle
  },style:({
    'color': _vm.color,
    'font-size': _vm.fontSize,
  })},[_c('span',{class:{
      'disabled': _vm.disabled,
    }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.helpIcon),expression:"helpIcon"}],staticClass:"icon-help txt-l disabled click",on:{"click":_vm.openHelpButton}}),_c('span',[_c('v-ons-switch',{staticClass:"right",attrs:{"disabled":_vm.disabled},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),_c('span',{class:{
    'disabled': _vm.disabled,
  }},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.activeIcon && _vm.value),expression:"!!activeIcon && value"}],class:_vm.activeIconClass}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.inactiveIcon && !_vm.value),expression:"!!inactiveIcon && !value"}],class:_vm.inactiveIconClass})])])
}
var staticRenderFns = []

export { render, staticRenderFns }