import { Component, Vue } from 'vue-property-decorator';
import _ from '@/services/_';
import rootScope from '@/services/rootScope';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import {
  NodeSetting,
  NodeData,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
  },
})

export default class installPmoSetup extends Vue {

  private devId: any = {};
  private homeId: any = {};
  private mainCircuitPmos: any = {};
  private pmoConsumptionList: any = {};
  private pmoGenerationList: any = {};
  private setup: any = {};
  private mainCircuit: any = {};
  private nodeGroups: any = {};
  private pmo!: NodeData;

  private mounted() {
    this.devId = this.$route.params.devid;
    this.homeId = this.$route.params.homeid;
    this.mainCircuitPmos = [];
    this.pmoConsumptionList = [];
    this.pmoGenerationList = [];
    this.setup = {
      name: '',
      circuit_type: '0',
      reverse: false
    };

    this.$devListService.getDevProxy(this.homeId, this.devId).then((devProxy: any) => {
      this.pmo = devProxy.getNodeList().filter((nodeData: NodeData) => nodeData.type === 'pmo')[0];
      this.mainCircuitPmos = devProxy.getMainCircuitPmos();
      return this.updateList();
    }).then(() => {
      this.mainCircuit = this.checkMainCircuit();
    });
  }

  private async save(): Promise<any> {
    const nodeController: any = await this.$devListService.getNodeController(
      this.homeId,
      this.devId,
      'pmo',
      this.pmo.addr,
    );
    const updatedSettings: any = Object.keys(this.setup).reduce((updatedSetup, currentSetting) => {
      let value: any = this.setup[currentSetting];
      if (currentSetting === NodeSetting.CIRCUIT_TYPE) {
        value = Number(value);
      }
      updatedSetup.set(currentSetting, { value });
      return updatedSetup;
    }, new Map());
    await nodeController.updateSettings(updatedSettings);
    await this.updateMainCircuit();
    this.$emit('close');
    this.$router.push({ name: 'root.authenticated.root.device.list.electricity' });
  }


  private nodeChecked(addr?: any, devId?: any, nodeSelectedList?: any): any {
    return !!_.find(nodeSelectedList, (node: any) => {
      return (node.nodeData || {}).devid === devId && (node.nodeData || {}).addr === addr;
    });
  }

  private updateList(): any {
    return this.$devListService.getDevClassifiedNodeList(this.homeId).then((classifiedNodeList: any) => {
      this.nodeGroups = classifiedNodeList || [];
      this.nodeGroups.forEach((nodeGroup: any) => {
        nodeGroup.pmoList = _.filter(nodeGroup.nodeList, (node: any) => {
          if (this.nodeChecked((node.nodeData || {}).addr, (node.nodeData || {}).devid, rootScope.pmoSelectedList)) {
            node.checked = true;
          }
          return (node.nodeData || {}).type === 'pmo';
        });
      });
      this.updatePmoLists();
    });
  }

  private updatePmoLists(): any {
    _.each(this.nodeGroups, (nodeGroup: any) => {
      _.each(nodeGroup.pmoList, (pmoListElement: any) => {
        if (pmoListElement.getSettingParams(NodeSetting.CIRCUIT_TYPE).value === 0) {
          this.pmoConsumptionList.push(pmoListElement);
        } else {
          this.pmoGenerationList.push(pmoListElement);
        }
      });
    });
  }

  private checkMainCircuit(): any {
    const list: any = this.setup.circuit_type === '0' ? this.pmoConsumptionList : this.pmoGenerationList;
    return _.findIndex(list, (powerMeter: any) => {
      return this.mainCircuitPmos.indexOf((powerMeter.nodeData || {}).addr) !== -1;
    }) === -1;
  }

  private addPmoToMainCircuit(): any {
    if (this.setup.circuit_type === '1') {
      this.removePreviousGenerationPmoFromMainCircuit();
    } else {
      this.removePreviousConsumptionPmoFromMainCircuit();
    }
    this.mainCircuitPmos.push(Number(this.pmo.addr));
    this.mainCircuitPmos = Array.from(new Set(this.mainCircuitPmos));
  }

  private removePreviousConsumptionPmoFromMainCircuit(): any {
    this.pmoConsumptionList.forEach((consumptionPmo: any) => {
      const index: any = this.mainCircuitPmos.indexOf((consumptionPmo.nodeData || {}).addr);
      if (index !== -1) {
        this.mainCircuitPmos.splice(index, 1);
      }
    });
  }

  private removePreviousGenerationPmoFromMainCircuit(): any {
    this.pmoGenerationList.forEach((generationPmo: any) => {
      const index: any = this.mainCircuitPmos.indexOf((generationPmo.nodeData || {}).addr);
      if (index !== -1) {
        this.mainCircuitPmos.splice(index, 1);
      }
    });
  }

  private updateMainCircuit(): any {
    if (this.mainCircuit) {
      this.addPmoToMainCircuit();
    }
    return this.$apiService.setPmoSystem(this.devId, { main_circuit_pmos: this.mainCircuitPmos });
  }

}
