var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a',{staticClass:"snav-m-a b-bottom alink node-label-container",on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.prevIcon)?_c('span',{staticClass:"node-image",class:{'icon-plus': _vm.prevIconPlus(),
                                   'icon-thm': _vm.prevIconThm(),
                                   'icon-trv': _vm.prevIconTrv(),
                                   'icon-htr': _vm.prevIconHtr(),
                                   'icon-heater-w-smartbox': _vm.prevIconHtrWithSmartbox(),
                                   'icon-htr-geolocation': _vm.prevIconHtrGeolocation(),
                                   'icon-towelRail': _vm.prevIconTowelRail(),
                                   'icon-acm': _vm.prevIconAcm(),
                                   'icon-storageheater-lhz': _vm.prevIconAcmLhz(),
                                   'icon-wifihtr-w4buttons': _vm.prevIconWifiHtr4Buttons(),
                                   'icon-wifihtr-w2buttons': _vm.prevIconWifiHtr2Buttons(),
                                   'icon-pmo': _vm.prevIconPmo(),
                                   'icon-pmo-lhz': _vm.prevIconPmoLhz(),
                                   }}):_vm._e(),_c('span',{staticClass:"displayb txt-c uppc txt-s node-label"},[_vm._v(_vm._s(_vm.label))]),(_vm.postIcon)?_c('span',{staticClass:"txt-l m-l-right m-xs-top",class:{'icon-plus': _vm.postIconPlus()}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }