import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {

  },
})
export default class NodeSelector extends Vue {
  @Prop() private nodes!: any[];
  @Prop() private homeId!: string;
  @Prop() private nodeSelectorIndex!: number;
  private refreshInterval: any;

  private mounted() {
    this.refreshInterval = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  }

  private destroyed() {
    clearInterval(this.refreshInterval);
  }

  private get selectedOption() {
    return this.currentNodeId();
  }

  private set selectedOption(newSelectOption: any) {
    const nodeIndex = this.nodes.findIndex((node) => {
      const nodeValue = `${node.devId}.${node.nodeData.addr}`;
      return nodeValue === newSelectOption;
    });
    this.$emit('updateNodeIndex', nodeIndex);
  }

  private currentNodeId(): any {
    return String(this.nodes[this.nodeSelectorIndex].devId + '.' + this.nodes[this.nodeSelectorIndex].nodeData.addr) ||
           String(this.nodes[0].devId + '.' + this.nodes[0].nodeData.addr) || undefined;
  }

  private get homeName() {
    const home = this.$store.state.homes[this.homeId];
    return home.owner ? home.name : `${home.name} (${String(this.$t('titles.guest')).toLowerCase()})`;
  }

  private get away(): boolean {
    return this.$store.state.homes[this.homeId]?.away_status?.away === true;
  }
}
