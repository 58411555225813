import { Component, Vue, Prop } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import { dateTimeHelpers } from '@/services/dateTimeHelpers';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import {
  NodeMode,
  NodeControllerSet,
  NodeSetting,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
  },
})
export default class setBoost extends Vue {

    @Prop() private title!: string;
    @Prop() private timeLabel!: string;
    @Prop() private targetMode!: NodeMode;
    private currentDate: any = {};
    private endMin: any = {};
    private endHour: any = {};
    private boostEndTimeModified: any = {};
    private temperatureList: any = {};
    private tempSetup: any = {};
    private temperatureListLength: any = {};
    private sliderPosition: any = {};
    private slickSettings: any = {};
    private slick: any = {};
    private nodeSet!: NodeControllerSet;
    private showSlick = false;

    private mounted() {
      this.nodeSet = (window as any).nodeSet;
      this.currentDate = new Date();
      this.endMin = this.currentDate.getMinutes();
      this.endHour = this.currentDate.getHours() + 1;
      this.initTime();
      this.initTemperatureSlider();
      this.initSliderPosition();
      this.initBoostEndMinUpdateTimer((60 - new Date().getSeconds()) * 1000);
    }

    private getMaxBoostTime() {
      let max = 24;
      if (this.nodeSet.getAvailableSettings().includes(NodeSetting.MAX_RUNBACK_TIME)) {
        max = Number(this.nodeSet.getFirstNodeSettingParams(NodeSetting.MAX_RUNBACK_TIME).value) / 60;
      }
      return max;
    }

    private hourUpDisabled() {
      const endHour = this.endHour % 24;
      return (endHour - new Date().getHours()) >= this.getMaxBoostTime();
    }

    private hourDownDisabled() {
      if (!(this.nodeSet.getAvailableModes() as NodeMode[]).includes(NodeMode.RUNBACK)) {
        return false;
      }
      const endTimeString = this.endHour + ':' + this.endMin;
      const endTime = new dateTimeHelpers().timeStringToDayMinute(endTimeString);
      const minOffset = Math.trunc((endTime - new Date().getMinutes() - new Date().getHours() * 60) / 10) * 10;
      return minOffset <= 60;
    }

    private tempDown(): any {
      const currentPos: any = this.sliderPosition;
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value - this.tempSetup.step;
      if (currentPos > 0 && this.temperatureList[currentPos].value < this.temperatureList[currentPos].initialValue) {
        this.temperatureList[currentPos - 1].value = this.temperatureList[currentPos].value;
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos - 1);
      } else if (this.temperatureList[currentPos].value < this.temperatureList[currentPos].min) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
      }
    }

    private tempUp(): any {
      const currentPos: any = this.sliderPosition;
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value + this.tempSetup.step;
      if (this.temperatureList[currentPos].value.toFixed(1) === this.temperatureList[currentPos].max.toFixed(1)) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos + 1);
      } else if (this.temperatureList[currentPos].value > this.temperatureList[currentPos].max) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
      }
    }

    private hourUp(): any {
      this.increaseEndHour();
      this.boostEndTimeModified = true;
    }

    private hourDown(): any {
      this.endHour = (this.endHour + 23) % 24;
      this.boostEndTimeModified = true;
    }

    private confirm(): any {
      const currentPos: any = this.sliderPosition;
      const selectedTemp: any = this.temperatureList[currentPos].value;
      const endTimeString: any = this.endHour + ':' + this.endMin;
      const endTime: any = new dateTimeHelpers().timeStringToDayMinute(endTimeString);
      let minOffset: any = Math.trunc((endTime - new Date().getMinutes() - new Date().getHours() * 60) / 10) * 10;
      while (minOffset < 0) {
        minOffset += 24 * 60;
      }
      rootScope.boostConfig = {
        temp: selectedTemp,
        time: minOffset
      };
      this.$emit('close');
    }

    private close(): any {
      rootScope.boostConfig = undefined;
      this.$emit('close');
    }


    private async initTime(): Promise<any> {
      const deviceTime: any = await this.$apiService.getTime((this.nodeSet as any).nodeControllers[0].devId);
      this.endMin = deviceTime.m;
      this.endHour = deviceTime.h + 1;
    }

    private getTempSetup(): any {
      const modeParams: any = this.nodeSet.getFirstNodeModeDefaultParams(this.targetMode);
      return {
        min: this.nodeSet.getMinBoostConfigTemperature(),
        max: this.nodeSet.getMaxTemperature((this.nodeSet as any).nodeControllers[0]?.nodeData),
        units: modeParams.temperatureUnits,
        step: modeParams.temperatureStep,
        temp: modeParams.temperature,
      };
    }

    private updateBoostEndMin(): any {
      this.endMin = this.endMin + 1;
      if (this.endMin === 60) {
        this.increaseEndHour();
        this.endMin = 0;
      }
    }

    private initBoostEndMinUpdateTimer(initialDelay?: any): any {
      setTimeout(() => {
        this.updateBoostEndMin();
        setInterval(() => {
          this.updateBoostEndMin();
        }, 60000);
      }, initialDelay);
    }

    private initTemperatureSlider(): any {
      this.temperatureList = [];
      this.tempSetup = this.getTempSetup();
      this.temperatureListLength = Math.ceil(this.tempSetup.max - this.tempSetup.min + 1);
      const minShownValue: any = Math.trunc(this.tempSetup.min);
      for (let i: any = 0; i < this.temperatureListLength; i++) {
        this.temperatureList.push({
          value: minShownValue + i,
          initialValue: minShownValue + i,
          min: i === 0 ? minShownValue : minShownValue + i - 1,
          max: i === this.temperatureListLength - 1 ? minShownValue + i : minShownValue + i + 1
        });
      }
    }

    private getSliderPosition(temp?: any): any {
      for (let i: any = 0; i < this.temperatureListLength - 1; i++) {
        if (temp < this.temperatureList[i + 1].initialValue) {
          return i;
        }
      }
      return this.temperatureListLength - 1;
    }

    private updateSliderPosition(): any {
      const sTemp: any = String(this.tempSetup.temp);
      const pos: any = this.getSliderPosition(parseInt(sTemp, 10));
      this.temperatureList[pos].value = Number(sTemp);
      this.slick.slickGoTo(pos);
      this.sliderPosition = pos;
    }

    private initSliderPosition(): any {
      const sTemp: any = (((this.nodeSet as any).nodeControllers[0].nodeData || {}).status || {}).stemp || '20.0';
      const pos: any = this.getSliderPosition(parseInt(sTemp, 10));
      this.temperatureList[pos].value = Number(sTemp);
      this.sliderPosition = pos;
      this.slickSettings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        swipeToSlide: true,
        method: {},
        initialSlide: this.sliderPosition,
        event: {
          beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
            this.temperatureList[oldPos].value = this.temperatureList[oldPos].initialValue;
            this.sliderPosition = newPos;
          },
          init: (event: any, slick: any) => {
            this.slick = slick;
            this.updateSliderPosition();
          }
        }
      };
      this.showSlick = true;
    }

    private increaseEndHour(): any {
      this.endHour = (this.endHour + 1) % 24;
    }

}
