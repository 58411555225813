import _ from 'lodash';
import window from '@/services/window';

export class sampleTools {

    public download(filename?: any, text?: any): any {
      if (window.cordova !== undefined) {
        window.plugins.socialsharing.share(null, filename, 'data:text/csv;base64,' + btoa(text), null);
      }
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + '\uFEFF' + encodeURIComponent(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    
    public downloadCSV(datasets?: any, options?: any, fileScope?: any, title?: any, ticks?: any): any {
      let csvString = '';
      csvString += ',';
      const labels = datasets.map((dataset: any) => {
        return dataset.label;
      });
      csvString += labels.join(',') + '\n';
      ticks.forEach((label: string, index: any) => {
        const dataAvailable = Boolean(datasets.find((dataset: any) => {
          return dataset.data[index] !== null && dataset.data[index] !== undefined;
        }));
        if (!dataAvailable) {
          return;
        }
        csvString += label;
        datasets.forEach((dataset: any) => {
          let data = '';
          if (dataset.data[index].y !== null && !_.isNaN(dataset.data[index].y)) {
            data = dataset.data[index].y.toFixed(1);
          }
          csvString += ',' + data;
        });
        csvString += '\n';
      });
      const filename = title.concat(' ').concat(fileScope).replace(/[\W]+/g, '_').concat('.csv');
      return this.download(filename, csvString);
    }
    
}
