import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import { dateTimeHelpers } from '@/services/dateTimeHelpers';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import EditSlot from '@/components/editSlot/EditSlot.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
    EditSlot,
  },
})
export default class maxPowerScheduleEditSlot extends Vue {

  private startTime: any = {};
  private endTime: any = {};
  private selectedProfile: any = {};
  private endTimeString = '';
  private startTimeString = '';

  private mounted() {
    this.startTime = rootScope.editSlot.from;
    this.endTime = rootScope.editSlot.to || 1;
    this.startTimeString = new dateTimeHelpers().dayMinuteToTimeString(this.startTime);
    this.endTimeString = new dateTimeHelpers().dayMinuteToTimeString(this.endTime);
    if (this.endTimeString === '24:00') {
      this.endTimeString = '00:00';
    }
    this.selectedProfile = String((rootScope.editSlot || {}).i || 0);
  }

  private get homeId() {
    return this.$route.params.homeid;
  }

  private get maxPowerProfiles() {
    return this.$store.state.homes[this.homeId].extraData.maxPowerProfiles;
  }

  private startTimeStringChange(data: any): any {
    this.startTimeString = data;
    if (this.endTimeString === '00:00') {
      return;
    }
    const startTime: any = new dateTimeHelpers().timeStringToDayMinute(this.startTimeString);
    const endTime: any = new dateTimeHelpers().timeStringToDayMinute(this.endTimeString);
    if (endTime < startTime) {
      this.endTimeString = new dateTimeHelpers().dayMinuteToTimeString(startTime + 60);
    }
  }

  private endTimeStringChange(data: any): any {
    this.endTimeString = data;
  }

  private confirm() {
    const startTimeString = this.startTimeString;
    const endTimeString = this.endTimeString;
    const startMinute = this.startTimeString ? new dateTimeHelpers().timeStringToDayMinute(startTimeString) : rootScope.editSlot.from;
    const endMinute = this.endTimeString ? new dateTimeHelpers().timeStringToDayMinute(endTimeString) : rootScope.editSlot.to;
    (window as any).editedSlot = {
      i: Number(this.selectedProfile),
      from: startMinute,
      to: endMinute,
      duration: endMinute - startMinute,
    };
    this.$emit('close');
  }

  private close(): any {
    rootScope.editedSlot = null;
    this.$emit('close');
  }


}
