import { Component, Vue, Watch } from 'vue-property-decorator';
import { GeoData, GeoDataPosition } from '@/services/api.service';
import ToggableSetting from '@/components/toggableSetting/ToggableSetting.vue';
import RangeSetting from '@/components/rangeSetting/RangeSetting.vue';
import IconHomeImg from '@/assets/ico-map-home.png';
import { GeoPosition } from '@/services/geolocation';
import GeolocationHelpModal from '@/components/modals/geolocationHelp/geolocationHelp.vue';
import GeolocationRationaleModal from '@/components/modals/geolocationRationale/geolocationRationale.vue';
import TitleText from '@/components/text/title/TitleText.vue';
import _ from 'lodash';
const DEFAULT_ZOOM = 15;
const DEFAULT_POS = {
  lat: 43.53,
  lng: -5.66,
};

@Component({
  components: {
    ToggableSetting,
    RangeSetting,
    GeolocationHelpModal,
    GeolocationRationaleModal,
    TitleText,
  },
})
export default class GeolocationManagement extends Vue {
  private toastShowTime = 2000;
  private active = false;
  private range = 0;
  private dataInitialized = false;
  private zoom = DEFAULT_ZOOM;
  private center = DEFAULT_POS;
  private geolocationEnabled = false;
  private setPosMarkerPos = DEFAULT_POS;
  private setPosMarkerVisible = false;
  private setPosMarkerWindowOpen = true;
  private homePos = DEFAULT_POS;
  private homeMarkerVisible = false;
  private debouncedPositionUpdate: any;
  private showGeolocationRationaleModal = false;
  private showGeolocationHelpModal = false;
  private mainColor = '333';

  private async mounted() {
    this.debouncedPositionUpdate = _.debounce(this.savePositionAndRange, 500);
    this.initData();
    this.mainColor = (await this.$configService.getConfig()).mainColor;
  }

  private get homeInfo(): string {
    return `${this.homeId}|${this.serialid}`;
  }

  private get name(): any {
    return this.$store.state.homes[this.homeId]?.name;
  }

  private get homeId(): string {
    return this.$route.params.homeid;
  }

  private get serialid(): string {
    const storeSerialId = this.$store.state.serialid;
    return storeSerialId ? storeSerialId.replace(/\./g, '') : '';
  }

  private get homeRange(): number|undefined {
    const homeGeoData = this.$store.state.homes[this.homeId]?.geo_data || {};
    return homeGeoData.outdoor_range;
  }

  private get homePosition(): GeoDataPosition|undefined {
    return this.$store.state.homes[this.homeId]?.geo_data?.position;
  }

  @Watch('homePosition')
  @Watch('homeRange')
  private onHomeGeoDataChanged() {
    this.initData();
  }

  private get isMobile(): boolean {
    const w = window as any;
    return Boolean(w.cordova);
  }

  private get iconHome(): string {
    return IconHomeImg;
  }

  @Watch('active')
  private onActiveChanged() {
    if (!this.dataInitialized) {
      // avoids doing anything when active state initialized
      return;
    }
    if (this.active) {
      return this.enableGeolocation();
    }
    return this.disableGeolocation();
  }

  @Watch('range')
  private onRangeChanged() {
    if (!this.dataInitialized || !this.homePosition) {
      return;
    }
    this.debouncedPositionUpdate();
  }

  private async initPermissions() {
    if (!this.isMobile) {
      this.geolocationEnabled = true;
      return;
    }

    return this.$geolocationService.hasPermission()
    .then((enabled: boolean) => {
      this.geolocationEnabled = enabled && this.$sessionService.getHomeGeolocationPermission(this.homeId);
    })
    .catch(() => {
      this.geolocationEnabled = false;
    });
  }

  private async enableGeolocation(): Promise<void> {
    this.$amplitudeService.sendEvent('geolocation_enabled');
    return this.$geolocationService.requestPermission()
    .then(() => {
      if (!this.homePosition) {
        this.centerMap();
      }
      this.$sessionService.setHomeGeolocationPermission(this.homeId, true);
      return this.$geolocationService.restartTracking();
    })
    .catch(() => {
      // Geolocation not granted
    });
  }

  private async disableGeolocation(): Promise<void> {
    this.$amplitudeService.sendEvent('geolocation_disabled');
    this.$sessionService.setHomeGeolocationPermission(this.homeId, false);
    return this.$geolocationService.restartTracking();
  }

  private async initData() {
    this.range = this.homeRange || 500;
    this.setDefaultPos();
    this.initPermissions()
    .then(() => {
      this.active = this.geolocationEnabled;
      return this.initPos();
    })
    .finally(() => {
      this.setPosMarkerVisible = !this.homePosition;
      this.dataInitialized = true;
    });
  }

  private async initPos() {
    return Promise.resolve()
    .then(() => {
      if (this.homePosition) {
        this.zoom = this.getZoomLevel();
        this.center = { lat: this.homePosition.latitude, lng: this.homePosition.longitude };
        this.homePos = { ...this.center };
        this.setPosMarkerPos = this.homePos;
        this.setPosMarkerVisible = false;
        this.homeMarkerVisible = true;
      } else {
        return this.setDefaultPos();
      }
    })
    .catch((e) => {
      if (e.constructor.name !== 'PositionError') {
        return;
      }
      // Could not get position from server or location service. Default one
      this.setDefaultPos();
    });
  }

  private getZoomLevel(): number {
    if (!this.homePosition || !this.homeRange || this.homeRange < 300) {
      return DEFAULT_ZOOM;
    } else if (this.homeRange < 600) {
      return 15;
    } else {
      return 14;
    }
  }

  private async centerMap() {
    this.$forceUpdate();
    return this.$geolocationService.getCurrentPosition()
    .then((position: GeoPosition) => {
      this.zoom = DEFAULT_ZOOM;
      this.center = DEFAULT_POS;
      setTimeout(() => {
        this.center = { lat: position.latitude, lng: position.longitude };
        this.setPosMarkerPos = { ...this.center  };
        this.setPosMarkerVisible = true;
      }, 100);
    });
  }

  private setDefaultPos() {
    this.center = DEFAULT_POS;
    this.setPosMarkerPos = DEFAULT_POS;
    this.zoom = DEFAULT_ZOOM;
  }

  private savePositionAndRange() {
    const geoData: GeoData = {
      position: {
        latitude: this.setPosMarkerPos.lat,
        longitude: this.setPosMarkerPos.lng,
      },
      outdoor_range: this.range,
    };
    this.$amplitudeService.sendEvent('update_geolocation_position');
    return this.$apiService.setGeoData(this.homeId, geoData)
    .then(() => {
      this.homePos = { ...this.setPosMarkerPos };
      this.setPosMarkerVisible = false;
      this.homeMarkerVisible = true;
    })
    .catch(() => this.$store.commit('setError', this.$store.state.errors.userRequest));
  }

  private setPosMarkerPositionChanged(event: any) {
    this.setPosMarkerPos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
  }

  private closeSetPosMarkerWindow() {
    this.setPosMarkerWindowOpen = false;
  }

  private openSetPosMarkerWindow() {
    this.setPosMarkerWindowOpen = true;
  }

  private setPosMarker(event: any) {
    this.setPosMarkerPos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    this.setPosMarkerVisible = true;
  }

  private get primaryColor(): string {
    return this.$store.state.brandingData?.css?.cprimary;
  }

  private openHelp() {
    this.showGeolocationHelpModal = true;
  }
}
