import { render, staticRenderFns } from "./MainButton.vue?vue&type=template&id=77756bb4&scoped=true"
import script from "./MainButton.ts?vue&type=script&lang=js&external"
export * from "./MainButton.ts?vue&type=script&lang=js&external"
import style0 from "./MainButton.vue?vue&type=style&index=0&id=77756bb4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77756bb4",
  null
  
)

export default component.exports