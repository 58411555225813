import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import WarningButton from '@/components/button/warningButton/WarningButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    CancelButton,
    WarningButton,
  },
})
export default class confirmRemoveAccount extends Vue {

  private showPassword: any = {};
  private password = '';

  private mounted() {
    this.showPassword = false;
  }

  private close(): any {
    this.$emit('close');
  }

  private togglePasswordVisibility(): any {
    this.showPassword = !this.showPassword;
  }

  private deleteAccount(): any {
    return this.$userService.deleteUserAccount(this.password)
    .then(() => {
      this.$router.replace({ name: 'root.anonymous.signIn' });
      this.showSuccessMsg();
      this.close();
    }).catch(() => {
      this.showErrorMsg();
    });
  }


  private showErrorMsg(): any {
    this.$toast.error(this.$t('ACCOUNT_REMOVAL_ERROR'));
  }

  private showSuccessMsg(): any {
    this.$toast.success(this.$t('ACCOUNT_REMOVAL_SUCCESS'));
  }

}
