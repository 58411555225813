import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})
export default class SignIn extends Vue {

    private credentials: any = {};
    private remember = false;
    private showPassword = false;
    private emailFocused = false;
    private credentialsError = false;
    private inputErrors = false;

    private mounted() {
      this.credentials = {};
      this.remember = !!(window as any).cordova;
      this.showPassword = false;
      this.emailFocused = false;
      this.$devListService.reset();
      this.$amplitudeService.sendEvent('signin_start');
    }

    private togglePasswordVisibility(): any {
      this.showPassword = !this.showPassword;
    }

    private signIn(): any {
      this.resetErrors();
      this.$userService.signIn(
        this.credentials.email,
        this.credentials.password,
        this.remember,
      ).then(async () => {
        routerWrapper.push(this.$router, 'root.authenticated.root');
      }).catch((err: any) => {
        this.credentialsError = true;
        if (err.response.status !== 401) {
          routerWrapper.push(this.$router, 'root.maintenanceError');
        }
        function getReason(err: any) {
          if (err.status === 401) {
            return 'bad this.credentials';
          } else if (err.status === 500) {
            return 'server error';
          } else if (err.status > 500 && err.status < 600) {
            return 'proxy error';
          } else {
            return 'unknown';
          }
        }
        this.$amplitudeService.sendEvent('signin_email_error', {
          reason: getReason(err),
          status_code: err.status
        });
      });
    }

    private hasErrors(): any {
      return this.inputErrors || this.credentialsError;
    }

    private missingFields(): any {
      return false;
    }

    private emailError(): any {
      return false;
    }

    private emailFocus(): any {
      if (!this.emailFocused) {
        this.emailFocused = true;
        this.$amplitudeService.sendEvent('signin_email_start');
      }
    }

    private resetErrors(): any {
      this.inputErrors = false;
      this.credentialsError = false;
    }

}
