import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import BPromise from 'bluebird';

@Component({
  components: {
    Slider,
  },
})

export default class MaintenanceError extends Vue {

  private async mounted() {
    while (!await this.$configService.connected()) {
      await BPromise.delay(1000);
    }
    this.$router.replace({ name: 'root.authenticated.root', query: {} });
  }

}
