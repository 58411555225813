export default {
  api: {
    host: process.env.VUE_APP_API_ENDPOINT || 'localhost',
    oauthClientBasicAuthString: process.env.VUE_APP_CLIENT_AUTH_STRING || '',
  },
  geolocation: {
    serialId: process.env.VUE_APP_SERIAL_ID || '10.00',
    apiEndpoint: process.env.VUE_APP_API_HOST || 'localhost',
    clientBasicAuth: process.env.VUE_APP_CLIENT_AUTH_STRING || '',
  },
  maps: {
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
  info: {
    gitHash: process.env.VUE_APP_GIT_HASH || '0000',
    gitDate: process.env.VUE_APP_GIT_DATE || String(new Date()),
    dirtyBranch: process.env.VUE_APP_DIRTY_BRANCH === '1',
    apiEndpoint: process.env.VUE_APP_API_HOST || 'localhost',
    serialId: process.env.VUE_APP_SERIAL_ID,
  },
  sentry: {
    dsn: process.env.VUE_APP_SENTRY_DSN,
  },
  amplitude: {
    apiKey: process.env.VUE_APP_AMPLITUDE_API_KEY || '',
  },
  interface: {
    maxItemsOnCarousel: process.env.VUE_APP_MAX_ITEMS_ON_CAROUSEL,
    onlyReadyNodes: process.env.VUE_APP_ONLY_READY_NODES,
  },
  development: {
    cordovaMock: process.env.VUE_APP_CORDOVA_MOCK,
    mockedDevId: process.env.VUE_APP_MOCKED_DEVID,
  },
};
