import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MainButton extends Vue {
  @Prop() private label!: string;
  @Prop() private disabled!: boolean;
  @Prop() private doubleButton!: boolean;
  @Prop() private icon!: any;

  private get isRedIcon() {
    return (this.icon === 'icon-red');
  }

  private get isQrScanIcon() {
    return (this.icon === 'icon-qr-scan');
  }
}
