import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from 'lodash';
import window from '@/services/window';
import ScheduleEditSlotModal from '@/components/modals/scheduleEditSlot/scheduleEditSlot.vue';
import CopyDayModal from '@/components/modals/copyDay/copyDay.vue';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
    ScheduleEditSlotModal,
    CopyDayModal,
  },
})
export default class ScheduleDay extends Vue {

    private copyDays: any = {};
    private nodeSet: any = {};
    private debouncedSaveProg: any = {};
    private showScheduleEditSlotModal = false;
    private dayProg: any = {};
    private showCopyDayModal = false;
    private rawDayProg: any = {};
    private rawProg: any = {};
    private dayString: any = {};
    private hours: any = {};
    private creatingNewSlot = false;
    private showScheduleEditSlot = false;
    private showCopyDay = false;

    private mounted() {
      this.copyDays = [];
      this.nodeSet = (window as any).nodeSet;
      this.debouncedSaveProg = _.debounce(this.saveProg, 5000);

      if (!(window as any).nodeSet) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      if (!(window as any).connected) {
        return this.$router.replace({ name: 'root.authenticated.root.device.list.heating', query: {} });
      }
      this.updateThmData();
      this.initData();
    }

    private destroyed() {
      this.debouncedSaveProg.flush();
    }



    private get day() {
      return this.$route.params.day;
    }





    private editSlot(slot?: any): any {
      rootScope.editSlot = slot || {};
      rootScope.editSlot.tempSetup = this.getTempSetup();
      this.creatingNewSlot = false;
      this.showScheduleEditSlotModal = true;
    }

    private newSlot(startTime?: any): any {
      startTime = !isNaN(startTime) ? Number(startTime) : 0;
      const tempSetup: any = this.getTempSetup();
      const slot: any = {
        temperature: tempSetup.units === 'F' ? '68.0' : '20.0',
        start: startTime,
        end: startTime + 60
      };
      rootScope.editSlot = slot;
      rootScope.editSlot.tempSetup = tempSetup;
      this.creatingNewSlot = true;
      this.showScheduleEditSlotModal = true;
    }

    private removeSlot(slotIndex?: any): any {
      if (!this.rawDayProg || this.rawDayProg.length < 2) {
        return;
      }
      if (slotIndex < this.rawDayProg.length - 1) {
        this.rawDayProg.splice(slotIndex, 1);
        this.dayProg = this.convertToDayProg();
      } else {
        this.rawDayProg[slotIndex - 1].end = 1440;
        this.rawDayProg.splice(-1);
        this.dayProg = this.convertToDayProg();
      }
      this.cleanDayProg();
      this.saveProg();
    }

    private openCopyDayDialog(): any {
            this.showCopyDayModal = true;
    }


    private convertToDayProg(): any {
      const dayProg: any = this.rawDayProg.concat([]);
      for (let j: any = 0; j < dayProg.length; j++) {
        dayProg[j].id = this.rawDayProg[j].id;
        dayProg[j].start = this.rawDayProg[j].start;
        dayProg[j].end = this.rawDayProg[j].end;
        dayProg[j].temperature = this.rawDayProg[j].temperature;
        dayProg[j].duration = this.rawDayProg[j].end - this.rawDayProg[j].start;
      }
      return dayProg;
    }

    private removeDurationField(rawProg?: any): any {
      const result: any = _.cloneDeep(rawProg);
      _.each(_.keys(result), (key: any) => {
        _.each(result['' + key], (slot: any) => {
          delete slot.duration;
        });
      });
      return result;
    }

    private saveProg(): any {
      this.$amplitudeService.sendEvent('saving_thermostat_schedule');
      this.rawDayProg = [];
      for (let i: any = 0; i < this.dayProg.length; i++) {
        this.rawDayProg[i] = {
          id: this.dayProg[i].id,
          start: this.dayProg[i].start,
          end: this.dayProg[i].end,
          temperature: this.dayProg[i].temperature
        };
      }
      this.rawProg['' + this.day] = this.rawDayProg;
      _.forEach(this.copyDays, (copyDay: any) => {
        this.rawProg['' + copyDay] = this.rawDayProg;
      });
      (window as any).nodeSet?.setSchedule(this.rawProg, 0);
      this.updateThmData();
    }

    private updateThmData(): any {
      this.rawProg = (window as any).nodeSet?.getFirstNodeSchedule();
      this.rawDayProg = this.rawProg['' + this.day];
      this.dayProg = this.convertToDayProg();
    }

    private onScheduleEditSlotModalClosed() {
      this.showScheduleEditSlotModal = false;
      this.creatingNewSlot ? this.onNewSlotCreated() : this.onSlotUpdated();
    }

    private onSlotUpdated(): any {
      if (!rootScope.editedSlot) {
        return;
      }
      if (rootScope.editedSlot.start === rootScope.editSlot.start && rootScope.editedSlot.end === rootScope.editSlot.end && rootScope.editedSlot.temperature === rootScope.editSlot.temperature) {
        return;
      }
      if (rootScope.editedSlot.start >= rootScope.editedSlot.end) {
        return;
      }
      if (rootScope.editedSlot.start < 0 || rootScope.editedSlot.end > 1440) {
        return;
      }
      const oldSlotIndex: any = this.getSlotIndex(rootScope.editSlot);
      if (typeof oldSlotIndex !== 'number') {
        return;
      }
      let newSlotIndex: any = this.getNewSlotIndex(rootScope.editedSlot);
      if (typeof newSlotIndex !== 'number') {
        if (rootScope.editedSlot.end === 1440 && rootScope.editedSlot.end === rootScope.editSlot.end) {
          newSlotIndex = this.dayProg.length - 1;
        } else {
          return;
        }
      }
      if (newSlotIndex === oldSlotIndex + 1 && oldSlotIndex !== 0) {
        newSlotIndex = oldSlotIndex;
      }
      if (oldSlotIndex !== newSlotIndex || newSlotIndex === this.dayProg.length - 1 && rootScope.editedSlot.end !== 1440) {
        this.insertSlot(newSlotIndex, rootScope.editedSlot);
      } else {
        this.updateSlot(oldSlotIndex, rootScope.editedSlot);
      }
      this.debouncedSaveProg();
    }

    private getSlotIndex(slot?: any): any {
      for (let i: any = 0; i < this.dayProg.length; i++) {
        if (this.dayProg[i].start === slot.start && this.dayProg[i].end === slot.end && this.dayProg[i].temperature === slot.temperature) {
          return i;
        }
      }
      return null;
    }

    private getNewSlotIndex(slot?: any): any {
      for (let i: any = 0; i < this.dayProg.length; i++) {
        if (slot.start <= this.dayProg[i].start) {
          return i;
        }
      }
      return this.dayProg.length < 9 ? this.dayProg.length : null;
    }

    private updateSlot(index?: any, newSlot?: any): any {
      if (index > 0) {
        this.dayProg[index - 1].end = (newSlot as any).start;
      }
      if (index < this.dayProg.length - 1) {
        this.dayProg[index + 1].start = (newSlot as any).end;
      }
      this.dayProg[index].temperature = newSlot.temperature;
      this.dayProg[index] = { ...this.dayProg[index] };
      this.cleanDayProg();
    }

    private insertSlot(index?: any, newSlot?: any): any {
      let slot: any = {};
      let oldTo;
      slot = { ...newSlot };
      this.dayProg.splice(index, 0, slot);
      if (index > 0) {
        oldTo = this.dayProg[index - 1].end;
        this.dayProg[index - 1].end = newSlot.start;
        this.dayProg[index - 1].duration = newSlot.start - this.dayProg[index - 1].start;
        if (oldTo > this.dayProg[index - 1].end) {
          const anotherSlot: any = {
            id: slot.id,
            temperature: this.dayProg[index - 1].temperature,
            start: slot.end,
            end: oldTo
          };
          this.dayProg.splice(index + 1, 0, anotherSlot);
        }
      }
      if (index < this.dayProg.length - 1) {
        this.dayProg[index + 1].start = newSlot.end;
      }
    }

    private cleanDayProg(): any {
      let i;
      for (i = 1; i < this.dayProg.length; i++) {
        this.dayProg[i].start = this.dayProg[i - 1].end;
      }
      for (i = 0; i < this.dayProg.length; i++) {
        this.dayProg[i].duration = this.dayProg[i].end - this.dayProg[i].start;
        if (this.dayProg[i].duration <= 0) {
          this.dayProg.splice(i, 1);
          return this.cleanDayProg();
        } else if (i < this.dayProg.length - 1 && this.dayProg[i].temperature === this.dayProg[i + 1].temperature) {
          this.dayProg[i + 1].start = this.dayProg[i].start;
          this.dayProg.splice(i, 1);
          return this.cleanDayProg();
        }
      }
    }

    private getTempSetup(): any {
      const modeParams: any = (window as any).nodeSet?.getFirstNodeModeParams((window as any).nodeSet?.getFirstNodeMode());
      const fahrenheit: any = modeParams.temperatureUnits === 'F';
      const tempLimits: any = (window as any).nodeSet?.getTempLimits();
      return {
        min: fahrenheit ? tempLimits.minFTemp : tempLimits.minCTemp,
        max: fahrenheit ? tempLimits.maxFTemp : tempLimits.maxCTemp,
        units: modeParams.temperatureUnits,
        step: modeParams.temperatureStep
      };
    }

    private onNewSlotCreated(): any {
      if (!rootScope.editedSlot) {
        return;
      }
      if (rootScope.editedSlot.start >= rootScope.editedSlot.end) {
        return;
      }
      if (rootScope.editedSlot.start < 0 || rootScope.editedSlot.end > 1440) {
        return;
      }
      const index: any = this.getNewSlotIndex(rootScope.editedSlot);
      if (typeof index !== 'number') {
        return;
      }
      this.insertSlot(index, rootScope.editedSlot);
      this.saveProg();
    }

    private onCopyDayModalClosed(): any {
      this.copyDays = rootScope.selectedDays.concat([]);
      this.debouncedSaveProg();
    }

    private getWeekDay(index?: any): any {
      switch (index) {
      case 0:
        return 'SUNDAY';
      case 1:
        return 'MONDAY';
      case 2:
        return 'TUESDAY';
      case 3:
        return 'WEDNESDAY';
      case 4:
        return 'THURSDAY';
      case 5:
        return 'FRIDAY';
      case 6:
        return 'SATURDAY';
      }
    }

    private initData(): any {
      let i;
      this.dayString = this.getWeekDay(this.day);
      this.hours = [];
      for (i = 0; i < 10; i++) {
        this.hours.push('0' + i);
      }
      for (i = 10; i < 24; i++) {
        this.hours.push('' + i);
      }
    }

}
