import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import { dateTimeHelpers } from '@/services/dateTimeHelpers';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import EditSlot from '@/components/editSlot/EditSlot.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
    EditSlot,
  },
})

export default class scheduleEditSlot extends Vue {

    private startTime: any = {};
    private endTime: any = {};
    private endTimeString = '';
    private temperatureList: any = {};
    private thmTempSetup: any = {};
    private temperatureListLength: any = {};
    private sliderPosition: any = {};
    private slickSettings: any = {};
    private slick: any = {};
    private startTimeString = '';

    private mounted() {
      this.startTime = rootScope.editSlot.start;
      this.endTime = rootScope.editSlot.end;
      this.startTimeString = new dateTimeHelpers().dayMinuteToTimeString(this.startTime);
      this.endTimeString = new dateTimeHelpers().dayMinuteToTimeString(this.endTime);
      if (this.endTimeString === '24:00') {
        this.endTimeString = '00:00';
      }
      this.initTemperatureSlider();
      this.initSliderPosition();
    }

    private tempDown(): any {
      const currentPos: any = this.sliderPosition;
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value - this.thmTempSetup.step;
      if (currentPos > 0 && this.temperatureList[currentPos].value < this.temperatureList[currentPos].initialValue) {
        this.temperatureList[currentPos - 1].value = this.temperatureList[currentPos].value;
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos - 1);
        this.sliderPosition --;
      } else if (this.temperatureList[currentPos].value < this.temperatureList[currentPos].min) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        return;
      }
    }

    private tempUp(): any {
      const currentPos = this.sliderPosition;
      this.temperatureList[currentPos].value = this.temperatureList[currentPos].value + this.thmTempSetup.step;
      if (this.temperatureList[currentPos].value.toFixed(1) === this.temperatureList[currentPos].max.toFixed(1)) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        (this.$refs.slick as any).goTo(currentPos + 1);
        this.sliderPosition ++;
      } else if (this.temperatureList[currentPos].value > this.temperatureList[currentPos].max) {
        this.temperatureList[currentPos].value = this.temperatureList[currentPos].initialValue;
        return;
      }
    }

    private confirm(): any {
      const currentPos: any = this.sliderPosition;
      const selectedTemp: any = this.temperatureList[currentPos].value;
      const startTimeString: any = this.startTimeString;
      const endTimeString: any = this.endTimeString;
      let updatedEndTime = endTimeString ? new dateTimeHelpers().timeStringToDayMinute(endTimeString) : rootScope.editSlot.end;
      if (updatedEndTime === 0) {
        updatedEndTime = 1440;
      }
      rootScope.editedSlot = {
        id: rootScope.editSlot.id || 'comfort',
        temperature: Number(selectedTemp),
        start: startTimeString ? new dateTimeHelpers().timeStringToDayMinute(startTimeString) : 0,
        end: updatedEndTime,
      };
      this.$emit('close');
    }

    private close(): any {
      rootScope.editedSlot = null;
      this.$emit('close');
    }

    private startTimeStringChange(data: any): any {
      this.startTimeString = data;
      if (this.endTimeString === '00:00') {
        return;
      }
      const startTime: any = new dateTimeHelpers().timeStringToDayMinute(this.startTimeString);
      const endTime: any = new dateTimeHelpers().timeStringToDayMinute(this.endTimeString);
      if (endTime < startTime) {
        this.endTimeString = new dateTimeHelpers().dayMinuteToTimeString(startTime + 60);
      }
    }

    private endTimeStringChange(data: any): any {
      this.endTimeString = data;
    }

    private initTemperatureSlider(): any {
      this.temperatureList = [];
      this.thmTempSetup = rootScope.editSlot.tempSetup;
      this.temperatureListLength = Math.ceil(this.thmTempSetup.max - this.thmTempSetup.min + 1);
      const minShownValue: any = Math.trunc(this.thmTempSetup.min);
      for (let i: any = 0; i < this.temperatureListLength; i++) {
        this.temperatureList.push({
          value: minShownValue + i,
          initialValue: minShownValue + i,
          min: i === 0 ? minShownValue : minShownValue + i - 1,
          max: i === this.temperatureListLength - 1 ? minShownValue + i : minShownValue + i + 1
        });
      }
    }

    private getSliderPosition(temp?: any): any {
      for (let i: any = 0; i < this.temperatureListLength - 1; i++) {
        if (temp < this.temperatureList[i + 1].initialValue) {
          return i;
        }
      }
      return this.temperatureListLength - 1;
    }

    private updateSliderPosition(): any {
      const rawTemp: string | undefined = rootScope.editSlot.temperature;
      const sTemp: string =  rawTemp !== undefined ? rawTemp : '20.0';
      const pos: any = this.getSliderPosition(parseInt(sTemp, 10));
      this.temperatureList[pos].value = Number(sTemp);
      (this.$refs.slick as any).goTo(pos);
      this.sliderPosition = pos;
    }

    private initSliderPosition(): any {
      const rawTemp: string | undefined = rootScope.editSlot.temperature;
      const sTemp: string =  rawTemp !== undefined ? rawTemp : '20.0';
      this.sliderPosition = this.getSliderPosition(parseInt(sTemp, 10));
      this.slickSettings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        swipeToSlide: true,
        method: {},
        initialSlide: this.sliderPosition,
        event: {
          beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
            this.temperatureList[oldPos].value = this.temperatureList[oldPos].initialValue;
            this.sliderPosition = newPos;
          },
          afterChange: (event: any, slick: any, newPos: any) => {
            this.sliderPosition = newPos;
          },
          init: (event: any, slick: any) => {
            this.slick = slick;
            this.updateSliderPosition();
          }
        }
      };
    }

}
