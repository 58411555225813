import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VoiceAssistantHelp extends Vue {
  @Prop() private title!: string;
  @Prop() private body!: string;

  private voiceAssistantInfoUri = "";
  private alexaInfoUri = "";
  private isHaverlandClient = false;
  private language!: string;

  private async mounted() {
    this.language = await this.$userService.getLanguage();
    this.voiceAssistantInfoUri = (await this.$configService.getConfig()).voiceAssistantInfoUri;
    this.alexaInfoUri = (await this.$configService.getConfig()).alexaInfoUri;
    this.isHaverlandClient = (await this.$configService.getConfig()).clientName === 'haverland';
    
    if (this.isHaverlandClient) {
      this.voiceAssistantInfoUri = this.voiceAssistantInfoUri.replace(/\/[a-z]{2}\//, `/${this.getLanguage()}/`);
      this.alexaInfoUri = this.alexaInfoUri.replace(/\/[a-z]{2}\//, `/${this.getLanguage()}/`);
    }
  }

  private getLanguage() {
    return this.voiceAssistantLanguageAvailable() ? this.language : 'en';
  }

  private voiceAssistantLanguageAvailable() {
    return this.language === 'en' ||
           this.language === 'es' ||
           this.language === 'pt' ||
           this.language === 'fr';
  }
}
