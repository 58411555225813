import { Component, Vue, Prop } from 'vue-property-decorator';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    MainButton,
  },
})
export default class deviceInstallationErrorStates extends Vue {
  @Prop() private pid!: any;
  @Prop() private homeId!: any;
  @Prop() private subtype!: any;

  private isWifiHeater: any = {};
  private isWifiSmartbox: any = {};
  private isEthernetSmartbox: any = {};
  private deviceType: any = {};
  private deviceHeaderString: any = {};
  private deviceErrorState1String = String(this.$t('DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED'));
  private deviceErrorState2String!: string;
  private deviceErrorState3String = String(this.$t('DEVICE_INSTALLATION_ERROR_NO_SERVER'));;
  private deviceErrorState4String = String(this.$t('DEVICE_INSTALLATION_ERROR_OK'));
  private errorHeaderString = '';
  private errorHelpString = '';
  private errorHelp2String = '';
  private errorData: any = {};
  private showErrorStates = true;
  private showDeviceDisconnected = false;
  private showDeviceInstallationError = false;

  private mounted() {
    this.deviceType = this.getProductName();
    this.isWifiHeater = this.deviceType === 'wifiHtr';
    this.isWifiSmartbox = this.deviceType === 'wifi';
    this.isEthernetSmartbox = this.deviceType === 'ethernet';
    this.showErrorStates = !this.isWifiHeater;
    this.showDeviceDisconnected = this.isWifiHeater;
    this.updateString();
  }

  private getProductName(): any {
    return this.$devListService.getDevProductName(this.pid);
  }

  private updateString(): any {
    switch(this.deviceType) {
      case 'wifiHtr':
        this.deviceHeaderString = String(this.$t('DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO'));
        this.deviceErrorState2String = String(this.$t('DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED'));
        break;
      case 'wifi':
        this.deviceHeaderString = String(this.$t('DEVICE_WIFI_INSTALLATION_ERROR_INFO'));
        this.deviceErrorState2String = String(this.$t('DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED'));
        break;
      case 'ethernet':
      default:
        this.deviceHeaderString = String(this.$t('DEVICE_ETHERNET_INSTALLATION_ERROR_INFO'));
        this.deviceErrorState2String = String(this.$t('DEVICE_INSTALLATION_ERROR_NO_IP'));
        this.deviceErrorState3String = String(this.$t('DEVICE_INSTALLATION_ERROR_NO_SERVER'));
        break;
    }
  }

  private goToMoreInfo(data: string) {
    switch(data) {
      case 'state1':
        this.errorData = 'notConfigured';
        break;
      case 'state2':
        this.errorData = this.deviceType === 'ethernet' ? 'noIp' : 'notConnected';
        break;
      case 'state3':
        this.errorData = 'noServer';
        break;
      case 'state4':
        this.errorData = 'ok';
        break;
      default:
        break;
    }
    this.onDevInstallationErrorStateModalClose();
  }

  private onDevInstallationErrorStateModalClose() {
    this.updateErrorHelpStrings();
    this.showErrorStates = false;
    if (this.errorData === 'notConfigured' || this.errorData === 'notConnected') {
      this.showDeviceDisconnected = true;
      setTimeout(() => {
        const linkElement = this.$el.getElementsByTagName('a')[0];
        const path = (linkElement.getAttribute('href') || '');
        const RouterLink = Vue.component('RouterLink');
        const routerLink = new RouterLink({ propsData: {to: path}, parent: this });
        routerLink.$mount(linkElement);
        routerLink.$el.innerHTML = linkElement.innerHTML;
      });
    } else {
      this.showDeviceInstallationError = true;
    }
  }

  private updateErrorHelpStrings() {
    switch(this.errorData) {
      case 'noIp':
        this.errorHeaderString = String(this.$t('DEVICE_INSTALLATION_ERROR_NO_IP'));
        this.errorHelpString = String(this.$t('DEVICE_ETHERNET_NO_IP_HELP_1'));
        this.errorHelp2String = String(this.$t('DEVICE_ETHERNET_NO_IP_HELP_2'));
        break;
      case 'noServer':
        this.errorHeaderString = String(this.$t('DEVICE_INSTALLATION_ERROR_NO_SERVER'));
        this.errorHelpString = String(this.$t('DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP'));
        break;
      case 'ok':
        this.errorHeaderString = String(this.$t('DEVICE_INSTALLATION_ERROR_OK'));
        this.errorHelpString = String(this.$t('DEVICE_INSTALLATION_ERROR_OK_HELP'));
        break;
      default:
        break;
    }
  }

  private deviceDisconnectedHelp3String(): any {
    const translatedString = String(this.$t('DEVICE_DISCONNECTED_HELP_3'));
    return translatedString.replace(/\{\{link\}\}/, `/homes/${this.homeId}/addDevice/wifi/wifi/setAPData?type=${this.getProductName()}&subtype=${this.subtype}`);
  }

  private close(accepted: boolean): any {
    this.$emit('close', accepted);
  }
}
