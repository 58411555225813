import { Component, Vue } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import _ from '@/services/_';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import ConfirmMaxPowerProfileRemovalModal from '@/components/modals/confirmMaxPowerProfileRemoval/confirmMaxPowerProfileRemoval.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    ConfirmMaxPowerProfileRemovalModal,
    ModalTitle,
    MainButton,
    CancelButton,
    TitleText,
  },
})

export default class htrSystemSetup extends Vue {

  private mainCircuitPmos: any = [];
  private deviceId = '';
  private acmList: any = [];
  private htrList: any = [];
  private htrModList: any = [];
  private showPowerLimitConfig = false;
  private showConfirmMaxPowerProfileRemovalModal = false;
  private maxPowerProfiles: any = [];
  private pmoConsumptionList: any = [];
  private pmoGenerationList: any = [];
  private selectedConsumptionPmo: any = {};
  private selectedGenerationPmo: any = {};
  private powerLimitMinValue = 0;
  private powerLimitMaxValue = 0;
  private powerLimit: string | null = '';
  private unlimited = false;
  private maxPowerSlots: any = [];
  private powerLevelLimitLabelText = '';
  private nodeGroup: any = {};
  private homeId = '';
  private maxPowerProfileIndex = -1;

  private async mounted() {
    this.homeId = this.$route.params.homeid;
    this.mainCircuitPmos = [];
    this.deviceId = rootScope.devId || this.$devListService.getDevids(this.homeId)[0];
    (window as any).devId = this.deviceId;
    this.acmList = [];
    this.htrList = [];
    this.htrModList = [];
    this.showPowerLimitConfig = (await this.$configService.getConfig()).multiplePowerLimitFlag;
    this.maxPowerProfiles = [];
    this.pmoConsumptionList = [];
    this.pmoGenerationList = [];
    this.selectedConsumptionPmo = undefined;
    this.selectedGenerationPmo = undefined;
    this.powerLimitMinValue = 1000;
    this.powerLimitMaxValue = 23000;
    this.powerLimit = rootScope.currentDevHtrSystemPowerLimit || null;
    this.unlimited = rootScope.currentDevHtrSystemPowerLimit === 0;
    this.$devListService.getExtraHomeData(this.homeId).then((extraHomeData: any) => {
      this.maxPowerProfiles = extraHomeData.maxPowerProfiles || [];
      return this.$devListService.getDevProxy(this.homeId, this.deviceId);
    }).then((devProxy: any) => {
      this.mainCircuitPmos = devProxy.getMainCircuitPmos();
      this.maxPowerSlots = devProxy.getMaxPowerConfigSlots();
    });
    this.powerLevelLimitLabelText = `${this.$t('NUMBER_INPUT_LABEL_HELP_MSG')} ${this.powerLimitMinValue} ${this.$t('AND')} ${this.powerLimitMaxValue}`;
    this.updateList();
  }

  private get pmoList() {
    return [...this.pmoConsumptionList, ...this.pmoGenerationList];
  }

  private goToMaxPowerManagement(): any {
    this.$router.push({ name: 'root.authenticated.root.editHome.maxPowerManagement', query: {} });
    this.$emit('close');
  }

  private confirmMaxPowerRemoval(index?: any): any {
    this.maxPowerProfileIndex = index;
    this.showConfirmMaxPowerProfileRemovalModal = true;
  }

  private cancel(): any {
    this.$emit('close');
  }

  private confirm(): any {
    rootScope.currentDevHtrSystemPowerLimit = this.unlimited ? 0 : Number(this.powerLimit);
    this.updateMainCircuitPmo();
    this.$emit('close');
  }

  private isAnyHtrOrAcmInstalled() {
    return this.acmList.length !== 0 || this.htrList.length !== 0 || this.htrModList.length !== 0;
  }

  private onLimitToggled(): any {
    if (!this.unlimited) {
      return;
    }
    this.powerLimit = null;
  }


  private updateList(): any {
    return this.$devListService.getDevClassifiedNodeList(this.homeId).then((classifiedNodeList: any) => {
      const nodeGroups: any = classifiedNodeList || [];
      this.nodeGroup = nodeGroups.find((group: any) => group.devId === this.deviceId);
      this.nodeGroup.htrList = _.filter(this.nodeGroup.nodeList, (node: any) => {
        return (node.nodeData || {}).type === 'htr';
      });
      this.nodeGroup.acmList = _.filter(this.nodeGroup.nodeList, (node: any) => {
        return (node.nodeData || {}).type === 'acm';
      });
      this.nodeGroup.pmoList = _.filter(this.nodeGroup.nodeList, (node: any) => {
        return (node.nodeData || {}).type === 'pmo';
      });
      this.nodeGroup.htrModList = _.filter(this.nodeGroup.nodeList, (node: any) => {
        return (node.nodeData || {}).type === 'htr_mod';
      });
    }).then(() => {
      this.updatePmoLists();
      this.updateAcmLists();
      this.updateHtrLists();
      this.updateHtrModLists();
    }).then(() => {
      this.getSelectedConsumptionPmo();
      this.getSelectedGenerationPmo();
    });
  }

  private updatePmoLists(): any {
    _.each(this.nodeGroup.pmoList, (pmo: any) => {
      if (((pmo.nodeData || {}).setup || {}).circuit_type === 0) {
        this.pmoConsumptionList.push(pmo);
      } else {
        this.pmoGenerationList.push(pmo);
      }
    });
  }

  private updateAcmLists(): any {
    _.each(this.nodeGroup.acmList, (acm: any) => {
      this.acmList.push(acm);
    });
  }

  private updateHtrLists(): any {
    _.each(this.nodeGroup.htrList, (htr: any) => {
      this.htrList.push(htr);
    });
  }

  private updateHtrModLists(): any {
    _.each(this.nodeGroup.htrModList, (htrMod: any) => {
      this.htrModList.push(htrMod);
    });
  }

  private getSelectedConsumptionPmo(): any {
    const index: any = _.findIndex(this.pmoConsumptionList, (pmo: any) => {
      return this.mainCircuitPmos.indexOf((pmo.nodeData || {}).addr) !== -1;
    });
    this.selectedConsumptionPmo = this.pmoConsumptionList[index];
  }

  private getSelectedGenerationPmo(): any {
    const index: any = _.findIndex(this.pmoGenerationList, (pmo: any) => {
      return this.mainCircuitPmos.indexOf((pmo.nodeData || {}).addr) !== -1;
    });
    this.selectedGenerationPmo = this.pmoGenerationList[index];
  }

  private removePreviousConsumptionPmoFromMainCircuit(): any {
    this.pmoConsumptionList.forEach((consumptionPmo: any) => {
      const index: any = this.mainCircuitPmos.indexOf((consumptionPmo.nodeData || {}).addr);
      if (index !== -1) {
        this.mainCircuitPmos.splice(index, 1);
      }
    });
  }

  private removePreviousGenerationPmoFromMainCircuit(): any {
    this.pmoGenerationList.forEach((generationPmo: any) => {
      const index: any = this.mainCircuitPmos.indexOf((generationPmo.nodeData || {}).addr);
      if (index !== -1) {
        this.mainCircuitPmos.splice(index, 1);
      }
    });
  }

  private updateMainCircuitPmo(): any {
    if (this.selectedConsumptionPmo !== undefined) {
      this.removePreviousConsumptionPmoFromMainCircuit();
      if (this.selectedConsumptionPmo !== null) {
        this.mainCircuitPmos.push((this.selectedConsumptionPmo.nodeData || {}).addr);
      }
    }
    if (this.selectedGenerationPmo !== undefined) {
      this.removePreviousGenerationPmoFromMainCircuit();
      if (this.selectedGenerationPmo !== null) {
        this.mainCircuitPmos.push((this.selectedGenerationPmo.nodeData || {}).addr);
      }
    }
    this.mainCircuitPmos = Array.from(new Set(this.mainCircuitPmos));
    this.$apiService.setPmoSystem(this.deviceId, { main_circuit_pmos: this.mainCircuitPmos });
  }

  private async onConfirmMaxPowerProfileRemovalModalClosed(index?: any): Promise<any> {
    this.showConfirmMaxPowerProfileRemovalModal = false;
    return Promise.resolve().then(() => {
      this.maxPowerProfiles.splice(index, 1);
      const slotsToRemove: any = [];
      let indexOffset: number;
      this.maxPowerSlots.forEach((powerSlot: any, slotIndex: any) => {
        if (powerSlot.i > index) {
          powerSlot.i--;
        } else if (powerSlot.i === index) {
          slotsToRemove.push(slotIndex);
        }
      });
      slotsToRemove.forEach((slotIndex: any) => {
        if (slotIndex === slotsToRemove[0]) {
          indexOffset = 0;
        }
        if (this.maxPowerSlots[slotIndex - indexOffset].m % 1440 === 0) {
          if (this.maxPowerProfiles.length > 1) {
            if (slotIndex - indexOffset + 1 < this.maxPowerSlots.length) {
              this.maxPowerSlots[slotIndex - indexOffset].i = this.maxPowerSlots[slotIndex - indexOffset + 1].i;
            } else {
              this.maxPowerSlots[slotIndex - indexOffset].i = this.maxPowerSlots[slotIndex - indexOffset - 1].i;
            }
          } else {
            this.maxPowerSlots[slotIndex - indexOffset].i = 0;
          }
        } else {
          this.maxPowerSlots.splice(slotIndex - indexOffset, 1);
          indexOffset++;
        }
      });
      if (this.maxPowerProfiles.length === 0) {
        this.maxPowerSlots = [];
      }
      const maxPowerConfig: any = {
        profiles: _.map(this.maxPowerProfiles, (data: any) => {
          return data.value;
        }),
        slots: this.maxPowerSlots
      };
      return Promise.all([
        this.$devListService.updateExtraData(this.homeId, 'maxPowerProfiles', this.maxPowerProfiles),
        this.$apiService.setPmoSystem((window as any).devId, { max_power_config: maxPowerConfig })
      ]);
    });
  }

}
