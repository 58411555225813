import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalTitle from '@/components/modalComponents/modalTitle/ModalTitle.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import CancelButton from '@/components/button/cancelButton/CancelButton.vue';

@Component({
  components: {
    Slider,
    ModalTitle,
    MainButton,
    CancelButton,
  },
})

export default class maxPowerSetup extends Vue {

    private configProfiles: any = [];
    private selectedConfigProfile: any = {};
    private ready = false;

    private async mounted() {
      return this.$configService.getMaxPowerConfig().then((config: any) => {
        this.configProfiles = config;
        this.selectedConfigProfile = config[0];
        this.ready = true;
      }).catch(() => {
        this.close();
      });
    }

    private close(): any {
      this.$emit('close');
    }

    private confirm(): any {
      this.$emit('close', this.selectedConfigProfile);
    }

}
