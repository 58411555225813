import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ToggableSetting extends Vue {
  @Prop() private disabled!: boolean;
  @Prop() private label!: string;
  @Prop() private value!: boolean;
  @Prop() private subtitle!: boolean;
  @Prop() private color!: string;
  @Prop() private fontSize!: string;
  @Prop() private activeIcon!: string;
  @Prop() private inactiveIcon!: string;
  @Prop() private helpIcon!: boolean;

  get checked() {
    return this.value;
  }

  set checked(value: boolean) {
    this.$emit('input', value);
  }

  private openHelpButton() {
    this.$emit('openHelp');
  }

  private get activeIconClass() {
    return `right toggle-icon ${this.activeIcon}`;
  }

  private get inactiveIconClass() {
    return `right toggle-icon ${this.inactiveIcon}`;
  }
}
