import { Store } from 'vuex';
import { NodeType, NodeData } from '@vdi-helki/helki-node-management';
import {
  GeoData, GuestUser, IApiService, DevTimeResp,
} from './api.service';
import { ISessionService } from './session.service';

export interface DevTime {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  weekDay: number;
}

export interface DevData {
  dev_id: string;
  type?: string;
  name: string;
  product_id: string;
  fw_version: string;
  serial_id: string;
  proxy?: IDevProxy;
  connected?: boolean;
  discovery?: any;
  away_status?: any;
  htr_system?: any;
  pmo_system?: any;
  nodes: any[];
}

export interface IDevProxy {
  getDevId(): string;
  setName(name: string): Promise<void>;
  setDiscovery(discovery: boolean, type?: NodeType, address?: string): Promise<void>;
  getDiscovery(): boolean;
  setGeoData(geoData: GeoData): Promise<void>;
  getGeoData(): Promise<GeoData>;
  setAwayStatus(away: boolean, enabled?: boolean): Promise<void>;
  getAwayStatus(): Promise<any>;
  getNodeList(): NodeData[];
  getNode(type: NodeType, addr: number): NodeData;
  getNodeByAddress(addr: number): NodeData;
  eraseNode(type: NodeType, addr: number, devId: string, purge?: boolean): Promise<void>;
  setHtrSystemPowerLimit(powerLimit: number): Promise<void>;
  getHtrSystemPowerLimit(): number | undefined;
  getGuests(): Promise<GuestUser[]>;
  getTime(): Promise<DevTime>;
  deleteDevice(): Promise<void>;
  transferToHome(newHomeId: string, newHomeName: string): Promise<void>;
  getMainCircuitPmos(): string[];
  getName(): string;
  getPid(): string;
  isWifi(): boolean;
}

export class DevProxy implements IDevProxy {
  constructor(
    private devData: DevData,
    private homeId: string,
    private sessionService: ISessionService,
    private apiService: IApiService,
    private store: Store<any>,
  ) {
  }

  public getName(): string {
    return this.devData.name;
  }

  public getPid(): string {
    return this.devData.product_id;
  }

  public getDevId(): string {
    return this.devData.dev_id;
  }

  public isWifi(): boolean {
    const pid = this.getPid();
    return pid === '0106' ||
           pid === '021D' ||
           pid === '0132' ||
           pid === '0B06' ||
           pid === '0433' ||
           pid === '0435' ||
           pid === '0A31' ||
           pid === '0A34' ||
           pid === '0A39' ||
           pid === '0838';
  }

  public getMainCircuitPmos() {
    return this.devData.pmo_system?.main_circuit_pmos || [];
  }


  public getMaxPowerConfigSlots() {
    return this.devData.pmo_system?.max_power_config?.slots || [];
  }

  public setPmoSystemMaxPowerConfig(maxPowerConfig: any) {
    return this.apiService.setPmoSystem(this.devData.dev_id, {
      ...this.devData.pmo_system,
      max_power_config: maxPowerConfig,
    });
  }

  public async setName(name: string): Promise<void> {
    this.devData.name = name;
    return this.apiService.setDevName(this.devData.dev_id, name)
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async setDiscovery(value: boolean, type?: NodeType, address?: string): Promise<void> {
    return this.apiService.setDiscovery(this.devData.dev_id, value, type, address);
  }

  public getDiscovery(): boolean {
    return this.store.state.homes[this.homeId].devs[this.devData.dev_id].discovery?.discovery === 'on';
  }

  public getHtrSystemPowerLimit(): number | undefined {
    if (!this.devData.htr_system) {
      return;
    }
    let powerLimitObj = this.devData?.htr_system?.power_limit;
    if (Object.keys(powerLimitObj).length === 0) {
      powerLimitObj = this.devData?.htr_system?.setup;
    }
    const powerLimit = powerLimitObj ? Number(powerLimitObj.power_limit) : 0;
    return powerLimit;
  }

  public async setGeoData(geoData: GeoData): Promise<void> {
    const currentGeoData = this.store.getters.getGeoData(this.devData.dev_id);
    const finalGeoData = { ...currentGeoData, ...geoData };
    return this.apiService.setGeoData(this.devData.dev_id, finalGeoData);
  }

  public async getGeoData(): Promise<GeoData> {
    return Promise.resolve()
    .then(() => this.store.getters.getGeoData(this.devData.dev_id));
  }

  public async getAwayStatus(): Promise<any> {
    return Boolean(this.devData.away_status?.away);
  }

  public async setAwayStatus(away: boolean, enabled?: boolean): Promise<void> {
    return this.apiService.setDevAwayStatus(this.devData.dev_id, away, enabled)
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public getNodeList(): NodeData[] {
    return this.store.getters.getNodeList(this.homeId, this.devData.dev_id);
  }

  public getNodeByAddress(addr: number): NodeData {
    const nodeList = this.store.getters.getNodeList(this.homeId, this.devData.dev_id);
    return nodeList.find((node: any) => node.addr === addr) || {};
  }

  public getNode(type: NodeType, addr: number): NodeData {
    const nodeList = this.store.getters.getNodeList(this.homeId, this.devData.dev_id);
    const foundNode = nodeList.find((node: any) => node.type === type && node.addr === addr);
    if (!foundNode) {
      throw new Error('Node not found');
    }
    return foundNode;
  }

  public eraseNode(type: NodeType, addr: number, devid: string, purge?: boolean): Promise<void> {
    return this.apiService.selectNode(this.devData.dev_id, addr, type, false)
    .then(() => {
      return this.apiService.eraseNode(this.devData.dev_id, addr, type, purge);
    });
  }

  public getTime(): Promise<DevTime> {
    return this.apiService.getTime(this.devData.dev_id)
    .then((devTimeData: DevTimeResp) => ({
      year: devTimeData.y,
      month: devTimeData.n,
      day: devTimeData.d,
      hour: devTimeData.h,
      minute: devTimeData.m,
      second: devTimeData.s,
      weekDay: devTimeData.w,
    }));
  }

  public async setHtrSystemPowerLimit(powerLimit: number): Promise<void> {
    return this.apiService.setHtrSystemPowerLimit(this.devData.dev_id, powerLimit);
  }

  public async getGuests(): Promise<GuestUser[]> {
    return this.apiService.getGuests(this.devData.dev_id);
  }

  public async deleteDevice(): Promise<void> {
    return this.apiService.deleteDevice(this.devData.dev_id);
  }

  public async transferToHome(homeId: string, homeName: string): Promise<void> {
    return this.apiService.updateDevGroup(this.devData.dev_id, { id: homeId, name: homeName });
  }
}
