import { Component, Vue}  from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    TitleText,
  },
})

export default class Version extends Vue {

  private displayApiHost: any = {};
  private versionClickCounter: any = {};
  private apiEndpoint: any = {};
  private serialId: any = {};
  private originalApiEndpoint: any = {};
  private frontendVersion: any = {};
  private backendVersion: any = {};

  private async mounted() {
    this.displayApiHost = !(await this.$configService.getConfig()).hideApiHost;
    this.versionClickCounter = 0;
    this.initData();
  }

  private onVersionClick() {
    if (this.versionClickCounter === 6) {
      this.displayApiHost = true;
      this.apiEndpoint = this.originalApiEndpoint;
      return;
    }
    this.versionClickCounter++;
  }

  private close(): any {
    this.$emit('close');
  }

  private transformData(data: string) {
    const dataString = String(data);
    return (dataString.indexOf(" ") !== -1) ? dataString.substring(0, dataString.indexOf(" ")) : dataString;
  }

  private async initData(): Promise<any> {
    this.serialId = (await this.$configService.getConfig()).serialId;
    this.originalApiEndpoint = (await this.$configService.getConfig()).apiEndpoint;
    this.apiEndpoint = (await this.$configService.getConfig()).apiEndpoint.replace(/helki/g, 'xxxxxx');
    new Promise((resolve) => resolve(this.$sessionService.getFrontendVersion())).then((frontendVersion: any) => {
      this.frontendVersion = frontendVersion;
    });
    this.$apiService.getVersion().then((backendVersion: any) => {
      this.backendVersion = backendVersion.major + '.' + backendVersion.minor + '.' + backendVersion.subminor + '-' + backendVersion.commit;
    });
  }

}
