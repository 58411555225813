import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    MainButton,
    TitleText,
  },
})

export default class SetName extends Vue {

    private nameFocused: any = {};
    private name = '';

    private mounted() {
      this.nameFocused = false;
      this.$amplitudeService.sendEvent('add_home_start');
    }

    private nameFocus(): any {
      if (!this.nameFocused) {
        this.nameFocused = true;
        this.$amplitudeService.sendEvent('add_home_set_name_start');
      }
    }

    private next(): any {
      (window as any).homeName = this.name;
      this.$amplitudeService.sendEvent('add_home_set_name_end');
      routerWrapper.push(this.$router, 'root.authenticated.root.addHome.setTimezone', {
        homeName: this.name,
        newHome: 'true'
      });
    }


}
