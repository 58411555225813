import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import InfoHeader from '@/components/infoHeader/InfoHeader.vue';

@Component({
  components: {
    Slider,
    InfoHeader,
  },
})

export default class AddDevice extends Vue {

}
