import { Component, Vue } from 'vue-property-decorator';
import { devWifiConfig } from '@/services/devWifiConfig';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    TitleText,
  },
})
export default class ESPProvisioning extends Vue {

    private wifiCredentials: any = {};
    private onEditDevice: any = {};
    private progressTimeout: any = {};
    private pid: any = {};
    private state: any = {};
    private networkList: any = {};
    private selectedAP: any = {};
    private password: any = {};
    private showPassword: any = {};
    private secretPass: any = {};
    private manualLabel: any = {};
    private type: any = {};
    private progressBar: any = {};
    private encryptedPass: any = {};
    private devType: any = 'wifiHtr';

    private mounted() {
      this.onEditDevice = this.$route.params.onEditDevice;
      this.progressBar;
      this.progressTimeout = 60000;
      this.pid = this.$devListService.getDevPID(this.$route.params.type);
      this.devType = this.$route.params.type.toUpperCase();
      this.state = 'loading';
      this.networkList = [];
      this.wifiCredentials = this.$route.params.wifiCredentials;
      this.selectedAP = this.wifiCredentials.essid;
      this.password = this.wifiCredentials .pass;
      this.showPassword = false;
      this.secretPass = false;
      this.manualLabel = '';
      this.type = this.$route.params.type;

      setTimeout(() => {
        this.createProgressBar(this.progressTimeout);
        this.progressBar.animate(1);
        this.startProvisioning();
      });
    }

    private startProvisioning(): any {
      return this.ESPProvisioning().then(async () => {
        return this.onSuccess();
      }).catch(() => {
        routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.connectionInfo', this.$route.params);
      });
    }

    private createProgressBar(progressTimeout: any): any {
      const item: any = (document as any).getElementById('progress-bar-container');
      this.progressBar = new (window as any).ProgressBar.Circle(item, {
        color: '#54a7f7',
        strokeWidth: 4,
        trailWidth: 1,
        easing: 'linear',
        duration: progressTimeout,
        text: { autoStyleContainer: false },
        from: {
          color: '#F9B521',
          width: 1
        },
        to: {
          color: '#54a7f7',
          width: 4
        },
        step: (state: any, circle: any) => {
          circle.path.setAttribute('stroke', state.color);
          circle.path.setAttribute('stroke-width', state.width);
          const value: any = Math.round(circle.value() * 100);
          if (value === 0) {
            circle.setText('');
          } else {
            circle.setText(value + ' %');
          }
        }
      });
    }

    private async completeProgressBar(): Promise<any> {
      return new Promise((resolve: any) => {
        const progressBarCompletionDelay: any = 750;
        this.progressBar.stop();
        const currentProgress: any = this.progressBar.value();
        this.progressBar.destroy();
        this.createProgressBar(progressBarCompletionDelay);
        this.progressBar.set(currentProgress);
        this.progressBar.animate(1);
        setTimeout(() => {
          resolve();
        }, 1.25 * progressBarCompletionDelay);
      });
    }

    private checkWifiHeaterConnection(): any {
      return this.$devListService.connectToSmartboxDiscovery()
      .then(async (devId: any) => {
        if (!devId) {
          this.$amplitudeService.sendEvent('device_wifi_provisioning_esp_touch_timeout');
          throw new Error('Device not connected');
        }
        (window as any).devId = (window as any).devId;
      });
    }

    private getSetNameRoute(): any {
      const productName: any = this.$devListService.getDevProductName(this.pid);
      return 'root.authenticated.root.addDevice.' + productName + '.setName';
    }

    private async onSuccess(): Promise<any> {
      return Promise.resolve().then(async () => {
        this.$amplitudeService.sendEvent('device_wifi_provisioning_esp_touch_success');
        return this.completeProgressBar();
      }).then(async () => {
        if (this.onEditDevice) {
          routerWrapper.push(this.$router, 'root.authenticated.root');
        } else {
          routerWrapper.push(this.$router, this.getSetNameRoute(), {
            ...this.$route.params,
            devid: (window as any).devId
          });
        }
      });
    }

    private async ESPProvisioning(): Promise<any> {
      return Promise.resolve().then(async () => {
        if (this.type === 'wifi') {
          throw new Error('ESP not supported on 0106 devices');
        }
        this.progressBar.animate(1);
        this.$amplitudeService.sendEvent('device_wifi_provisioning_esp_touch_start');
        this.state = 'configuring';
        return new devWifiConfig().storeWifiConfig(this.selectedAP, this.password);
      }).then(async () => {
        this.encryptedPass = this.wifiCredentials.encryptedPass;
        return new devWifiConfig().smartWifiConfig(this.selectedAP, this.encryptedPass);
      }).then(async () => {
        return this.checkWifiHeaterConnection();
      });
    }

}
