import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from '@/services/_';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import {
  NodeCapability,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
  },
})

export default class Schedule extends Vue {

    private nodeSet: any = {};
    private scheduleTemps: any = {};
    private highResolutionSchedule: any = {};
    private multipleResolutionAvailable = false;
    private prog: any = {};

    private mounted() {
      this.nodeSet = (window as any).nodeSet;
      this.multipleResolutionAvailable = this.nodeSet.getActiveCapabilities()
      .includes(NodeCapability.MULTIPLE_SCHEDULE_RESOLUTIONS);
      this.scheduleTemps = (window as any).nodeSet?.getFirstNodeScheduleTemperatures();
      
      if (!(window as any).nodeSet) {
        return this.$router.replace({ name: 'root.authenticated.root', query: {} });
      }
      if (!(window as any).connected) {
        return this.$router.replace({ name: 'root.authenticated.root.device.list.heating', query: {} });
      }
      this.updateHtrData();
      this.$userService.addListener(this.homeId, this.devListener);
    }

    private destroyed() {
      this.$userService.removeListener(this.homeId, this.devListener);
    }
  
    private get homeId() {
      return this.$route.params.homeid;
    }

    private get multi() {
      return this.$route.params.multi;
    }

    @Watch('homeId')
    @Watch('multi')
    private onRouteParamsChanged() {
      this.devListener();
    }
  
    private goToDay(index?: any): any {
      this.$userService.getLanguage().then(async (currentLanguage: any) => {
        let dayIndex;
        switch (currentLanguage) {
        case 'en':
          dayIndex = index;
          break;
        default:
          dayIndex = index + 1;
          if (dayIndex > 6) {
            dayIndex = 0;
          }
          break;
        }
        let params: any = {};
        params = { ...params };
        params.day = dayIndex;
        if ((window as any).nodeSet?.nodeControllers[0].nodeData.type === 'thm') {
          routerWrapper.push(this.$router, 'root.authenticated.root.device.thermostat.scheduleDay', params);
          return;
        }
        routerWrapper.push(this.$router, 'root.authenticated.root.device.heater.scheduleDay', params);
      });
    }
    
    private getSlotStyle(slot?: any): any {
      const index: any = this.highResolutionSchedule ? 1 : 2;
      return { 'height': (slot.end - slot.start) / index + 'px' };
    }
    
    private getSlotTemp(slot?: any): any {
      return this.scheduleTemps[this.getTemperatureIndex(slot)].value;
    }
    
    private isSettingAvailable(setting?: any): any {
      return this.$helkiNMService.isSettingAvailable((window as any).nodeSet, setting);
    }
    
    private updateScheduleResolution(): any {
      (window as any).nodeSet?.setScheduleMethod(this.highResolutionSchedule ? 0 : 1).then(async () => {
        this.updateHtrData();
      });
    }
    
  
    private convertProg(rawProg?: any): any {
      return this.$userService.getLanguage().then(async (currentLanguage: any) => {
        let order;
        const prog: any = [];
        let i;
        switch (currentLanguage) {
        case 'en':
          order = [
            0,
            1,
            2,
            3,
            4,
            5,
            6
          ];
          break;
        default:
          order = [
            1,
            2,
            3,
            4,
            5,
            6,
            0
          ];
          break;
        }
        for (i = 0; i < order.length; i++) {
          prog[i] = rawProg['' + order[i]];
        }
        return prog;
      });
    }
    
    private updateHtrData(): any {
      const progResolution: any = ((window as any).nodeSet?.getFirstNodeSettingParams('prog_resolution') || {}).value || 0;
      this.highResolutionSchedule = progResolution === 1 || 0;
      if (this.nodeSet.nodeControllers[0].nodeData.prog.prog[0].length === 48) {
          this.highResolutionSchedule = true;
      }
      const heaterProg: any = (window as any).nodeSet?.getFirstNodeSchedule();
      this.convertProg(heaterProg).then(async (convertedProg: any) => {
        this.prog = convertedProg;
      });
    }
    
    private getTemperatureIndex(slot?: any): any {
      return _.findIndex((window as any).nodeSet?.getFirstNodeScheduleTemperatures(), (scheduleTemp: any) => {
        return slot === scheduleTemp.id;
      });
    }
    
    private copyScheduleResolution(list?: any, scheduleResolution?: any): any {
      _.forEach(list, (data: any) => {
        this.$devListService.getNodeController(data.devid, data.addr).then(async (currentNodeController: any) => {
          return currentNodeController.setScheduleMethod(scheduleResolution ? 1 : 0);
        });
      });
    }
    
    private devListener(): any {
      this.updateHtrData();
    }
    
}
